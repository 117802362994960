import React from "react";
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ChooseSubscription from "./ChooseSubscription";
import {CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import useDeepCompareEffect from "use-deep-compare-effect";
import CircularProgress from "@material-ui/core/CircularProgress";
import Avatar from "@material-ui/core/Avatar";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import PaymentIntent from "../payment/PaymentIntent";


const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    dialogTitleText: {
        paddingLeft: '10px',
        fontSize: '0.9rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        },
    },
    pricingHeaderText: {
        fontSize: '20px',
        lineHeight: '1.1',
        marginBottom: '20px'
    },
    cardItemMargin: {
        marginTop: '10px',
        marginBottom: '10px'
    },
    dialogContent: {
        [theme.breakpoints.down('xs')]: {
            padding: '8px'
        }
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    dialog: {
        '& div.MuiPaper-root.MuiDialog-paper': {
            [theme.breakpoints.down('xs')]: {
                margin: '16px',
                width: 'calc(100% - 32px)',
                maxWidth: 'calc(100% - 32px)'
            }
        }
    },
    cardErrorText: {
        color: 'red',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    progressWrapper: {
        position: 'relative',
    }
}));

const ChooseSubscriptionModal = props => {

    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const {
        handleClose,
        createSubscription,
        isOpen,
        excludeTier,
        creditCards,
        loadCreditCards,
        emitError,
        reloadCrowdUsers,
    } = props;

    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedCard, setSelectedCard] = React.useState(creditCards.items.find(c => c.primary) || creditCards.items[0] || {});
    const [useExisting, setUseExisting] = React.useState(false);
    const [cardError, setCardError] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [subscriptionTier, setSubscriptionTier] = React.useState(0);

    useDeepCompareEffect(() => {
        if (creditCards.loaded && creditCards.items.length) {
            setSelectedCard(creditCards.items.find(c => c.primary) || creditCards.items[0] || {});
            setUseExisting(true);
        }
    }, [creditCards]);

    const successCallback = () => {
        reloadCrowdUsers();
        loadCreditCards();
        setLoading(false);
        handleClose();
    };

    const paymentFailedCallback = () => {
        setPaymentError(true);
        setLoading(false);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);

        if (useExisting) {
            return createSubscription(subscriptionTier, selectedCard.id, successCallback, paymentFailedCallback);
        } else {
            const cardElement = elements.getElement(CardNumberElement);
            if (!cardElement || !cardElement._complete || cardElement._invalid) {
                setCardError(true);
                return;
            }
            setLoading(true);
            return stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            }).then(({error, paymentMethod}) => {
                if (error)
                    throw Error();
                return createSubscription(subscriptionTier, paymentMethod.id, successCallback, paymentFailedCallback);
            }).catch(() => {
                setLoading(false);
                emitError("credit_card_error");
            });
        }
    };

    const handleExited = () => {
        setCardError(false);
        setPaymentError(false);
        setUseExisting(false);
        setActiveStep(0);
    };

    const tierSelected = tier => {
        setSubscriptionTier(tier);
        setActiveStep(1);
    };

    const goBack = () => {
        setActiveStep(0);
    };

    return <Dialog fullWidth
                   maxWidth='lg'
                   scroll='body'
                   className={classes.dialog}
                   open={isOpen}
                   onExited={handleExited}
                   onClose={handleClose}>
        <DialogTitle className={classes.dialogTitle} disableTypography>
            {activeStep === 0 && <>
                <Avatar className={classes.avatar}>
                    <LocalActivityIcon/>
                </Avatar>
                <Typography variant={'body1'} className={classes.dialogTitleText}>
                    {props.intl.formatMessage({id: 'choose_subscription'})}
                </Typography>
            </>}
            {activeStep === 1 && <>
                <Avatar className={classes.avatar}>
                    <CreditCardIcon/>
                </Avatar>
                <Typography variant={'body1'} className={classes.dialogTitleText}>
                    {props.intl.formatMessage({id: 'enter_payment_details'})}
                </Typography>
            </>}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            {activeStep === 0 && <ChooseSubscription displayFreeSubscription={false}
                                                     buttonClickHandler={tierSelected}
                                                     excludeTier={excludeTier}
                                                     removeContainerSpacing/>}
            {activeStep === 1 && <PaymentIntent creditCards={creditCards}
                                                selectedCard={selectedCard}
                                                setSelectedCard={setSelectedCard}
                                                useExisting={useExisting}
                                                setUseExisting={setUseExisting}
                                                setCardError={setCardError}
                                                cardError={cardError}
                                                paymentError={paymentError}/>}
        </DialogContent>
        <DialogActions>
            <Grid container
                  justify="flex-end"
                  alignItems="center"
                  spacing={1}>
                {activeStep === 1 && <>
                    <Grid item xs={12} sm='auto'>
                        <Button color='primary' variant="contained" className={classes.button}
                                onClick={goBack} disabled={loading}>
                            {props.intl.formatMessage({id: 'back'})}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm='auto'>
                        <div className={classes.progressWrapper}>
                            <Button color='primary' variant="contained" className={classes.button}
                                    onClick={handleSubmit} disabled={loading}>
                                {props.intl.formatMessage({id: 'submit'})}
                                {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                            </Button>
                        </div>
                    </Grid>
                </>}
                <Grid item xs={12} sm='auto'>
                    <Button color='primary' variant="contained" className={classes.button}
                            onClick={handleClose} disabled={loading}>
                        {props.intl.formatMessage({id: 'close'})}
                    </Button>
                </Grid>
            </Grid>
        </DialogActions>
    </Dialog>
};

export default injectIntl(ChooseSubscriptionModal);