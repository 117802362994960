import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {Button} from "@material-ui/core";
import {GoogleIcon} from "./GoogleIcon";
import FacebookIcon from '@material-ui/icons/Facebook';
import {GOOGLE_LOGIN_CLIENT_ID, FACEBOOK_LOGIN_APP_ID} from "../../constants";

const useStyles = makeStyles(theme => ({
    socialLoginButton: {
        maxWidth: '45%',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90%',
        }
    }
}));


const OAuthLogin = props => {

    const classes = useStyles();
    const {googleLogin, facebookLogin} = props;

    return <>
        <Grid item xs={12} sm={6} className={classes.socialLoginButton}>
            <GoogleLogin
                clientId={GOOGLE_LOGIN_CLIENT_ID}
                onSuccess={googleLogin}
                onFailure={googleLogin}
                render={renderProps =>
                    (<Button
                        startIcon={<GoogleIcon/>}
                        onClick={renderProps.onClick}
                        variant="contained"
                        color="primary"
                        style={{color: 'white'}}
                        fullWidth>Google
                    </Button>)
                }
            />
        </Grid>
        <Grid item xs={12} sm={6} className={classes.socialLoginButton}>
            <FacebookLogin
                appId={FACEBOOK_LOGIN_APP_ID}
                // autoLoad={true}
                fields="name,email"
                callback={facebookLogin}
                render={renderProps =>
                    (<Button
                        startIcon={<FacebookIcon/>}
                        onClick={renderProps.onClick}
                        variant="contained"
                        color="primary"
                        style={{color: 'white'}}
                        fullWidth>Facebook
                    </Button>)
                }
            />
        </Grid>
    </>
};

export default OAuthLogin;