import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import CardActions from "@material-ui/core/CardActions";
import {injectIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import FavoriteIcon from '@material-ui/icons/Favorite';
import Typography from "@material-ui/core/Typography";
import SvgIcon from "@material-ui/core/SvgIcon";
import {API_URL} from "../../../constants";
import {formatDate, shortenString} from "../../../utils/utils";

const useStyles = makeStyles(theme => ({
    card: {
        width: '340px',
        cursor: 'pointer'
    },
    defaultImage: {
        textAlign: 'center',
        backgroundColor: '#fafafa',
        color: '#f1f1f1',
        height: '230px'
    },
    defaultCrowdImage: {
        textAlign: 'center',
        backgroundColor: 'black',
        height: '230px'
    },
    likeCount: {
        fontSize: '24px',
        display: 'flex',
        alignItems: 'center',
        marginRight: '8px'
    },
    commentCount: {
        fontSize: '24px',
        display: 'flex',
        alignItems: 'center'
    }
}));

const CrowdPreview = props => {

    const classes = useStyles();
    const {crowd} = props;

    const clickHandler = () => {
        if (crowd.link)
            window.location.href = crowd.link;
    };

    const imageToRender = () => {
        if (crowd.featuredImageId)
            return <CardMedia style={{height: '230px'}} image={`${API_URL}/file?fileId=${crowd.featuredImageId}`}/>;
        else if (crowd.defaultCrowd)
            return <div className={classes.defaultCrowdImage}>
                <img style={{height: '230px'}} src="/images/logo384.png"/>
            </div>;
        else
            return <div className={classes.defaultImage}>
                <SvgIcon style={{height: '230px', width: '230px'}}>
                    <svg aria-hidden="true" focusable="false"
                         role="img" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 512 512">
                        <path
                            d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z"/>
                        <path
                            d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z"/>
                    </svg>
                </SvgIcon>
            </div>;
    };

    return <>
        <Card elevation={3} className={classes.card} onClick={clickHandler}>
            {imageToRender()}
            <CardContent style={{height: '140px'}}>
                <Typography gutterBottom variant="h5">
                    {shortenString(crowd.title, 24)}
                </Typography>
                <Typography gutterBottom variant="body2" color="textSecondary">
                    {shortenString(crowd.description, 120)}
                </Typography>
            </CardContent>
            <CardContent>
                <Typography component='span' style={{height: '24px'}}>
                    {props.intl.formatMessage({id: 'created_on'})} {formatDate(crowd.createdAt)}
                </Typography>
            </CardContent>
            <CardActions>
                <span style={{flexGrow: 1}}/>
                <img style={{height: '24px'}} src="/images/lightbulb.png"/>
                <Typography component='span' color='primary' className={classes.commentCount}>
                    {crowd.ideaCount}
                </Typography>
                <FavoriteIcon color='primary'/>
                <Typography component='span' color='primary' className={classes.likeCount}>
                    {crowd.likeSum}
                </Typography>
            </CardActions>
        </Card>
    </>
};
export default injectIntl(CrowdPreview);
