import React, {useEffect} from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Spinner from "../common/Spinner";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import IconButton from "@material-ui/core/IconButton";
import AddBoxIcon from '@material-ui/icons/AddBox';
import CreditCardView from "./CreditCardView";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        marginRight: '16px'
    },
    unread: {
        backgroundColor: lighten(theme.palette.secondary.main, 0.85),
    },
    highlight: {
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
    },
    header: {
        display: 'flex',
        padding: '16px',
        alignItems: 'center'
    }
}));


const CreditCards = props => {

    const classes = useStyles();
    const {intl, creditCards, loadCreditCards, deleteCreditCard} = props;

    useEffect(() => {
        if (!creditCards.loaded)
            loadCreditCards();
    }, []);

    if (!creditCards.loaded)
        return <Spinner/>;

    const handleDelete = card => () => {
        deleteCreditCard(card, loadCreditCards);
    };

    const renderButton = () => {
        if (window.innerWidth < 450)
            return <IconButton color='primary'>
                <AddBoxIcon fontSize="large"/>
            </IconButton>;
        else
            return <Button color='primary' variant="contained" style={{color: 'white'}} startIcon={<AddBoxIcon/>}>
                {props.intl.formatMessage({id: 'add_new_credit_card'})}
            </Button>;
    };

    const cardsList = <Grid
        container
        spacing={1}>
        {creditCards.items.map(card =>
            <Grid item xs={12}>
                <CreditCardView singleCard={creditCards.items.length === 1}
                                key={card.id}
                                card={card}
                                onDelete={handleDelete(card)}/>
            </Grid>)}
    </Grid>;

    const noCardsYet = <Grid
        container
        justify="center"
        alignItems="center"
        spacing={2}
        style={{minHeight: '65vh'}}>
        <Grid item xs/>
        <Grid item xs={12}>
            <Typography align='center'>
                {intl.formatMessage({id: 'no_credit_cards_yet'})}
            </Typography>
        </Grid>
        <Grid item xs/>
    </Grid>;

    return <Card elevation={3} style={{minHeight: '65vh'}}>
        <div className={clsx(classes.header, 'cardHeader')}>
            <Avatar className={classes.avatar}>
                <CreditCardIcon/>
            </Avatar>
            <Typography className={'cardHeaderText'} component='div' style={{flex: '1 1 auto'}}>
                {intl.formatMessage({id: 'credit_cards'})}
            </Typography>
            {/*{renderButton()}*/}
        </div>
        <CardContent className={'cardContent'}>
            {creditCards.items.length ? cardsList : noCardsYet}
        </CardContent>
    </Card>
};

export default injectIntl(CreditCards);