import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import SelectLanguageSidebar from "../common/selectlanguage/SelectLanguageSidebar";
import {redirectToBlog} from "./LandingPage";

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: 240,
            flexShrink: 0
        },
    },
    drawerPaper: {
        width: 240,
        background: theme.palette.purple.main
    },
    toolbar: theme.mixins.toolbar,
    link: {
        textDecoration: 'none',
        color: 'white',
    },
    linkText: {
        fontSize: '23px'
    },
    menuItem: {
        color: 'white',
        fontSize: '28px'
    }
}));

const Sidebar = props => {

    const classes = useStyles();

    return <>
        <nav className={classes.drawer}>
            <Hidden lgUp>
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={props.open}
                    onClose={props.toggleDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <div onClick={props.closeDrawer} onKeyDown={props.closeDrawer} className={classes.toolbar}>
                        <ListItem button key={'menu'}>
                            <Typography className={classes.menuItem}>
                                {props.intl.formatMessage({id: 'menu'})}
                            </Typography>
                        </ListItem>
                        <Divider/>
                        <List>
                            <Link to='/'
                                  className={classes.link}>
                                <ListItem button key={'for_businesses'}>
                                    <Typography className={classes.linkText}>
                                        {props.intl.formatMessage({id: 'for_businesses'})}
                                    </Typography>
                                </ListItem>
                            </Link>
                            <Link to='/intro'
                                  className={classes.link}>
                                <ListItem button key={'for_public'}>
                                    <Typography className={classes.linkText}>
                                        {props.intl.formatMessage({id: 'for_public'})}
                                    </Typography>
                                </ListItem>
                            </Link>
                            {/*<ListItem button key={'blog'} onClick={redirectToBlog}>
                                <Typography className={classes.linkText}>
                                    {props.intl.formatMessage({id: 'blog'})}
                                </Typography>
                            </ListItem>*/}
                            {!props.user.loggedIn ? (<>
                                <Divider/>
                                <Link to='/login' className={classes.link}>
                                    <ListItem button key={'login'}>
                                        <Typography className={classes.linkText}>
                                            {props.intl.formatMessage({id: 'login'})}
                                        </Typography>
                                    </ListItem>
                                </Link>
                                <Divider/>
                            </>) : (<>
                                <Divider/>
                                <Link to='/crowds' className={classes.link}>
                                    <ListItem button key={'dashboard'}>
                                        <Typography className={classes.linkText}>
                                            {props.intl.formatMessage({id: 'dashboard'})}
                                        </Typography>
                                    </ListItem>
                                </Link>
                                <Divider/>
                                <Link to='/account' className={classes.link}>
                                    <ListItem button key={'my_account'}>
                                        <Typography className={classes.linkText}>
                                            {props.intl.formatMessage({id: 'my_account'})}
                                        </Typography>
                                    </ListItem>
                                </Link>
                                <Link to='' className={classes.link}>
                                    <ListItem button key={'logout'} onClick={props.logout}>
                                        <Typography className={classes.linkText}>
                                            {props.intl.formatMessage({id: 'logout'})}
                                        </Typography>
                                    </ListItem>
                                </Link>
                            </>)}
                            <SelectLanguageSidebar/>
                        </List>
                    </div>
                </Drawer>
            </Hidden>
        </nav>
    </>;
};

export default injectIntl(Sidebar);