import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";
import {userUpdated} from "./user";

export async function createCreditCard(body) {
    return async dispatch => {
        secureFetch(API_URL + "/creditCard",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => extractBodyOrThrow(response))
            .then(body => {
                const {creditCard, user} = body;
                if (user)
                    dispatch(userUpdated(user));
            })
            .catch(error => console.log(error.message));
    }
}

export function deleteCreditCard(card, successCallback) {
    return async dispatch => {
        return secureFetch(`${API_URL}/creditcard/${card.id}`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                if (card.primary) {
                    dispatch(emitAppNotification(NotificationType.SUCCESS, "credit_card_deleted_new_default"));
                }
                if (successCallback)
                    successCallback();
            })
            .catch(error => console.log(error.message));
    }
}

export function creditCardsLoaded(items) {
    return {
        type: ActionType.CREDIT_CARDS_LOADED,
        items
    };
}

export function loadCreditCards() {
    return async dispatch => {
        secureFetch(API_URL + "/creditcard",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(cards => dispatch(creditCardsLoaded(cards)))
            .catch(error => console.log(error.message));
    }
}

export function creditCardMadeDefault(id) {
    return {
        type: ActionType.CREDIT_CARD_MADE_DEFAULT,
        id
    };
}

export function makeCreditCardDefault(id) {
    return async dispatch => {
        secureFetch(`${API_URL}/creditcard/${id}`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => dispatch(creditCardMadeDefault(id)))
            .catch(error => console.log(error.message));
    };
}
