import socketIOClient from "socket.io-client";
import {ActionType} from "../constants";
import {store} from "../store/configureStore";

export function websocketConnect(userId) {
    return {
        type: ActionType.WEBSOCKET_CONNECT,
        socket: initSocket(userId)
    };
}

function initSocket(userId) {
    const socket = socketIOClient(`${process.env.REACT_APP_API_URL}?userId=${userId}`);

    socket.on("notification", data => {
        store.dispatch(notificationLoaded(data));
    });

    return socket;
}

export function websocketDisconnect() {
    return {
        type: ActionType.WEBSOCKET_DISCONNECT
    };
}

function notificationLoaded(notification) {
    return {
        type: ActionType.NOTIFICATION_LOADED,
        notification
    };
}