import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {injectIntl} from "react-intl";
import {compose} from "redux";
import {connect} from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import {lighten} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TablePaginationActions from "../common/TablePaginationActions";
import {useHistory} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import {getComparator, stableSort} from "../../utils/utils";
import IdeasTableHead from "./IdeasTableHead";
import DeleteIcon from '@material-ui/icons/Delete';
import capitalize from "@material-ui/core/utils/capitalize";
import {deleteIdea} from "../../actions/idea";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxHeight: '65vh'
    },
    paper: {
        boxShadow: '0 0 35px -2px #ccc',
    },
    highlight: {
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
    },
    icon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px',
        padding: '2px 2px'
    },
    iconTableCell: {
        paddingTop: 0,
        paddingBottom: 0
    },
    toolbarContent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    chipStyle: {
        color: 'white',
        backgroundColor: '#3D58FF',
        marginRight: '5px'
    },
    pagination: {
        '& div.MuiToolbar-root': {
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center'
            }
        },
        '& div.MuiTablePagination-spacer': {
            flex: 'none'
        }
    }
}));

const Ideas = props => {

    const classes = useStyles();
    const {ideas, crowds, deleteIdea} = props;
    const history = useHistory();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const displayedRowsLabel = ({from, to, count}) => {
        const of = props.intl.formatMessage({id: 'of'});
        return `${from}-${to === -1 ? count : to} ${of} ${count}`;
    };

    const getCrowdName = idea => {
        const crowd = crowds.items.find(c => c.id === idea.crowdId);
        return crowd ? crowd.title : ''
    };

    const getChips = idea => {
        const ideaTags = Object.keys(idea)
            .reduce((acc, key) => {
                if (['featured', 'rewarded', 'implemented'].includes(key) && idea[key])
                    acc.push(key);
                return acc;
            }, []);
        return ideaTags.map(flag => <Chip size="small"
                                          label={props.intl.formatMessage({id: flag})}
                                          className={classes.chipStyle}/>)
    };

    const handleDelete = idea => event => {
        event.stopPropagation();
        deleteIdea(idea.id, idea.crowdId)
    };

    const paginationActions = window.innerWidth > 600 ? {ActionsComponent: TablePaginationActions} : {ActionsComponent: 'span'};

    return (
        <Paper elevation={3} className={classes.paper}>
            <Toolbar className={classes.toolbarContent}>
                <Typography variant="h6">
                    {capitalize(props.intl.formatMessage({id: 'ideas'}))}
                </Typography>
            </Toolbar>
            <Grid container
                  direction="column"
                  justify="center"
                  style={{minHeight: '60vh'}}>
                {ideas.items.length ? (<>
                    <Grid item>
                        <TableContainer className={classes.tableContainer}>
                            <Table size={'medium'} stickyHeader>
                                <IdeasTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {(rowsPerPage > 0 ? stableSort(ideas.items, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : stableSort(ideas.items, getComparator(order, orderBy)))
                                        .map(idea => {
                                            return (
                                                <TableRow
                                                    className={classes.highlight}
                                                    onClick={() => history.push(`/ideas/${idea.id}`)}
                                                    tabIndex={-1}
                                                    key={idea.name}>
                                                    <TableCell align="left">{idea.name}</TableCell>
                                                    <Hidden smDown>
                                                        <TableCell align="left">{getCrowdName(idea)}</TableCell>
                                                        <TableCell align="left">{getChips(idea)}</TableCell>
                                                        <TableCell align="right">{idea.likeCount}</TableCell>
                                                        <TableCell align="right">{idea.commentCount}</TableCell>
                                                        <TableCell align="right" className={classes.iconTableCell}>
                                                            <IconButton onClick={handleDelete(idea)}>
                                                                <DeleteIcon className={classes.icon}/>
                                                            </IconButton>
                                                        </TableCell>
                                                    </Hidden>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs/>
                </>) : (<>
                        <Grid item>
                            <TableContainer className={classes.tableContainer}>
                                <Table size={'medium'} stickyHeader>
                                    <IdeasTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs/>
                        <Grid item>
                            <Typography variant="h6" align='center'>
                                {props.intl.formatMessage({id: 'no_ideas_yet'})}
                            </Typography>
                        </Grid>
                        <Grid item xs/>
                    </>
                )}
                <Grid item>
                    <TablePagination
                        className={classes.pagination}
                        component="div"
                        rowsPerPageOptions={[10, 25, 50, {
                            label: props.intl.formatMessage({id: 'all_rows'}),
                            value: -1
                        }]}
                        count={ideas.items.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        {...paginationActions}
                        labelRowsPerPage={props.intl.formatMessage({id: 'rows_per_page'})}
                        labelDisplayedRows={displayedRowsLabel}
                    />
                </Grid>
                <Hidden smUp>
                    <Grid item style={{textAlign: 'center'}}>
                        <TablePaginationActions count={ideas.items.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePage}/>
                    </Grid>
                </Hidden>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = state => {
    return {
        ideas: state.ideas,
        crowds: state.crowds,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        deleteIdea: (ideaId, crowdId) => dispatch(deleteIdea(ideaId, crowdId))
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(Ideas);