import React, {useEffect} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {injectIntl} from "react-intl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import {Link, Route, useHistory, useLocation, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import _ from 'lodash';
import Spinner from "../common/Spinner";
import {loadCategories} from "../../actions/categories";
import IdeaComments from "./IdeaComments";
import {changeIdeaState, loadIdeaStats, updateIdea} from "../../actions/idea";
import {createComment, deleteComment, loadComments, updateComment} from "../../actions/comments";
import IdeaStats from "./IdeaStats";
import {IdeaGeneralInfo} from "./IdeaGeneralInfo";


const useStyles = makeStyles(theme => ({
    tabTextColorPrimary: {
        color: theme.palette.primary.main
    }
}));

const ideaDefaults = {
    name: '',
    description: '',
    featured: false,
    rewarded: false,
    implemented: false,
    ownerId: '',
    crowdId: '',
    categoryId: '',
    fileIds: [],
    embeddableVideos: [],
    creatorName: '',
    creatorAvatarImageId: '',
    createdAt: '',
    commentCount: '',
    likeCount: '',
    milestoneReachedAt: ''
};

const IdeaDetail = props => {

    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const ideaId = parseInt(useParams().ideaId);
    const {
        ideas,
        updateIdea,
        createComment,
        deleteComment,
        categories,
        comments,
        loadComments,
        loadCategories,
        updateComment,
        changeIdeaState,
        stats,
        loadIdeaStats
    } = props;
    const ideaComments = comments[ideaId] || {items: []};
    const ideaStats = stats[ideaId] || {stats: {}};

    const findIdea = () => {
        const idea = ideas.items.find(i => i.id === ideaId);
        if (idea)
            return _.mergeWith({}, ideaDefaults, idea, (a, b) => b === null ? a : undefined);
        else if (ideas.loaded)
            history.push('/ideas');
        return ideaDefaults;
    };

    const idea = findIdea();
    const crowdId = idea.crowdId;
    const ideaCategories = categories[crowdId] || {items: []};

    useEffect(() => {
        if (crowdId === "") return;

        if (!ideaCategories.loaded)
            loadCategories(crowdId);
        if (!ideaComments.loaded)
            loadComments(crowdId, ideaId);
        if (!ideaStats.loaded)
            loadIdeaStats(crowdId, ideaId);
    }, [crowdId]);

    const preselectTab = () => {
        switch (location.pathname) {
            case `/ideas/${ideaId}`:
            case `/ideas/${ideaId}/detail`:
                return 0;
            case `/ideas/${ideaId}/comments`:
                return 1;
            case `/ideas/${ideaId}/stats`:
                return 2;
            default:
                return 0;
        }
    };

    const [value, setValue] = React.useState(preselectTab());

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (!ideas.loaded)
        return <Spinner/>;

    return <>
        <Paper elevation={3} style={{marginBottom: '10px'}}>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary"
                  variant='fullWidth'>
                <Tab className={clsx({[classes.tabTextColorPrimary]: value === 0})}
                     label={props.intl.formatMessage({id: 'idea_detail'})}
                     component={Link}
                     to={`/ideas/${ideaId}/detail`}
                />
                <Tab className={clsx({[classes.tabTextColorPrimary]: value === 1})}
                     label={props.intl.formatMessage({id: 'idea_comments'})}
                     component={Link}
                     to={`/ideas/${ideaId}/comments`}
                />
                <Tab className={clsx({[classes.tabTextColorPrimary]: value === 2})}
                     label={props.intl.formatMessage({id: 'idea_stats'})}
                     component={Link}
                     to={`/ideas/${ideaId}/stats`}
                />
            </Tabs>
        </Paper>
        <Route path={["/ideas/:ideaId", "/ideas/:ideaId/detail"]} exact
               render={() => <IdeaGeneralInfo idea={idea} categories={ideaCategories} updateIdea={updateIdea} changeIdeaState={changeIdeaState}/>}/>
        <Route path="/ideas/:ideaId/comments" exact
               render={() => <IdeaComments idea={idea} comments={ideaComments} loadComments={loadComments}
                                           createComment={createComment} deleteComment={deleteComment}
                                           updateComment={updateComment}/>}/>
        <Route path="/ideas/:ideaId/stats" exact
               render={() => <IdeaStats ideaStats={ideaStats} ideaId={ideaId} crowdId={crowdId}/>}/>
    </>
};

const mapStateToProps = state => {
    return {
        user: state.user,
        ideas: state.ideas,
        crowds: state.crowds,
        crowdUsers: state.crowdUsers,
        categories: state.categories,
        comments: state.comments,
        stats: state.ideaStats
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadCategories: crowdId => dispatch(loadCategories(crowdId)),
        updateIdea: (body, successCb) => dispatch(updateIdea(body, successCb)),
        loadComments: (crowdId, ideaId, cb, offset, limit) => dispatch(loadComments(crowdId, ideaId, cb, offset, limit)),
        createComment: (crowdId, ideaId, content, successCb) => dispatch(createComment(crowdId, ideaId, content, successCb)),
        updateComment: (crowdId, ideaId, body, successCb) => dispatch(updateComment(crowdId, ideaId, body, successCb)),
        deleteComment: (commentId, ideaId, crowdId) => dispatch(deleteComment(commentId, ideaId, crowdId)),
        changeIdeaState: body => dispatch(changeIdeaState(body)),
        loadIdeaStats: (crowdId, ideaId) => dispatch(loadIdeaStats(crowdId, ideaId))
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(IdeaDetail);