import {AllIdeaTags} from "../constants";
import DOMPurify from "dompurify";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

export const formatDate = (dateString, dateSeparator = '/') => {
    const date = new Date(dateString);
    return `${date.getDate()}${dateSeparator}${date.getMonth() + 1}${dateSeparator}${date.getFullYear()}`;
};

export const shortenString = (toShorten, maxChars) => {
    if (toShorten && toShorten.length > maxChars)
        return toShorten.substring(0, maxChars) + '...';
    return toShorten;
};

export const composeLoginUrl = (crowdView, from) => {
    const params = new URLSearchParams();
    if (from)
        params.append('redirectUrl', window.location.origin + from);
    else
        params.append('redirectUrl', window.location.href);

    if (crowdView && crowdView.loaded)
        params.append('crowdId', crowdView.data.id);

    return `${process.env.REACT_APP_LOGIN_URL}?${params.toString()}`;
};

export const determineIdeaTagColor = tag => {
    switch (tag) {
        case AllIdeaTags.FEATURED:
            return 'ideaTagOrange';
        case AllIdeaTags.IMPLEMENTED:
            return 'ideaTagIndigo';
        case AllIdeaTags.REWARDED:
            return 'ideaTagGreen';
        case AllIdeaTags.NEW_IDEA:
            return 'ideaTagBlack';
        case AllIdeaTags.MOST_COMMENTS:
            return 'ideaTagIndigo';
        case AllIdeaTags.MOST_LIKES:
            return 'ideaTagRed';
        default:
            return 'ideaTagIndigo';
    }
};

export const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
};

export const extractPreviewTextFromHtml = text => {
    const temporalDivElement = document.createElement("div");
    temporalDivElement.innerHTML = DOMPurify.sanitize(text);
    return Array.from(temporalDivElement.childNodes)
        .filter(element => element.tagName === 'P')
        .map(e => e.textContent)
        .join(' ');
};

export const runValidators = validators => {
    return Object.entries(validators)
        .reduce((acc, currentValue) => {
            const [key, validator] = currentValue;
            const output = validator();
            if (output !== true) {
                acc[key] = output;
            }
            return acc;
        }, {});
};

export const isObject = variable => Object.prototype.toString.call(variable) === '[object Object]';