import {combineReducers} from "redux";
import {ActionType, Locale} from "../constants"

// TODO break up into smaller files as in redux ducks

function locale(state = Locale.EN, action) {
    if (action.type === ActionType.CHANGE_LOCALE) {
        return action.locale;
    }
    return state
}

function user(state = {loaded: false, loggedIn: false}, action) {
    switch (action.type) {
        case ActionType.USER_UPDATED:
        case ActionType.USER_LOADED:
            return {
                ...state,
                loaded: true,
                loggedIn: action.data.id !== undefined,
                ...action.data
            };
        default:
            return state;
    }
}

function userRoles(state = {data: {}, loaded: false}, action) {
    switch (action.type) {
        case ActionType.USER_ROLES_LOADED:
            return {
                data: action.data,
                loaded: true
            };
        default:
            return state;
    }
}

function notificationSettings(state = {}, action) {
    switch (action.type) {
        case ActionType.NOTIFICATION_SETTINGS_LOADED:
            return action.settings;
        default:
            return state;
    }
}

function notifications(state = {
    items: [],
    loaded: false,
    allCount: 0,
    unreadCount: 0
}, action) {
    switch (action.type) {
        case ActionType.NOTIFICATIONS_LOADED:
            return {
                ...state,
                items: deduplicateAndSort([...action.notifications, ...state.items]),
                loaded: true,
                ...(action.allCount !== undefined && {allCount: action.allCount, unreadCount: action.unreadCount})
            };
        case ActionType.NOTIFICATION_LOADED:
            return {
                items: deduplicateAndSort([action.notification, ...state.items]),
                loaded: true,
                allCount: state.allCount + 1,
                unreadCount: state.unreadCount + 1
            };
        case ActionType.NOTIFICATIONS_READ:
            const now = new Date().toISOString();
            return {
                ...state,
                unreadCount: action.ids ? Math.max(state.unreadCount - action.ids.length, 0) : 0,
                items: state.items.map(n => {
                    if ((action.ids && action.ids.includes(n.id)) || (!action.ids && !n.readAt))
                        return {
                            ...n,
                            readAt: now
                        };
                    return n;
                })
            };
        default:
            return state;
    }
}

function deduplicateAndSort(arr) {
    return arr.reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []).sort(sortByDate);
}

function crowds(state = {items: [], loaded: false}, action) {
    switch (action.type) {
        case ActionType.CROWDS_LOADED:
            return {
                items: action.items,
                loaded: true
            };
        case ActionType.CROWD_LOADED:
            return {
                items: [...state.items, action.item],
                loaded: true
            };
        case ActionType.CROWD_UPDATED:
            const updatedItems = state.items.map(item => {
                if (item.id !== action.item.id) {
                    return item;
                }
                return {
                    ...item,
                    ...action.item
                }
            });
            return {
                ...state,
                items: updatedItems
            };
        case ActionType.THEMING_UPDATED:
            const updatedTheming = state.items.map(item => {
                if (item.id !== action.crowdId) {
                    return item;
                }
                return {
                    ...item,
                    themingData: action.item
                }
            });
            return {
                ...state,
                items: updatedTheming
            };
        default:
            return state;
    }
}

function ideas(state = {items: [], loaded: false}, action) {
    switch (action.type) {
        case ActionType.IDEAS_LOADED:
            return {
                items: action.items,
                loaded: true
            };
        case ActionType.IDEA_UPDATED:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.id !== action.item.id) {
                        return item
                    }
                    return {
                        ...item,
                        ...action.item
                    }
                })
            };
        case ActionType.IDEA_RATED:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.id !== action.ideaId) {
                        return item
                    }
                    return {
                        ...item,
                        rating: action.averageRating
                    }
                })
            };
        case ActionType.IDEA_DELETED:
            return {
                ...state,
                items: state.items.filter(idea => idea.id !== action.id)
            };
        case ActionType.COMMENT_LOADED:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.id !== action.ideaId) {
                        return item
                    }

                    return {
                        ...item,
                        commentCount: parseInt(item.commentCount) + 1
                    }
                })
            };
        case ActionType.COMMENT_DELETED:
            return {
                ...state,
                items: state.items.map(item => {
                    if (item.id !== action.ideaId) {
                        return item
                    }

                    return {
                        ...item,
                        commentCount: parseInt(item.commentCount) - 1
                    }
                })
            };
        default:
            return state;
    }
}

function ideaStats(state = {}, action) {
    const ideaId = action.ideaId;
    switch (action.type) {
        case ActionType.IDEA_STATS_LOADED:
            return {
                ...state,
                [ideaId]: {
                    stats: action.item,
                    loaded: true
                }
            };
        default:
            return state;
    }
}

function crowdView(state = {data: {}, loaded: false}, action) {
    if (action.type === ActionType.CROWD_VIEW_DATA_LOADED) {
        return {
            data: action.data,
            loaded: true
        };
    } else {
        return state;
    }
}

function crowdViewQuestionnaire(state = {loaded: false}, action) {
    if (action.type === ActionType.CROWD_VIEW_QUESTIONNAIRE_LOADED) {
        return {
            ...action.item,
            loaded: true
        };
    } else {
        return state;
    }
}

function crowdViewCategories(state = {data: [], loaded: false}, action) {
    if (action.type === ActionType.CROWD_VIEW_CATEGORIES_LOADED) {
        return {
            data: action.items,
            loaded: true
        };
    } else {
        return state;
    }
}

function crowdViewIdeasLikedCommented(state = {
    data: {ideasLikedIds: [], ideasCommentedOnIds: [], ideasAnsweredIds: []},
    loaded: false
}, action) {
    switch (action.type) {
        case ActionType.CROWD_VIEW_USER_IDEAS_LIKED_LOADED:
            return {
                data: action.items,
                loaded: true
            };
        case ActionType.CROWD_VIEW_USER_IDEA_LIKED:
            return {
                ...state,
                data: {
                    ...state.data,
                    ideasLikedIds: [...state.data.ideasLikedIds, action.ideaId],
                    ...(action.questionnaireId && {ideasAnsweredIds: [...state.data.ideasAnsweredIds, action.ideaId]})
                }
            };
        case ActionType.CROWD_VIEW_USER_IDEA_UNLIKED:
            return {
                ...state,
                data: {
                    ...state.data,
                    ideasLikedIds: state.data.ideasLikedIds.filter(id => id !== action.ideaId)
                }
            };
        case ActionType.COMMENT_LOADED:
            if (state.data.ideasCommentedOnIds.includes(action.ideaId))
                return state;

            return {
                ...state,
                data: {
                    ...state.data,
                    ideasCommentedOnIds: [...state.data.ideasCommentedOnIds, action.ideaId]
                }
            };
        case ActionType.IDEA_RATED:
            if (state.data.ideasRatedIds.includes(action.ideaId))
                return state;

            return {
                ...state,
                data: {
                    ...state.data,
                    ideasRatedIds: [...state.data.ideasCommentedOnIds, action.ideaId]
                }
            };
        default:
            return state;
    }
}

function crowdViewIdeas(state = {data: [], loaded: false}, action) {
    switch (action.type) {
        case ActionType.CROWD_VIEW_IDEAS_LOADED:
            return {
                data: action.items,
                loaded: true
            };
        case ActionType.CROWD_VIEW_IDEA_LOADED:
            return {
                data: [...state.data, action.item],
                loaded: true
            };
        case ActionType.CROWD_VIEW_IDEA_UPDATED:
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id !== action.item.id) {
                        return item
                    }
                    return {
                        ...item,
                        ...action.item
                    }
                })
            };
        case ActionType.IDEA_RATED:
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id !== action.ideaId) {
                        return item
                    }
                    return {
                        ...item,
                        rating: action.averageRating
                    }
                })
            };
        case ActionType.COMMENT_LOADED:
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id !== action.ideaId) {
                        return item
                    }

                    return {
                        ...item,
                        commentCount: parseInt(item.commentCount) + 1
                    }
                })
            };
        case ActionType.COMMENT_DELETED:
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id !== action.ideaId) {
                        return item
                    }

                    return {
                        ...item,
                        commentCount: parseInt(item.commentCount) - 1
                    }
                })
            };
        case ActionType.CROWD_VIEW_USER_IDEA_UNLIKED:
        case ActionType.CROWD_VIEW_USER_IDEA_LIKED:
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id !== action.ideaId) {
                        return item
                    }

                    return {
                        ...item,
                        likeCount: action.type === ActionType.CROWD_VIEW_USER_IDEA_LIKED ? parseInt(item.likeCount) + 1 : parseInt(item.likeCount) - 1
                    }
                })
            };
        case ActionType.IDEA_SHARED:
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id !== action.ideaId) {
                        return item
                    }

                    return {
                        ...item,
                        shareCount: parseInt(item.shareCount) + 1
                    }
                })
            };
        case ActionType.CROWD_VIEW_IDEA_VIEWED:
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id !== action.ideaId) {
                        return item
                    }

                    return {
                        ...item,
                        viewCount: parseInt(item.viewCount) + 1
                    }
                })
            };
        default:
            return state;
    }
}

function categories(state = {}, action) {
    const crowdId = action.crowdId;
    switch (action.type) {
        case ActionType.CATEGORIES_LOADED:
            return {
                ...state,
                [action.crowdId]: {
                    items: action.items,
                    loaded: true
                }
            };
        case ActionType.CATEGORY_LOADED:
            const items = state[crowdId].items ? [...state[crowdId].items, action.item] : [action.item];
            return {
                ...state,
                [crowdId]: {
                    items,
                    loaded: true
                }
            };
        case ActionType.CATEGORY_UPDATED:
            const updatedItems = state[crowdId].items.map(item => {
                if (item.id !== action.item.id) {
                    return item
                }
                return {
                    ...item,
                    ...action.item
                }
            });
            return {
                ...state,
                [crowdId]: {
                    items: updatedItems,
                    loaded: true
                }
            };
        case ActionType.CATEGORY_DELETED:
            return {
                ...state,
                [crowdId]: {
                    items: state[crowdId].items.filter(c => c.id !== action.categoryId),
                    loaded: true
                }
            };
        default:
            return state;
    }
}

function comments(state = {}, action) {
    const ideaId = action.ideaId;
    const currentIdeaComments = (state[ideaId] && state[ideaId].items) || [];
    switch (action.type) {
        case ActionType.COMMENTS_LOADED:
            return {
                ...state,
                [ideaId]: {
                    items: [...action.items, ...currentIdeaComments].sort(sortByDate),
                    loaded: true
                }
            };
        case ActionType.COMMENT_LOADED:
            return {
                ...state,
                [ideaId]: {
                    items: [action.item, ...currentIdeaComments].sort(sortByDate),
                    loaded: true
                }
            };
        case ActionType.COMMENT_UPDATED:
            const updatedItems = currentIdeaComments.map(item => {
                if (item.id !== action.item.id) {
                    return item
                }
                return {
                    ...item,
                    ...action.item
                }
            });
            return {
                ...state,
                [ideaId]: {
                    items: updatedItems,
                    loaded: true
                }
            };
        case ActionType.COMMENT_DELETED:
            return {
                ...state,
                [ideaId]: {
                    items: currentIdeaComments.filter(c => c.id !== action.commentId).sort(sortByDate),
                    loaded: true
                }
            };
        case ActionType.USER_AVATAR_UPDATED:
            const userId = action.userId;
            const avatarId = action.avatarId;
            const newState = {...state};
            Object.keys(newState).map(k =>
                newState[k].items = newState[k].items.map(comment => {
                    if (comment.ownerId === userId)
                        comment.authorAvatarId = avatarId;
                    return comment;
                }));
            return newState;
        default:
            return state;
    }
}

function subscriptions(state = {}, action) {
    const crowdId = action.crowdId;
    switch (action.type) {
        case ActionType.SUBSCRIPTION_LOADED:
            return {
                ...state,
                [crowdId]: {
                    loaded: true,
                    current: action.current,
                    next: action.next
                }
            };
        case ActionType.SUBSCRIPTIONS_LOADED:
            const newState = {
                loaded: true,
                ...state
            };
            Object.keys(action.items).forEach(k => {
                newState[k] = {
                    loaded: true,
                    current: action.items[k].current,
                    next: action.items[k].next
                }
            });
            return newState;
        default:
            return state;
    }
}

function creditCards(state = {items: [], loaded: false}, action) {
    switch (action.type) {
        case ActionType.CREDIT_CARDS_LOADED:
            return {
                items: action.items.sort(sortByPrimary),
                loaded: true
            };
        case ActionType.CREDIT_CARD_MADE_DEFAULT:
            return {
                items: state.items.map(card => {
                    card.primary = card.id === action.id;
                    return card;
                }).sort(sortByPrimary),
                ...state
            };
        default:
            return state;
    }
}

function crowdUsers(state = {}, action) {
    const crowdId = action.crowdId;
    switch (action.type) {
        case ActionType.CROWD_USERS_LOADED:
            return {
                ...state,
                [action.crowdId]: {
                    items: action.items,
                    loaded: true
                }
            };
        case ActionType.CROWD_USERS_ADDED:
            const items = [...state[crowdId].items, ...action.items];
            return {
                ...state,
                [crowdId]: {
                    items,
                    loaded: true
                }
            };
        case ActionType.CROWD_USERS_UPDATED:
            const updatedItems = state[crowdId].items.map(user => {
                const toUpdate = action.items.find(u => u.email === user.email);
                if (toUpdate) {
                    return toUpdate;
                }
                return user;
            });

            return {
                ...state,
                [crowdId]: {
                    items: updatedItems,
                    loaded: true
                }
            };
        case ActionType.CROWD_USER_DELETED:
            return {
                ...state,
                [crowdId]: {
                    items: state[crowdId].items.filter(user => user.id !== action.crowdUserId),
                    loaded: true
                }
            };
        default:
            return state;
    }
}

function questionnaires(state = {}, action) {
    const crowdId = action.crowdId;
    if (action.type === ActionType.QUESTIONNAIRE_LOADED) {
        return {
            ...state,
            [crowdId]: {
                ...action.item,
                loaded: true
            }
        };
    } else {
        return state;
    }
}

function landingPage(state = {
    crowds: {items: [], loaded: false},
    mostLiked: {items: [], loaded: false},
    mostRecent: {items: [], loaded: false}
}, action) {
    switch (action.type) {
        case ActionType.LANDING_PAGE_CROWDS_LOADED:
            return {
                ...state,
                crowds: {
                    items: action.items,
                    loaded: true
                }
            };
        case ActionType.LANDING_PAGE_MOST_LIKED_LOADED:
            return {
                ...state,
                mostLiked: {
                    items: action.items,
                    loaded: true
                }
            };
        case ActionType.LANDING_PAGE_MOST_RECENT_LOADED:
            return {
                ...state,
                mostRecent: {
                    items: action.items,
                    loaded: true
                }
            };
        default:
            return state;
    }
}

function registration(state = {success: false, errors: {}}, action) {
    switch (action.type) {
        case ActionType.REGISTRATION_SUCCEEDED:
            return {
                success: true,
                errors: {}
            };
        case ActionType.REGISTRATION_ERRORED:
            return {
                success: false,
                errors: action.errors
            };
        default:
            return state;
    }
}

function websocket(state = {}, action) {
    switch (action.type) {
        case ActionType.WEBSOCKET_CONNECT:
            return action.socket;
        case ActionType.WEBSOCKET_DISCONNECT:
            state.disconnect();
            return {};
        default:
            return state;
    }
}

function passwordChange(state = {success: false, errors: {}}, action) {
    switch (action.type) {
        case ActionType.CHANGE_PASSWORD_SUCCEEDED:
            return {
                success: true,
                errors: {}
            };
        case ActionType.CHANGE_PASSWORD_ERRORED:
            return {
                success: false,
                errors: action.errors
            };
        default:
            return state;
    }
}

function appNotification(state = {}, action) {
    if (action.type === ActionType.APP_NOTIFICATION) {
        return action.notification;
    } else return state;
}

function sortByDate(a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt);
}

function sortByPrimary(a, b) {
    if (a.primary) return -1;
    if (b.primary) return 1;
    return 0;
}

const appReducer = combineReducers({
    locale,
    user,
    userRoles,
    notificationSettings,
    notifications,
    crowds,
    ideas,
    ideaStats,
    crowdView,
    crowdViewCategories,
    crowdViewIdeas,
    crowdViewQuestionnaire,
    crowdViewIdeasLikedCommented,
    categories,
    subscriptions,
    creditCards,
    crowdUsers,
    comments,
    questionnaires,
    landingPage,
    registration,
    passwordChange,
    appNotification,
    websocket
});

const rootReducer = (state, action) => {
    let newState = state;
    if (action.type === ActionType.USER_LOGGED_OUT) {
        if (action.keepState) {
            newState = {
                ...state,
                user: {loaded: true, loggingOut: true, loggedIn: false},
                notifications: undefined,
                notificationSettings: undefined,
                crowdViewIdeasLikedCommented: undefined
            }
        } else {
            newState = {user: {loaded: true, loggingOut: true, loggedIn: false}};
        }
    }

    return appReducer(newState, action)
};


export default rootReducer;
