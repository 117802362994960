import React, {useEffect} from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import {compose} from "redux";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import {loadNotificationSettings, updateNotificationSettings} from "../../actions/user";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import {connect} from "react-redux";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import {UserNotificationSettingsLocalizationCode} from "../../constants";
import Spinner from "../common/Spinner";
import {isEqual} from 'lodash';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    button: {
        color: 'white',
        marginTop: '8px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));


const NotificationSettings = props => {

    const classes = useStyles();
    const settings = props.notificationSettings;
    const [settingsState, setSettingsState] = React.useState({});

    useEffect(() => {
        const settingsNoVersion = {...settings};
        delete settingsNoVersion.version;
        setSettingsState(settingsNoVersion)
    }, [settings]);

    const handleSettingToggle = setting => () => {
        setSettingsState({
            ...settingsState,
            [setting]: !settingsState[setting]
        })
    };

    const changesMade = () => {
        return !isEqual(settingsState, settings);
    };

    const handleSubmit = () => {
        if (changesMade()) {
            props.updateSettings(settingsState)
        }
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <MailOutlineIcon/>
                </Avatar>
            }
            title={<Typography
                className={'cardHeaderText'}>{props.intl.formatMessage({id: 'email_notification_settings'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            {Object.keys(settingsState).length === 0 ? (
                <Spinner/>
            ) : (
                <List component="div" disablePadding>
                    {Object.keys(settingsState).map(key =>
                        <ListItem key={key}
                                  dense
                                  button
                                  onClick={handleSettingToggle(key)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    color="primary"
                                    checked={!!settingsState[key]}
                                    disableRipple/>
                            </ListItemIcon>
                            <ListItemText
                                primary={props.intl.formatMessage({id: UserNotificationSettingsLocalizationCode[key]})}/>
                        </ListItem>
                    )}
                </List>
            )}
            <Button color='primary' variant="contained" className={classes.button} onClick={handleSubmit}>
                {props.intl.formatMessage({id: 'save'})}
            </Button>
        </CardContent>
    </Card>
};


const mapStateToProps = state => {
    return {
        notificationSettings: state.notificationSettings,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadSettings: () => dispatch(loadNotificationSettings()),
        updateSettings: settings => dispatch(updateNotificationSettings(settings)),
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(NotificationSettings);