import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {injectIntl} from "react-intl";
import CrowdsTableHead from "./CrowdsTableHead";
import {compose} from "redux";
import {connect} from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import {lighten} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TablePaginationActions from "../common/TablePaginationActions";
import {useHistory} from 'react-router-dom';
import {createCrowd} from "../../actions/crowds";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddBoxIcon from '@material-ui/icons/AddBox';
import Chip from "@material-ui/core/Chip";
import {getComparator, stableSort} from "../../utils/utils";
import Hidden from "@material-ui/core/Hidden";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {CROWDEA_HOSTNAME} from "../../constants";

const useStyles = makeStyles(theme => ({
    tableContainer: {
        maxHeight: '65vh'
    },
    paper: {
        boxShadow: '0 0 35px -2px #ccc',
    },
    highlight: {
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
    },
    icon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px',
        padding: '2px 2px'
    },
    iconTableCell: {
        padding: 0
    },
    toolbarContent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    pagination: {
            '& div.MuiToolbar-root': {
                [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center'
                }
            },
            '& div.MuiTablePagination-spacer': {
                flex: 'none'
            }
    }
}));

const Crowds = props => {

    const classes = useStyles();
    const {crowds, subscriptions} = props;
    const history = useHistory();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const displayedRowsLabel = ({from, to, count}) => {
        const of = props.intl.formatMessage({id: 'of'});
        return `${from}-${to === -1 ? count : to} ${of} ${count}`;
    };

    const getSubscription = crowdId => subscriptions[crowdId] || {current: {}};

    const getChip = (crowd, compact = false) => {
        if (!subscriptions.loaded)
            return <></>;

        const sub = getSubscription(crowd.id);
        const status = crowd.visible && (sub.current.active || sub.next);

        let backgroundColor = '';
        let label = '';
        if (status) {
            backgroundColor = 'green';
            label = 'active';
        } else {
            backgroundColor = 'orangered';
            label = 'inactive';
        }

        if (compact)
            return <FiberManualRecordIcon style={{color: backgroundColor}}/>;
        else
            return <Chip size="small"
                         label={props.intl.formatMessage({id: label})}
                         style={{color: 'white', backgroundColor: backgroundColor}}/>
    };

    const renderButton = () => {
        if (window.innerWidth < 450)
            return <IconButton color='primary' onClick={() => props.createCrowd()}>
                <AddBoxIcon fontSize="large"/>
            </IconButton>;
        else
            return <Button color='primary' variant="contained" style={{color: 'white'}} startIcon={<AddBoxIcon/>}
                           onClick={() => props.createCrowd()}>
                {props.intl.formatMessage({id: 'create_new_crowd'})}
            </Button>
    };

    const viewHomepage = crowd => event => {
        event.stopPropagation();
        if (crowd.domain && crowd.domain !== '' && !crowd.subdomainRouting)
            window.open(`https://${crowd.domain}`, "_blank");
        else
            window.open(`https://${crowd.subdomain}.${CROWDEA_HOSTNAME}`, "_blank");
    };

    const getSubscriptionName = crowd => {
        const sub = getSubscription(crowd.id);
        let label = '';
        if (sub && sub.current.name)
            label = sub.current.name.toLowerCase();
        else
            label = 'no_subscription';
        return props.intl.formatMessage({id: label});
    };

    const paginationActions = window.innerWidth > 600 ? {ActionsComponent: TablePaginationActions} : {ActionsComponent: 'span'};

    return (
        <Paper elevation={3} className={classes.paper}>
            <Toolbar className={classes.toolbarContent}>
                <Typography variant="h6">
                    {props.intl.formatMessage({id: 'crowds'})}
                </Typography>
                {renderButton()}
            </Toolbar>
            <Grid container
                  direction="column"
                  justify="center"
                  style={{minHeight: '60vh'}}>
                {crowds.items.length ? (<>
                    <Grid item>
                        <TableContainer className={classes.tableContainer}>
                            <Table size={'medium'} stickyHeader>
                                <CrowdsTableHead
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {(rowsPerPage > 0 ? stableSort(crowds.items, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : stableSort(crowds.items, getComparator(order, orderBy)))
                                        .map(crowd =>
                                            <TableRow
                                                className={classes.highlight}
                                                onClick={() => history.push(`/crowds/${crowd.id}`)}
                                                tabIndex={-1}
                                                key={crowd.name}>
                                                <TableCell align="left">{crowd.title}</TableCell>
                                                <Hidden mdUp>
                                                    <TableCell align="left">
                                                        {getChip(crowd, true)}
                                                    </TableCell>
                                                </Hidden>
                                                <Hidden smDown>
                                                    <TableCell align="left">
                                                        {getChip(crowd)}
                                                    </TableCell>
                                                </Hidden>
                                                <Hidden smDown>
                                                    <TableCell align="left">
                                                        {getSubscriptionName(crowd)}
                                                    </TableCell>
                                                </Hidden>
                                                <TableCell align="right" className={classes.iconTableCell}>
                                                    <IconButton onClick={viewHomepage(crowd)}>
                                                        <VisibilityIcon className={classes.icon}/>
                                                    </IconButton>
                                                    <IconButton>
                                                        <EditIcon className={classes.icon}/>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs/>
                </>) : (<>
                        <Grid item>
                            <TableContainer className={classes.tableContainer}>
                                <Table size={'medium'} stickyHeader>
                                    <CrowdsTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs/>
                        <Grid item>
                            <Typography variant="h6" align='center'>
                                {props.intl.formatMessage({id: 'no_crowds_yet'})}
                            </Typography>
                        </Grid>
                        <Grid item xs/>
                    </>
                )}
                <Grid item>
                    <TablePagination
                        className={classes.pagination}
                        component="div"
                        rowsPerPageOptions={[10, 25, 50, {
                            label: props.intl.formatMessage({id: 'all_rows'}),
                            value: -1
                        }]}
                        count={crowds.items.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        {...paginationActions}
                        labelRowsPerPage={props.intl.formatMessage({id: 'rows_per_page'})}
                        labelDisplayedRows={displayedRowsLabel}
                        marginLeft='20px'
                    />
                </Grid>
                <Hidden smUp>
                    <Grid item style={{textAlign: 'center'}}>
                        <TablePaginationActions count={crowds.items.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                onChangePage={handleChangePage}/>
                    </Grid>
                </Hidden>
            </Grid>
        </Paper>
    );
};

const mapStateToProps = state => {
    return {
        crowds: state.crowds,
        subscriptions: state.subscriptions
    }
};

const mapDispatchToProps = dispatch => {
    return {
        createCrowd: () => dispatch(createCrowd())
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(Crowds);