import React from "react";
import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Grow from "@material-ui/core/Grow";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card/Card";

const useStyles = makeStyles(theme => ({
    accordionBackground: {
        backgroundColor: '#F9FAFC',
    },
    cardErrorText: {
        color: 'red',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    cardInput: {
        border: '0.5px solid transparent',
        borderColor: 'rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        padding: '10.5px 14px',
        backgroundColor: 'white'
    }
}));

const PaymentIntent = props => {

    const classes = useStyles();
    const {
        width = '100%',
        creditCards,
        setSelectedCard,
        setUseExisting,
        setCardError,
        useExisting,
        selectedCard,
        cardError,
        paymentError
    } = props;

    const cardSelected = event => {
        event.preventDefault();
        setSelectedCard(event.target.value);
    };

    const handlePanelChange = useExisting => (event, isExpanded) => {
        console.log();
        if (useExisting && !isExpanded)
            return;
        setUseExisting(isExpanded ? useExisting : false);
    };

    const cardElementOnReady = el => {
        el.focus();
        el.addEventListener('change', event => {
            if (event.error) {
                setCardError(true);
            } else {
                setCardError(false);
            }
        });
    };

    const cardOptions = {
        iconStyle: 'solid',
        style: {
            base: {
                '::placeholder': {color: '#A8ABB2'},
            }
        },
    };

    const cardItem = <div style={{width: '100%'}}>
        <div style={{padding: '10px 0'}}>
            <Typography style={{color: '#A8ABB2', marginBottom: '10px'}}>
                {props.intl.formatMessage({id: 'credit_card_number'})}
            </Typography>
            <div className={classes.cardInput}>
                <CardNumberElement options={cardOptions} onReady={cardElementOnReady}/>
            </div>
        </div>
        <div style={{padding: '10px 0'}}>
            <Typography style={{color: '#A8ABB2', marginBottom: '10px'}}>
                {props.intl.formatMessage({id: 'credit_card_expiration'})}
            </Typography>
            <div className={classes.cardInput}>
                <CardExpiryElement options={cardOptions}/>
            </div>
        </div>
        <div style={{padding: '10px 0'}}>
            <Typography style={{color: '#A8ABB2', marginBottom: '10px'}}>
                {props.intl.formatMessage({id: 'credit_card_cvv'})}
            </Typography>
            <div className={classes.cardInput}>
                <CardCvcElement options={cardOptions}/>
            </div>
        </div>
        <Grow in={cardError || paymentError}>
            <Typography component='div' variant={'caption'} className={classes.cardErrorText}>
                {props.intl.formatMessage({id: paymentError ? 'credit_card_payment_failed' : 'credit_card_incorrect_number'})}
            </Typography>
        </Grow>
    </div>;

    return <Grid
        style={{width}}
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={2}>
        {creditCards.items.length > 0 ?
            <>
                <Grid item xs={12}>
                    <Accordion className={clsx({[classes.accordionBackground]: useExisting})}
                               expanded={useExisting} onChange={handlePanelChange(true)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}>
                            <Typography>
                                {props.intl.formatMessage({id: 'use_existing_credit_card'})}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {creditCards.items.length > 1 ?
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="select_credit_card_label">
                                        {props.intl.formatMessage({id: 'select_credit_card'})}
                                    </InputLabel>
                                    <Select
                                        labelId="select_credit_card_label"
                                        id="select_credit_card"
                                        fullWidth
                                        label={props.intl.formatMessage({id: 'select_credit_card'})}
                                        value={selectedCard}
                                        style={{backgroundColor: 'white'}}
                                        onChange={cardSelected}>
                                        {creditCards.items.map((card, index) =>
                                            <MenuItem key={`card${index}`}
                                                      value={card}>{`**** **** **** ${card.last4}`}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl> :
                                <Typography>
                                    {`${creditCards.items[0].brand.toUpperCase()} **** **** **** ${creditCards.items[0].last4}`}
                                </Typography>}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion className={clsx({[classes.accordionBackground]: !useExisting})}
                               expanded={!useExisting} onChange={handlePanelChange(false)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}>
                            <Typography>
                                {props.intl.formatMessage({id: 'add_new_credit_card'})}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {cardItem}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </> : <Grid item xs={12}>
                <Card elevation={3} className={classes.accordionBackground}>
                    <CardContent>
                        <Typography>
                            {props.intl.formatMessage({id: 'add_new_credit_card'})}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        {cardItem}
                    </CardContent>
                </Card>
            </Grid>
        }
    </Grid>;
};

export default injectIntl(PaymentIntent);