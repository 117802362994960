import React from "react";
import {injectIntl} from "react-intl";
import CrowdDetailNotification from "./CrowdDetailNotification";
import {useHistory} from "react-router-dom";


const CrowdDetailNotificationStack = props => {

    const history = useHistory();
    const {
        subscription,
        crowd,
        setValue,
    } = props;

    const getRemainingTrialDays = () => {
        return Math.floor((subscription.current.validTo * 1000 - Date.now()) / 1000 / 86400) + 1;
    };

    const getTrialNotificationText = () => {
        const remainingTrialDays = getRemainingTrialDays();
        if (remainingTrialDays === 1)
            return props.intl.formatMessage({id: 'crowd_trial_subscription_ends_today_notification'});
        else {
            const trialText = props.intl.formatMessage({id: 'crowd_trial_subscription_notification'});
            const daysRemngText = props.intl.formatMessage({id: 'crowd_trial_subscription_days_remaining_notification'});
            return `${trialText} ${getRemainingTrialDays()} ${daysRemngText}.`;
        }
    };

    return <>
        {subscription.current.tier === 0 &&
        <CrowdDetailNotification text={getTrialNotificationText()}
                                 buttonTextCode='change_subscription'
                                 clickHandler={() => {
                                     history.push(`/crowds/${crowd.id}/subscription`);
                                     setValue(5);
                                 }}/>}
        {subscription.loaded && !subscription.current.active && !subscription.next &&
        <CrowdDetailNotification textCode='crowd_no_subscription_notification'
                                 buttonTextCode='crowd_no_subscription_notification_button'
                                 clickHandler={() => {
                                     history.push(`/crowds/${crowd.id}/subscription`);
                                     setValue(5);
                                 }}/>}
        {crowd.featuredImageId === null &&
        <CrowdDetailNotification colorScheme='secondary'
                                 textCode='crowd_does_not_have_logo_notification'
                                 buttonTextCode='crowd_does_not_have_logo_notification_button'
                                 clickHandler={() => {
                                     history.push(`/crowds/${crowd.id}/theming`);
                                     setValue(1);
                                 }}/>}
        {!crowd.visible &&
        <CrowdDetailNotification colorScheme='secondary'
                                 textCode='crowd_not_visible_notification'
                                 buttonTextCode='crowd_not_visible_notification_button'
                                 clickHandler={() => {
                                     history.push(`/crowds/${crowd.id}/profile`);
                                     setValue(0);
                                 }}/>}
    </>
};

export default injectIntl(CrowdDetailNotificationStack);