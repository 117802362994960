import {createMuiTheme} from "@material-ui/core";

export const muiTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#1eb2f3',
            light: '#bce8f1',
            dark: '#31708f'
        },
        secondary: {
            main: '#FC9000',
            light: '#FFFAF0',
            dark: '#8a6d3b'
        },
        orange: {
            main: '#F77A22',
            light: '#FC9000',
            dark: '#EE5126'
        },
        green: {
            main: '#5ed52e',
            light: '#6CD53D',
            dark: '#47C621'
        },
        red: {
            main: '#EE1B22'
        },
        indigo: {
            main: '#3D58FF',
            dark: '#022961'
        },
        purple: {
            main: '#6670FC',
            dark: '#4a39fc',
            light: '#AEB2FD'
        },
        grey: {
            main: '#D3D3D3'
        }
    },
    typography: {
        fontFamily: 'Work Sans'
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 768,
            lg: 1024,
            xl: 1280
        }
    }
});