import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {connect} from "react-redux";
import {Locale} from "../../../constants"
import Button from "@material-ui/core/Button";
import {injectIntl} from "react-intl";
import {compose} from "redux";
import {changeLocale} from "../../../actions/locale";
import {SelectLanguageMenu} from "./SelectLanguageMenu";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    button: {
        fontWeight: 'bold',
        background: 'transparent',
        '&:hover': {
            backgroundColor: 'transparent'
        }
    },
    primary: {
        color: theme.palette.primary.main,
    },
    indigo: {
        color: theme.palette.indigo.dark,
    }
}));

const SelectLanguageNavbar = props => {

    const {changeLocale, currentLocale, useColor = 'primary'} = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = locale => {
        setAnchorEl(null);
        changeLocale(locale);
    };

    return (
        <>
            <Button
                disableElevation
                disableRipple
                onClick={handleClick}
                variant="contained"
                className={clsx(classes.button, classes[useColor])}
                // startIcon={<TranslateIcon/>}
                endIcon={<ExpandMoreIcon/>}
            >
                {currentLocale === Locale.EN ? 'EN' : 'SK'}
            </Button>
            <SelectLanguageMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                currentLocale={currentLocale}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        currentLocale: state.locale
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLocale: (locale) => dispatch(changeLocale(locale))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(SelectLanguageNavbar);
