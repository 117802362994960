import React from "react";
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import HorizontalScroll from "../../common/HorizontalScroll";
import IdeaPreview from "./IdeaPreview";
import {itemsToRender} from "./MostLikedIdeasPreview";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'white',
        position: 'relative',
        overflow: 'hidden',
        paddingBottom: '20px',
        marginBottom: '100px'
    },
    contentWrapper: {
        position: 'relative',
        zIndex: 1
    },
    text1: {
        paddingTop: '165px',
        color: 'white',
        fontSize: '60px',
        lineHeight: '1.1',
        fontFamily: 'Open Sans',
        textAlign: 'center',
        [theme.breakpoints.down('xl')]: {
            fontSize: '55px',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '45px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '32px',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '120px',
        }
    },
    triangle: {
        bottom: 0,
        position: 'absolute',
        borderLeft: '3000px solid transparent',
        borderRight: '3000px solid transparent',
        borderBottom: '750px solid #002361',
        right: 'calc(-2600px - (1280px - 100vw) / 2)',
        [theme.breakpoints.down('lg')]: {
            borderBottom: '720px solid #002361',
        },
        [theme.breakpoints.down('sm')]: {
            right: 'calc(-2500px - (1280px - 100vw) / 2)',
            borderBottom: '690px solid #002361',
        },
        [theme.breakpoints.down('xs')]: {
            right: 'calc(-2450px - (1280px - 100vw) / 2)',
            borderBottom: '650px solid #002361',
        }
    },
}));

const NewestIdeasPreview = props => {

    const classes = useStyles();
    const scrollContainerRef = React.useRef(null);
    const {ideas, intl} = props;

    return <div className={classes.container}>
        <Container maxWidth="xl" className={classes.contentWrapper}>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={4}
            >
                <Grid item xs={12}>
                    <Typography className={classes.text1}>
                        {intl.formatMessage({id: 'newest_ideas_preview'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{position: 'relative'}}>
                    <HorizontalScroll scrollContainerRef={scrollContainerRef}>
                        {itemsToRender(ideas, intl).map(idea => <IdeaPreview idea={idea}/>)}
                    </HorizontalScroll>
                </Grid>
            </Grid>
        </Container>
        <div className={classes.triangle}/>
    </div>
};

export default injectIntl(NewestIdeasPreview);