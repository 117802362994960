import React from "react";
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import {HashLink} from "react-router-hash-link";
import {offsetScroll} from "../LandingPage";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'white',
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            backgroundImage: 'url("/images/polygon_orange.png")',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            paddingTop: '75px'
        }
    },
    triangle: {
        bottom: 0,
        position: 'absolute',
        borderLeft: '4000px solid transparent',
        borderRight: '1500px solid transparent',
        borderBottom: '550px solid #F77A22',
        left: 'calc(-2700px - (1280px - 100vw) / 2)',
        [theme.breakpoints.down('lg')]: {
            borderBottom: '450px solid #F77A22',
        },
        [theme.breakpoints.down('md')]: {
            borderBottom: '370px solid #F77A22',
        }
    },
    contentWrapper: {
        position: 'relative',
        zIndex: 1
    },
    textWrapper: {
        [theme.breakpoints.up('md')]: {
            marginTop: '100px'
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '50px'
        }
    },
    text1: {
        marginBottom: '20px',
        color: 'white',
        fontSize: '60px',
        lineHeight: '1.1',
        fontFamily: 'Open Sans',
        [theme.breakpoints.down('xl')]: {
            fontSize: '55px',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '45px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '40px',
        }
    },
    text2: {
        fontWeight: 'bold',
        color: 'white',
        opacity: '0.7',
        marginBottom: '20px'
    },
    image: {
        height: '500px',
        width: 'auto',
        [theme.breakpoints.down('lg')]: {
            height: '400px',
            width: 'auto'
        },
        [theme.breakpoints.down('md')]: {
            height: '300px',
            width: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            width: '100%',
            marginTop: 'unset'
        },
        marginTop: '50px',
    },
    greenButton: {
        color: 'white',
        background: 'linear-gradient(to right, #6CD53D, #47C621)',
        borderRadius: '15px',
        width: '170px',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            bottom: '20px',
            left: '24px',
        },
        [theme.breakpoints.down('xs')]: {
            position: 'absolute',
            bottom: '20px',
            left: '16px',
        },
    },
    captionWrapper: {
        padding: '32px',
        [theme.breakpoints.down('md')]: {
            marginTop: '100px'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
            padding: 0
        }
    },
    link: {
        textDecoration: 'none'
    }
}));

const HowItWorks = props => {

    const classes = useStyles();

    return <div className={classes.container}>

        <Container maxWidth="xl" className={classes.contentWrapper}>
            <Grid container
                  justify="center"
                  alignItems="center"
            >
                <Hidden mdUp>
                    <Grid item xs={12} className={classes.captionWrapper}>
                        <Typography className={classes.text1}>
                            {props.intl.formatMessage({id: 'how_does_it_work'}) + '?'}
                        </Typography>
                        <Typography className={classes.text2}>
                            {props.intl.formatMessage({id: 'how_does_it_work_user_desc'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <img className={classes.image} src="/images/user_man.png"/>
                        <Button variant="contained" className={classes.greenButton} disableElevation>
                            {props.intl.formatMessage({id: 'try_crowdea'})}
                        </Button>
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item style={{textAlign: 'center'}}>
                        <img className={classes.image} src="/images/user_man.png"/>
                    </Grid>
                    <Grid item xs={6} className={classes.captionWrapper}>
                        <Typography className={classes.text1}>
                            {props.intl.formatMessage({id: 'how_does_it_work'}) + '?'}
                        </Typography>
                        <Typography className={classes.text2}>
                            {props.intl.formatMessage({id: 'how_does_it_work_user_desc'})}
                        </Typography>
                        <HashLink
                            scroll={el => offsetScroll(el)}
                            to='#crowdspreview'
                            className={classes.link}>
                            <Button variant="contained" className={classes.greenButton} disableElevation>
                                {props.intl.formatMessage({id: 'try_crowdea'})}
                            </Button>
                        </HashLink>
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
        <Hidden smDown>
            <div className={classes.triangle}/>
        </Hidden>
    </div>
};

export default injectIntl(HowItWorks);