import {store} from "../store/configureStore";
import {emitAppNotification} from "../actions/common";
import {NotificationType} from "../constants";

export function secureFetch(url, init) {

    const headers = {...init.headers, ...privateCrowdHeader()};
    return fetch(url, {...init, ...{headers: headers}, credentials: 'include'})
        .then(response => throwOnError(response))
        .then(response => optimisticLockingError(response))
        .catch((e) => {
            store.dispatch(emitAppNotification(NotificationType.ERROR, e.message, 5000));
            throw e;
        });
}

function privateCrowdHeader() {
    const crowd = store.getState().crowdView;

    if (crowd.loaded && crowd.data.privateCrowd) {
        return {'private-crowd': crowd.data.id};
    } else {
        return {};
    }
}

function throwOnError(response) {
    if(response.status === 426)
        throw new Error("crowd_user_limit_reached");

    if (response.ok || [401, 402, 409, 422, 429].includes(response.status)) {
        return response;
    }
    throw new Error();
}

function optimisticLockingError(response) {
    const staleEntity = response.headers.get('stale-entity');
    if (staleEntity) {
        throw new Error("optimistic_locking_error");
    }
    return response;
}