import React from "react";
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import useDeepCompareEffect from "use-deep-compare-effect";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import {CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaymentIntent from "./PaymentIntent";


const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    dialogTitleText: {
        paddingLeft: '10px',
        fontSize: '0.9rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        },
    },
    dialogContent: {
        [theme.breakpoints.down('xs')]: {
            padding: '8px'
        }
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    dialog: {
        '& div.MuiPaper-root.MuiDialog-paper': {
            [theme.breakpoints.down('xs')]: {
                margin: '16px',
                width: 'calc(100% - 32px)',
                maxWidth: 'calc(100% - 32px)'
            }
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    progressWrapper: {
        position: 'relative',
    }
}));

const ChoosePaymentMethodModal = props => {

    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const {
        handleClose,
        isOpen,
        creditCards,
        loadCreditCards,
        createSubscription,
        emitError,
        reloadCrowdUsers,
        tier
    } = props;

    const [selectedCard, setSelectedCard] = React.useState(creditCards.items.find(c => c.primary) || creditCards.items[0] || {});
    const [useExisting, setUseExisting] = React.useState(false);
    const [cardError, setCardError] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    useDeepCompareEffect(() => {
        if (creditCards.loaded && creditCards.items.length) {
            setSelectedCard(creditCards.items.find(c => c.primary) || creditCards.items[0] || {});
            setUseExisting(true);
        }
    }, [creditCards]);

    const successCallback = () => {
        reloadCrowdUsers();
        loadCreditCards();
        setLoading(false);
        handleClose();
    };

    const paymentFailedCallback = () => {
        setPaymentError(true);
        setLoading(false);
    };

    const handleSubmit = event => {
        event.preventDefault();
        setLoading(true);

        if (useExisting) {
            return createSubscription(tier, selectedCard.id, successCallback, paymentFailedCallback);
        } else {
            const cardElement = elements.getElement(CardNumberElement);
            if (!cardElement || !cardElement._complete || cardElement._invalid) {
                setCardError(true);
                return;
            }

            stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            }).then(({error, paymentMethod}) => {
                if (error)
                    throw Error();
                return createSubscription(tier, paymentMethod.id, successCallback, paymentFailedCallback);
            }).catch(() => {
                setLoading(false);
                emitError("credit_card_error");
            });
        }
    };

    const handleExited = () => {
        setCardError(false);
        setPaymentError(false);
        setUseExisting('existing');
    };

    return <Dialog fullWidth
                   className={classes.dialog}
                   open={isOpen}
                   scroll='body'
                   onExited={handleExited}
                   onClose={handleClose}>
        <DialogTitle className={classes.dialogTitle} disableTypography>
            <Avatar className={classes.avatar}>
                <CreditCardIcon/>
            </Avatar>
            <Typography variant={'body1'} className={classes.dialogTitleText}>
                {props.intl.formatMessage({id: 'enter_payment_details'})}
            </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <PaymentIntent creditCards={creditCards}
                           selectedCard={selectedCard}
                           setSelectedCard={setSelectedCard}
                           useExisting={useExisting}
                           setUseExisting={setUseExisting}
                           setCardError={setCardError}
                           cardError={cardError}
                           paymentError={paymentError}
            />
        </DialogContent>
        <DialogActions>
            <div className={classes.progressWrapper}>
                <Button color='primary' variant="contained" className={classes.button}
                        onClick={handleSubmit} disabled={loading}>
                    {props.intl.formatMessage({id: 'submit'})}
                    {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                </Button>
            </div>
            <Button color='primary' variant="contained" className={classes.button}
                    onClick={handleClose} disabled={loading}>
                {props.intl.formatMessage({id: 'close'})}
            </Button>
        </DialogActions>
    </Dialog>
};

export default injectIntl(ChoosePaymentMethodModal);