import React from "react";
import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import {injectIntl} from "react-intl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

const Festival = props => {

    const {
        isLandingPage,
        buttonClickHandler,
        cardClickHandler,
        hideButtons,
        selected
    } = props;

    const cardElevation = isLandingPage ? 0 : 3;

    return <Card
        className={clsx('pricingCard', selected === 3 ? 'pricingCardSelected' : 'pricingCardHover', {'cursorPointer': selected !== undefined})}
        elevation={cardElevation}
        onClick={cardClickHandler}>
        <div className={'pricingCardImageWrapper'}>
            <img src="/images/festival.png"/>
        </div>
        <CardContent>
            <Typography variant={'h4'}
                        className={clsx('pricingCardHeaderText', 'pricingCardItemMargin')}>
                {props.intl.formatMessage({id: 'festival'})}
            </Typography>
            <div className={'pricingCardTextWrapper'}>
                <Typography color='primary' component={'span'}
                            className={clsx('pricingCardEuroSign', {'purpleColor': isLandingPage})}>
                    {'€'}
                </Typography>
                <Typography color='primary' component={'span'}
                            className={clsx('pricingCardPriceText', {'purpleColor': isLandingPage})}>
                    {'499'}
                </Typography>
            </div>
            <Typography className={'pricingCardMonthlyText'}>
                {props.intl.formatMessage({id: 'monthly'})}
            </Typography>
            {!hideButtons && <Button variant="contained" color="primary" disableElevation
                                     className={clsx('pricingCardButton', 'pricingCardItemMargin', {'purpleBackground': isLandingPage})}
                                     onClick={buttonClickHandler}>
                {props.intl.formatMessage({id: 'buy'})}
            </Button>}
            <div>&nbsp;</div>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'unlimited_users'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'unlimited_ideas'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'unlimited'}) + ' ' +
                props.intl.formatMessage({id: 'crowd_admins'}) + '/' +
                props.intl.formatMessage({id: 'crowd_managers2'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'dedicated_domain'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'private_crowdea_users'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'standard_support'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'private_crowd'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'advanced_crowd_settings'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'complementary_services'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'new_crowdea_features_vote'})}
            </Typography>
        </CardContent>
    </Card>
};

export default injectIntl(Festival);