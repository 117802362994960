import React from "react";
import CardContent from "@material-ui/core/CardContent";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Avatar from "@material-ui/core/Avatar";
import CategoryIcon from '@material-ui/icons/Category';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {useHistory} from 'react-router-dom';
import {categoryDefaults} from "./IdeaCategories";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        marginRight: '16px'
    },
    icon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px',
        padding: '2px 2px'
    },
    iconTableCell: {
        paddingTop: 0,
        paddingBottom: 0
    },
    toolbarContent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    header: {
        display: 'flex',
        padding: '16px',
        alignItems: 'center'
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

const IdeaCategoryCreate = props => {

    const classes = useStyles();
    const history = useHistory();
    const {createCategory, crowdId} = props;
    const [categoryState, setCategoryState] = React.useState(categoryDefaults);
    const [validationErrors, setValidationErrors] = React.useState({});
    const validators = {
        name: () => {
            if (categoryState.name === '')
                return 'validation_category_name';
            return true;
        }
    };

    const backToList = () => history.push(`/crowds/${crowdId}/categories`);

    const validateInput = () => {
        const result = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});

        setValidationErrors(result);
        return Object.keys(result).length === 0;
    };

    const handleSwitchChange = event => {
        setCategoryState({
            ...categoryState,
            visible: event.target.checked
        });
    };

    const handleInputChange = name => event => {
        setCategoryState({
            ...categoryState,
            [name]: event.target.value
        })
    };

    const handleCreate = () => {
        if (validateInput()) {
            createCategory(crowdId, categoryState, backToList);
        }
    };

    const commonTextFieldProps = (name, i18n) => {
        return {
            label: props.intl.formatMessage({id: i18n}),
            variant: "outlined",
            name: name,
            margin: "dense",
            fullWidth: true,
            error: validationErrors[name],
            helperText: validationErrors[name] && props.intl.formatMessage({id: validationErrors[name]}),
            value: categoryState[name],
            onChange: handleInputChange(name)
        };
    };

    const renderButton = () => {
        if (window.innerWidth < 450)
            return <IconButton color='primary' onClick={backToList}>
                <ArrowBackIosIcon fontSize="large"/>
            </IconButton>;
        else
            return <Button color='primary' variant="contained" style={{color: 'white'}} startIcon={<ArrowBackIosIcon/>}
                           onClick={backToList}>
                {props.intl.formatMessage({id: 'back_to_category_list'})}
            </Button>;
    };

    return <Card elevation={3}>
        <div className={clsx(classes.header, 'cardHeader')}>
            <Avatar className={classes.avatar}>
                <CategoryIcon/>
            </Avatar>
            <Typography className={'cardHeaderText'} component='div' style={{flex: '1 1 auto'}}>
                {props.intl.formatMessage({id: 'categories'})}
            </Typography>
            {renderButton()}
        </div>
        <CardContent className={'cardContent'}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('name', 'category_name')}
                               inputProps={{maxLength: 40}}
                               helperText={`${categoryState.name.length}/40`}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...commonTextFieldProps('description', 'category_description')}
                        multiline
                        rows={5}
                        rowsMax={20}
                        inputProps={{maxLength: 600}}
                        helperText={`${categoryState.description.length}/600`}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={categoryState.visible}
                                onChange={handleSwitchChange}
                                value={categoryState.visible}
                                color="primary"
                            />
                        }
                        label={props.intl.formatMessage({id: 'category_visible'})}
                    />
                </Grid>
                <Grid item xs={12} sm='auto'>
                    <Button color='primary' variant="contained" className={classes.button}
                            onClick={handleCreate}>
                        {props.intl.formatMessage({id: 'save'})}
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    </Card>;
};

export default injectIntl(IdeaCategoryCreate);