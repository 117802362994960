import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {injectIntl} from "react-intl";
import {Link as RouterLink, useParams} from "react-router-dom";
import {compose} from "redux";
import Button from "@material-ui/core/Button";
import ContactsIcon from '@material-ui/icons/Contacts';
import SelectLanguageButton from "../common/selectlanguage/SelectLanguageButton";
import {makeStyles} from "@material-ui/core/styles";
import {withAnonymous} from "../common/withAnonymous";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {passwordRules} from "../../utils/passwordRules";
import {resetPassword} from "../../actions/login";
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';

const useStyles = makeStyles(theme => ({
    buttonTrayRight: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-end"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    buttonTrayLeft: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-start"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    crowdeaLogo: {
        height: '30px',
        margin: '20px 0 20px'
    },
    itemWidth: {
        width: '90%'
    }
}));


const ForgotPasswordReset = props => {

    const classes = useStyles();
    const {token} = useParams();
    const [messageCode, setMessageCode] = React.useState('password_reset_token_verifying');
    const [resetAttempted, setResetAttempted] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [passwordValid, setPasswordValid] = React.useState(true);
    const [confirmPasswordValid, setConfirmPasswordValid] = React.useState(true);

    const validate = () => {
        const passwordValidation = passwordRules.validate(password);
        setPasswordValid(passwordValidation);

        const confirmPasswordValidation = password === confirmPassword;
        setConfirmPasswordValid(confirmPasswordValidation);

        return passwordValidation && confirmPasswordValidation;
    };

    const handleSubmit = () => {
        if (validate()) {
            resetPassword(token, {password, confirmPassword})
                .then(result => {
                    if (result) {
                        setResetAttempted(true);
                        setMessageCode(result.status);
                    }
                })
        }
    };

    return <>
        <Container maxWidth="sm" style={{height: '100vh'}}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'stretch',
                height: '100%'
            }}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item>
                                <RouterLink to='/'>
                                    <img className={classes.crowdeaLogo} src="/images/crowdea.png"/>
                                </RouterLink>
                            </Grid>
                            {resetAttempted ? (
                                <Grid item className={classes.itemWidth}>
                                    <Typography align='center'>
                                        {props.intl.formatMessage({id: messageCode})}
                                    </Typography>
                                </Grid>
                            ) : (<>
                                    <Grid item className={classes.itemWidth}>
                                        <TextField
                                            variant="outlined"
                                            label={props.intl.formatMessage({id: 'password'})}
                                            type="password"
                                            name="password"
                                            autoComplete="new-password"
                                            margin="dense"
                                            fullWidth
                                            error={!passwordValid}
                                            helperText={!passwordValid && props.intl.formatMessage({id: 'validation_registration_password'})}
                                            value={password}
                                            onChange={event => setPassword(event.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            disableFocusListener
                                                            placement="bottom-end"
                                                            title={props.intl.formatMessage({id: 'password_rules'})}>
                                                            <IconButton>
                                                                <HelpOutlineRoundedIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid item className={classes.itemWidth}>
                                        <TextField
                                            variant="outlined"
                                            label={props.intl.formatMessage({id: 'confirm_password'})}
                                            type="password"
                                            name="confirmPassword"
                                            autoComplete="new-password"
                                            margin="dense"
                                            fullWidth
                                            error={!confirmPasswordValid}
                                            helperText={!confirmPasswordValid && props.intl.formatMessage({id: 'validation_registration_confirmPassword'})}
                                            value={confirmPassword}
                                            onChange={event => setConfirmPassword(event.target.value)}/>
                                    </Grid>
                                    <Grid item className={classes.itemWidth}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            onClick={handleSubmit}
                                            style={{color: 'white'}}
                                            fullWidth>
                                            {props.intl.formatMessage({id: 'reset_password'})}
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
                <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{marginTop: '10px'}}
                >
                    <Grid item xs={12} sm={6} className={classes.buttonTrayLeft}>
                        <RouterLink to="/login" style={{textDecoration: 'none'}}>
                            <Button
                                type="button"
                                startIcon={<ContactsIcon/>}>
                                {props.intl.formatMessage({id: 'to_login'})}
                            </Button>
                        </RouterLink>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.buttonTrayRight}>
                        <SelectLanguageButton/>
                    </Grid>
                </Grid>
            </div>
        </Container>
    </>
};

export default compose(
    withAnonymous,
    injectIntl,
)(ForgotPasswordReset);
