import React from 'react';
import {IdeaQuestionnaire} from "./IdeaQuestionnaire";
import {IdeaCreationQuestions} from "./IdeaCreationQuestions";
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {QuestionnaireType} from "../../constants";

const IdeaSettings = props => {

    const {crowdId} = props;
    const {questionnaires} = props;
    const likeQuestionnaire = (questionnaires[crowdId] && questionnaires[crowdId][QuestionnaireType.IDEA_LIKE]) || {};
    const creationQuestionnaire = (questionnaires[crowdId] && questionnaires[crowdId][QuestionnaireType.IDEA_CREATION]) || {};

    return <Grid container spacing={2}>
        <Grid item xs={12}>
            <IdeaCreationQuestions crowdId={crowdId} questionnaire={creationQuestionnaire}/>
        </Grid>
        <Grid item xs={12}>
            <IdeaQuestionnaire crowdId={crowdId} questionnaire={likeQuestionnaire}/>
        </Grid>
    </Grid>;
};

const mapStateToProps = state => {
    return {
        questionnaires: state.questionnaires
    }
};

export default connect(mapStateToProps, null)(IdeaSettings);