import React from "react";
import {injectIntl} from "react-intl";
import IdeaCategoriesTable from "./IdeaCategoriesTable";
import IdeaCategoryView from "./IdeaCategoryEdit";
import {createCategory, deleteCategory, updateCategory} from "../../actions/categories";
import {compose} from "redux";
import {connect} from "react-redux";
import {Route, Switch} from "react-router-dom";
import IdeaCategoryCreate from "./IdeaCategoryCreate";

export const categoryDefaults = {
    name: '',
    description: '',
    visible: true,
};

const IdeaCategories = props => {

    const {
        createCategory,
        updateCategory,
        deleteCategory,
        crowdId
    } = props;

    const categories = (props.categories[crowdId] && props.categories[crowdId].items) || [];

    return <Switch>
        <Route path="/crowds/:crowdId/categories" exact
               render={() => <IdeaCategoriesTable crowdId={crowdId}
                                                  categories={categories}
                                                  deleteCategory={deleteCategory}/>}/>
        <Route path="/crowds/:crowdId/categories/create" exact
               render={() => <IdeaCategoryCreate crowdId={crowdId}
                                                 createCategory={createCategory}/>}/>
        <Route path="/crowds/:crowdId/categories/:categoryId" exact
               render={() => <IdeaCategoryView crowdId={crowdId}
                                               categories={categories}
                                               updateCategory={updateCategory}/>}/>
    </Switch>;
};

const mapStateToProps = state => {
    return {
        categories: state.categories
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        createCategory: (crowdId, category, successCallback) => dispatch(createCategory(category, crowdId, successCallback)),
        updateCategory: (crowdId, category, successCallback) => dispatch(updateCategory(category, crowdId, successCallback)),
        deleteCategory: (crowdId, categoryId, successCallback) => dispatch(deleteCategory(categoryId, crowdId, successCallback))
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(IdeaCategories);