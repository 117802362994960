import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";

export function createIdeaRating(ideaId, value) {
    return async dispatch => {
        secureFetch(API_URL + "/ideaRating",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ideaId,
                    value
                })
            })
            .then(response => extractBodyOrThrow(response))
            .then(rating => {
                dispatch(ideaRated(ideaId, rating.averageRating));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_rated", 3000));
            })
            .catch(error => console.log(error.message));
    };
}

function ideaRated(ideaId, averageRating) {
    return {
        type: ActionType.IDEA_RATED,
        ideaId,
        averageRating
    };
}

