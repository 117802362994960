import {injectIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import Button from "@material-ui/core/Button";
import Spinner from "../common/Spinner";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {IdeaQuestionType} from "../../constants";
import TextField from "@material-ui/core/TextField";
import useDeepCompareEffect from "use-deep-compare-effect";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Grow from "@material-ui/core/Grow";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    errorText: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
        color: 'red'
    }
}));


const QuestionnaireDialog = props => {

    const classes = useStyles();
    const {open, questionnaire, createIdeaLike, onClose, ideaId, domain} = props;
    const [validationErrors, setValidationErrors] = React.useState([]);
    const validators = {
        [IdeaQuestionType.TEXT]: value => {
            if (value === '')
                return 'idea_question_validation_answer_empty';
        },
        [IdeaQuestionType.SINGLE_CHOICE]: value => {
            if (value === '')
                return 'idea_question_validation_option_empty';
        },
        [IdeaQuestionType.MULTIPLE_CHOICE]: value => {
            if (value.length === 0)
                return 'idea_question_validation_option_empty';
        },
    };

    const initState = () => {
        if (questionnaire.questions) {
            return questionnaire.questions.map(q => {
                let answer = '';
                if (q.questionType === IdeaQuestionType.SINGLE_CHOICE) {
                    answer = q.options[0];
                }
                if (q.questionType === IdeaQuestionType.MULTIPLE_CHOICE) {
                    answer = [];
                }
                return {
                    questionId: q.id,
                    answer
                };
            });
        } else return [];
    };

    const [answers, setAnswers] = React.useState(initState());

    useDeepCompareEffect(() => {
        setAnswers(initState());
    }, [questionnaire]);

    const handleQuestionInputChange = index => event => {
        if (validationErrors[index]) {
            const clearError = [...validationErrors];
            clearError[index] = undefined;
            setValidationErrors(clearError);
        }

        const newState = [...answers];
        newState[index].answer = event.target.value;
        setAnswers(newState);
    };

    const handleMultipleChoiceChange = index => event => {
        if (validationErrors[index]) {
            const clearError = [...validationErrors];
            clearError[index] = undefined;
            setValidationErrors(clearError);
        }

        const answer = event.target.checked ?
            [...answers[index].answer, event.target.value] :
            answers[index].answer.filter(a => a !== event.target.value);

        if (answer.length === 0) {
            const errors = [...validationErrors];
            errors[index] = 'idea_question_validation_option_empty';
            setValidationErrors(errors);
        }

        const newState = [...answers];
        newState[index].answer = answer;
        setAnswers(newState);
    };

    const handleClose = () => {
        onClose();
        setAnswers(initState());
    };

    const validateInput = () => {
        const result = questionnaire.questions.map(q => q.questionType)
            .map((questionType, index) => {
                const validator = validators[questionType];
                return validator(answers[index].answer);
            });

        setValidationErrors(result);
        return result.filter(err => err !== undefined).length === 0;
    };

    const handleSubmit = () => {
        if (validateInput())
            createIdeaLike(domain, ideaId, questionnaire.id, answers, handleClose);
    };

    const questionAnswerElements = (question, index) => {
        switch (question.questionType) {
            case IdeaQuestionType.TEXT:
                return <Grid item xs={12} style={{marginBottom: '20px'}}>
                    <TextField
                        label={props.intl.formatMessage({id: 'idea_question_answer'})}
                        variant="outlined"
                        name={`question${index}`}
                        margin="dense"
                        fullWidth
                        error={validationErrors[index]}
                        helperText={validationErrors[index] && props.intl.formatMessage({id: validationErrors[index]})}
                        value={(answers[index] && answers[index].answer) || ''}
                        onChange={handleQuestionInputChange(index)}/>
                </Grid>;
            case IdeaQuestionType.SINGLE_CHOICE:
                return <>
                    {question.options.map(option =>
                        <Grid item xs={12} sm={4}
                              style={{width: "100%", display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                            <Radio
                                color='primary'
                                checked={answers[index] && answers[index].answer === option}
                                onChange={handleQuestionInputChange(index)}
                                value={option}
                                name={`question${index}`}/>
                            <Typography component='span'>{option}</Typography>
                        </Grid>
                    )}
                </>;
            case IdeaQuestionType.MULTIPLE_CHOICE:
                return <>
                    {question.options.map(option =>
                        <Grid item xs={12} sm={4} style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
                            <Checkbox
                                color='primary'
                                checked={answers[index] ? answers[index].answer && answers[index].answer.includes(option) : false}
                                onChange={handleMultipleChoiceChange(index)}
                                value={option}
                                name={`question${index}`}/>
                            <Typography component='span'>{option}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Grow in={validationErrors[index]}>
                            <Typography variant={'caption'} className={classes.errorText}>
                                {validationErrors[index] && props.intl.formatMessage({id: validationErrors[index]})}
                            </Typography>
                        </Grow>
                    </Grid>
                </>;
            default:
                return <div/>;
        }
    };

    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='lg'
            fullWidth
            scroll='body'>
            <DialogTitle>
                {props.intl.formatMessage({id: 'idea_questionnaire'})}
            </DialogTitle>
            <DialogContent dividers={true}>
                {questionnaire.questions !== undefined ? <Grid container
                                                               direction="row"
                                                               justify="flex-start"
                                                               alignItems="flex-start"
                                                               spacing={2}
                >
                    {questionnaire.questions.map((q, index) => <>
                            <Grid item xs={12}>
                                <Typography>
                                    {`${index + 1}. `}{q.questionText}
                                </Typography>
                            </Grid>
                            {questionAnswerElements(q, index)}
                        </>
                    )}
                </Grid> : <Spinner/>}
            </DialogContent>
            <DialogActions>
                <Button color='primary' variant="contained" style={{color: 'white'}} onClick={handleClose}>
                    {props.intl.formatMessage({id: 'close'})}
                </Button>
                <Button color='primary' variant="contained" style={{color: 'white'}} onClick={handleSubmit}>
                    {props.intl.formatMessage({id: 'submit'})}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default injectIntl(QuestionnaireDialog);