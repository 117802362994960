import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import {lighten, makeStyles} from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import GroupIcon from '@material-ui/icons/Group';
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../utils/utils";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../common/TablePaginationActions";
import CrowdUsersTableHead from "./CrowdUsersTableHead";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Button from "@material-ui/core/Button";
import useDeepCompareEffect from "use-deep-compare-effect";
import CrowdUsersRolesMenu from "./CrowdUsersRolesMenu";
import capitalize from "@material-ui/core/utils/capitalize";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from "clsx";
import {CrowdUserType} from "../../constants";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import CrowdUsersMenu from "./CrowdUsersMenu";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    iconTableCell: {
        paddingTop: 0,
        paddingBottom: 0
    },
    toolbarContent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    tableContainer: {
        maxHeight: '65vh'
    },
    button: {
        background: 'transparent',
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
        },
        textTransform: 'none'
    },
    buttonGreenBackground: {
        background: 'yellowgreen'
    },
    status: {
        height: '30px'
    },
    saveButton: {
        color: 'white',
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    pagination: {
        '& div.MuiToolbar-root': {
            paddingLeft: 0,
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center'
            }
        },
        '& div.MuiTablePagination-spacer': {
            flex: 'none'
        }
    }
}));


const CrowdUsersTable = props => {

    const classes = useStyles();
    const {crowdUsers = [], deleteCrowdUser, allowedUserRoles, updateCrowdUsers, userCounts} = props;
    const [crowdUsersState, setCrowdUsersState] = React.useState(crowdUsers);
    const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
    const [rolesMenuAnchorElement, setRolesMenuAnchorElement] = React.useState(null);
    const [crowdUser, setCrowdUser] = React.useState({});

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('email');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [tooManyUsers, setTooManyUsers] = React.useState(false);


    useDeepCompareEffect(() => {
        setCrowdUsersState(crowdUsers);
    }, [crowdUsers]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const displayedRowsLabel = ({from, to, count}) => {
        const of = props.intl.formatMessage({id: 'of'});
        return `${from}-${to === -1 ? count : to} ${of} ${count}`;
    };

    const handleMenuClick = crowdUser => event => {
        setRolesMenuAnchorElement(event.currentTarget);
        setCrowdUser(crowdUser);
    };

    const handleRowClick = crowdUser => event => {
        setMenuAnchorElement(event.currentTarget);
        setCrowdUser(crowdUser);
    };

    const handleCloseCurried = (crowdUser, newRole) => () => {
        handleClose(crowdUser, newRole);
    };

    const handleClose = (crowdUser, newRole) => {
        if (newRole && crowdUser.userType !== newRole)
            setCrowdUsersState(crowdUsersState.map(user => {
                if (user.email === crowdUser.email) {
                    return {...user, userType: newRole}
                }
                return user;
            }));
        setRolesMenuAnchorElement(null);
        setMenuAnchorElement(null);
        setCrowdUser(crowdUser);
    };

    const handleStatusClick = crowdUser => () => {
        setCrowdUsersState(crowdUsersState.map(user => {
            if (user.email === crowdUser.email) {
                return {...user, disabled: !crowdUser.disabled}
            }
            return user;
        }));
    };

    const isRoleChanged = user => {
        const toCompare = crowdUsers.find(u => u.email === user.email);
        return toCompare && toCompare.userType !== user.userType;
    };

    const isUserStatusChanged = user => {
        const toCompare = crowdUsers.find(u => u.email === user.email);
        return toCompare && toCompare.disabled !== user.disabled;
    };

    const validateSubscriptionLimits = () => {
        const adminCount = crowdUsersState.filter(user => user.userType === CrowdUserType.CROWD_ADMIN && !user.disabled).length -
            crowdUsersState.filter(user => user.userType === CrowdUserType.CROWD_ADMIN && user.disabled).length;
        const managerCount = crowdUsersState.filter(user => user.userType === CrowdUserType.CROWD_MANAGER && !user.disabled).length -
            crowdUsersState.filter(user => user.userType === CrowdUserType.CROWD_MANAGER && user.disabled).length;

        return !((userCounts[CrowdUserType.CROWD_ADMIN].limit > 0 && adminCount > userCounts[CrowdUserType.CROWD_ADMIN].limit) ||
            (userCounts[CrowdUserType.CROWD_MANAGER].limit > 0 && managerCount > userCounts[CrowdUserType.CROWD_MANAGER].limit));
    };

    const handleUpdate = () => {

        if (!validateSubscriptionLimits()) {
            setTooManyUsers(true);
            return;
        }

        const keyedOriginalState = crowdUsers.reduce((acc, cur) => {
            acc[cur.email] = cur;
            return acc;
        }, {});
        const keyedState = crowdUsersState.reduce((acc, cur) => {
            acc[cur.email] = cur;
            return acc;
        }, {});

        const toUpdate = Object.entries(keyedState).reduce((acc, [key, value]) => {
            if (value.userType !== keyedOriginalState[key].userType || value.disabled !== keyedOriginalState[key].disabled)
                acc.push(value);
            return acc;
        }, []);

        if (toUpdate.length) {
            updateCrowdUsers(toUpdate);
            setTooManyUsers(false);
        }
    };

    const paginationActions = window.innerWidth > 600 ? {ActionsComponent: TablePaginationActions} : {ActionsComponent: 'span'};

    return <>
        <Card elevation={3}>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar}>
                        <GroupIcon/>
                    </Avatar>
                }
                title={<Typography
                    className={'cardHeaderText'}>{props.intl.formatMessage({id: 'crowd_users'})}</Typography>}
                className={'cardHeader'}
            />
            <CardContent className={'cardContent'}>
                <Grid container
                      direction="column"
                      justify="center"
                      style={{minHeight: '40vh'}}>
                    {crowdUsersState.length ? (<>
                        <Grid item>
                            <TableContainer className={classes.tableContainer}>
                                <Table size={'medium'} stickyHeader>
                                    <CrowdUsersTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {(rowsPerPage > 0 ? stableSort(crowdUsersState, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : stableSort(crowdUsersState, getComparator(order, orderBy)))
                                            .map(row => {
                                                return (<>
                                                        <Hidden smUp>
                                                            <TableRow
                                                                onClick={handleRowClick(row)}
                                                                tabIndex={-1}
                                                                key={row.name}>
                                                                <TableCell align="left">{row.email}</TableCell>
                                                            </TableRow>
                                                        </Hidden>
                                                        <Hidden xsDown>
                                                            <TableRow
                                                                tabIndex={-1}
                                                                key={row.name}>
                                                                <TableCell align="left">{row.email}</TableCell>
                                                                <TableCell align="left">
                                                                    <Button
                                                                        fullWidth
                                                                        size='small'
                                                                        disableElevation
                                                                        onClick={handleMenuClick(row)}
                                                                        variant="contained"
                                                                        endIcon={<ExpandMoreIcon/>}
                                                                        className={clsx(classes.button, {[classes.buttonGreenBackground]: isRoleChanged(row)})}
                                                                    >
                                                                        {capitalize(props.intl.formatMessage({id: row.userType.toLowerCase()}))}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell align="left">
                                                                    <Button
                                                                        fullWidth
                                                                        size='small'
                                                                        disableElevation
                                                                        onClick={handleStatusClick(row)}
                                                                        variant="contained"
                                                                        className={clsx(classes.button, {[classes.buttonGreenBackground]: isUserStatusChanged(row)})}
                                                                    >
                                                                        {props.intl.formatMessage({id: row.disabled ? 'disabled' : 'enabled'})}
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell align="right"
                                                                           className={classes.iconTableCell}>
                                                                    <IconButton onClick={deleteCrowdUser(row.id)}>
                                                                        <DeleteIcon color='primary'/>
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        </Hidden>
                                                    </>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs/>
                    </>) : (<>
                            <Grid item>
                                <TableContainer className={classes.tableContainer}>
                                    <Table size={'medium'} stickyHeader>
                                        <CrowdUsersTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                        />

                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs/>
                            <Grid item>
                                <Typography variant="h6" align='center'>
                                    {props.intl.formatMessage({id: 'no_crowd_users'})}
                                </Typography>
                            </Grid>
                            <Grid item xs/>
                        </>
                    )}
                    <Grid item>
                        <TablePagination
                            className={classes.pagination}
                            component="div"
                            rowsPerPageOptions={[10, 25, 50, {
                                label: props.intl.formatMessage({id: 'all_rows'}),
                                value: -1
                            }]}
                            count={crowdUsersState.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            {...paginationActions}
                            labelRowsPerPage={props.intl.formatMessage({id: 'rows_per_page'})}
                            labelDisplayedRows={displayedRowsLabel}
                        />
                    </Grid>
                    <Hidden smUp>
                        <Grid item style={{textAlign: 'center'}}>
                            <TablePaginationActions count={crowdUsersState.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}/>
                        </Grid>
                    </Hidden>
                    <Grid item xs={12} sm={'auto'}>
                        <Button color='primary' variant="contained" className={classes.saveButton}
                                onClick={handleUpdate}>
                            {props.intl.formatMessage({id: 'save'})}
                        </Button>
                    </Grid>
                    {tooManyUsers &&
                    <Grid item xs={12} sm>
                        <Grow in={tooManyUsers}>
                            <Typography variant={'caption'} style={{color: 'red'}}>
                                {props.intl.formatMessage({id: 'validation_crowd_users_too_many'})}
                            </Typography>
                        </Grow>
                    </Grid>}
                </Grid>
            </CardContent>
        </Card>
        <CrowdUsersRolesMenu handleClose={handleCloseCurried}
                             allowedUserRoles={allowedUserRoles}
                             anchorEl={rolesMenuAnchorElement}
                             crowdUser={crowdUser}/>
        <CrowdUsersMenu handleClose={handleClose}
                        handleStatusClick={handleStatusClick}
                        allowedUserRoles={allowedUserRoles}
                        anchorEl={menuAnchorElement}
                        crowdUser={crowdUser}/>
    </>;
};

export default injectIntl(CrowdUsersTable);