import React from 'react';
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {injectIntl} from "react-intl";
import {composeNotificationLink} from "../../actions/notification";
import {useHistory} from "react-router-dom";
import {lighten, makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import clsx from "clsx";
import ListItemText from "@material-ui/core/ListItemText";
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Divider from "@material-ui/core/Divider";
import {formatDate, shortenString} from "../../utils/utils";
import {UserNotificationSettingsLocalizationCode} from "../../constants";

const useStyles = makeStyles(theme => ({
    unread: {
        backgroundColor: lighten(theme.palette.secondary.main, 0.85),
    },
    highlight: {
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
    },
}));

const NotificationsMenu = props => {

    const classes = useStyles();
    const {handleClose, anchorEl, notifications, intl, markNotificationsAsRead} = props;
    const history = useHistory();

    const handleClick = notification => () => {
        if (!notification.readAt)
            markNotificationsAsRead([notification.id]);

        const link = composeNotificationLink(notification);
        window.location.href = `https://${link}`;
        handleClose();
    };

    const expandNotifications = () => {
        handleClose();
        history.push(`/account/notificationHistory`);
    };

    const markAllAsRead = () => {
        markNotificationsAsRead();
    };

    return <Popper open={Boolean(anchorEl)}
                   anchorEl={anchorEl}
                   role={undefined}
                   transition
                   placement='bottom-end'
                   style={{zIndex: 1200, width: '100%', maxWidth: 360}}>
        {({TransitionProps}) => (
            <Grow {...TransitionProps}>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <List dense>
                            {notifications.items.length ? <>
                                <ListItem key={'markAllAsRead'}
                                          value={'markAllAsRead'}
                                          button
                                          className={classes.highlight}
                                          onClick={markAllAsRead}>
                                    <Typography align='center' style={{width: '100%'}}>
                                        {intl.formatMessage({id: 'notification_mark_all_as_read'})}
                                    </Typography>
                                </ListItem>
                                <Divider/>
                                {notifications.items.slice(0, 5).map(notification =>
                                    <ListItem key={notification.id}
                                              value={notification}
                                              button
                                              className={clsx({[classes.unread]: !notification.readAt}, classes.highlight)}
                                              onClick={handleClick(notification)}>
                                        <ListItemText
                                            primary={intl.formatMessage({id: UserNotificationSettingsLocalizationCode[notification.type]})}
                                            secondary={shortenString(notification.data.name, 25)}>
                                        </ListItemText>
                                        {!notification.readAt &&
                                        <FiberNewIcon color='secondary' style={{marginRight: '16px'}}/>}
                                        <Typography>
                                            {formatDate(notification.createdAt)}
                                        </Typography>
                                    </ListItem>
                                )}
                            </> : <ListItem key={'noneYet'}
                                            value={'noneYet'}>
                                <Typography align='center' style={{width: '100%'}}>
                                    {intl.formatMessage({id: 'notifications_history_empty'})}
                                </Typography>
                            </ListItem>}
                            <Divider/>
                            <ListItem key={'expandNotifications'}
                                      value={'expandNotifications'}
                                      button
                                      className={classes.highlight}
                                      onClick={expandNotifications}>
                                <Typography align='center' style={{width: '100%'}}>
                                    . . .
                                </Typography>
                            </ListItem>
                        </List>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>;
};

export default injectIntl(NotificationsMenu);