import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import {lighten, makeStyles} from "@material-ui/core/styles";
import HistoryIcon from '@material-ui/icons/History';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import InfiniteScroll from "../common/InfiniteScroll";
import Spinner from "../common/Spinner";
import clsx from "clsx";
import {composeNotificationLink} from "../../actions/notification";
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Button from "@material-ui/core/Button";
import {formatDate} from "../../utils/utils"
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import {UserNotificationSettingsLocalizationCode} from "../../constants";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        marginRight: '16px'
    },
    unread: {
        backgroundColor: lighten(theme.palette.secondary.main, 0.85),
    },
    highlight: {
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
    },
    header: {
        display: 'flex',
        padding: '16px',
        alignItems: 'center'
    }
}));


const NotificationHistory = props => {

    const classes = useStyles();
    const {handleTabChange, notifications, intl, loadNotifications, markNotificationsAsRead} = props;
    handleTabChange();

    const loadAdditionalNotifications = cb => {
        loadNotifications(cb, notifications.items.length, 20);
    };

    const handleClick = notification => () => {
        if (!notification.readAt)
            markNotificationsAsRead([notification.id]);

        const link = composeNotificationLink(notification);
        window.location.href = `https://${link}`;
    };

    const markAllAsRead = () => {
        markNotificationsAsRead();
    };

    if (!notifications.loaded)
        return <Spinner/>;

    return <Card elevation={3}>
        <div className={clsx(classes.header, 'cardHeader')}>
            <Avatar className={classes.avatar}>
                <HistoryIcon/>
            </Avatar>
            <div style={{flex: '1 1 auto'}}>
                <Typography className={'cardHeaderText'}>
                    {props.intl.formatMessage({id: 'notifications_history'})}
                </Typography>
                {notifications.unreadCount > 0 &&
                <Typography>
                    {props.intl.formatMessage({id: 'notifications_history_you_have'})}
                    &nbsp;
                    {notifications.unreadCount}
                    &nbsp;
                    {props.intl.formatMessage({id: 'notifications_history_new_notifications'})}
                </Typography>}
            </div>
            <Hidden xsDown>
                {notifications.items.length &&
                <Button color='primary' variant="contained" style={{color: 'white'}} onClick={markAllAsRead}>
                    {props.intl.formatMessage({id: 'notification_mark_all_as_read'})}
                </Button>
                }
            </Hidden>
        </div>
        <Hidden smUp>
            <CardContent>
                <Button color='primary' fullWidth variant="contained" style={{color: 'white'}} onClick={markAllAsRead}>
                    {props.intl.formatMessage({id: 'notification_mark_all_as_read'})}
                </Button>
            </CardContent>
        </Hidden>
        <CardContent className={'cardContent'}>
            {notifications.items.length ? <>
                <List dense style={{minHeight: '65vh'}}>
                    <InfiniteScroll
                        loadMore={loadAdditionalNotifications}
                        hasMore={notifications.items.length < notifications.allCount}
                        threshold={100}
                        loader={<Spinner minHeight={'15vh'}/>}
                    >
                        {notifications.items.map(notification =>
                            <ListItem key={notification.id}
                                      value={notification}
                                      button
                                      className={clsx({[classes.unread]: !notification.readAt}, classes.highlight)}
                                      onClick={handleClick(notification)}>
                                <ListItemText
                                    primary={intl.formatMessage({id: UserNotificationSettingsLocalizationCode[notification.type]})}
                                    secondary={notification.data.name}>
                                </ListItemText>
                                {!notification.readAt &&
                                <FiberNewIcon color='secondary' style={{marginRight: '16px'}}/>}
                                <Typography>
                                    {formatDate(notification.createdAt)}
                                </Typography>
                            </ListItem>
                        )}
                    </InfiniteScroll>
                </List>
            </> : <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
                style={{minHeight: '65vh'}}>
                <Grid item xs/>
                <Grid item xs={12}>
                    <Typography align='center'>
                        {intl.formatMessage({id: 'notifications_history_empty'})}
                    </Typography>
                </Grid>
                <Grid item xs/>
            </Grid>
            }
        </CardContent>
    </Card>
};

export default injectIntl(NotificationHistory);