import {injectIntl} from "react-intl";
import React, {useCallback, useRef, useState} from "react";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import {API_URL, ValidImageTypes} from "../../constants";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import FaceIcon from '@material-ui/icons/Face';
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    centerOnMobile: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    errorText: {
        color: 'red'
    },
    image: {
        maxHeight: '590px',
        maxWidth: '890px',
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
            maxHeight: '425px',
            maxWidth: '640px'
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: '400px',
            maxWidth: '520px'
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: '170px',
            maxWidth: '250px'
        }
    },
    deleteMainImageButton: {
        position: 'absolute',
        marginLeft: '-24px',
        marginTop: '-24px'
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    backupImage: {
        display: 'inline-block',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: '#fafafa',
        color: '#f1f1f1',
        [theme.breakpoints.up('md')]: {
            width: '300px'
        }
    }
}));

function getCroppedImg(image, crop) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            // blob.name = 'cropped.jpg';
            resolve(blob);
        }, 'image/jpeg', 1);
    });
}

const UserAvatar = props => {

    const classes = useStyles();
    const {user, updateAccount, uploadUserAvatar} = props;
    const [avatar, setAvatar] = useState(false);
    const [avatarError, setAvatarError] = useState(false);
    const imgRef = useRef(null);
    const [crop, setCrop] = useState({unit: "%", width: 20, aspect: 1});

    const selectFile = event => {
        setAvatarError(false);
        const file = event.target.files[0];
        if (!file)
            return;

        if (!ValidImageTypes.includes(file.type)) {
            setAvatarError(true);
            return;
        }

        const reader = new FileReader();
        reader.addEventListener("load", () => setAvatar(reader.result));
        reader.readAsDataURL(file);
    };

    const uploadAvatar = async () => {
        if (avatarError)
            return;

        const croppedFile = await getCroppedImg(imgRef.current, crop);

        const body = new FormData();
        body.append('avatar', croppedFile);
        body.append('id', user.id);
        body.append('version', user.version);

        const successCb = () => {
            setAvatarError(false);
            setAvatar(false);
        };
        uploadUserAvatar(body, successCb);
    };

    const removeAvatar = event => {
        event.stopPropagation();
        updateAccount({
            id: user.id,
            version: user.version,
            avatarImageId: null
        });
    };

    const onLoad = useCallback(img => {
        imgRef.current = img;
    }, []);

    const clearImage = () => {
        setAvatar(false);
        setAvatarError(false);
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <FaceIcon/>
                </Avatar>
            }
            title={<Typography className={'cardHeaderText'}>{props.intl.formatMessage({id: 'avatar'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                alignItems="center"
                spacing={1}
            >
                <Grid item xs={12}>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={selectFile}
                        id="avatar-img"
                        type="file"
                    />
                </Grid>
                {!avatar && (!(user.avatarImageId === undefined || user.avatarImageId === null) ? (
                    <Grid item xs={12}>
                        <label htmlFor="avatar-img">
                            <img className={classes.image} loading="lazy"
                                 src={`${API_URL}/file?fileId=${user.avatarImageId}`}/>
                            <IconButton color='primary' className={classes.deleteMainImageButton}
                                        onClick={removeAvatar}>
                                <CancelIcon/>
                            </IconButton>
                        </label>
                        <div style={{flexGrow: 1, display: 'inline-block'}}/>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <label htmlFor="avatar-img">
                            <div className={classes.backupImage}>
                                <AccountCircleIcon style={{height: '230px', width: '230px'}}/>
                            </div>
                        </label>
                        <div style={{flexGrow: 1, display: 'inline-block'}}/>
                    </Grid>
                ))}
                {avatar && <Grid item xs={12}>
                    <ReactCrop
                        src={avatar}
                        crop={crop}
                        onImageLoaded={onLoad}
                        onChange={c => setCrop(c)}
                    />
                </Grid>}
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'avatar_upload_hint'})}
                    </Typography>
                </Grid>
                {avatarError && (<>
                        <Grid item xs={12} sm={'auto'}>
                            <Typography className={classes.centerOnMobile}>
                                {avatar.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant={'caption'} className={clsx(classes.errorText, classes.centerOnMobile)}>
                                {props.intl.formatMessage({id: 'validation_account_avatar'})}
                            </Typography>
                        </Grid>
                    </>
                )}
                {avatar && <>
                    <Grid item xs={12} sm='auto'>
                        <Button variant="contained" color="primary" className={classes.button} disabled={avatarError}
                                onClick={uploadAvatar}>
                            {props.intl.formatMessage({id: 'upload'})}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm='auto'>
                        <Button variant="contained" color="primary" className={classes.button} onClick={clearImage}>
                            {props.intl.formatMessage({id: 'cancel'})}
                        </Button>
                    </Grid>
                </>}
            </Grid>
        </CardContent>
    </Card>
};

export default injectIntl(UserAvatar);