import {connect} from "react-redux";
import React from "react";
import {Redirect} from "react-router-dom";

export function withAnonymous(ToRender) {

    const anonymous = props => {

        const {user} = props;

        if (user.loggedIn) {
            return <Redirect to="/"/>
        }

        return <ToRender {...props}/>;
    };

    const mapStateToProps = state => {
        return {
            user: state.user
        }
    };

    return connect(mapStateToProps)(anonymous)
}