import React from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {useHistory} from "react-router-dom";
import {useScrollTrigger} from "@material-ui/core";
import SidebarButton from "./SidebarButton";

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: 'white'
    },
    link: {
        textDecoration: 'none',
        color: 'black'
    },
    toolBarDesktop: {
        height: '70px'
    },
    toolBarMobile: {
        height: '65px'
    },
    crowdeaLogo: {
        cursor: 'pointer',
        height: '30px'
    },
}));

function ElevationScroll(props) {
    const {children} = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0
    });
}

const Navbar = props => {

    const classes = useStyles();
    const history = useHistory();

    return <>
        <Hidden lgUp>
            <ElevationScroll>
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.toolBarMobile}>
                        <img className={classes.crowdeaLogo} src="/images/crowdea.png"
                             onClick={() => history.push('/')}/>
                        <Grid container
                              direction="row"
                              justify="flex-end"
                              alignItems="center"
                              spacing={4}
                        >
                            <SidebarButton toggleDrawer={props.toggleDrawer}/>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
        </Hidden>
        <Hidden mdDown>
            <ElevationScroll {...props}>
                <AppBar position="fixed" className={classes.appBar}>
                    <Container maxWidth="xl">
                        <Toolbar className={classes.toolBarDesktop}>
                            <img className={classes.crowdeaLogo} src="/images/crowdea.png"
                                 onClick={() => history.push('/')}/>
                            <Grid container
                                  direction="row"
                                  justify="flex-end"
                                  alignItems="center"
                                  spacing={4}
                            >
                                {props.children}
                            </Grid>
                        </Toolbar>
                    </Container>
                </AppBar>
            </ElevationScroll>
        </Hidden>
    </>
};

export default injectIntl(Navbar);