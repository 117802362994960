import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";
import {userUpdated} from "./user";

export function loadAllSubscriptions() {
    return async dispatch => {
        secureFetch(API_URL + "/subscription",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(subscriptions => dispatch(subscriptionsLoaded(subscriptions)))
            .catch(error => console.log(error.message));
    };
}

function subscriptionsLoaded(items) {
    return {
        type: ActionType.SUBSCRIPTIONS_LOADED,
        items
    };
}

export function loadSubscription(crowdId) {
    return async dispatch => {
        secureFetch(API_URL + "/subscription/" + crowdId,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(subscription => dispatch(subscriptionLoaded(crowdId, subscription.current, subscription.next)))
            .catch(error => console.log(error.message));
    };
}

export function createSubscription(requestedTier, crowdId, paymentMethodId, successCallback, errorCallback) {
    return async dispatch => {
        secureFetch(API_URL + "/subscription",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    requestedTier,
                    paymentMethodId,
                    crowdId
                })
            })
            .then(response => {
                if (response.status === 402) {
                    errorCallback();
                    dispatch(emitAppNotification(NotificationType.ERROR, "credit_card_error", 3000));
                    throw Error();
                }

                return extractBodyOrThrow(response)
            })
            .then(body => {
                const {subscription, user} = body;

                dispatch(subscriptionLoaded(crowdId, subscription.current, subscription.next));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "subscription_created", 3000));

                if (user)
                    dispatch(userUpdated(user));

                if (successCallback)
                    successCallback();
            })
            .catch(error => {
                if (errorCallback)
                    errorCallback();
                console.log(error.message)
            });
    };
}

export function cancelSubscription(crowdId) {
    return async dispatch => {
        secureFetch(API_URL + "/subscription/" + crowdId,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(subscription => {
                dispatch(emitAppNotification(NotificationType.SUCCESS, "subscription_cancelled", 3000));
                dispatch(subscriptionLoaded(crowdId, subscription.current, subscription.next));
            })
            .catch(error => console.log(error.message));
    };
}

function subscriptionLoaded(crowdId, current, next) {
    return {
        type: ActionType.SUBSCRIPTION_LOADED,
        current,
        next,
        crowdId
    };
}