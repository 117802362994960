import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import useDeepCompareEffect from "use-deep-compare-effect";
import {isEqual} from 'lodash';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));


const CrowdBillingInfo = props => {

    const classes = useStyles();
    const {crowd, updateCrowd} = props;
    const [billingInfoState, setBillingInfoState] = React.useState(crowd.billingInfo);
    const [validationErrors, setValidationErrors] = React.useState({});
    const validators = {
        name: () => {
            if (billingInfoState.name === '') return 'validation_crowd_billing_name';
            return true;
        },
        companyName: () => {
            if (billingInfoState.companyName === '') return 'validation_crowd_billing_companyName';
            return true;
        },
        street: () => {
            if (billingInfoState.street === '') return 'validation_crowd_billing_street';
            return true;
        },
        postalCode: () => {
            if (billingInfoState.postalCode === '') return 'validation_crowd_billing_postalCode';
            return true;
        },
        city: () => {
            if (billingInfoState.city === '') return 'validation_crowd_billing_city';
            return true;
        },
        country: () => {
            if (billingInfoState.country === '') return 'validation_crowd_billing_country';
            return true;
        },
        companyId: () => {
            if (billingInfoState.companyId === '') return 'validation_crowd_billing_companyId';
            return true;
        },
        taxId: () => {
            if (billingInfoState.taxId === '') return 'validation_crowd_billing_taxId';
            return true;
        }
    };

    useDeepCompareEffect(() => {
        setBillingInfoState(crowd.billingInfo);
    }, [crowd]);

    const validateInput = () => {
        const result = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});

        setValidationErrors(result);
        return Object.keys(result).length === 0;
    };

    const handleInputChange = name => event => {
        setBillingInfoState({
            ...billingInfoState,
            [name]: event.target.value
        })
    };

    const changesMade = () => {
        return !isEqual(billingInfoState, crowd.billingInfo);
    };

    const handleSubmit = () => {
        if (validateInput() && changesMade()) {
            updateCrowd({
                id: crowd.id,
                version: crowd.version,
                billingInfo: billingInfoState
            });
        }
    };

    const commonTextFieldProps = (name, i18n) => {
        return {
            label: props.intl.formatMessage({id: i18n}),
            variant: "outlined",
            name: name,
            margin: "dense",
            fullWidth: true,
            error: validationErrors[name],
            helperText: validationErrors[name] && props.intl.formatMessage({id: validationErrors[name]}),
            value: billingInfoState[name],
            onChange: handleInputChange(name)
        };
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <CreditCardIcon/>
                </Avatar>
            }
            title={<Typography
                className={'cardHeaderText'}>{props.intl.formatMessage({id: 'crowd_billing_info'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('name', 'crowd_billing_name')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('companyName', 'crowd_billing_companyName')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('street', 'crowd_billing_street')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('postalCode', 'crowd_billing_postalCode')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('city', 'crowd_billing_city')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('country', 'crowd_billing_country')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('companyId', 'crowd_billing_companyId')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('taxId', 'crowd_billing_taxId')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('vatId', 'crowd_billing_vatId')}/>
                </Grid>
                <Grid item xs={12} sm='auto'>
                    <Button color='primary' variant="contained" className={classes.button} onClick={handleSubmit}>
                        {props.intl.formatMessage({id: 'save'})}
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
};

export default injectIntl(CrowdBillingInfo);