import React, {useEffect, useState} from "react";

const InfiniteScroll = props => {
    const {loadMore, hasMore, loader, threshold = 0, children} = props;
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (!isFetching) return;
        loadMore(() => setIsFetching(false));
    }, [isFetching]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [hasMore]);

    const handleScroll = () => {
        if (isFetching || !hasMore)
            return;

        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - threshold) {
            setIsFetching(hasMore);
        }
    };

    return <>
        {children}
        {isFetching && (loader)}
    </>;
};

export default InfiniteScroll;