import {ActionType} from "../constants.js";
import {emitAppNotification, throwOnError} from "./common";
import {API_URL, NotificationType} from "../constants";
import {store} from "../store/configureStore";
import {websocketDisconnect} from "./websocket";
import {secureFetch} from "../http/secureFetch";

export function login(loginInfo, redirectHandler) {
    return async dispatch => {
        fetch(API_URL + "/login",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(loginInfo)
            })
            .then(async response => {
                // TODO rework to use response body instead of HTTP codes to determine the login error
                if (!response.ok) {
                    switch (response.status) {
                        case 401:
                            response.json()
                                .then(responseBody => dispatch(emitAppNotification(NotificationType.ERROR, responseBody.error, 6000)))
                                .catch(() => dispatch(emitAppNotification(NotificationType.ERROR, 'invalid_username_password', 6000)));
                            break;
                        case 403:
                            dispatch(emitAppNotification(NotificationType.ERROR, "account_not_verified", 6000));
                            break;
                        default:
                            dispatch(emitAppNotification(NotificationType.ERROR, "default_error", 6000));
                    }
                    throw Error(response.statusText);
                }

                return response;
            })
            .then(() => redirectHandler())
            .catch(error => console.log(error.message));
    };
}

export function userLogOut(keepState = false) {
    return async dispatch => {
        secureFetch(API_URL + "/user/logout",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
            })
            .then(response => throwOnError(response))
            .then(() => {
                dispatch(websocketDisconnect());
                dispatch(userLoggedOut(keepState));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "logout_successful", 3000));
            })
            .catch(error => console.log(error.message));
    }
}

function userLoggedOut(keepState) {
    return {
        type: ActionType.USER_LOGGED_OUT,
        keepState
    };
}

export function sendResetPasswordMail(body) {
    return fetch(API_URL + "/login/sendPasswordResetEmail",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        .then(response => response.json())
        .catch(() => {
            store.dispatch(emitAppNotification(NotificationType.ERROR))
        });
}

export function resetPassword(token, passwordInfo) {
    return fetch(API_URL + "/login/resetPassword/" + token,
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(passwordInfo)
        })
        .then(response => response.json())
        .catch(() => {
            store.dispatch(emitAppNotification(NotificationType.ERROR))
        });
}
