import React from "react";
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: '80px',
        backgroundColor: theme.palette.secondary.light,
        [theme.breakpoints.up('md')]: {
            paddingBottom: '80px'
        },
    },
    text1: {
        fontFamily: 'Open Sans',
        marginBottom: '20px',
        color: theme.palette.indigo.dark,
        fontSize: '60px',
        lineHeight: '1.1',
        [theme.breakpoints.down('xl')]: {
            fontSize: '55px',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '45px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '40px',
        }
    },
    text2: {
        fontWeight: 'bold',
        color: 'grey',
        opacity: '0.8'
    },
    card: {
        height: '300px',
        backgroundColor: theme.palette.secondary.light,
        [theme.breakpoints.down('xs')]: {
            height: 'auto'
        },
    },
    cardText1: {
        fontFamily: 'Open Sans',
        marginBottom: '25px',
        color: theme.palette.indigo.dark,
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        }
    },
    cardText2: {
        fontWeight: 'bold',
        color: 'grey',
        opacity: '0.8',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('md')]: {
            marginRight: '0px',
            width: '100%',
            textAlign: 'center'
        },
    },
    imageWrapper: {
        width: '80px',
        overflowX: 'visible',
        display: 'inline-block'
    }
}));

const Benefits = props => {

    const classes = useStyles();

    return <div className={classes.container}>
        <Container maxWidth="xl" id='benefits'>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={4}
            >
                <Grid item xs={12}>
                    <Typography variant={'h1'} className={classes.text1}>
                        {props.intl.formatMessage({id: 'what_is_crowdea'})}
                    </Typography>
                    <Typography className={classes.text2}>
                        {props.intl.formatMessage({id: 'what_is_crowdea_desc'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card elevation={0} className={classes.card}>
                        <CardHeader
                            classes={{avatar: classes.cardHeader}}
                            avatar={<img src="/images/innovation.png"/>}/>
                        <CardContent>
                            <Typography className={classes.cardText1}>
                                {props.intl.formatMessage({id: 'innovation'})}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'innovation_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card elevation={0} className={classes.card}>
                        <CardHeader
                            classes={{avatar: classes.cardHeader}}
                            avatar={
                                <span className={classes.imageWrapper}>
                                    <img src="/images/ab_testing.png"/>
                            </span>}/>
                        <CardContent>
                            <Typography className={classes.cardText1}>
                                {props.intl.formatMessage({id: 'ab_testing'})}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'ab_testing_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card elevation={0} className={classes.card}>
                        <CardHeader
                            classes={{avatar: classes.cardHeader}}
                            avatar={<span>
                                <img src="/images/loyalty.png"/>
                            </span>}/>
                        <CardContent>
                            <Typography className={classes.cardText1}>
                                {props.intl.formatMessage({id: 'loyalty'})}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'loyalty_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card elevation={0} className={classes.card}>
                        <CardHeader
                            classes={{avatar: classes.cardHeader}}
                            avatar={<span className={classes.imageWrapper}>
                                <img src="/images/sociaL_networks.png"/>
                            </span>}/>
                        <CardContent>
                            <Typography className={classes.cardText1}>
                                {props.intl.formatMessage({id: 'social_networks'})}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'social_networks_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </div>
};

export default injectIntl(Benefits);