import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {store as reduxStore} from "./store/configureStore";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import {MuiThemeProvider} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {muiTheme} from "./style/theme";
import {STRIPE_PUBLISHABLE_KEY} from "./constants";

const stripe = loadStripe(STRIPE_PUBLISHABLE_KEY);

ReactDOM.render(
    <Provider store={reduxStore}>
        <Router>
            <Elements stripe={stripe}>
                <MuiThemeProvider theme={muiTheme}>
                    <CssBaseline/>
                    <App/>
                </MuiThemeProvider>
            </Elements>
        </Router>
    </Provider>
    , document.getElementById('root'));

serviceWorker.unregister();
