import React, {useEffect} from "react";
import {injectIntl} from "react-intl";
import {compose} from "redux";
import {connect} from "react-redux";
import NavbarMenu from "./NavbarMenu";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import {userLogOut} from "../../actions/login";
import CustomerLanding from "./customer/CustomerLanding";
import {ScrollTopButton} from "./ScrollTopButton";
import {Route} from "react-router-dom";
import UserLanding from "./user/UserLanding";
import {CROWDEA_HOSTNAME} from "../../constants";
import {loadMostLiked, loadMostRecent, loadPublicCrowds} from "../../actions/landing";

export const redirectToBlog = () => {
    window.location.href = `https://blog.${CROWDEA_HOSTNAME}`;
};

export function offsetScroll(el) {
    const offset = 100;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = el.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
    });
}

const LandingPage = props => {

    const {user, logout, landingPage, loadCrowds, loadMostLiked, loadMostRecent} = props;
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    useEffect(() => {
        if (!landingPage.crowds.loaded)
            loadCrowds();
        if (!landingPage.mostLiked.loaded)
            loadMostLiked();
        if (!landingPage.mostRecent.loaded)
            loadMostRecent();
    }, []);

    const closeDrawer = event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        toggleDrawer();
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return <>
        <Navbar user={user} logout={logout} toggleDrawer={toggleDrawer}>
            <NavbarMenu user={user} logout={logout}/>
        </Navbar>
        <Sidebar user={user} logout={logout} open={drawerOpen} toggleDrawer={toggleDrawer}
                 closeDrawer={closeDrawer}/>
        <main>
            <Route path="/" exact render={() => <CustomerLanding user={user}/>}/>
            <Route path="/intro" render={() => <UserLanding user={user} landingPage={landingPage}/>}/>
            <ScrollTopButton/>
        </main>
    </>
};

const mapStateToProps = state => {
    return {
        user: state.user,
        landingPage: state.landingPage
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(userLogOut()),
        loadCrowds: () => dispatch(loadPublicCrowds()),
        loadMostLiked: () => dispatch(loadMostLiked()),
        loadMostRecent: () => dispatch(loadMostRecent())
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
)(LandingPage);