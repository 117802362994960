import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";
import {store} from "../store/configureStore";

export function createCrowd(successCallback, errorCallback) {
    return async dispatch => {
        secureFetch(API_URL + "/crowd",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(crowd => {
                dispatch(crowdLoaded(crowd));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "crowd_created", 3000));
                if (successCallback)
                    successCallback(crowd);
            })
            .catch(error => {
                if (errorCallback)
                    errorCallback();
                console.log(error.message)
            });
    };
}

function crowdLoaded(item) {
    return {
        type: ActionType.CROWD_LOADED,
        item
    };
}

export function loadCrowds() {
    return async dispatch => {
        secureFetch(API_URL + "/crowd",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(crowds => dispatch(crowdsLoaded(crowds)))
            .catch(error => console.log(error.message));
    };
}

function crowdsLoaded(items) {
    return {
        type: ActionType.CROWDS_LOADED,
        items
    };
}

export function updateCrowd(crowd, errorCallback) {
    return async dispatch => {
        secureFetch(API_URL + "/crowd",
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(crowd)
            })
            .then(response => {
                if (response.status === 422) {
                    return response.json().then(result => {
                        // dispatch(emitAppNotification(NotificationType.ERROR, result.status, 3000));
                        if (errorCallback)
                            errorCallback(result);
                        return result;
                    });
                } else {
                    response.json().then(updatedCrowd => {
                        dispatch(crowdUpdated(updatedCrowd));
                        dispatch(emitAppNotification(NotificationType.SUCCESS, "crowd_update_successful", 3000));
                    });
                }
            })
            .catch(error => console.log(error.message))
    }
}

export function uploadCoverImage(formData) {
    return secureFetch(API_URL + "/crowd/uploadCoverImage",
        {
            method: 'POST',
            body: formData
        })
        .then(response => {
            response.json().then(updatedCrowd => {
                store.dispatch(crowdUpdated(updatedCrowd));
                store.dispatch(emitAppNotification(NotificationType.SUCCESS, "crowd_update_successful", 3000));
            });
        })
        .catch(error => console.log(error.message));
}

function crowdUpdated(item) {
    return {
        type: ActionType.CROWD_UPDATED,
        item
    };
}

export function updateThemingData(themingData, crowdId) {
    return async dispatch => {
        secureFetch(API_URL + "/crowd/theming",
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(themingData)
            })
            .then(response => response.json())
            .then(updatedTheming => {
                dispatch(themingUpdated(updatedTheming, crowdId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "crowd_update_successful", 3000));
            })
            .catch(error => console.log(error.message))
    }
}

function themingUpdated(item, crowdId) {
    return {
        type: ActionType.THEMING_UPDATED,
        item,
        crowdId
    };
}