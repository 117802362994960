import React from "react";
import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import {injectIntl} from "react-intl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

const Party = props => {

    const {
        isLandingPage,
        buttonClickHandler,
        cardClickHandler,
        hideButtons,
        selected
    } = props;

    const cardElevation = isLandingPage ? 0 : 3;

    return <Card
        className={clsx('pricingCard', selected === 1 ? 'pricingCardSelected' : 'pricingCardHover', {'cursorPointer': selected !== undefined})}
        elevation={cardElevation}
        onClick={cardClickHandler}>
        <div className={'pricingCardImageWrapper'}>
            <img src="/images/party.png"/>
        </div>
        <CardContent>
            <Typography variant={'h4'}
                        className={clsx('pricingCardHeaderText', 'pricingCardItemMargin')}>
                {props.intl.formatMessage({id: 'party'})}
            </Typography>
            <div className={'pricingCardTextWrapper'}>
                <Typography color='primary' component={'span'}
                            className={clsx('pricingCardEuroSign', {'purpleColor': isLandingPage})}>
                    {'€'}
                </Typography>
                <Typography color='primary' component={'span'}
                            className={clsx('pricingCardPriceText', {'purpleColor': isLandingPage})}>
                    {'99'}
                </Typography>
            </div>
            <Typography className={'pricingCardMonthlyText'}>
                {props.intl.formatMessage({id: 'monthly'})}
            </Typography>
            {!hideButtons && <Button variant="contained" color="primary" disableElevation
                                     className={clsx('pricingCardButton', 'pricingCardItemMargin', {'purpleBackground': isLandingPage})}
                                     onClick={buttonClickHandler}>
                {props.intl.formatMessage({id: 'buy'})}
            </Button>}
            <div>&nbsp;</div>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'max_users'}, {number: '500'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'max_ideas'}, {number: '250'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {'1 ' + props.intl.formatMessage({id: 'crowd_admin'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'third_level_domain'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'shared_crowdea_users'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'limited_support'})}
            </Typography>
        </CardContent>
    </Card>
};

export default injectIntl(Party);