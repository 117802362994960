import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {CROWDEA_HOSTNAME, wwwDomainRegex, ReservedSubdomains} from "../../constants";
import Switch from "@material-ui/core/Switch";
import useDeepCompareEffect from "use-deep-compare-effect";
import InputAdornment from "@material-ui/core/InputAdornment";
import HttpIcon from '@material-ui/icons/Http';
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

// TODO refresh notifications on subdomainRouting change, as regular aaa.crowdea.co domain will no longer work

const CrowdDomainSettings = props => {

    const classes = useStyles();
    const {crowd, subscription, updateCrowd} = props;
    const subscriptionFeatures = subscription.current.features || {};
    const [crowdState, setCrowdState] = React.useState({
        domain: crowd.domain,
        subdomain: crowd.subdomain,
        subdomainRouting: crowd.subdomainRouting,
    });
    const [validationErrors, setValidationErrors] = React.useState({});
    const validators = {
        subdomain: () => {
            if (crowdState.subdomain === '')
                return 'validation_crowd_subdomain_empty';
            if (ReservedSubdomains.includes(crowdState.subdomain.toLowerCase()))
                return 'validation_crowd_subdomain_invalid';
            if (!/^[a-z0-9]+[-]*[a-z0-9]*$/i.test(crowdState.subdomain))
                return 'validation_crowd_subdomain_regex';
            return true;
        },
        domain: () => {
            if (!subscriptionFeatures.ownDomain)
                return true;

            if (crowdState.domain === '') {
                if (!crowdState.subdomainRouting)
                    return 'validation_crowd_subdomain_disabled_domain_empty';
            } else {
                if (!wwwDomainRegex.test(crowdState.domain))
                    return 'validation_crowd_domain_regex';
                if (crowdState.domain.toLowerCase().includes('crowdea'))
                    return 'validation_crowd_domain_invalid';
            }
            return true;
        }
    };

    useDeepCompareEffect(() => {
        setCrowdState({
            domain: crowd.domain,
            subdomain: crowd.subdomain,
            subdomainRouting: crowd.subdomainRouting,
        });
    }, [crowd]);

    const validateInput = () => {
        const result = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});

        setValidationErrors(result);
        return Object.keys(result).length === 0;
    };

    const handleSwitchChange = event => {
        setCrowdState({
            ...crowdState,
            subdomainRouting: event.target.checked
        });
    };

    const handleInputChange = name => event => {
        setCrowdState({
            ...crowdState,
            [name]: event.target.value
        })
    };

    const handleSubmit = () => {
        const originalState = {
            domain: crowd.domain,
            subdomain: crowd.subdomain,
            subdomainRouting: crowd.subdomainRouting,
        };

        const changesMade = !isEqual({...crowdState, domain: crowdState.domain.replace(/^(www\.)/,"")}, originalState);

        if (validateInput() && changesMade) {
            const errCb = result => {
                setValidationErrors({
                    ...validationErrors,
                    subdomain: result.errors.subdomain,
                    domain: result.errors.domain
                });
            };

            const diff = differenceWith(Object.entries(crowdState), Object.entries(originalState), isEqual).reduce((acc, [key, val]) => {
                acc[key] = val;
                return acc;
            }, {});

            updateCrowd({
                id: crowd.id,
                version: crowd.version,
                ...diff
            }, errCb);
        }
    };

    const commonTextFieldProps = (name, i18n) => {
        return {
            label: props.intl.formatMessage({id: i18n}),
            variant: "outlined",
            name: name,
            margin: "dense",
            fullWidth: true,
            error: validationErrors[name],
            helperText: validationErrors[name] && props.intl.formatMessage({id: validationErrors[name]}),
            value: crowdState[name],
            onChange: handleInputChange(name)
        };
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <HttpIcon/>
                </Avatar>
            }
            title={<Typography className={'cardHeaderText'}>{props.intl.formatMessage({id: 'crowd_domain_settings'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                direction="row"
                spacing={2}
            >
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('subdomain', 'crowd_subdomain')}
                               InputProps={{
                                   endAdornment: <InputAdornment position="end">{`.${CROWDEA_HOSTNAME}`}</InputAdornment>
                               }}/>
                </Grid>
                {subscriptionFeatures.ownDomain && <>
                    <Grid item xs={12}>
                        <TextField {...commonTextFieldProps('domain', 'crowd_domain')}
                                   InputProps={{
                                       startAdornment: <InputAdornment position="start">www.</InputAdornment>
                                   }}
                                   placeholder={'example.com'}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={crowdState.subdomainRouting}
                                    onChange={handleSwitchChange}
                                    value={crowdState.subdomainRouting}
                                    color="primary"
                                />
                            }
                            label={props.intl.formatMessage({id: 'crowd_subdomain_enabled'})}
                        />
                    </Grid>
                </>}
                <Grid item xs={12} sm='auto'>
                    <Button color='primary' variant="contained" className={classes.button} onClick={handleSubmit}>
                        {props.intl.formatMessage({id: 'save'})}
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    </Card>;
};

export default injectIntl(CrowdDomainSettings);