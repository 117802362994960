import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, CROWDEA_HOSTNAME} from "../constants";
import {extractBodyOrThrow} from "./common";

export function loadNotifications(successCb, offset = 0, limit = 20) {
    return async dispatch => {
        secureFetch(`${API_URL}/notification?offset=${offset}&limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(body => {
                if (successCb)
                    successCb();
                dispatch(notificationsLoaded(body))
            })
            .catch(error => console.log(error.message));
    };
}

export function markNotificationsAsRead(ids) {
    return async dispatch => {
        secureFetch(`${API_URL}/notification`,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                ...(ids !== undefined && {body: JSON.stringify({ids})})
            })
            .then(() => dispatch(notificationsMarkedAsRead(ids)))
            .catch(error => console.log(error.message));
    };
}

function notificationsLoaded(body) {
    return {
        type: ActionType.NOTIFICATIONS_LOADED,
        ...body
    };
}

function notificationsMarkedAsRead(ids) {
    return {
        type: ActionType.NOTIFICATIONS_READ,
        ids
    };
}

export function composeNotificationLink(notification) {
    switch(notification.entityType) {
        case 'crowd': return `${CROWDEA_HOSTNAME}/crowds/${notification.entityId}`;
        case 'idea': return `${notification.data.domain}/idea/${notification.entityId}`;
        default: return '/';
    }
}