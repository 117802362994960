import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 100
    },
    button: {
        background: theme.palette.purple.main,
        color: 'white',
        '&:hover': {
            background: theme.palette.purple.light
        }
    }
}));

export const ScrollTopButton = props => {
    const classes = useStyles();
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    };

    return (
        <Zoom in={trigger}>
            <div className={classes.root}>
                <Fab className={classes.button} onClick={scrollTop}>
                    <KeyboardArrowUpIcon/>
                </Fab>
            </div>
        </Zoom>
    );
};