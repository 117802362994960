import React from "react";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {injectIntl} from "react-intl";
import {emailRegex} from "../../constants";
import Typography from "@material-ui/core/Typography";

const FacebookLoginMissingEmail = props => {

    const [email, setEmail] = React.useState('');
    const [emailInvalid, setEmailInvalid] = React.useState(false);

    const handleSubmit = () => {
        const emailValid = emailRegex.test(email);
        setEmailInvalid(!emailValid);
        if (emailValid) {
            props.login(email);
        }
    };

    const handleInputChange = event => {
        setEmail(event.target.value);
    };

    return <>
        <Grid item xs={12} style={{maxWidth: '90%'}}>
            <Typography align='center'>
                {props.intl.formatMessage({id: 'facebook_login_no_email'})}
            </Typography>
        </Grid>
        <Grid item xs={12} style={{maxWidth: '90%'}}>
            <TextField
                variant="outlined"
                label={props.intl.formatMessage({id: 'email'})}
                type="email"
                name="email"
                autoComplete="username"
                margin="dense"
                fullWidth
                error={emailInvalid}
                helperText={emailInvalid && props.intl.formatMessage({id: 'validation_registration_email'})}
                value={email}
                onChange={handleInputChange}/>
        </Grid>
        <Grid item xs={12} style={{maxWidth: '90%'}}>
            <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{color: 'white'}}
                fullWidth
                onClick={handleSubmit}>
                {props.intl.formatMessage({id: 'login'})}
            </Button>
        </Grid>
    </>
};

export default injectIntl(FacebookLoginMissingEmail);