import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const Spinner = props => {
    const {minHeight = '75vh'} = props;
        return <div style={{minHeight: minHeight, display: 'flex', flexDirection: 'column'}}>
            <div style={{flexGrow: 1}}/>
            <div style={{width: '100%', textAlign: 'center'}}>
                <CircularProgress/>
            </div>
            <div style={{flexGrow: 1}}/>
        </div>;
};

export default Spinner;