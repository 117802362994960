import React from "react";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import GenericDialog from "../common/GenericDialog";
import CrowdDomainSettings from "./CrowdDomainSettings";
import CrowdGeneralInfo from "./CrowdGeneralInfo";


const CrowdProfile = props => {

    const {crowd, subscription, dialogOnSubmitHandler, updateCrowd} = props;
    const [showDialog, setShowDialog] = React.useState(false);

    return <>
        <Grid container
            justify="center"
            alignItems="center"
            spacing={1}
        >
            <Grid item xs={12}>
                <CrowdDomainSettings crowd={crowd} subscription={subscription} updateCrowd={updateCrowd}/>
            </Grid>
            <Grid item xs={12}>
                <CrowdGeneralInfo crowd={crowd}
                                  subscription={subscription}
                                  openDialog={() => setShowDialog(true)}
                                  updateCrowd={updateCrowd}/>
            </Grid>
        </Grid>
        <GenericDialog open={showDialog}
                       onClose={() => setShowDialog(false)}
                       onSubmit={dialogOnSubmitHandler}
                       titleText={"crowd_subscription_required"}
                       contentText={'crowd_visible_subscription_required'}
                       submitButtonText={'crowd_no_subscription_notification_button'}/>
    </>;
};

export default injectIntl(CrowdProfile);