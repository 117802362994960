import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {injectIntl} from "react-intl";
import ChooseSubscription from "../subscription/ChooseSubscription";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import ActiveSubscription from "./ActiveSubscription";
import Spinner from "../common/Spinner";
import ChooseSubscriptionModal from "../subscription/ChooseSubscriptionModal";
import ChoosePaymentMethodModal from "../payment/ChoosePaymentMethodModal";

const useStyles = makeStyles(theme => ({
    pricingHeaderText: {
        fontSize: '20px',
        lineHeight: '1.1',
        padding: '40px',
    },
    gridItem: {
        width: '100%'
    },
}));

const CrowdSubscription = props => {

    const classes = useStyles();
    const {
        trialEligible,
        subscription = {},
        createSubscription,
        cancelSubscription,
        reloadCrowdUsers,
        creditCards,
        loadCreditCards,
        emitError
    } = props;
    const currentSubscription = subscription.current || {};
    const nextSubscription = subscription.next || {};
    const [subscriptionTier, setSubscriptionTier] = React.useState(0);
    const [chooseSubscriptionModalOpen, setChooseSubscriptionModalOpen] = React.useState(false);
    const [chooseCardModalOpen, setChooseCardModalOpen] = React.useState(false);

    if (!subscription.loaded)
        return <Spinner/>;

    const openSubscriptionModal = () => {
        setChooseSubscriptionModalOpen(true);
    };

    const closeSubscriptionModal = () => {
        setChooseSubscriptionModalOpen(false);
    };

    const openCardModal = () => {
        setChooseCardModalOpen(true);
    };

    const closeCardModal = () => {
        setChooseCardModalOpen(false);
    };

    const renewSubscription = () => {
        openCardModal();
        setSubscriptionTier(currentSubscription.tier);
    };

    const clickHandler = tier => {
        if (tier === 0) {
            createSubscription(0);
            return;
        }
        setSubscriptionTier(tier);
        openCardModal();
    };

    return <>
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={1}
        >
            {currentSubscription.id !== undefined ? <Grid item className={classes.gridItem}>
                <ActiveSubscription subscription={subscription}
                                    cancelSubscription={cancelSubscription}
                                    renewSubscription={renewSubscription}
                                    changeSubscription={openSubscriptionModal}/>
            </Grid> : <Grid item className={classes.gridItem}>
                <Paper elevation={3}>
                    <Typography align={"center"} variant={'h1'}
                                className={clsx(classes.pricingHeaderText)}>
                        {props.intl.formatMessage({id: 'choose_subscription_for_crowd'})}
                    </Typography>
                    <ChooseSubscription displayFreeSubscription={trialEligible}
                                        createSubscription={createSubscription}
                                        buttonClickHandler={clickHandler}
                                        removeContainerSpacing/>
                </Paper>
            </Grid>}
        </Grid>
        <ChoosePaymentMethodModal handleClose={closeCardModal}
                                  creditCards={creditCards}
                                  loadCreditCards={loadCreditCards}
                                  createSubscription={createSubscription}
                                  reloadCrowdUsers={reloadCrowdUsers}
                                  tier={subscriptionTier}
                                  emitError={emitError}
                                  isOpen={chooseCardModalOpen}/>
        <ChooseSubscriptionModal handleClose={closeSubscriptionModal}
                                 creditCards={creditCards}
                                 loadCreditCards={loadCreditCards}
                                 createSubscription={createSubscription}
                                 reloadCrowdUsers={reloadCrowdUsers}
                                 emitError={emitError}
                                 excludeTier={currentSubscription.active ? currentSubscription.tier : nextSubscription.tier}
                                 isOpen={chooseSubscriptionModalOpen}/>
    </>;
};

export default injectIntl(CrowdSubscription);