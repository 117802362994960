import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Button from "@material-ui/core/Button";
import {injectIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Avatar from "@material-ui/core/Avatar";
import Spinner from "../common/Spinner";
import CardHeader from "@material-ui/core/CardHeader";
import EqualizerIcon from '@material-ui/icons/Equalizer';
import {exportIdeaAnswers} from "../../actions/idea";


const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    button: {
        color: 'white',
        marginTop: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    }
}));


const IdeaStats = props => {

    const classes = useStyles();
    const {ideaStats, ideaId, crowdId} = props;
    const comments = ideaStats.stats.comments || {};
    const likes = ideaStats.stats.likes || {};

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <EqualizerIcon/>
                </Avatar>
            }
            title={<Typography className={'cardHeaderText'}>{props.intl.formatMessage({id: 'idea_stats'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            {ideaStats.loaded ? <>
                <List dense
                      subheader={
                          <Typography>
                              {props.intl.formatMessage({id: 'idea_stats_comments'})}
                          </Typography>
                      }>
                    <ListItem>
                        <ListItemText
                            primary={props.intl.formatMessage({id: 'idea_stats_all'})}
                            secondary={comments.all}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={props.intl.formatMessage({id: 'idea_stats_7days'})}
                            secondary={comments.last7Days}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={props.intl.formatMessage({id: 'idea_stats_30days'})}
                            secondary={comments.last30Days}/>
                    </ListItem>
                </List>
                <List dense
                      subheader={
                          <Typography>
                              {props.intl.formatMessage({id: 'idea_stats_likes'})}
                          </Typography>
                      }>
                    <ListItem>
                        <ListItemText
                            primary={props.intl.formatMessage({id: 'idea_stats_all'})}
                            secondary={likes.all}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={props.intl.formatMessage({id: 'idea_stats_7days'})}
                            secondary={likes.last7Days}/>
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={props.intl.formatMessage({id: 'idea_stats_30days'})}
                            secondary={likes.last30Days}/>
                    </ListItem>
                </List>
            </> : <Spinner/>}
            <Button color='primary' variant="contained" className={classes.button}
                    onClick={() => exportIdeaAnswers(crowdId, ideaId)}>
                {props.intl.formatMessage({id: 'idea_stats_download_answers'})}
            </Button>
        </CardContent>
    </Card>;
};

export default injectIntl(IdeaStats);
