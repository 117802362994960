import React, {useEffect} from "react";
import {Button} from "@material-ui/core";
import {connect} from 'react-redux';
import {login} from "../../actions/login"
import {AuthenticationProvider, CROWDEA_ORIGIN, NotificationType} from "../../constants"
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import EmailPasswordLogin from "./EmailPasswordLogin";
import Container from "@material-ui/core/Container";
import {compose} from "redux";
import LockIcon from '@material-ui/icons/Lock';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {injectIntl} from "react-intl";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HelpIcon from '@material-ui/icons/Help';
import Link from "@material-ui/core/Link";
import ServiceAgreementDialog from "../registration/ServiceAgreementDialog";
import SelectLanguageButton from "../common/selectlanguage/SelectLanguageButton";
import OAuthLogin from "./OAuthLogin";
import FacebookLoginMissingEmail from "./FacebookLoginMissingEmail";
import {emitAppNotification} from "../../actions/common";
import {makeStyles} from "@material-ui/core/styles";
import PrivacyPolicyDialog from "../registration/PrivacyPolicyDialog";
import useDeepCompareEffect from "use-deep-compare-effect";
import {loadUser} from "../../actions/user";
import {loadCrowdView} from "../../actions/crowdView";
import Spinner from "../common/Spinner";

const useStyles = makeStyles(theme => ({
    itemWidth: {
        maxWidth: '90%'
    },
    buttonTrayRight: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-end"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    buttonTrayLeft: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-start"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    buttonTrayCenter: {
        display: "flex",
        justifyContent: "center"
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: "10vh",
        [theme.breakpoints.down('sm')]: {
            marginTop: "3vh",
            marginBottom: "3vh"
        },
    },
    crowdeaLogo: {
        height: '30px',
        margin: '20px 0 20px'
    },
    avatar: {
        margin: 'auto',
        backgroundColor: theme.palette.primary.main
    }
}));

const Login = props => {

    const classes = useStyles();
    const {
        login,
        emitError,
        currentLocale,
        intl,
        crowdView,
        user,
        loadCrowdView,
        loadUser
    } = props;

    const [termsShown, setTermsShown] = React.useState(false);
    const [privacyPolicyShown, setPrivacyPolicyShown] = React.useState(false);
    const [emailMissing, setEmailMissing] = React.useState(false);
    const [credentials, setCredentials] = React.useState({});
    const params = new URLSearchParams(window.location.search);
    const crowdId = params.get('crowdId');
    const redirectUrl = params.get('redirectUrl') ? new URL(decodeURIComponent(params.get('redirectUrl'))) : CROWDEA_ORIGIN;

    useEffect(() => {
        if (crowdId)
            loadCrowdView(crowdId);
        else
            loadUser();
    }, []);

    useDeepCompareEffect(() => {
        if (crowdView.loaded && !user.loaded)
            loadUser();
    }, [crowdView]);

    if (!user.loaded)
        return <Spinner/>;

    if (user.loaded && user.loggedIn) {
        window.location.href = redirectUrl.toString();
        return;
    }

    const googleLogin = response => {
        if (response.tokenId)
            login({
                authProvider: AuthenticationProvider.GOOGLE,
                accessToken: response.tokenId,
                locale: currentLocale,
                ...(crowdId && {crowdId})
            }, redirectAfterLogin(redirectUrl));
    };

    const showTermsOfService = event => {
        event.preventDefault();
        setTermsShown(true);
    };

    const showPrivacyPolicy = event => {
        event.preventDefault();
        setPrivacyPolicyShown(true);
    };

    const attemptFacebookLogin = response => {
        const {id, name, email, accessToken} = response;
        if (id) {
            if (email) {
                facebookLogin(name, accessToken)(email);
            } else {
                setEmailMissing(true);
                setCredentials({
                    name,
                    accessToken
                });
            }
        } else {
            emitError("facebook_login_error")
        }
    };

    const facebookLogin = (name, accessToken) => email => {
        login({
            authProvider: AuthenticationProvider.FACEBOOK,
            accessToken,
            name,
            email,
            locale: currentLocale,
            ...(crowdId && {crowdId})
        }, redirectAfterLogin(redirectUrl));
    };

    const emailPasswordLogin = emailPassword => {
        login({
            authProvider: AuthenticationProvider.EMAIL_PASSWORD,
            ...emailPassword,
            ...(crowdId && {crowdId})
        }, redirectAfterLogin(redirectUrl));
    };

    const redirectAfterLogin = redirectUrl => () => {
        window.location.href = redirectUrl.toString();
    };

    return <>
        <Container maxWidth="sm" style={{minHeight: '90vh'}}>
            <div className={classes.content}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid
                            justify='center'
                            container
                            spacing={1}
                        >
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <a href={CROWDEA_ORIGIN}>
                                    <img className={classes.crowdeaLogo} src="/images/crowdea.png"/>
                                </a>
                            </Grid>
                            <Grid item xs={12}>
                                <Avatar className={classes.avatar}><LockIcon/></Avatar>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <Typography>
                                    {intl.formatMessage({id: 'login'})}
                                </Typography>
                            </Grid>
                            {emailMissing ?
                                (<FacebookLoginMissingEmail
                                    login={facebookLogin(credentials.name, credentials.accessToken)}/>) :
                                (<>
                                    <Grid item xs={12} className={classes.itemWidth}>
                                        <EmailPasswordLogin login={emailPasswordLogin}/>
                                    </Grid>
                                    <OAuthLogin googleLogin={googleLogin}
                                                facebookLogin={attemptFacebookLogin}/>
                                </>)}
                            <Grid item xs={12} className={classes.itemWidth}>
                                <Typography align='center' variant="body2">
                                    {intl.formatMessage({id: 'agree_terms_of_service'})}
                                    {' '}
                                    <Link underline="none"
                                          href=""
                                          onClick={showTermsOfService}>
                                        {intl.formatMessage({id: 'terms_of_service'})}
                                    </Link>
                                    {' '}
                                    {intl.formatMessage({id: 'and_the'})}
                                    {' '}
                                    <Link underline="none"
                                          href=""
                                          onClick={showPrivacyPolicy}>
                                        {intl.formatMessage({id: 'privacy_policy'})}
                                    </Link>.
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{marginTop: '10px'}}
                >
                    <Grid item xs={12} sm className={classes.buttonTrayLeft}>
                        <a style={{textDecoration: 'none'}} href={`${redirectUrl.origin}/register`}>
                            <Button
                                type="button"
                                startIcon={<PersonAddIcon/>}>
                                {intl.formatMessage({id: 'registration'})}
                            </Button>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm={4} className={classes.buttonTrayCenter}>
                        <a style={{textDecoration: 'none'}} href={`${redirectUrl.origin}/forgotPassword`}>
                            <Button
                                type="button"
                                startIcon={<HelpIcon/>}>
                                {intl.formatMessage({id: 'forgot_password'})}
                            </Button>
                        </a>
                    </Grid>
                    <Grid item xs={12} sm className={classes.buttonTrayRight}>
                        <SelectLanguageButton/>
                    </Grid>
                </Grid>
            </div>
        </Container>
        <ServiceAgreementDialog open={termsShown} onClose={() => setTermsShown(false)}/>
        <PrivacyPolicyDialog open={privacyPolicyShown} onClose={() => setPrivacyPolicyShown(false)}/>
    </>;
};

const mapStateToProps = state => {
    return {
        crowdView: state.crowdView,
        user: state.user,
        currentLocale: state.locale
    }
};

const mapDispatchToProps = dispatch => {
    return {
        login: (loginInfo, redirectHandler) => dispatch(login(loginInfo, redirectHandler)),
        emitError: errCode => dispatch(emitAppNotification(NotificationType.ERROR, errCode, 3000)),
        loadUser: () => dispatch(loadUser()),
        loadCrowdView: domain => dispatch(loadCrowdView(domain)),
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(Login);
