import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import LockIcon from '@material-ui/icons/Lock';
import {passwordRules} from "../../utils/passwordRules";
import {injectIntl} from "react-intl";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    gridItem: {
        width: '100%'
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

const ChangePassword = props => {
    const classes = useStyles();
    const {apiErrors, clearApiErrors, user, onSubmit, intl} = props;

    const validators = {
        currentPassword: (passwordInfo) => "" !== passwordInfo.currentPassword,
        password: (passwordInfo) => passwordRules.validate(passwordInfo.password),
        confirmPassword: (passwordInfo) => passwordInfo.password === passwordInfo.confirmPassword,
    };

    const initState = {
        currentPassword: "",
        password: "",
        confirmPassword: ""
    };

    const [passwordInfo, setPasswordInfo] = React.useState(initState);
    const [validationErrors, setValidationErrors] = React.useState([]);

    const validateFormInput = () => {
        const userInput = passwordInfo;
        const validationErrors = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                if (!validator(userInput)) {
                    acc.push(key);
                }
                return acc;
            }, []);

        setValidationErrors(validationErrors);
        return validationErrors.length === 0;
    };

    const isInvalid = field => {
        return validationErrors.includes(field) ||
            apiErrors.hasOwnProperty(field);
    };

    const validationErrorMessage = field => {

        if (validationErrors.includes(field)) {
            return intl.formatMessage({id: 'validation_change_password_' + field});
        }

        if (apiErrors[field]) {
            return intl.formatMessage({id: apiErrors[field]});
        }

        return "";
    };

    const handleFormInputChange = name => event => {
        setPasswordInfo({...passwordInfo, [name]: event.target.value});
    };

    const successCallback = () => {
        setPasswordInfo(initState);
    };

    const handleSubmit = event => {
        event.preventDefault();
        if (validateFormInput()) {
            clearApiErrors();
            const body = {
                ...passwordInfo,
                id: user.id,
                version: user.version,
            };
            onSubmit(body, user.hasPassword, successCallback);
        }
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <LockIcon/>
                </Avatar>
            }
            title={<Typography
                className={'cardHeaderText'}>{intl.formatMessage({id: 'change_password'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <form>
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={intl.formatMessage({id: 'current_password'})}
                            type="password"
                            name="currentPassword"
                            autoComplete="new-password"
                            margin="dense"
                            fullWidth
                            error={isInvalid('currentPassword')}
                            helperText={validationErrorMessage('currentPassword')}
                            value={passwordInfo.currentPassword}
                            onChange={handleFormInputChange('currentPassword')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={intl.formatMessage({id: 'new_password'})}
                            type="password"
                            name="password"
                            margin="dense"
                            fullWidth
                            error={isInvalid('password')}
                            helperText={validationErrorMessage('password')}
                            value={passwordInfo.password}
                            onChange={handleFormInputChange('password')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip
                                            disableFocusListener
                                            placement="bottom-end"
                                            title={intl.formatMessage({id: 'password_rules'})}>
                                            <IconButton>
                                                <HelpOutlineRoundedIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label={intl.formatMessage({id: 'confirm_new_password'})}
                            type="password"
                            name="confirmPassword"
                            margin="dense"
                            fullWidth
                            error={isInvalid('confirmPassword')}
                            helperText={validationErrorMessage('confirmPassword')}
                            value={passwordInfo.confirmPassword}
                            onChange={handleFormInputChange('confirmPassword')}/>
                    </Grid>
                    <Grid item xs={12} sm={'auto'}>
                        <Button color='primary' variant="contained" className={classes.button}
                                onClick={handleSubmit}>
                            {intl.formatMessage({id: 'save'})}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </CardContent>
    </Card>;
};

export default injectIntl(ChangePassword);
