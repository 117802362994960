import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";

export function createIdeaLike(domain, ideaId, questionnaireId, body, successCallback) {
    return async dispatch => {
        secureFetch(API_URL + "/ideaLike/" + domain,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ideaId,
                    questionnaireId,
                    ...(body && {questionnaireAnswers: body}),
                })
            })
            .then(response => extractBodyOrThrow(response))
            .then(() => {
                dispatch(ideaLiked(ideaId, questionnaireId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_liked", 3000));
                if (successCallback)
                    successCallback();
            })
            .catch(error => console.log(error.message));
    };
}

function ideaLiked(ideaId, questionnaireId) {
    return {
        type: ActionType.CROWD_VIEW_USER_IDEA_LIKED,
        ideaId,
        questionnaireId
    };
}

export function unlikeIdea(domain, ideaId) {
    return async dispatch => {
        secureFetch(`${API_URL}/ideaLike/${domain}/${ideaId}`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                dispatch(ideaUnliked(ideaId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_unliked", 3000));
            })
            .catch(error => console.log(error.message));
    };
}

function ideaUnliked(ideaId) {
    return {
        type: ActionType.CROWD_VIEW_USER_IDEA_UNLIKED,
        ideaId
    };
}

