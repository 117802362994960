import React from "react";
import MenuIcon from '@material-ui/icons/Menu';
import {makeStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    iconColor: {
        color: theme.palette.indigo.dark
    }
}));

const SidebarButton = props => {

    const classes = useStyles();

    return <Grid item>
        <IconButton onClick={props.toggleDrawer}>
            <MenuIcon className={classes.iconColor}/>
        </IconButton>
    </Grid>;
};

export default injectIntl(SidebarButton);