const CrowdUserType = {
    CROWD_ADMIN: 'CROWD_ADMIN',
    CROWD_MANAGER: 'CROWD_MANAGER',
    CROWD_FOLLOWER: 'CROWD_FOLLOWER',
};

const AuthenticationProvider = {
    GOOGLE: 'GOOGLE',
    FACEBOOK: 'FACEBOOK',
    EMAIL_PASSWORD: 'EMAIL_PASSWORD'
};

const ActionType = {
    USER_IS_LOGGING_IN: "USER_IS_LOGGING_IN",
    USER_LOGGED_OUT: "USER_LOGGED_OUT",
    USER_LOGIN_FAILED: "USER_LOGIN_FAILED",
    USER_LOADED: "USER_LOADED",
    USER_UPDATED: "USER_UPDATED",
    USER_ROLES_LOADED: "USER_ROLES_LOADED",
    USER_AVATAR_UPDATED: "USER_AVATAR_UPDATED",
    REGISTRATION_ERRORED: "REGISTRATION_ERRORED",
    REGISTRATION_SUCCEEDED: "REGISTRATION_SUCCEEDED",
    CHANGE_PASSWORD_ERRORED: "CHANGE_PASSWORD_ERRORED",
    CHANGE_PASSWORD_SUCCEEDED: "CHANGE_PASSWORD_SUCCEEDED",
    NOTIFICATION_SETTINGS_LOADED: "NOTIFICATION_SETTINGS_LOADED",
    NOTIFICATIONS_LOADED: "NOTIFICATIONS_LOADED",
    NOTIFICATION_LOADED: "NOTIFICATION_LOADED",
    NOTIFICATIONS_READ: "NOTIFICATIONS_READ",
    CROWDS_LOADED: "CROWDS_LOADED",
    CROWD_LOADED: "CROWD_LOADED",
    CROWD_UPDATED: "CROWD_UPDATED",
    THEMING_UPDATED: "THEMING_UPDATED",
    IDEAS_LOADED: "IDEAS_LOADED",
    IDEA_UPDATED: "IDEA_UPDATED",
    IDEA_DELETED: "IDEA_DELETED",
    IDEA_TAGGED: "IDEA_TAGGED",
    IDEA_RATED: "IDEA_RATED",
    IDEA_SHARED: "IDEA_SHARED",
    IDEA_STATS_LOADED: "IDEA_STATS_LOADED",
    CROWD_VIEW_DATA_LOADED: "CROWD_VIEW_DATA_LOADED",
    CROWD_VIEW_QUESTIONNAIRE_LOADED: "CROWD_VIEW_QUESTIONNAIRE_LOADED",
    CROWD_VIEW_USER_IDEAS_LIKED_LOADED: "CROWD_VIEW_USER_IDEAS_LIKED_LOADED",
    CROWD_VIEW_USER_IDEA_LIKED: "CROWD_VIEW_USER_IDEA_LIKED",
    CROWD_VIEW_USER_IDEA_UNLIKED: "CROWD_VIEW_USER_IDEA_UNLIKED",
    CROWD_VIEW_CATEGORIES_LOADED: "CROWD_VIEW_CATEGORIES_LOADED",
    CROWD_VIEW_IDEAS_LOADED: "CROWD_VIEW_IDEAS_LOADED",
    CROWD_VIEW_IDEA_LOADED: "CROWD_VIEW_IDEA_LOADED",
    CROWD_VIEW_IDEA_UPDATED: "CROWD_VIEW_IDEA_UPDATED",
    CROWD_VIEW_IDEA_VIEWED: "CROWD_VIEW_IDEA_VIEWED",
    COMMENTS_LOADED: "COMMENTS_LOADED",
    COMMENT_LOADED: "COMMENT_LOADED",
    COMMENT_DELETED: "COMMENT_DELETED",
    COMMENT_UPDATED: "COMMENT_UPDATED",
    CATEGORIES_LOADED: "CATEGORIES_LOADED",
    CATEGORY_LOADED: "CATEGORY_LOADED",
    CATEGORY_UPDATED: "CATEGORY_UPDATED",
    CATEGORY_DELETED: "CATEGORY_DELETED",
    QUESTIONNAIRE_LOADED: "QUESTIONNAIRE_LOADED",
    SUBSCRIPTION_LOADED: "SUBSCRIPTION_LOADED",
    SUBSCRIPTIONS_LOADED: "SUBSCRIPTIONS_LOADED",
    CREDIT_CARDS_LOADED: "CREDIT_CARDS_LOADED",
    CREDIT_CARD_MADE_DEFAULT: "CREDIT_CARD_MADE_DEFAULT",
    CROWD_USERS_LOADED: "CROWD_USERS_LOADED",
    CROWD_USERS_ADDED: "CROWD_USERS_ADDED",
    CROWD_USERS_UPDATED: "CROWD_USERS_UPDATED",
    CROWD_USER_DELETED: "CROWD_USER_DELETED",
    APP_NOTIFICATION: "APP_NOTIFICATION",
    CHANGE_LOCALE: "CHANGE_LOCALE",
    FILE_LOADED: "FILE_LOADED",
    WEBSOCKET_CONNECT: "WEBSOCKET_CONNECT",
    WEBSOCKET_DISCONNECT: "WEBSOCKET_DISCONNECT",
    LANDING_PAGE_CROWDS_LOADED: "LANDING_PAGE_CROWDS_LOADED",
    LANDING_PAGE_MOST_LIKED_LOADED: "LANDING_PAGE_MOST_LIKED_LOADED",
    LANDING_PAGE_MOST_RECENT_LOADED: "LANDING_PAGE_MOST_RECENT_LOADED"
};

const Locale = {
    SK: 'sk',
    EN: 'en'
};

const Currency = {
    EUR: 'EUR',
    CZK: 'CZK',
    USD: 'USD',
    GBP: 'GBP',
};

const IdeasListingView = {
    PREVIEWS: 'PREVIEWS',
    ROWS: 'ROWS'
};

const NotificationType = {
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    INFO: "INFO",
    WARNING: "WARNING"
};

const IdeaQuestionType = {
    TEXT: "TEXT",
    SINGLE_CHOICE: "SINGLE_CHOICE",
    MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
};

const QuestionnaireType = {
    IDEA_LIKE: "IDEA_LIKE",
    IDEA_CREATION: "IDEA_CREATION"
};

const UserNotificationSettingsLocalizationCode = {
    crowdCreated: 'notification_crowd_created',
    crowdTopLevelDomainAdded: 'notification_crowd_top_level_domain_added',
    crowdSubscriptionActivated: 'notification_crowd_subscription_activated',
    crowdSubscriptionUpgraded: 'notification_crowd_subscription_upgraded',
    crowdSubscriptionDowngraded: 'notification_crowd_subscription_downgraded',
    crowdSubscriptionCancelled: 'notification_crowd_subscription_cancelled',
    crowdSubscriptionPastDue: 'notification_crowd_subscription_past_due',
    crowdSubscriptionPastDueTemporarilyDeactivated: 'notification_crowd_subscription_past_due_temporarily_deactivated',
    crowdSubscriptionRenewed: 'notification_crowd_subscription_renewed',
    crowdSubscriptionReactivated: 'notification_crowd_subscription_reactivated',
    crowdRoleAdded: 'notification_crowd_role_added',
    ideaAdded: 'notification_idea_added',
    ideaCommented: 'notification_idea_commented',
    ideaLiked: 'notification_idea_liked',
    ideaLikeMilestoneReached: 'notification_idea_like_milestone_reached',
    ideaMarkedByAdmin: 'notification_idea_marked_by_admin',
    ideaStateChanged: 'notification_idea_state_changed'
};

const AssignableIdeaTags = {
    IMPLEMENTED: "implemented",
    FEATURED: "featured",
    REWARDED: "rewarded",
};

const AllIdeaTags = {
    ...AssignableIdeaTags,
    NEW_IDEA: "new_idea",
    MOST_LIKES: "most_likes",
    MOST_COMMENTS: "most_comments",
};

const IdeaState = {
    SUBMITTED: "SUBMITTED",
    IN_REVIEW: "IN_REVIEW",
    DEFERRED: "DEFERRED",
    APPROVED: "APPROVED",
    UNDERWAY: "UNDERWAY",
    IMPLEMENTED: "IMPLEMENTED",
    REJECTED: "REJECTED",
};

const ReservedSubdomains = [
    'api',
    'login',
    'registration',
    'admin',
    'dashboard',
    'challenge',
    'blog',
    'support',
    'app',
    'in',
    'web',
    'tools',
    'report'
];

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const wwwDomainRegex = /^([a-zA-Z0-9_-]+\.)+[a-zA-Z]+$/i;
const separatorRegex = /[,;\s]+/;
const skPhoneNumberRegex = /^(\+?421|0)([0-9]){9}$/;
const ValidImageTypes = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
const CROWDEA_HOSTNAME = process.env.REACT_APP_CROWDEA_DOMAIN || 'crowdea.engerau.com';
const CROWDEA_ORIGIN = `https://` + CROWDEA_HOSTNAME;
const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE_API_KEY;
const IDEA_DESCRIPTION_LENGTH_LIMIT = process.env.REACT_APP_IDEA_DESCRIPTION_LENGTH_LIMIT;
const CROWD_INTRO_LENGTH_LIMIT = process.env.REACT_APP_CROWD_INTRO_LENGTH_LIMIT;
const REACT_APP_LOGIN_URL = process.env.REACT_APP_LOGIN_URL || 'https://login.crowdea.engerau.com';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL || 'https://crowdea-api.engerau.com';
const ENVIRONMENT = process.env.NODE_ENV;
const API_PREFIX = process.env.REACT_APP_API_PREFIX || '/api';
const API_URL = ENVIRONMENT === 'production' ? `${REACT_APP_API_URL}${API_PREFIX}` : API_PREFIX;
const ANALYTICS_TRACKING_ID = process.env.REACT_APP_ANALYTICS_TRACKING_ID;
const GOOGLE_LOGIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID;
const FACEBOOK_LOGIN_APP_ID = process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID;
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export {
    CrowdUserType,
    AuthenticationProvider,
    ActionType,
    Locale,
    Currency,
    NotificationType,
    UserNotificationSettingsLocalizationCode,
    emailRegex,
    wwwDomainRegex,
    separatorRegex,
    skPhoneNumberRegex,
    API_URL,
    IdeasListingView,
    ValidImageTypes,
    IdeaQuestionType,
    QuestionnaireType,
    AssignableIdeaTags,
    AllIdeaTags,
    IdeaState,
    ReservedSubdomains,
    CROWDEA_HOSTNAME,
    CROWDEA_ORIGIN,
    TINY_MCE_API_KEY,
    IDEA_DESCRIPTION_LENGTH_LIMIT,
    REACT_APP_LOGIN_URL,
    REACT_APP_API_URL,
    ENVIRONMENT,
    ANALYTICS_TRACKING_ID,
    GOOGLE_LOGIN_CLIENT_ID,
    FACEBOOK_LOGIN_APP_ID,
    STRIPE_PUBLISHABLE_KEY,
    CROWD_INTRO_LENGTH_LIMIT
};
