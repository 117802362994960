import {makeStyles} from '@material-ui/core/styles';

export const useGlobalStyles = makeStyles(theme => ({
    '@global': {
        '.purpleColor': {
            color: theme.palette.purple.main
        },
        '.purpleBackground': {
            backgroundColor: theme.palette.purple.main,
            '&:hover': {
                backgroundColor: theme.palette.purple.dark
            }
        },
        '.ideaTagIndigo': {
            backgroundColor: theme.palette.indigo.main,
            '&:focus': {
                backgroundColor: theme.palette.indigo.main
            },
        },
        '.ideaTagOrange': {
            backgroundColor: theme.palette.orange.main,
            '&:focus': {
                backgroundColor: theme.palette.orange.main
            },
        },
        '.ideaTagGreen': {
            backgroundColor: theme.palette.green.main,
            '&:focus': {
                backgroundColor: theme.palette.green.main
            },
        },
        '.ideaTagBlack': {
            backgroundColor: 'black',
            '&:focus': {
                backgroundColor: 'black'
            },
        },
        '.ideaTagRed': {
            backgroundColor: theme.palette.red.main,
            '&:focus': {
                backgroundColor: theme.palette.red.main
            },
        },
        '.hideScrollbar::-webkit-scrollbar': {
            display: 'none'
        },
        '.hideScrollbar': {
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none'
        },
        '.openSansBold': {
            fontFamily: 'Open Sans'
        },
        '.cursorPointer': {
            cursor: 'pointer'
        },
        '.pricingCard': {
            borderRadius: '10px',
            transition: 'all 0.2s ease-in-out'
        },
        '.pricingCardSelected': {
            boxShadow: '0 0 20px -2px #1eb2f3'
        },
        '.pricingCardHover': {
            '&:hover': {
                boxShadow: '0 0 20px -2px #ccc',
            }
        },
        '.pricingCardImageWrapper': {
            textAlign: 'right',
            paddingTop: '24px',
            padding: '24px 16px 0'
        },
        '.pricingCardItemMargin': {
            marginTop: '10px',
            marginBottom: '10px'
        },
        '.pricingCardMonthlyText': {
            fontSize: '16px',
            color: '#A8ABB2'
        },
        '.pricingCardButton': {
            textTransform: 'none',
            color: 'white',
            fontSize: '18px',
            width: '100%'
        },
        '.pricingCardEuroSign': {
            fontSize: '32px'
        },
        '.pricingCardPriceText': {
            fontSize: '50px',
            lineHeight: '49px',
            fontWeight: '300'
        },
        '.pricingCardTextWrapper': {
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center'
        },
        '.pricingCardHeaderText': {
            fontWeight: 'bold',
            fontSize: '18px',
            color: theme.palette.indigo.dark
        },
        '.cardHeader': {
            padding: '32px 32px 16px',
            [theme.breakpoints.down('xs')]: {
                padding: '16px',
            }
        },
        '.cardHeaderText': {
            fontSize: '20px'
        },
        '.cardContent': {
            padding: '16px 32px',
            [theme.breakpoints.down('xs')]: {
                padding: '16px'
            }
        },
        '.cardAvatar': {
            backgroundColor: theme.palette.primary.main
        },
        '.buttonProgress': {
            color: theme.palette.primary.main,
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        '.progressWrapper': {
            position: 'relative'
        },
    },
}));