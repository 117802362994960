import React from "react";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {lighten, makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
    notificationBackgroundPrimary: {
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.dark,
        marginBottom: '10px',
        border: '1px solid transparent'
    },
    notificationBackgroundSecondary: {
        backgroundColor: lighten(theme.palette.secondary.main, 0.85),
        borderColor: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        marginBottom: '10px',
        border: '1px solid transparent'
    },
    gridContainer: {
        width: '100%',
        margin: '0'
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    centerOnMobile: {
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        },
    }
}));


const CrowdDetailNotification = props => {

    const classes = useStyles();
    const {
        colorScheme = 'primary',
        text,
        textCode,
        buttonTextCode,
        clickHandler
    } = props;
    return <Paper elevation={0}
                  className={colorScheme === 'primary' ? classes.notificationBackgroundPrimary : classes.notificationBackgroundSecondary}>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
            style={{width: '100%', margin: '0'}}
        >
            <Grid item xs={12} sm={'auto'} className={classes.centerOnMobile}>
                <WarningRoundedIcon color={colorScheme} style={{marginRight: '5px'}}/>
                <Typography component={'span'}>
                    {text !== undefined ? text : props.intl.formatMessage({id: textCode})}
                </Typography>
            </Grid>
            <Hidden xsDown>
                <Grid item sm/>
            </Hidden>
            <Grid item xs={12} sm={'auto'}>
                <Button color={colorScheme} size='small' variant="contained" className={classes.button}
                        onClick={clickHandler}>
                    {props.intl.formatMessage({id: buttonTextCode})}
                </Button>
            </Grid>
        </Grid>
    </Paper>
};

export default injectIntl(CrowdDetailNotification);