import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';

export const StyledRating = withStyles(theme => ({
    iconFilled: {
        color: theme.palette.primary.main,
    },
    iconHover: {
        color: theme.palette.secondary.main,
    },
}), {withTheme: true})(Rating);