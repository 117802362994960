import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import Container from "@material-ui/core/Container";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(theme => ({
    container: {
        padding: '30px',
        position: 'relative'
    },
    closeButton: {
        color: 'white',
        mixBlendMode: 'difference',
        cursor: 'pointer',
        position: 'absolute',
        right: '15px',
        top: '15px'
    }
}));

export const CustomIntro = props => {

    const classes = useStyles();
    const {themingData} = props;
    const [hideIntro, setHideIntro] = React.useState(!!localStorage.getItem('hideCustomIntro'));

    if (hideIntro)
        return null;

    const handleClose = () => {
        localStorage.setItem('hideCustomIntro', 'true');
        setHideIntro(true);
    };

    return <div className={classes.container} style={{backgroundColor: themingData.customIntroBackgroundColor}}>
        <CloseIcon className={classes.closeButton} onClick={handleClose}/>
        <Container maxWidth="lg" id='benefits'>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={4}
            >
                <Grid item xs={12}>
                    <Typography>
                        {parse(DOMPurify.sanitize(themingData.customIntroContent))}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </div>;
};