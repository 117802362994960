import React from "react";
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: '80px',
        backgroundColor: 'white',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '40px'
        },
    },
    text1: {
        marginBottom: '20px',
        color: theme.palette.indigo.dark,
        fontSize: '60px',
        lineHeight: '1.1',
        fontFamily: 'Open Sans',
        textAlign: 'center',
        [theme.breakpoints.down('xl')]: {
            fontSize: '55px',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '45px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '40px',
        }
    },
    text2: {
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'grey',
        opacity: '0.8'
    },
    card: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            height: 'auto'
        },
    },
    cardText1: {
        fontFamily: 'Open Sans',
        color: theme.palette.indigo.dark,
        marginTop: '20px',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    cardText2: {
        color: theme.palette.indigo.dark,
        opacity: '0.8',
        padding: '20px',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('md')]: {
            marginRight: '0px',
            width: '100%',
            textAlign: 'center'
        },
    },
    imageWrapper: {
        width: '80px',
        overflowX: 'visible',
        display: 'inline-block'
    }
}));

const Benefits = props => {

    const classes = useStyles();

    return <div className={classes.container}>
        <Container maxWidth="xl">
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={4}
            >
                <Grid item xs={12}>
                    <Typography variant={'h1'} className={classes.text1}>
                        {props.intl.formatMessage({id: 'user_benefits'})}
                    </Typography>
                    <Typography className={classes.text2}>
                        {props.intl.formatMessage({id: 'user_benefits_desc'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card elevation={0} className={classes.card}>
                        <CardContent>
                            <img src="/images/handheart.png"/>
                            <Typography className={classes.cardText1}>
                                {props.intl.formatMessage({id: 'support_your_favorite_ideas'})}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'support_your_favorite_ideas_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card elevation={0} className={classes.card}>
                        <CardContent>
                            <img src="/images/piggybank.png"/>
                            <Typography className={classes.cardText1}>
                                {props.intl.formatMessage({id: 'make_your_life_easier'})}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'make_your_life_easier_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </div>
};

export default injectIntl(Benefits);