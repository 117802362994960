import {injectIntl} from "react-intl";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "@material-ui/core/CardHeader";
import {LabeledText} from "../common/LabeledText";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Locale} from "../../constants";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    centerOnMobile: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    errorText: {
        color: 'red'
    },
    gutter: {
        marginBottom: '20px'
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

const UserProfileInfo = props => {

    const classes = useStyles();
    const {user, updateAccount, handleTabChange} = props;
    handleTabChange();

    const validators = {
        name: () => {
            if (userInfo.name === '')
                return 'validation_account_name';
            return true;
        }
    };

    const [userInfo, setUserInfo] = React.useState({
        name: user.name || "",
        about: user.about || "",
        locale: user.locale || Locale.EN
    });
    const [validationErrors, setValidationErrors] = React.useState({});

    const localeSelected = event => {
        event.preventDefault();
        setUserInfo({
            ...userInfo,
            locale: event.target.value
        })
    };

    const validateInput = () => {
        const result = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});

        setValidationErrors(result);
        return Object.keys(result).length === 0;
    };

    const handleInputChange = name => event => {
        const clearError = {...validationErrors};
        delete clearError[name];
        setValidationErrors(clearError);
        setUserInfo({
            ...userInfo,
            [name]: event.target.value
        });
    };

    const changesMade = () => {
        return Object.entries(userInfo)
            .reduce((acc, stateValue) => {
                const [key, value] = stateValue;
                if (!acc && value !== user[key] && value !== '')
                    return true;
                return acc;
            }, false);
    };

    const handleSubmit = event => {
        event.preventDefault();
        if (validateInput() && changesMade()) {
            const body = Object.entries(userInfo)
                .reduce((acc, stateValue) => {
                    const [key, value] = stateValue;
                    if (value !== user[key] && value !== '')
                        acc[key] = value;
                    return acc;
                }, {});

            body.id = user.id;
            body.version = user.version;

            updateAccount(body);
        }
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <AccountBoxIcon/>
                </Avatar>
            }
            title={<Typography className={'cardHeaderText'}>{props.intl.formatMessage({id: 'profile'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <LabeledText
                        fullWidth
                        label={props.intl.formatMessage({id: 'email'})}
                        name="email"
                        value={user.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        label={props.intl.formatMessage({id: 'name'})}
                        name="name"
                        margin="dense"
                        fullWidth
                        error={validationErrors['name']}
                        helperText={validationErrors['name'] && props.intl.formatMessage({id: validationErrors['name']})}
                        value={userInfo.name}
                        onChange={handleInputChange('name')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        multiline
                        rows={5}
                        rowsMax={10}
                        inputProps={{maxLength: 600}}
                        label={props.intl.formatMessage({id: 'about'})}
                        name="about"
                        margin="dense"
                        fullWidth
                        helperText={`${userInfo.about.length}/600`}
                        value={userInfo.about}
                        onChange={handleInputChange('about')}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="default_locale_select_label">
                            {props.intl.formatMessage({id: 'default_locale'})}
                        </InputLabel>
                        <Select
                            labelId="default_locale_select_label"
                            id="default_locale_select"
                            fullWidth
                            label={props.intl.formatMessage({id: 'default_locale'})}
                            value={userInfo.locale}
                            onChange={localeSelected}
                        >
                            {Object.values(Locale).map(value =>
                                <MenuItem key={value}
                                          value={value}>{props.intl.formatMessage({id: value})}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <Button color='primary' variant="contained" className={classes.button} onClick={handleSubmit}>
                        {props.intl.formatMessage({id: 'save'})}
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
};

export default injectIntl(UserProfileInfo);