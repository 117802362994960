import React from "react";
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
    container: {
        background: theme.palette.purple.main
    },
    introText: {
        [theme.breakpoints.up('md')]: {
            marginTop: '100px'
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '50px'
        }
    },
    introText1: {
        fontSize: '60px',
        lineHeight: '65px',
        fontFamily: 'Open Sans',
        color: 'white',
        marginBottom: '30px',
        [theme.breakpoints.down('xl')]: {
            fontSize: '55px',
            lineHeight: '60px',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '45px',
            lineHeight: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '40px',
            lineHeight: '50px',
        },
    },
    introText2: {
        fontSize: '19px',
        fontWeight: 'bold',
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            fontSize: '15px',
        },
    },
    image: {
        [theme.breakpoints.down('lg')]: {
            height: '500px',
            width: 'auto'
        },
        [theme.breakpoints.down('md')]: {
            height: '400px',
            width: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            width: '100%'
        },
        marginTop: '120px',
        width: '100%'
    },
    crescent: {
        marginTop: '-30px',
        position: 'absolute',
        marginLeft: '90px'
    },
    crescentWrapper: {
        position: 'relative',
        width: '100%',
        textAlign: 'center'
    },
    purpleBall: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        backgroundColor: theme.palette.purple.light,
        position: 'absolute',
        right: '95px',
        top: '-100px'
    },
    whiteBall: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        backgroundColor: 'white',
        position: 'absolute',
        left: '-70px',
        bottom: '-50px'
    }
}));

const IntroBenefits = props => {

    const classes = useStyles();

    return <div className={classes.container}>
        <Container maxWidth="xl">
            <Grid container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
            >
                <Hidden mdUp>
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                        <img className={classes.image} src="/images/crowdea_hero.png"/>
                    </Grid>
                    <Grid item xs={12} className={classes.introText}>
                        <Typography variant={'h1'} className={classes.introText1}>
                            {props.intl.formatMessage({id: 'let_the_crowd'})}<br/>
                            {props.intl.formatMessage({id: 'figure_it_out'})}<br/>
                            {props.intl.formatMessage({id: 'for_you'})}
                        </Typography>
                        <Typography className={classes.introText2}>
                            {props.intl.formatMessage({id: 'let_the_crowd_desc'})}
                        </Typography>
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item md={5} xl={4} className={classes.introText} style={{position: 'relative'}}>
                        <div className={classes.purpleBall}/>
                        <div className={classes.whiteBall}/>
                        <Typography variant={'h1'} className={classes.introText1}>
                            {props.intl.formatMessage({id: 'let_the_crowd'})}<br/>
                            {props.intl.formatMessage({id: 'figure_it_out'})}<br/>
                            {props.intl.formatMessage({id: 'for_you'})}
                        </Typography>
                        <Typography className={classes.introText2}>
                            {props.intl.formatMessage({id: 'let_the_crowd_desc'})}
                        </Typography>
                    </Grid>
                    <Grid item md={7} xl={8} style={{textAlign: 'center'}}>
                        <img className={classes.image} src="/images/crowdea_hero.png"/>
                        <div className={classes.crescentWrapper}>
                            <img className={classes.crescent} src="/images/crescent.png"/>
                        </div>
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    </div>
};

export default injectIntl(IntroBenefits);