import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import IdeaCategoriesTableHead from "./IdeaCategoriesTableHead";
import TableBody from "@material-ui/core/TableBody";
import {getComparator, stableSort} from "../../utils/utils";
import Typography from "@material-ui/core/Typography";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../common/TablePaginationActions";
import {lighten, makeStyles} from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import CategoryIcon from '@material-ui/icons/Category';
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import AddBoxIcon from '@material-ui/icons/AddBox';
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import GenericDialog from "../common/GenericDialog";
import {useHistory} from 'react-router-dom';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main,
        marginRight: '16px'
    },
    icon: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px',
        padding: '2px 2px'
    },
    iconTableCell: {
        padding: 0
    },
    toolbarContent: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    header: {
        display: 'flex',
        padding: '16px',
        alignItems: 'center'
    },
    tableContainer: {
        maxHeight: '65vh'
    },
    highlight: {
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
    },
    pagination: {
        '& div.MuiToolbar-root': {
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'center'
            }
        },
        '& div.MuiTablePagination-spacer': {
            flex: 'none'
        }
    }
}));

const IdeaCategoriesTable = props => {

    const classes = useStyles();
    const history = useHistory();
    const {categories, deleteCategory, crowdId} = props;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('name');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [toDeleteCategoryId, setToDeleteCategoryId] = React.useState(0);

    const openModal = categoryId => event => {
        event.stopPropagation();
        setToDeleteCategoryId(categoryId);
        setDialogOpen(true);
    };

    const closeModal = () => {
        setDialogOpen(false);
    };

    const deleteSuccessCallback = () => {
        if ((categories.length - 1) % rowsPerPage === 0)
            setPage(Math.max(page - 1, 0));
    };

    const rowClickHandler = categoryId => () => history.push(`/crowds/${crowdId}/categories/${categoryId}`);

    const createButtonClickHandler = () => history.push(`/crowds/${crowdId}/categories/create`);

    const deleteHandler = () => deleteCategory(crowdId, toDeleteCategoryId, deleteSuccessCallback);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const displayedRowsLabel = ({from, to, count}) => {
        const of = props.intl.formatMessage({id: 'of'});
        return `${from}-${to === -1 ? count : to} ${of} ${count}`;
    };

    const renderButton = () => {
        if (window.innerWidth < 450)
            return <IconButton color='primary' onClick={createButtonClickHandler}>
                <AddBoxIcon fontSize="large"/>
            </IconButton>;
        else
            return <Button color='primary' variant="contained" style={{color: 'white'}} startIcon={<AddBoxIcon/>}
                           onClick={createButtonClickHandler}>
                {props.intl.formatMessage({id: 'create_new_category'})}
            </Button>;
    };

    const paginationActions = window.innerWidth > 600 ? {ActionsComponent: TablePaginationActions} : {ActionsComponent: 'span'};

    return <>
        <Card elevation={3}>
            <div className={clsx(classes.header, 'cardHeader')}>
                <Avatar className={classes.avatar}>
                    <CategoryIcon/>
                </Avatar>
                <Typography className={'cardHeaderText'} component='div' style={{flex: '1 1 auto'}}>
                    {props.intl.formatMessage({id: 'categories'})}
                </Typography>
                {renderButton()}
            </div>
            <CardContent className={'cardContent'}>
                <Grid container
                      direction="column"
                      justify="center"
                      style={{minHeight: '60vh'}}>
                    {categories.length ? (<>
                        <Grid item>
                            <TableContainer className={classes.tableContainer}>
                                <Table size={'medium'} stickyHeader>
                                    <IdeaCategoriesTableHead
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                    />
                                    <TableBody>
                                        {(rowsPerPage > 0 ? stableSort(categories, getComparator(order, orderBy))
                                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            : stableSort(categories, getComparator(order, orderBy)))
                                            .map((category, index) => {
                                                return (
                                                    <TableRow
                                                        className={classes.highlight}
                                                        onClick={rowClickHandler(category.id)}
                                                        tabIndex={-1}
                                                        key={category.name}>
                                                        <TableCell align="left">{category.name}</TableCell>
                                                        <TableCell align="center">{category.ideaCount}</TableCell>
                                                        <TableCell align="right" className={classes.iconTableCell}>
                                                            <IconButton>
                                                                <EditIcon className={classes.icon}/>
                                                            </IconButton>
                                                            <IconButton onClick={openModal(category.id)}>
                                                                <DeleteIcon className={classes.icon}/>
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs/>
                    </>) : (<>
                            <Grid item>
                                <TableContainer className={classes.tableContainer}>
                                    <Table size={'medium'} stickyHeader>
                                        <IdeaCategoriesTableHead
                                            order={order}
                                            orderBy={orderBy}
                                            onRequestSort={handleRequestSort}
                                        />

                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs/>
                            <Grid item>
                                <Typography variant="h6" align='center'>
                                    {props.intl.formatMessage({id: 'no_categories'})}
                                </Typography>
                            </Grid>
                            <Grid item xs/>
                        </>
                    )}
                    <Grid item>
                        <TablePagination
                            className={classes.pagination}
                            component="div"
                            rowsPerPageOptions={[10, 25, 50, {
                                label: props.intl.formatMessage({id: 'all_rows'}),
                                value: -1
                            }]}
                            count={categories.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            {...paginationActions}
                            labelRowsPerPage={props.intl.formatMessage({id: 'rows_per_page'})}
                            labelDisplayedRows={displayedRowsLabel}
                        />
                    </Grid>
                    <Hidden smUp>
                        <Grid item style={{textAlign: 'center'}}>
                            <TablePaginationActions count={categories.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}/>
                        </Grid>
                    </Hidden>
                </Grid>
            </CardContent>
        </Card>
        <GenericDialog open={dialogOpen}
                       onClose={closeModal}
                       onSubmit={deleteHandler}
                       titleText={"category_delete"}
                       contentText={'category_delete_delete_are_you_sure'}
                       submitButtonText={'delete'}/>
    </>;
};

export default injectIntl(IdeaCategoriesTable);