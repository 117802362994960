import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import capitalize from "@material-ui/core/utils/capitalize";
import {injectIntl} from "react-intl";

const CrowdUsersRolesMenu = props => {

    const {handleClose, allowedUserRoles, crowdUser, anchorEl} = props;

    return <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} role={undefined} transition>
        {({TransitionProps}) => (
            <Grow {...TransitionProps}>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose(crowdUser, undefined)}>
                        <MenuList>
                            {allowedUserRoles.map(value =>
                                <MenuItem key={value}
                                          value={value}
                                          onClick={handleClose(crowdUser, crowdUser.userType === value ? undefined : value)}>
                                    <Typography>
                                        {capitalize(props.intl.formatMessage({id: value.toLowerCase()}))}
                                    </Typography>
                                </MenuItem>
                            )}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>;
};

export default injectIntl(CrowdUsersRolesMenu);