export const getYoutubeThumbnailUrl = videoId => `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

export const getYoutubeVideoId = url => {
    if (!url.includes('youtube'))
        return false;

    var videoId = false;
    videoId = new URL(url).searchParams.get('v');
    if (!videoId && url.includes('embed')) {
        const arr = url.split('/');
        return arr[arr.length - 1]
    }
    return videoId;
};