import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {injectIntl} from "react-intl";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {LabeledText} from "../common/LabeledText";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    }
}));

const CreditCardView = props => {
    const classes = useStyles();
    const {singleCard, card, intl, onDelete, makeDefault} = props;

    const headerTitle = (<Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
    >
        <Grid item>
            <Typography className={'cardHeaderText'}>{card.brand.toUpperCase()}</Typography>
        </Grid>
        {/*{card.primary && !singleCard && (
            <Grid item>
                <Typography>{intl.formatMessage({id: 'default'})}</Typography>
            </Grid>
        )}*/}
    </Grid>);

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <CreditCardIcon/>
                </Avatar>
            }
            title={headerTitle}
            className={'cardHeader'}
        />
        <Divider variant="middle"/>
        <CardContent className={'cardContent'}>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <LabeledText
                        label={intl.formatMessage({id: 'credit_card_number'})}
                        name="cardNumber"
                        value={`**** **** **** ${card.last4}`}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LabeledText
                        label={intl.formatMessage({id: 'credit_card_expiration'})}
                        name="city"
                        value={`${card.expMonth} / ${card.expYear}`}
                    />
                </Grid>
                {!singleCard && <>
                    {/*<Grid item xs={12} sm='auto'>
                        <Button onClick={makeDefault} disabled={card.primary}>
                            {intl.formatMessage({id: 'make_default'})}
                        </Button>
                    </Grid>*/}
                    <Grid item xs={12} sm='auto'>
                        <Button onClick={onDelete}>
                            {intl.formatMessage({id: 'delete'})}
                        </Button>
                    </Grid>
                </>}
            </Grid>
        </CardContent>
    </Card>;
};

export default injectIntl(CreditCardView);