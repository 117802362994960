import React from "react";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import CrowdUsersAddNew from "./CrowdUsersAddNew";
import {CrowdUserType} from "../../constants";
import {addCrowdUsers} from "../../actions/crowdUsers";
import CrowdUsersTable from "./CrowdUsersTable";
import CrowdUsersPrivacy from "./CrowdUsersPrivacy";


const CrowdUsers = props => {

    const {
        crowd,
        crowdId,
        crowdUsers = {},
        deleteCrowdUser,
        updateCrowdUsers,
        subscription,
        updateCrowd
    } = props;
    const subscriptionFeatures = subscription.current.features || {};
    const users = crowdUsers.loaded ? crowdUsers.items : [];

    const getSubscriptionLimit = featureName => {
        return subscription.current.id !== undefined ? subscriptionFeatures[featureName] : false;
    };

    const allowedUserRoles = [
        ...(getSubscriptionLimit('managers') ? [CrowdUserType.CROWD_MANAGER] : []),
        ...(getSubscriptionLimit('admins') - 1 ? [CrowdUserType.CROWD_ADMIN] : []),
    ];

    const userCounts = {
        [CrowdUserType.CROWD_MANAGER]: {
            count: users.filter(u => u.userType === CrowdUserType.CROWD_MANAGER && !u.disabled).length,
            limit: getSubscriptionLimit('managers')
        },
        [CrowdUserType.CROWD_ADMIN]: {
            count: users.filter(u => u.userType === CrowdUserType.CROWD_ADMIN && !u.disabled).length,
            limit: getSubscriptionLimit('admins') - 1
        }
    };

    const addCrowdUsersCurried = crowdId => (body, successCb, errorCb) => addCrowdUsers(crowdId, body, successCb, errorCb);

    return <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={1}
    >
        {subscriptionFeatures.privateCrowd && <Grid item style={{width: '100%'}}>
            <CrowdUsersPrivacy crowd={crowd} updateCrowd={updateCrowd} subscriptionFeatures={subscriptionFeatures}/>
        </Grid>}
        <Grid item style={{width: '100%'}}>
            <CrowdUsersAddNew allowedUserRoles={allowedUserRoles}
                              userCounts={userCounts}
                              addCrowdUsers={addCrowdUsersCurried(crowdId)}/>
        </Grid>
        <Grid item style={{width: '100%'}}>
            <CrowdUsersTable allowedUserRoles={allowedUserRoles}
                             userCounts={userCounts}
                             crowdUsers={crowdUsers.items}
                             updateCrowdUsers={updateCrowdUsers}
                             deleteCrowdUser={deleteCrowdUser}/>
        </Grid>
    </Grid>
};

export default injectIntl(CrowdUsers);