import {injectIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const GenericDialog = props => {

    const {
        open,
        onClose,
        onSubmit,
        titleText,
        contentText,
        submitButtonText = 'yes',
        closeButtonText = 'close'
    } = props;

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = () => {
        onSubmit();
        handleClose();
    };

    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='sm'
            fullWidth
            scroll='body'>
            <DialogTitle>
                {props.intl.formatMessage({id: titleText})}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {props.intl.formatMessage({id: contentText})}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={handleClose}>
                    {props.intl.formatMessage({id: closeButtonText})}
                </Button>
                <Button color='primary' variant="contained" style={{color: 'white'}} onClick={handleSubmit}>
                    {props.intl.formatMessage({id: submitButtonText})}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default injectIntl(GenericDialog);