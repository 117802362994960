import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";

export function createIdeaQuestionnaire(body) {
    const crowdId = body.crowdId;
    return async dispatch => {
        secureFetch(API_URL + "/questionnaire",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => extractBodyOrThrow(response))
            .then(questionnaire => {
                dispatch(questionnaireLoaded(questionnaire, crowdId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_questionnaire_saved", 3000));
            })
            .catch(error => console.log(error.message));
    };
}

export function loadIdeaQuestionnaire(crowdId) {
    return async dispatch => {
        secureFetch(API_URL + "/questionnaire/" + crowdId,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(questionnaires => dispatch(questionnaireLoaded(questionnaires, crowdId)))
            .catch(error => console.log(error.message));
    };
}

function questionnaireLoaded(item, crowdId) {
    return {
        type: ActionType.QUESTIONNAIRE_LOADED,
        item,
        crowdId
    };
}