import React from "react";
import {useHistory, useLocation} from 'react-router-dom';

export const useRedirectWithState = () => {
    const history = useHistory();
    const location = useLocation();

    const currentPath = location.pathname;
    const searchParams = location.search;

    return to => {
        history.push({
            pathname: to,
            state: {from: `${currentPath}${searchParams}`}
        });
    };
};