import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";
import {secureFetch} from "../http/secureFetch";

export function loadCrowdView(domain) {
    return async dispatch => {
        fetch(API_URL + "/crowdView/" + domain,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(crowdView => dispatch(crowdViewLoaded(crowdView)))
            .catch(() => dispatch(emitAppNotification(NotificationType.ERROR)));
    };
}

function crowdViewLoaded(data) {
    return {
        type: ActionType.CROWD_VIEW_DATA_LOADED,
        data
    };
}

export function loadCrowdViewCategories(domain) {
    return async dispatch => {
        secureFetch(API_URL + "/crowdView/category/" + domain,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(categories => dispatch(crowdViewCategoriesLoaded(categories)))
            .catch(() => dispatch(emitAppNotification(NotificationType.ERROR)));
    };
}

function crowdViewCategoriesLoaded(items) {
    return {
        type: ActionType.CROWD_VIEW_CATEGORIES_LOADED,
        items
    };
}

export function loadCrowdViewQuestionnaire(domain) {
    return async dispatch => {
        secureFetch(API_URL + "/crowdView/ideaQuestionnaire/" + domain,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(questionnaire => dispatch(crowdViewQuestionnaireLoaded(questionnaire)))
            .catch(() => dispatch(emitAppNotification(NotificationType.ERROR)));
    };
}

function crowdViewQuestionnaireLoaded(item) {
    return {
        type: ActionType.CROWD_VIEW_QUESTIONNAIRE_LOADED,
        item
    };
}

export function loadCrowdViewIdeas(domain) {
    return async dispatch => {
        secureFetch(API_URL + "/crowdView/ideas/" + domain,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(ideas => dispatch(ideasLoaded(ideas)))
            .catch(() => dispatch(emitAppNotification(NotificationType.ERROR)));
    };
}

function ideasLoaded(items) {
    return {
        type: ActionType.CROWD_VIEW_IDEAS_LOADED,
        items
    };
}

export function loadCrowdViewComments(domain, ideaId, successCb, offset = 0, limit = 20) {
    return async dispatch => {
        secureFetch(`${API_URL}/crowdView/comment/${domain}/${ideaId}?offset=${offset}&limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(comments => {
                dispatch(commentsLoaded(comments, ideaId));
                if (successCb)
                    successCb();
            })
            .catch(error => console.log(error.message));
    };
}

function commentsLoaded(items, ideaId) {
    return {
        type: ActionType.COMMENTS_LOADED,
        items,
        ideaId
    };
}

export function createCrowdViewComment(domain, content, ideaId, successCb) {
    return async dispatch => {
        secureFetch(API_URL + "/crowdView/comment/" + domain,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    content,
                    ideaId
                })
            })
            .then(response => extractBodyOrThrow(response))
            .then(comment => {
                dispatch(commentLoaded(comment, ideaId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "comment_created", 3000));
                successCb();
            })
            .catch(error => console.log(error.message));
    };
}

function commentLoaded(item, ideaId) {
    return {
        type: ActionType.COMMENT_LOADED,
        item,
        ideaId
    };
}

export function updateCrowdViewComment(domain, ideaId, body, successCb) {
    return async dispatch => {
        secureFetch(API_URL + "/crowdView/comment/" + domain,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => extractBodyOrThrow(response))
            .then(comment => {
                dispatch(commentUpdated(comment, ideaId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "comment_updated", 3000));
                successCb();
            })
            .catch(error => console.log(error.message));
    };
}

function commentUpdated(item, ideaId) {
    return {
        type: ActionType.COMMENT_UPDATED,
        item,
        ideaId
    };
}

export function deleteCrowdViewComment(domain, commentId, ideaId) {
    return async dispatch => {
        secureFetch(`${API_URL}/crowdView/comment/${domain}/${commentId}`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                dispatch(commentDeleted(commentId, ideaId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "comment_deleted", 3000));
            })
            .catch(error => console.log(error.message));
    };
}

function commentDeleted(commentId, ideaId) {
    return {
        type: ActionType.COMMENT_DELETED,
        commentId,
        ideaId
    };
}