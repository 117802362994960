import React from "react";
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import capitalize from "@material-ui/core/utils/capitalize";

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: '80px',
        backgroundColor: '#FD8123',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '80px'
        },
    },
    text1: {
        marginBottom: '20px',
        color: 'white',
        fontSize: '60px',
        lineHeight: '1.1',
        fontFamily: 'Open Sans',
        [theme.breakpoints.down('xl')]: {
            fontSize: '55px',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '45px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '40px',
        }
    },
    text2: {
        fontWeight: 'bold',
        color: 'white',
        opacity: '0.7',
        [theme.breakpoints.up('md')]: {
            width: '40%'
        }
    },
    card: {
        height: '330px',
        backgroundColor: '#FD8123',
        [theme.breakpoints.down('xs')]: {
            height: 'auto'
        },
    },
    cardText1: {
        fontFamily: 'Open Sans',
        marginBottom: '25px',
        color: 'white',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        }
    },
    cardText2: {
        fontWeight: 'bold',
        color: 'white',
        opacity: '0.7',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    cardHeader: {
        [theme.breakpoints.down('md')]: {
            marginRight: '0px',
            width: '100%',
            textAlign: 'center'
        },
    },
    imageWrapper: {
        overflowX: 'visible',
        display: 'inline-block'
    },
    whiteBall: {
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        backgroundColor: 'white',
        position: 'absolute',
        left: '-190px',
        bottom: '-50px'
    },
    whiteBall2: {
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
        borderRadius: '50%',
        height: '20px',
        width: '20px',
        backgroundColor: 'white',
        position: 'absolute',
        right: '50px',
        bottom: '-50px'
    },
    whiteBall3: {
        borderRadius: '50%',
        height: '20px',
        width: '20px',
        backgroundColor: 'white',
        position: 'absolute',
        left: '-150px',
        bottom: '-50px'
    }
}));

const HowItWorks = props => {

    const classes = useStyles();

    return <div className={classes.container}>
        <Container maxWidth="xl">
            <div style={{position: 'relative', width: '100%'}}>
                <div className={classes.whiteBall}/>
                <div className={classes.whiteBall2}/>
            </div>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={4}
            >
                <Grid item xs={12}>
                    <Typography variant={'h1'} className={classes.text1}>
                        {props.intl.formatMessage({id: 'how_does_it_work'}) + '?'}
                    </Typography>
                    <Typography className={classes.text2}>
                        {props.intl.formatMessage({id: 'how_does_it_work_desc'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card elevation={0} className={classes.card}>
                        <CardHeader
                            classes={{avatar: classes.cardHeader}}
                            avatar={<span className={classes.imageWrapper} style={{width: '70px'}}>
                                <img src="/images/users.png"/>
                            </span>}/>
                        <CardContent>
                            <Typography className={classes.cardText1}>
                                {capitalize(props.intl.formatMessage({id: 'users'}))}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'how_does_it_work_users_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card elevation={0} className={classes.card}>
                        <CardHeader
                            classes={{avatar: classes.cardHeader}}
                            avatar={
                                <span className={classes.imageWrapper}>
                                    <img src="/images/ideas.png"/>
                            </span>}/>
                        <CardContent>
                            <Typography className={classes.cardText1}>
                                {capitalize(props.intl.formatMessage({id: 'ideas'}))}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'how_does_it_work_ideas_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card elevation={0} className={classes.card}>
                        <CardHeader
                            classes={{avatar: classes.cardHeader}}
                            avatar={<span>
                                <img src="/images/ratings.png"/>
                            </span>}/>
                        <CardContent>
                            <Typography className={classes.cardText1}>
                                {capitalize(props.intl.formatMessage({id: 'ratings'}))}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'how_does_it_work_ratings_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <Card elevation={0} className={classes.card}>
                        <CardHeader
                            classes={{avatar: classes.cardHeader}}
                            avatar={<span className={classes.imageWrapper}>
                                <img src="/images/rewards.png"/>
                            </span>}/>
                        <CardContent>
                            <Typography className={classes.cardText1}>
                                {capitalize(props.intl.formatMessage({id: 'rewards'}))}
                            </Typography>
                            <Typography className={classes.cardText2}>
                                {props.intl.formatMessage({id: 'how_does_it_work_rewards_desc'})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div style={{position: 'relative'}}>
                <div className={classes.whiteBall3}/>
            </div>
        </Container>
    </div>
};

export default injectIntl(HowItWorks);