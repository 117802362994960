import React, {useEffect} from "react";
import MyAccount from "../userAccount/MyAccount";
import {Route, Switch} from 'react-router-dom';
import Crowds from "../crowdAdmin/Crowds";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../layout/Footer";
import Container from "@material-ui/core/Container";
import {withAuthentication} from "../common/withAuthentication";
import Ideas from "../ideaAdmin/Ideas";
import {userLogOut} from "../../actions/login";
import {compose} from "redux";
import {connect} from "react-redux";
import CrowdDetail from "../crowdAdmin/CrowdDetail";
import {loadCrowds} from "../../actions/crowds";
import {loadAllSubscriptions} from "../../actions/subscriptions";
import {loadNotifications, markNotificationsAsRead} from "../../actions/notification";
import {loadIdeas} from "../../actions/idea";
import IdeaDetail from "../ideaAdmin/IdeaDetail";
import DirectSubscribe from "../payment/DirectSubscribe";

const useStyles = makeStyles(theme => ({
    toolBarDesktop: {
        height: '100px'
    },
    toolBarMobile: {
        height: '65px'
    },
    crowdeaLogo: {
        height: '30px'
    },
    content: {
        flex: '1 0 auto',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        }
    },
    container: {
        paddingTop: '100px',
    },
    flexBox: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#F9FAFC',
        overflow: 'auto'
    }
}));

const Dashboard = props => {

    const classes = useStyles();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const {
        loadCrowds,
        loadAllSubscriptions,
        loadNotifications,
        notifications,
        logout,
        markNotificationsAsRead,
        loadIdeas
    } = props;

    useEffect(() => {
        loadCrowds();
        loadAllSubscriptions();
        loadIdeas();

        if (!notifications.loaded)
            loadNotifications();
    }, []);

    const closeDrawer = event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        toggleDrawer();
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return <>
        <DashboardNavbar logout={logout} toggleDrawer={toggleDrawer} notifications={notifications}
                         markNotificationsAsRead={markNotificationsAsRead}/>
        <DashboardSidebar logout={logout} open={drawerOpen} toggleDrawer={toggleDrawer} notifications={notifications}
                          closeDrawer={closeDrawer}/>
        <div className={classes.flexBox}>
            <main className={classes.content}>
                <Container maxWidth="xl" className={classes.container}>
                    <Switch>
                        <Route path="/account" component={MyAccount}/>
                        <Route path="/crowds/:crowdId" component={CrowdDetail}/>
                        <Route path="/crowds" component={Crowds}/>
                        <Route path="/ideas/:ideaId" component={IdeaDetail}/>
                        <Route path="/ideas" component={Ideas}/>
                        <Route path="/subscription" component={DirectSubscribe}/>
                    </Switch>
                </Container>
            </main>
            <Footer/>
        </div>
    </>
};


const mapStateToProps = state => {
    return {
        notifications: state.notifications
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(userLogOut()),
        loadCrowds: () => dispatch(loadCrowds()),
        loadIdeas: () => dispatch(loadIdeas()),
        loadAllSubscriptions: () => dispatch(loadAllSubscriptions()),
        loadNotifications: () => dispatch(loadNotifications()),
        markNotificationsAsRead: ids => dispatch(markNotificationsAsRead(ids))
    };
};

export default compose(
    withAuthentication,
    connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);