import React from "react";
import {injectIntl} from "react-intl";
import Intro from "./Intro";
import HowItWorks from "./HowItWorks";
import Benefits from "./Benefits";
import CrowdsPreview from "./CrowdsPreview";
import MostLikedIdeasPreview from "./MostLikedIdeasPreview";
import NewestIdeasPreview from "./NewestIdeasPreview";
import Contact from "../Contact";

const UserLanding = props => {

    const {landingPage} = props;

    return <>
        <Intro/>
        <HowItWorks/>
        <Benefits/>
        <CrowdsPreview crowds={landingPage.crowds.items}/>
        <MostLikedIdeasPreview ideas={landingPage.mostLiked.items}/>
        <NewestIdeasPreview ideas={landingPage.mostRecent.items}/>
        <Contact/>
    </>
};

export default injectIntl(UserLanding);