import React from "react";
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import {HashLink} from "react-router-hash-link";
import {offsetScroll} from "../LandingPage";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: 'white'
    },
    textWrapper: {
        [theme.breakpoints.up('md')]: {
            marginTop: '100px'
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: '50px'
        }
    },
    introText: {
        fontSize: '60px',
        lineHeight: '65px',
        fontFamily: 'Open Sans',
        [theme.breakpoints.down('lg')]: {
            fontSize: '55px',
            lineHeight: '60px',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '40px',
            lineHeight: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '35px',
            lineHeight: '45px',
        }
    },
    introText1: {
        color: theme.palette.indigo.dark
    },
    introText2: {
        color: theme.palette.orange.main
    },
    image: {
        height: '500px',
        width: 'auto',
        [theme.breakpoints.down('lg')]: {
            height: '400px',
            width: 'auto'
        },
        [theme.breakpoints.down('md')]: {
            height: '300px',
            width: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            width: '100%',
            marginTop: 'unset'
        },
        marginTop: '50px',
    },
    greenButton: {
        color: 'white',
        background: 'linear-gradient(to right, #6CD53D, #47C621)',
        borderRadius: '15px',
        width: '170px',
        textTransform: 'none'
    },
    captionWrapper: {
        marginTop: '140px',
        padding: '32px',
        [theme.breakpoints.down('md')]: {
            marginTop: '100px',
            padding: 0
        },
    },
    link: {
        textDecoration: 'none'
    }
}));

const IntroBenefits = props => {

    const classes = useStyles();

    return <div className={classes.container}>
        <Container maxWidth="xl">
            <Grid container
                  justify="center"
                  alignItems="center"
            >
                <Hidden mdUp>
                    <Grid item xs={12} className={classes.captionWrapper}>
                        <Typography className={clsx(classes.introText, classes.introText1)}>
                            {props.intl.formatMessage({id: 'monetize'})}
                        </Typography>
                        <Typography className={clsx(classes.introText, classes.introText2)} style={{marginBottom: '20px'}}>
                            {props.intl.formatMessage({id: 'your_ideas'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <img className={classes.image} src="/images/user_woman.png"/>
                    </Grid>
                    <Grid item xs={12} style={{paddingBottom: '20px'}}>
                        <Typography className={classes.introText1} style={{marginBottom: '20px'}}>
                            {props.intl.formatMessage({id: 'love_getting_creative'})}
                        </Typography>
                        <Button variant="contained" className={classes.greenButton} disableElevation>
                            {props.intl.formatMessage({id: 'try_crowdea'})}
                        </Button>
                    </Grid>
                </Hidden>
                <Hidden smDown>
                    <Grid item xs={5} className={classes.captionWrapper}>
                        <Typography className={clsx(classes.introText, classes.introText1)}>
                            {props.intl.formatMessage({id: 'monetize'})}
                        </Typography>
                        <Typography className={clsx(classes.introText, classes.introText2)} style={{marginBottom: '20px'}}>
                            {props.intl.formatMessage({id: 'your_ideas'})}
                        </Typography>
                        <Typography className={classes.introText1} style={{marginBottom: '20px'}}>
                            {props.intl.formatMessage({id: 'love_getting_creative'})}
                        </Typography>
                        <HashLink
                            scroll={el => offsetScroll(el)}
                            to='#crowdspreview'
                            className={classes.link}>
                            <Button variant="contained" className={classes.greenButton} disableElevation>
                                {props.intl.formatMessage({id: 'try_crowdea'})}
                            </Button>
                        </HashLink>
                    </Grid>
                    <Grid item xs={'auto'} style={{textAlign: 'center'}}>
                        <img className={classes.image} src="/images/user_woman.png"/>
                    </Grid>
                </Hidden>
            </Grid>
        </Container>
    </div>
};

export default injectIntl(IntroBenefits);