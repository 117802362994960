import {injectIntl} from "react-intl";
import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    caption: {
        fontWeight: 'bold'
    },
}));

const ServiceAgreementContent = props => {

    const classes = useStyles();

    return <>
        <Typography paragraph className={classes.caption}>
            I. {props.intl.formatMessage({id: 'terms_of_service_I'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'terms_of_service_I_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'terms_of_service_I_2'})}
        </Typography>
        <Typography paragraph>
            3. {props.intl.formatMessage({id: 'terms_of_service_I_3'})}
        </Typography>
        <Typography paragraph>
            4. {props.intl.formatMessage({id: 'terms_of_service_I_4'})}
        </Typography>
        <Typography paragraph>
            5. {props.intl.formatMessage({id: 'terms_of_service_I_5'})}
        </Typography>
        <Typography paragraph>
            6. {props.intl.formatMessage({id: 'terms_of_service_I_6'})}
        </Typography>
        <Typography paragraph>
            7. {props.intl.formatMessage({id: 'terms_of_service_I_7'})}
        </Typography>
        <Typography paragraph>
            8. {props.intl.formatMessage({id: 'terms_of_service_I_8'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            II. {props.intl.formatMessage({id: 'terms_of_service_II'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'terms_of_service_II_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'terms_of_service_II_2'})}
        </Typography>
        <Typography paragraph>
            3. {props.intl.formatMessage({id: 'terms_of_service_II_3'})}
        </Typography>
        <Typography paragraph>
            4. {props.intl.formatMessage({id: 'terms_of_service_II_4'})}
        </Typography>
        <Typography paragraph>
            5. {props.intl.formatMessage({id: 'terms_of_service_II_5'})}
        </Typography>
        <Typography paragraph>
            6. {props.intl.formatMessage({id: 'terms_of_service_II_6'})}
        </Typography>
        <Typography paragraph>
            7. {props.intl.formatMessage({id: 'terms_of_service_II_7'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            III. {props.intl.formatMessage({id: 'terms_of_service_III'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'terms_of_service_III_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'terms_of_service_III_2'})}
        </Typography>
        <Typography paragraph>
            3. {props.intl.formatMessage({id: 'terms_of_service_III_3'})}
        </Typography>
        <Typography paragraph>
            4. {props.intl.formatMessage({id: 'terms_of_service_III_4'})}
        </Typography>
        <Typography paragraph>
            5. {props.intl.formatMessage({id: 'terms_of_service_III_5'})}
        </Typography>
        <Typography paragraph>
            6. {props.intl.formatMessage({id: 'terms_of_service_III_6'})}
        </Typography>
        <Typography paragraph>
            7. {props.intl.formatMessage({id: 'terms_of_service_III_7'})}
        </Typography>
        <Typography paragraph>
            8. {props.intl.formatMessage({id: 'terms_of_service_III_8'})}
        </Typography>
        <Typography paragraph>
            9. {props.intl.formatMessage({id: 'terms_of_service_III_9'})}
        </Typography>
        <Typography paragraph>
            10. {props.intl.formatMessage({id: 'terms_of_service_III_10'})}
        </Typography>
        <Typography paragraph>
            11. {props.intl.formatMessage({id: 'terms_of_service_III_11'})}
        </Typography>
        <Typography paragraph>
            12. {props.intl.formatMessage({id: 'terms_of_service_III_12'})}
        </Typography>
        <Typography paragraph>
            13. {props.intl.formatMessage({id: 'terms_of_service_III_13'})}
        </Typography>
        <Typography paragraph>
            14. {props.intl.formatMessage({id: 'terms_of_service_III_14'})}
        </Typography>
        <ul>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'terms_of_service_III_14_1'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'terms_of_service_III_14_2'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'terms_of_service_III_14_3'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'terms_of_service_III_14_4'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'terms_of_service_III_14_5'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'terms_of_service_III_14_6'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'terms_of_service_III_14_7'})}
                </Typography>
            </li>
        </ul>
        <Typography paragraph className={classes.caption}>
            IV. {props.intl.formatMessage({id: 'terms_of_service_IV'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'terms_of_service_IV_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'terms_of_service_IV_2'})}
        </Typography>
        <Typography paragraph>
            3. {props.intl.formatMessage({id: 'terms_of_service_IV_3'})}
        </Typography>
        <Typography paragraph>
            4. {props.intl.formatMessage({id: 'terms_of_service_IV_4'})}
        </Typography>
        <Typography paragraph>
            5. {props.intl.formatMessage({id: 'terms_of_service_IV_5'})}
        </Typography>
        <Typography paragraph>
            6. {props.intl.formatMessage({id: 'terms_of_service_IV_6'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            V. {props.intl.formatMessage({id: 'terms_of_service_V'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'terms_of_service_V_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'terms_of_service_V_2'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            VI. {props.intl.formatMessage({id: 'terms_of_service_VI'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'terms_of_service_VI_1'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            VII. {props.intl.formatMessage({id: 'terms_of_service_VII'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'terms_of_service_VII_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'terms_of_service_VII_2'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            VIII. {props.intl.formatMessage({id: 'terms_of_service_VIII'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'terms_of_service_VIII_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'terms_of_service_VIII_2'})}
        </Typography>
        <Typography paragraph>
            3. {props.intl.formatMessage({id: 'terms_of_service_VIII_3'})}
        </Typography>
        <Typography paragraph>
            4. {props.intl.formatMessage({id: 'terms_of_service_VIII_4'})}
        </Typography>
        <Typography paragraph>
            5. {props.intl.formatMessage({id: 'terms_of_service_VIII_5'})}
        </Typography>
        <Typography paragraph>
            6. {props.intl.formatMessage({id: 'terms_of_service_VIII_6'})}
        </Typography>
        <Typography paragraph>
            7. {props.intl.formatMessage({id: 'terms_of_service_VIII_7'})}
        </Typography>
    </>;
};

export default injectIntl(ServiceAgreementContent);