import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {CrowdUserType, emailRegex, separatorRegex} from "../../constants";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import capitalize from "@material-ui/core/utils/capitalize";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));


const CrowdUsersAddNew = props => {

    const classes = useStyles();
    const {allowedUserRoles, userCounts, addCrowdUsers} = props;
    const [emails, setEmails] = React.useState('');
    const [selectedRole, setSelectedRole] = React.useState(CrowdUserType.CROWD_MANAGER);
    const [validationErrors, setValidationErrors] = React.useState({});
    const validators = {
        emails: () => {
            const emailArr = emails.split(separatorRegex).filter(e => e !== '');
            if (!emailArr.every(e => emailRegex.test(e)))
                return props.intl.formatMessage({id: 'validation_crowd_users_emails'});

            if (userCounts[selectedRole].limit > 0 && userCounts[selectedRole].count + emailArr.length > userCounts[selectedRole].limit) {
                const countRemaining = userCounts[selectedRole].limit - userCounts[selectedRole].count;
                if (countRemaining === 0)
                    return props.intl.formatMessage({id: 'validation_crowd_users_can_not_add'}) + ' '
                        + props.intl.formatMessage({id: selectedRole.toLowerCase() + 's'});
                else
                    return `${props.intl.formatMessage({id: 'validation_crowd_users_can_only_add'})} ${countRemaining} more ${props.intl.formatMessage({id: selectedRole.toLowerCase() + 's'})}`;
            }

            return true;
        }
    };

    const validateInput = () => {
        const result = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});

        setValidationErrors(result);
        return Object.keys(result).length === 0;
    };

    const handleInputChange = event => {
        setEmails(event.target.value);
    };

    const roleSelected = event => {
        event.preventDefault();
        setSelectedRole(event.target.value);
    };

    const successCallback = () => {
        setEmails('');
        setValidationErrors({});
        setSelectedRole(CrowdUserType.CROWD_MANAGER);
    };

    const errCallback = () => {
        setValidationErrors({emails: props.intl.formatMessage({id: 'validation_crowd_users_do_not_exist'})});
    };

    const handleSubmit = () => {
        if (emails !== '' && validateInput()) {
            const body = {
                emails: [...new Set(emails.split(separatorRegex).filter(e => e !== ''))],
                userType: selectedRole
            };
            addCrowdUsers(body, successCallback, errCallback);
        }
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <GroupAddIcon/>
                </Avatar>
            }
            title={<Typography
                className={'cardHeaderText'}>{props.intl.formatMessage({id: 'crowd_users_add_new'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'crowd_users_emails_to_add_desc'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        multiline
                        rows={3}
                        rowsMax={10}
                        label={props.intl.formatMessage({id: 'crowd_users_emails_to_add'})}
                        name="emails"
                        margin="dense"
                        fullWidth
                        error={validationErrors['emails']}
                        helperText={validationErrors['emails']}
                        value={emails}
                        onChange={handleInputChange}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="crowd_user_role_select_label">
                            {props.intl.formatMessage({id: 'crowd_users_role'})}
                        </InputLabel>
                        <Select
                            labelId="crowd_user_role_select_label"
                            id="crowd_user_role_select"
                            fullWidth
                            label={props.intl.formatMessage({id: 'crowd_users_role'})}
                            value={selectedRole}
                            onChange={roleSelected}
                        >
                            {allowedUserRoles.map(value =>
                                <MenuItem key={value}
                                          value={value}>{capitalize(props.intl.formatMessage({id: value.toLowerCase()}))}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm='auto'>
                    <Button color='primary' variant="contained" className={classes.button} onClick={handleSubmit}>
                        {props.intl.formatMessage({id: 'crowd_users_add_button'})}
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
};

export default injectIntl(CrowdUsersAddNew);