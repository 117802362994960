import React from 'react';
import {withStyles,} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const disabledTextField = (props) => {
    const mergedProps = {
        ...props,
        value: props.value || '',
        InputProps: {
            ...props.InputProps,
            disableUnderline: true
        }
    };
    return <TextField
        margin="dense"
        disabled
        {...mergedProps}/>
};

export const LabeledText = withStyles({
    root: {
        '& label.MuiFormLabel-root.Mui-disabled': {
            color: 'black',
        },
        '& input.MuiInputBase-input.Mui-disabled': {
            color: 'black'
        }
    }
})(disabledTextField);