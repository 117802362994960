import {ActionType, API_URL, NotificationType} from "../constants";
import {secureFetch} from "../http/secureFetch";
import {emitAppNotification, extractBodyOrThrow, parseApiValidationErrors} from "./common";
import {changeLocale} from "./locale";

export function loadUser() {
    return async dispatch => {
        secureFetch(API_URL + "/user",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => {
                if (response.status === 401) {
                    return {};
                }
                return response.json();
            })
            .then(user => {
                dispatch(userLoaded(user));
                if (user.id)
                    dispatch(changeLocale(user.locale));
            })
            .catch(error => console.log(error.message));
    };
}

export function userLoaded(data) {
    return {
        type: ActionType.USER_LOADED,
        data
    };
}

export function updateUser(user, emitNotification = false) {
    return async dispatch => {
        secureFetch(API_URL + "/user",
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user)
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }

                return response;
            })
            .then(response => extractBodyOrThrow(response))
            .then(updatedUser => {
                dispatch(userUpdated(updatedUser));
                dispatch(changeLocale(updatedUser.locale));

                if (emitNotification) {
                    dispatch(emitAppNotification(NotificationType.SUCCESS, "account_update_successful", 3000));
                }
            })
            .catch(error => console.log(error.message));
    };
}

export function userUpdated(data) {
    return {
        type: ActionType.USER_UPDATED,
        data
    };
}

export function uploadUserAvatar(formData, successCb) {
    return async dispatch => {
        secureFetch(API_URL + "/user/avatar",
            {
                method: 'PUT',
                body: formData
            })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response;
            })
            .then(response => extractBodyOrThrow(response))
            .then(updatedUser => {
                successCb();
                dispatch(userUpdated(updatedUser));
                dispatch(userAvatarUpdated(updatedUser.id, updatedUser.avatarImageId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "avatar_upload_successful", 3000));
            })
            .catch(error => console.log(error.message));
    };
}

function userAvatarUpdated(userId, avatarId) {
    return {
        type: ActionType.USER_AVATAR_UPDATED,
        userId,
        avatarId
    };
}

export function changePassword(body, hadPassword, successCallback) {
    return async dispatch => {
        secureFetch(API_URL + "/user/password",
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                switch (response.status) {
                    case 422:
                        response.json()
                            .then(validationErrors => {
                                const parsedErrors = parseApiValidationErrors(validationErrors.errors);
                                dispatch(changePasswordErrored(parsedErrors))
                            });
                        break;
                    case 200:
                        break;
                    default:
                        throw Error(response.statusText);
                }
                return response;
            })
            .then(response => extractBodyOrThrow(response))
            .then(user => {
                dispatch(userUpdated(user));
                dispatch(clearChangePasswordErrors());
                dispatch(changePasswordSuccess());

                if (successCallback)
                    successCallback();
                return user;
            })
            .then(() => {
                if (hadPassword) {
                    dispatch(emitAppNotification(NotificationType.SUCCESS, "change_password_successful", 3000));
                } else {
                    dispatch(emitAppNotification(NotificationType.SUCCESS, "set_password_successful", 3000));
                }
            })
            .catch(error => console.log(error.message));
    };
}

function changePasswordErrored(errors) {
    return {
        type: ActionType.CHANGE_PASSWORD_ERRORED,
        errors
    };
}

function changePasswordSuccess() {
    return {
        type: ActionType.CHANGE_PASSWORD_SUCCEEDED
    };
}

export function clearChangePasswordErrors() {
    return {
        type: ActionType.CHANGE_PASSWORD_ERRORED,
        errors: {}
    };
}

export function loadNotificationSettings() {
    return async dispatch => {
        secureFetch(API_URL + "/user/notificationSettings",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(settings => dispatch(notificationSettingsLoaded(settings)))
            .catch(error => console.log(error.message));
    };
}

export function updateNotificationSettings(settings) {
    return async dispatch => {
        secureFetch(API_URL + "/user/notificationSettings",
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({settings})
            })
            .then(response => extractBodyOrThrow(response))
            .then(updatedSettings => {
                dispatch(notificationSettingsLoaded(updatedSettings));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "notifications_update_successful", 3000));
            })
            .catch(error => console.log(error.message));
    };
}

function notificationSettingsLoaded(settings) {
    return {
        type: ActionType.NOTIFICATION_SETTINGS_LOADED,
        settings
    };
}

export function loadIdeasLikedAndCommentedIds() {
    return async dispatch => {
        secureFetch(API_URL + "/user/ideasLiked",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(ids => dispatch(ideasLikedAndCommentedIdsLoaded(ids)))
            .catch(error => console.log(error.message));
    };
}

function ideasLikedAndCommentedIdsLoaded(items) {
    return {
        type: ActionType.CROWD_VIEW_USER_IDEAS_LIKED_LOADED,
        items
    };
}

export function loadUserRoles() {
    return async dispatch => {
        secureFetch(API_URL + "/user/roles",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(roles => dispatch(userRolesLoaded(roles)))
            .catch(error => console.log(error.message));
    };
}

function userRolesLoaded(data) {
    return {
        type: ActionType.USER_ROLES_LOADED,
        data
    };
}