import React from "react";
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import SelectLanguage from "../common/selectlanguage/SelectLanguageNavbar";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import NotificationsMenu from "../dashboard/NotificationsMenu";
import LoggedInNavbar from "../common/accountDropdown/LoggedInNavbar";
import {useRedirectWithState} from "../../hooks/useRedirectWithState";

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none'
    },
    badge: {
        '& span.MuiBadge-badge': {
            backgroundColor: theme.palette.secondary.main,
            color: 'white'
        },
    },
    pointer: {
        cursor: 'pointer'
    }
}));

const CrowdNavbarMenu = props => {

    const classes = useStyles();
    const {user, generateAddIdeaClickHandler, notifications, intl, markNotificationsAsRead} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const redirectTo = useRedirectWithState();

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <Grid item>
            <Button color='primary' variant="contained" style={{color: 'white'}} onClick={generateAddIdeaClickHandler()}>
                {intl.formatMessage({id: 'add_idea'})}
            </Button>
        </Grid>
        {user.loggedIn ? (<Grid item style={{padding: 0}}>
                <IconButton color='primary' onClick={openMenu}>
                    {notifications.unreadCount > 0 ?
                        <Badge badgeContent={notifications.unreadCount} className={classes.badge}>
                            <NotificationsIcon/>
                        </Badge> : <NotificationsIcon/>
                    }
                </IconButton>
            </Grid>
        ) : (
            <Grid item>
                <Typography color='primary' onClick={() => redirectTo('/login')} className={classes.pointer}>
                    {intl.formatMessage({id: 'login'})}
                </Typography>
            </Grid>
        )}
        <Grid item style={{padding: 0}}>
            <SelectLanguage/>
        </Grid>
        {user.loggedIn &&
        <Grid item style={{padding: 0}}>
            <LoggedInNavbar isCrowdHomepage/>
        </Grid>}
        <NotificationsMenu handleClose={handleClose} anchorEl={anchorEl} notifications={notifications}
                           markNotificationsAsRead={markNotificationsAsRead}/>
    </>
};

export default injectIntl(CrowdNavbarMenu);