import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Spinner from "../common/Spinner";
import {injectIntl} from "react-intl";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";
import {useHistory, useLocation} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Grid from "@material-ui/core/Grid";
import IdeaView from "./IdeaPreview";
import useDeepCompareEffect from "use-deep-compare-effect";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MobileNavMenu from "../common/MobileNavMenu";
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(theme => ({
    tabTextColorPrimary: {
        color: theme.palette.primary.main
    },
    tabLabel: {
        textTransform: 'none',
        fontWeight: 'bold',
        opacity: 'unset'
    },
    buttonSelectedCategory: {
        background: 'transparent',
        textTransform: 'none',
        fontWeight: 'bold'
    }
}));

const IdeasAll = props => {

    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const {
        categories,
        user,
        ideas,
        likeClickHandler,
        openShareDialog,
        ideasLikedCommented,
        isAdmin,
        intl,
        generateAddIdeaClickHandler
    } = props;
    const [tabIndex, setTabIndex] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (link, newValue) => {
        setTabIndex(newValue);
        history.push(link);
        handleClose();
    };

    const preselectTab = () => {
        return categories.data.map(c => normalizeCategoryName(c.name)).indexOf(location.pathname.replace('/', '')) + 1;
    };

    const getTabsVariant = () => {
        return window.innerWidth > 768 ? 'fullWidth' : 'scrollable';
    };

    const normalizeCategoryName = name => {
        return name.toLowerCase().replace(/\s+/g, '-');
    };

    const createNavigation = () => {
        const navigation = [];
        navigation.push({
            name: intl.formatMessage({id: 'all_ideas'}),
            link: '/'
        });

        return navigation.concat(categories.data.map(cat => ({name: cat.name, link: normalizeCategoryName(cat.name)})));
    };

    const navigation = createNavigation();

    useDeepCompareEffect(() => {
        setTabIndex(preselectTab());
    }, [categories]);


    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        history.push(navigation[newValue].link);
    };

    const getCategoryName = () => {
        if (tabIndex > 0)
            return categories.data[tabIndex - 1].name;
        else
            return intl.formatMessage({id: 'all_ideas'});
    };

    const isIdeaLiked = ideaId => {
        return ideasLikedCommented.loaded && ideasLikedCommented.data.ideasLikedIds.includes(ideaId);
    };

    const isIdeaCommentedOn = ideaId => {
        return ideasLikedCommented.loaded && ideasLikedCommented.data.ideasCommentedOnIds.includes(ideaId);
    };

    const ideasToDisplay = () => {
        let items = ideas.data;
        const category = categories.data[tabIndex - 1] || {};
        if (tabIndex > 0)
            items = items.filter(idea => idea.categoryId === category.id);
        if (items.length > 0)
            return <Grid container
                         direction="row"
                         justify="flex-start"
                         alignItems="center"
                         spacing={4}
                         style={{marginTop: '16px'}}>
                {items.map(idea =>
                    <Grid key={`idea${idea.id}`} item xs={12} sm={6} lg={4}>
                        <IdeaView idea={idea}
                                  user={user}
                                  isAdmin={isAdmin}
                                  ideaAuthored={user.id === idea.ownerId}
                                  ideaLiked={isIdeaLiked(idea.id)}
                                  ideaCommented={isIdeaCommentedOn(idea.id)}
                                  likeClickHandler={likeClickHandler(idea.id, isIdeaLiked(idea.id))}
                                  openShareDialog={() => openShareDialog(idea.id)}/>
                    </Grid>)}
            </Grid>;
        else
            return <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={2}
                style={{minHeight: '60vh', marginTop: '16px'}}>
                <Grid item xs/>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <IconButton size="large" color='primary' onClick={generateAddIdeaClickHandler(category.id)}>
                        <AddCircleIcon style={{width: '60px', height: 'auto'}}/>
                    </IconButton>
                    <Typography align='center'>
                        {intl.formatMessage({id: 'be_the_first_to_add'})}
                    </Typography>
                </Grid>
                <Grid item xs/>
            </Grid>;
    };

    if (!categories.loaded)
        return <Spinner/>;

    return <>
        <Hidden smUp>
            <Button
                fullWidth
                disableElevation
                variant="contained"
                endIcon={<ExpandMoreIcon/>}
                className={classes.buttonSelectedCategory}
                onClick={openMenu}
            >
                {getCategoryName()}
            </Button>
        </Hidden>
        <Hidden xsDown>
            <Tabs value={tabIndex} onChange={handleTabChange}
                  indicatorColor="primary"
                  scrollButtons="auto"
                  variant={getTabsVariant()}>
                <Tab className={clsx({[classes.tabTextColorPrimary]: tabIndex === 0}, classes.tabLabel)}
                     label={intl.formatMessage({id: 'all_ideas'})}
                />
                {categories.data.map((category, index) =>
                    <Tab key={category.name}
                         className={clsx({[classes.tabTextColorPrimary]: tabIndex === index + 1}, classes.tabLabel)}
                         label={category.name}
                    />)}
            </Tabs>
        </Hidden>
        {/*<IconButton style={{float: 'right'}}>
            <AppsIcon fontSize='large'/>
        </IconButton>
        <IconButton style={{float: 'right'}}>
            <ViewListIcon fontSize='large'/>
        </IconButton>*/}
        {ideas.loaded ? ideasToDisplay() : <Spinner/>}
        <MobileNavMenu handleClose={handleClose} anchorEl={anchorEl} navigation={navigation}
                       handleClick={handleMenuClick}/>
    </>
};

export default injectIntl(IdeasAll);