import React from "react";
import ListItem from "@material-ui/core/ListItem";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {changeLocale} from "../../../actions/locale";
import {compose} from "redux";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import Typography from "@material-ui/core/Typography";
import {Locale} from "../../../constants";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    textColor: {
        color: 'white',
        fontSize: '23px'
    }
}));

export const SelectLanguageSidebar = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const toggleSubList = event => {
        event.stopPropagation();
        setOpen(!open);
    };

    const handleClick = locale => event => {
        event.stopPropagation();
        props.changeLocale(locale);
        setOpen(false);
    };

    return <>
        <ListItem button onClick={toggleSubList} style={{justifyContent: 'space-between'}}>
            <Typography variant="h6" className={classes.textColor}>
                {props.currentLocale === Locale.EN ? 'English' : 'Slovensky'}
            </Typography>
            {open ? <ExpandLess style={{color: 'white'}}/> : <ExpandMore style={{color: 'white'}}/>}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItem button onClick={handleClick(Locale.EN)} className={clsx(classes.nested, classes.textColor)}>
                    <Typography variant="h6">
                        English
                    </Typography>
                </ListItem>
                <ListItem button onClick={handleClick(Locale.SK)} className={clsx(classes.nested, classes.textColor)}>
                    <Typography variant="h6">
                        Slovensky
                    </Typography>
                </ListItem>
            </List>
        </Collapse></>;
};

const mapStateToProps = state => {
    return {
        currentLocale: state.locale
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeLocale: locale => dispatch(changeLocale(locale))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(SelectLanguageSidebar);