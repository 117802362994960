import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, parseApiValidationErrors} from "./common";
import {store} from "../store/configureStore";

export function registrationErrored(errors) {
    return {
        type: ActionType.REGISTRATION_ERRORED,
        errors
    };
}

export function registrationSucceeded() {
    return {
        type: ActionType.REGISTRATION_SUCCEEDED
    };
}

export function register(registrationInfo) {
    return async dispatch => {

        fetch(API_URL + "/register",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(registrationInfo)
            })
            .then(response => {
                switch (response.status) {
                    // TODO extract all 422 into secureFetch and unify validation error handling and messaging
                    case 422:
                        response.json()
                            .then(validationErrors => {
                                const parsedErrors = parseApiValidationErrors(validationErrors.errors);
                                dispatch(registrationErrored(parsedErrors))
                            });
                        break;
                    case 200:
                    case 201:
                        dispatch(registrationSucceeded());
                        break;
                    default:
                        throw Error(response.statusText);
                }

                return response;
            })
            .catch(() => dispatch(emitAppNotification(NotificationType.ERROR)));
    };
}

export async function activateAccount(token) {
        return fetch(API_URL + "/register/activateAccount/" + token,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .catch(() => {store.dispatch(emitAppNotification(NotificationType.ERROR))});
}
