import React from "react";
import {withAnonymous} from "../common/withAnonymous";
import {composeLoginUrl} from "../../utils/utils";
import {useLocation} from 'react-router-dom';
import {compose} from "redux";
import {connect} from "react-redux";

const LoginRedirect = props => {

    const {crowdView} = props;
    const location = useLocation();
    const from = location.state && location.state.from;

    window.location.href = composeLoginUrl(crowdView, from);

    return <></>;
};

const mapStateToProps = state => {
    return {
        crowdView: state.crowdView
    }
};

export default compose(
    withAnonymous,
    connect(mapStateToProps),
)(LoginRedirect);