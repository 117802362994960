import {connect} from "react-redux";
import React from "react";
import {composeLoginUrl} from "../../utils/utils";
import {Redirect} from "react-router-dom";
import {store} from "../../store/configureStore";
import {userUpdated} from "../../actions/user";

export function withAuthentication(ToAuthenticate) {

    const authenticate = props => {

        const {user, crowdView} = props;

        if (user.loggingOut) {
            store.dispatch(userUpdated({...user, loggingOut: false}));
            return <Redirect to="/"/>;
        }

        if (!user.loggedIn) {
            window.location.href = composeLoginUrl(crowdView);
            return;
        }

        return <ToAuthenticate {...props}/>;
    };

    const mapStateToProps = state => {
        return {
            user: state.user,
            crowdView: state.crowdView
        }
    };

    return connect(mapStateToProps)(authenticate);
}