import {injectIntl} from "react-intl";
import React from "react";
import PrivacyPolicyContent from "./PrivacyPolicyContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card/Card";
import PolicyIcon from '@material-ui/icons/Policy';
import Container from "@material-ui/core/Container";
import SelectLanguageButton from "../common/selectlanguage/SelectLanguageButton";
import Hidden from "@material-ui/core/Hidden";

const PrivacyPolicy = props => {

    return <Container maxWidth="xl" style={{margin: '30px auto'}}>
        <Card elevation={3}>
            <div style={{display: 'flex', alignItems: 'center'}} className={'cardHeader'}>
                <Avatar style={{marginRight: '16px'}} className={'cardAvatar'}>
                    <PolicyIcon/>
                </Avatar>
                <div style={{flex: '1 1 auto'}}>
                    <Typography
                        className={'cardHeaderText'}>{props.intl.formatMessage({id: 'privacy_policy_headline'})}</Typography>
                </div>
                <Hidden xsDown>
                    <SelectLanguageButton/>
                </Hidden>
            </div>
            <Hidden smUp>
                <CardContent style={{textAlign: 'center'}}>
                    <SelectLanguageButton/>
                </CardContent>
            </Hidden>
            <CardContent className={'cardContent'}>
                <PrivacyPolicyContent/>
            </CardContent>
        </Card>
    </Container>;
};

export default injectIntl(PrivacyPolicy);