import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import SettingsIcon from '@material-ui/icons/Settings';
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Currency, Locale} from "../../constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import useDeepCompareEffect from "use-deep-compare-effect";
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));


const CrowdGeneralInfo = props => {

    const classes = useStyles();
    const {crowd, updateCrowd, openDialog, subscription} = props;
    const [crowdState, setCrowdState] = React.useState(crowd);
    const [validationErrors, setValidationErrors] = React.useState({});
    const validators = {
        title: () => {
            if (crowdState.title === '')
                return 'validation_crowd_title';
            return true;
        },
        gaCode: () => {
            if (crowdState.gaCode !== '' && !/^[GU]A-[a-z0-9]+-[a-z0-9]+$/i.test(crowdState.gaCode))
                return 'validation_crowd_gacode';
            return true;
        },
        likeMilestone: () => {
            if (!/^\d*$/.test(crowdState.likeMilestone))
                return 'validation_crowd_likeMilestone';
            return true;
        },
        rewardAmount: () => {
            if (!/^\d*$/.test(crowdState.rewardAmount))
                return 'validation_crowd_rewardAmount';
            return true;
        }
    };

    useDeepCompareEffect(() => {
        setCrowdState(crowd);
    }, [crowd]);

    const validateInput = () => {
        const result = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});

        setValidationErrors(result);
        return Object.keys(result).length === 0;
    };

    const dropdownSelected = name => event => {
        event.preventDefault();
        setCrowdState({
            ...crowdState,
            [name]: event.target.value
        })
    };

    const handleVisibleSwitchChange = event => {
        if (event.target.checked && subscription.current.id === undefined) {
            openDialog();
            return;
        }

        setCrowdState({
            ...crowdState,
            visible: event.target.checked
        });
    };

    const handleHideTitleSwitchChange = event => {
        setCrowdState({
            ...crowdState,
            hideTitle: event.target.checked
        });
    };

    const handleInputChange = name => event => {
        setCrowdState({
            ...crowdState,
            [name]: event.target.value
        })
    };

    const changesMade = () => {
        return !isEqual(crowdState, crowd);
    };

    const handleSubmit = () => {
        if (validateInput() && changesMade()) {
            const diff = differenceWith(Object.entries(crowdState), Object.entries(crowd), isEqual).reduce((acc, [key, val]) => {
                acc[key] = val;
                return acc;
            }, {});

            updateCrowd({
                id: crowd.id,
                version: crowd.version,
                ...diff
            });
        }
    };

    // TODO use this in the whole application, possibly as a HOC
    const commonTextFieldProps = (name, i18n) => {
        return {
            label: props.intl.formatMessage({id: i18n}),
            variant: "outlined",
            name: name,
            margin: "dense",
            fullWidth: true,
            error: validationErrors[name],
            helperText: validationErrors[name] && props.intl.formatMessage({id: validationErrors[name]}),
            value: crowdState[name],
            onChange: handleInputChange(name)
        };
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <SettingsIcon/>
                </Avatar>
            }
            title={<Typography
                className={'cardHeaderText'}>{props.intl.formatMessage({id: 'crowd_profile_settings'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                direction="row"
                spacing={2}
            >
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={crowdState.visible}
                                onChange={handleVisibleSwitchChange}
                                value={crowdState.visible}
                                color="primary"
                            />
                        }
                        label={props.intl.formatMessage({id: 'crowd_visible'})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('title', 'crowd_title')}/>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={crowdState.hideTitle}
                                onChange={handleHideTitleSwitchChange}
                                value={crowdState.hideTitle}
                                color="primary"
                            />
                        }
                        label={props.intl.formatMessage({id: 'crowd_hide_title'})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        {...commonTextFieldProps('description', 'crowd_description')}
                        multiline
                        rows={5}
                        rowsMax={20}
                        inputProps={{maxLength: 600}}
                        helperText={`${crowdState.description.length}/600`}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="default_locale_select_label">
                            {props.intl.formatMessage({id: 'default_locale'})}
                        </InputLabel>
                        <Select
                            labelId="default_locale_select_label"
                            id="default_locale_select"
                            fullWidth
                            label={props.intl.formatMessage({id: 'default_locale'})}
                            value={crowdState.locale}
                            onChange={dropdownSelected('locale')}
                        >
                            {Object.values(Locale).map(value =>
                                <MenuItem key={value}
                                          value={value}>{props.intl.formatMessage({id: value})}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('gaCode', 'crowd_gaCode')}
                               placeholder={props.intl.formatMessage({id: 'crowd_gaCode_helper'})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('likeMilestone', 'crowd_likeMilestone')}/>
                </Grid>
                <Grid item xs={12}>
                    <TextField {...commonTextFieldProps('rewardAmount', 'crowd_rewardAmount')}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="reward_currency_select_label">
                            {props.intl.formatMessage({id: 'crowd_reward_currency'})}
                        </InputLabel>
                        <Select
                            labelId="reward_currency_select_label"
                            id="reward_currency_select"
                            fullWidth
                            label={props.intl.formatMessage({id: 'crowd_reward_currency'})}
                            value={crowdState.rewardCurrency}
                            onChange={dropdownSelected('rewardCurrency')}
                        >
                            {Object.values(Currency).map(value =>
                                <MenuItem key={value} value={value}>{value}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm='auto'>
                    <Button color='primary' variant="contained" className={classes.button} onClick={handleSubmit}>
                        {props.intl.formatMessage({id: 'save'})}
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    </Card>;
};

export default injectIntl(CrowdGeneralInfo);