import React from 'react';
import {useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import {lighten} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SubjectIcon from '@material-ui/icons/Subject';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {IdeaQuestionType, QuestionnaireType} from "../../constants";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import useDeepCompareEffect from "use-deep-compare-effect";
import _, {isEqual} from 'lodash';
import Hidden from "@material-ui/core/Hidden";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {useDispatch} from "react-redux";
import {createIdeaQuestionnaire} from "../../actions/ideaQuestionnaire";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

const useStyles = makeStyles(theme => ({
    menuItem: {
        color: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
            color: theme.palette.primary.main,
        },
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
        },
    },
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    icon: {
        color: theme.palette.primary.main,
    },
    questionContainer: {
        padding: '16px',
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    deleteQuestionButton: {
        [theme.breakpoints.down('xs')]: {
            padding: 0
        }
    },
    accordion: {
        borderRadius: '4px',
        margin: '0 !important',
        '&:before': {
            backgroundColor: 'unset !important'
        },
        '& div.MuiAccordionDetails-root': {
            flexWrap: 'wrap'
        }
    },
    accordionSummary: {
        padding: '32px',
        '& div.MuiAccordionSummary-content': {
            margin: 0
        }
    },
    accordionDetails: {
        padding: '0 32px 16px'
    },
}));


export const IdeaCreationQuestions = props => {

    const classes = useStyles();
    const intl = useIntl();
    const dispatch = useDispatch();
    const {crowdId, questionnaire} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [questions, setQuestions] = React.useState([]);
    const [validationErrors, setValidationErrors] = React.useState([]);
    const [enabled, setEnabled] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandedChange = () => {
        setExpanded(!expanded);
    };

    const validators = {
        questionText: value => {
            if (value === '')
                return 'idea_question_validation_empty';
            return true;
        },
        options: value => {
            if (!value || value.filter(v => v !== '').length === value.length)
                return true;
            return value.map(v => v === '' ? 'idea_question_option_validation_empty' : undefined);
        },
    };

    useDeepCompareEffect(() => {
        setQuestions(questionnaire.questions || []);
        setEnabled(questionnaire.enabled || false);
    }, [questionnaire]);

    const validateInput = () => {
        const result = questions.reduce((acc, q) => {
            acc.push(Object.entries(validators)
                .reduce((nestedAcc, [key, validator]) => {
                    const output = validator(q[key]);
                    if (output !== true) {
                        nestedAcc[key] = output;
                    }
                    return nestedAcc;
                }, {}));
            return acc;
        }, []);

        setValidationErrors(result);
        return result.flatMap(err => Object.keys(err)).length === 0;
    };

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getI18nCode = questionType => {
        switch (questionType) {
            case IdeaQuestionType.TEXT:
                return 'idea_question_text';
            case IdeaQuestionType.SINGLE_CHOICE:
                return 'idea_question_radio';
            case IdeaQuestionType.MULTIPLE_CHOICE:
                return 'idea_question_checkbox';
            default:
                return 'idea_question_text';
        }
    };

    const getQuestionIcon = questionType => {
        switch (questionType) {
            case IdeaQuestionType.TEXT:
                return <SubjectIcon className={classes.icon}/>;
            case IdeaQuestionType.SINGLE_CHOICE:
                return <RadioButtonCheckedIcon className={classes.icon}/>;
            case IdeaQuestionType.MULTIPLE_CHOICE:
                return <CheckBoxIcon className={classes.icon}/>;
            default:
                return <SubjectIcon/>;
        }
    };

    const addQuestion = questionType => () => {
        const newQuestion = {questionType: questionType, questionText: ''};
        if ([IdeaQuestionType.SINGLE_CHOICE, IdeaQuestionType.MULTIPLE_CHOICE].includes(questionType))
            newQuestion['options'] = ['', ''];
        setQuestions([...questions, newQuestion]);
        handleClose();
    };

    const addRecommendedQuestions = () => {
        const newQuestions = [
            {
                questionType: IdeaQuestionType.SINGLE_CHOICE,
                questionText: intl.formatMessage({id: 'idea_creation_questions_q1'}),
                options: [
                    '1000 - 10 000€',
                    '10 000 - 50 000€',
                    '50 000 - 100 000€',
                    intl.formatMessage({id: 'more_than_100k'})
                ]
            },
            {
                questionType: IdeaQuestionType.SINGLE_CHOICE,
                questionText: intl.formatMessage({id: 'idea_creation_questions_q2'}),
                options: [
                    '1000 - 10 000€',
                    '10 000 - 50 000€',
                    '50 000 - 100 000€',
                    intl.formatMessage({id: 'more_than_100k'})
                ]
            },
            {
                questionType: IdeaQuestionType.SINGLE_CHOICE,
                questionText: intl.formatMessage({id: 'idea_creation_questions_q3'}),
                options: ['0 - 100', '100 - 1000', '1000 - 10 000', intl.formatMessage({id: 'more_than_10k'})]
            },
            {
                questionType: IdeaQuestionType.SINGLE_CHOICE,
                questionText: intl.formatMessage({id: 'idea_creation_questions_q4'}),
                options: [
                    intl.formatMessage({id: 'less_than_a_month'}),
                    intl.formatMessage({id: 'one_to_three_months'}),
                    intl.formatMessage({id: 'three_to_six_months'}),
                    intl.formatMessage({id: 'more_than_six_months'})
                ]
            },
            {
                questionType: IdeaQuestionType.SINGLE_CHOICE,
                questionText: intl.formatMessage({id: 'idea_creation_questions_q5'}),
                options: [
                    '1000 - 10 000€',
                    '10 000 - 50 000€',
                    '50 000 - 100 000€',
                    intl.formatMessage({id: 'more_than_100k'})
                ]
            }
        ];
        setQuestions([...questions, ...newQuestions]);
    };

    const removeQuestion = index => () => {
        setQuestions(questions.filter((q, i) => i !== index));
        setValidationErrors(validationErrors.filter((q, i) => i !== index));
    };

    const handleQuestionInputChange = index => event => {
        if (validationErrors[index] && validationErrors[index].questionText) {
            const clearError = [...validationErrors];
            delete clearError[index].questionText;
            setValidationErrors(clearError);
        }

        const newState = [...questions];
        newState[index] = {
            ...questions[index],
            questionText: event.target.value
        };
        setQuestions(newState);
    };

    const handleOptionInputChange = (questionIndex, optionIndex) => event => {
        if (validationErrors[questionIndex] &&
            validationErrors[questionIndex].options &&
            validationErrors[questionIndex].options[optionIndex]) {
            const clearError = [...validationErrors];
            delete clearError[questionIndex].options[optionIndex];
            setValidationErrors(clearError);
        }

        const newOptions = [...questions[questionIndex].options];
        newOptions[optionIndex] = event.target.value;
        const newState = [...questions];
        newState[questionIndex] = {
            ...questions[questionIndex],
            options: newOptions
        };
        setQuestions(newState);
    };

    const handleSwitchChange = event => {
        setEnabled(event.target.checked);
    };

    const addOption = index => () => {
        if (questions[index].options.length === 10)
            return;

        const newState = [...questions];
        newState[index] = {
            ...questions[index],
            options: [...questions[index].options, '']
        };
        setQuestions(newState);
    };

    const removeOption = (questionIndex, optionIndex) => () => {
        const newState = [...questions];
        newState[questionIndex] = {
            ...questions[questionIndex],
            options: questions[questionIndex].options.filter((o, i) => i !== optionIndex)
        };
        setQuestions(newState);

        if (validationErrors[questionIndex] && validationErrors[questionIndex].options) {
            const newErrorState = [...validationErrors];
            newErrorState[questionIndex] = {
                ...validationErrors[questionIndex],
                options: validationErrors[questionIndex].options.filter((o, i) => i !== optionIndex)
            };
            setValidationErrors(newErrorState);
        }
    };

    const isQuestionInvalid = index => {
        return validationErrors[index] && validationErrors[index].questionText;
    };

    const getQuestionErrorText = index => {
        return isQuestionInvalid(index) && intl.formatMessage({id: validationErrors[index].questionText});
    };

    const isOptionInvalid = (index, optionIndex) => {
        return validationErrors[index] && validationErrors[index].options && validationErrors[index].options[optionIndex];
    };

    const getOptionErrorText = (index, optionIndex) => {
        return isOptionInvalid(index, optionIndex) && intl.formatMessage({id: validationErrors[index].options[optionIndex]});
    };

    const changesMade = () => {
        const current = {
            enabled: questionnaire.enabled,
            questions: questionnaire.questions
        };

        const newState = {
            enabled,
            questions
        };
        return !isEqual(current, newState);
    };

    const handleSubmit = () => {
        if (validateInput() && changesMade()) {
            const body = {
                id: questionnaire.id,
                version: questionnaire.version,
                questionnaireType: QuestionnaireType.IDEA_CREATION,
                crowdId,
                ...(questionnaire.enabled !== enabled && {enabled}),
                ...(!isEqual(questionnaire.questions, questions) && {questions}),
            };
            dispatch(createIdeaQuestionnaire(body));
        }
    };

    return <Accordion className={classes.accordion} elevation={3} expanded={expanded}
                      onChange={handleExpandedChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>} className={classes.accordionSummary}>
            <Grid container spacing={2}>
                <Grid item>
                    <PlaylistAddCheckIcon className={classes.icon}/>
                </Grid>
                <Grid item>
                    <Typography>
                        {intl.formatMessage({id: 'idea_creation_questions'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {intl.formatMessage({id: 'idea_creation_questions_desc'})}
                    </Typography>
                </Grid>
            </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ol style={{margin: 0}}>
                        {_.range(1, 6).map(i =>
                            <li>
                                <Typography>
                                    {intl.formatMessage({id: `idea_creation_questions_q${i}`})}
                                </Typography>
                            </li>)}
                    </ol>
                </Grid>
                {questions.length > 0 &&
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={enabled}
                                onChange={handleSwitchChange}
                                value={enabled}
                                color="primary"
                            />
                        }
                        label={intl.formatMessage({id: 'idea_questionnaire_enabled'})}
                    />
                </Grid>}
                <Grid item xs={12} sm={'auto'}>
                    <Button color='primary' variant="contained" className={classes.button}
                            onClick={addRecommendedQuestions}>
                        {intl.formatMessage({id: 'idea_question_add_recommended'})}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <Button color='primary' variant="contained" className={classes.button} onClick={openMenu}>
                        {intl.formatMessage({id: 'idea_question_add'})}
                    </Button>
                    <Menu
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {Object.values(IdeaQuestionType).map(qType =>
                            <MenuItem key={qType} className={classes.menuItem} onClick={addQuestion(qType)}>
                                <ListItemIcon>
                                    {getQuestionIcon(qType)}
                                </ListItemIcon>
                                <ListItemText
                                    primary={intl.formatMessage({id: getI18nCode(qType)})}/>
                            </MenuItem>
                        )}
                    </Menu>
                </Grid>
                <Grid item xs={12}>
                    {questions.length ? (
                        <Grid container
                              spacing={2}
                        >
                            {questions.map((q, index) =>
                                <Grid item xs={12} key={`question${index}`}>
                                    <Paper elevation={2} className={classes.questionContainer}>
                                        <Grid container
                                              direction="row"
                                              justify="flex-start"
                                              alignItems="center"
                                              spacing={2}>
                                            <Grid item>
                                                {getQuestionIcon(q.questionType)}
                                            </Grid>
                                            <Grid item xs={8} sm={'auto'}>
                                                <Typography>
                                                    {intl.formatMessage({id: getI18nCode(q.questionType)})}
                                                </Typography>
                                            </Grid>
                                            <Hidden xsDown>
                                                <Grid item xs/>
                                            </Hidden>
                                            <Grid item style={{alignSelf: 'flex-start'}}>
                                                <IconButton color='secondary' onClick={removeQuestion(index)}
                                                            className={classes.deleteQuestionButton}>
                                                    <CancelIcon/>
                                                </IconButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label={intl.formatMessage({id: 'idea_question'})}
                                                    variant="outlined"
                                                    name={`question${index}`}
                                                    margin="dense"
                                                    fullWidth
                                                    error={isQuestionInvalid(index)}
                                                    helperText={getQuestionErrorText(index)}
                                                    value={questions[index].questionText}
                                                    onChange={handleQuestionInputChange(index)}/>
                                            </Grid>
                                            {[IdeaQuestionType.SINGLE_CHOICE, IdeaQuestionType.MULTIPLE_CHOICE].includes(q.questionType) && (<>
                                                <Grid item xs={12}>
                                                    <Button color='primary' variant="contained"
                                                            className={classes.button}
                                                            startIcon={<AddCircleIcon/>}
                                                            onClick={addOption(index)}>
                                                        {intl.formatMessage({id: 'idea_question_option_add'})}
                                                    </Button>
                                                </Grid>
                                                {q.options.map((option, optionIndex) => (<>
                                                        <Grid item style={{display: 'flex'}} xs={12}
                                                              key={`question${index}_option${optionIndex}`}>
                                                            <TextField
                                                                label={`${intl.formatMessage({id: 'idea_question_option'})} ${optionIndex + 1}`}
                                                                variant="outlined"
                                                                name={option}
                                                                margin="dense"
                                                                style={{flexGrow: 1}}
                                                                error={isOptionInvalid(index, optionIndex)}
                                                                helperText={getOptionErrorText(index, optionIndex)}
                                                                value={questions[index].options[optionIndex]}
                                                                onChange={handleOptionInputChange(index, optionIndex)}/>
                                                            {q.options.length > 2 && (
                                                                <IconButton color='secondary'
                                                                            onClick={removeOption(index, optionIndex)}>
                                                                    <CancelIcon/>
                                                                </IconButton>
                                                            )}
                                                        </Grid>
                                                    </>
                                                ))}
                                            </>)}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            )}
                        </Grid>) : (
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item xs/>
                            <Grid item xs={12}>
                                <Typography align='center'>
                                    {intl.formatMessage({id: 'idea_question_none_yet'})}
                                </Typography>
                            </Grid>
                            <Grid item xs/>
                        </Grid>
                    )}
                </Grid>
                {questions.length > 0 && (
                    <Grid item xs={12}>
                        <Button color='primary' variant="contained" className={classes.button} onClick={handleSubmit}>
                            {intl.formatMessage({id: 'save'})}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </AccordionDetails>
    </Accordion>;
};