import React from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import {amber, green} from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import {makeStyles} from '@material-ui/core/styles';
import {injectIntl} from "react-intl";
import {NotificationType} from "../../constants";

const variantIcon = {
    [NotificationType.SUCCESS]: CheckCircleIcon,
    [NotificationType.WARNING]: WarningIcon,
    [NotificationType.ERROR]: ErrorIcon,
    [NotificationType.INFO]: InfoIcon,
};

const snackbarContentStyles = makeStyles(theme => ({
    [NotificationType.SUCCESS]: {
        backgroundColor: green[600],
    },
    [NotificationType.ERROR]: {
        backgroundColor: theme.palette.error.dark,
    },
    [NotificationType.INFO]: {
        backgroundColor: theme.palette.primary.main,
    },
    [NotificationType.WARNING]: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    }
}));

function SnackbarContentWrapper(props) {
    const classes = snackbarContentStyles();
    const {message, onClose, variant, ...other} = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={clsx(classes[variant])}
            message={
                <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)}/>
                    {message}
                </span>
            }
            action={[
                <IconButton key="close" color="inherit" onClick={onClose}>
                    <CloseIcon className={classes.icon}/>
                </IconButton>
            ]}
            {...other}
        />
    );
}

function defaultMessage(variant) {
    switch (variant) {
        case NotificationType.SUCCESS:
            return "default_success";
        case NotificationType.ERROR:
            return "default_error";
        default:
            return "default_error";
    }
}

function CustomSnackbar(props) {
    const [open, setOpen] = React.useState(props.open);

    const message = props.messageCode ? props.messageCode : defaultMessage(props.variant);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={props.duration}
                onClose={handleClose}
            >
                <SnackbarContentWrapper
                    onClose={handleClose}
                    variant={props.variant}
                    message={props.intl.formatMessage({id: message})}
                />
            </Snackbar>
    );
}

export default injectIntl(CustomSnackbar);
