import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import Button from "@material-ui/core/Button";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField/TextField";
import {useCommonTextFieldProps} from "../../hooks/useCommonTextFieldProps";
import {emailRegex, separatorRegex} from "../../constants";
import {useDispatch} from "react-redux";
import {shareIdea} from "../../actions/idea";
import Typography from "@material-ui/core/Typography";


export const ShareIdeaDialog = props => {

    const intl = useIntl();
    const dispatch = useDispatch();
    const {open, onClose, ideaId} = props;
    const [emails, setEmails] = React.useState('');
    const [emailsError, setEmailsError] = React.useState(false);
    const validator = () => {
        if (emails === '')
            return 'validation_crowd_users_emails_empty';
        const emailArr = emails.split(separatorRegex).filter(e => e !== '');
        if (!emailArr.every(e => emailRegex.test(e)))
            return 'validation_crowd_users_emails';
        return false;
    };

    const validateInput = () => {
        const result = validator();
        setEmailsError(result);
        return !result;
    };

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = () => {
        if (validateInput()) {
            dispatch(shareIdea(emails, ideaId));
            handleClose();
            setEmails('');
        }
    };

    const handleInputChange = event => {
        setEmails(event.target.value);
    };
    const commonTextFieldProps = useCommonTextFieldProps(emails, emailsError, handleInputChange);

    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='lg'
            fullWidth
            scroll='body'>
            <DialogTitle>
                {intl.formatMessage({id: 'share_idea_dialog_title'})}
            </DialogTitle>
            <DialogContent>
                <Typography style={{fontSize: '18px'}}>
                    {intl.formatMessage({id: 'share_idea_dialog_desc'})}
                </Typography>
                <TextField
                    {...commonTextFieldProps('emails')}
                    multiline
                    rows={3}
                    rowsMax={10}/>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={handleClose}>
                    {intl.formatMessage({id: 'close'})}
                </Button>
                <Button color='primary' variant="contained" style={{color: 'white'}} onClick={handleSubmit}>
                    {intl.formatMessage({id: 'share'})}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};