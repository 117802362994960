import React, {useEffect} from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import {compose} from "redux";
import {withAuthentication} from "../common/withAuthentication";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadCreditCards} from "../../actions/creditCards";
import {connect} from "react-redux";
import {createCrowd} from "../../actions/crowds";
import {createSubscription} from "../../actions/subscriptions";
import {useHistory, useLocation} from 'react-router-dom';
import {emitAppNotification} from "../../actions/common";
import {NotificationType} from "../../constants";
import useDeepCompareEffect from "use-deep-compare-effect";
import Spinner from "../common/Spinner";
import PaymentIntent from "./PaymentIntent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import ChooseSubscription from "../subscription/ChooseSubscription";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    button: {
        color: 'white',
        width: '130px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    buttonProgress: {
        color: theme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    progressWrapper: {
        position: 'relative',
        textAlign: 'center'
    },
    header: {
        padding: '32px 32px 16px'
    },
    content: {
        padding: '16px 32px 32px !important'
    },
    headerText: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    invoicingText: {
        color: theme.palette.primary.main,
        textAlign: 'center'
    }
}));

const DirectSubscribe = props => {

    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const location = useLocation();

    const {
        user,
        creditCards,
        loadCreditCards,
        createCrowd,
        createSubscription,
        emitError
    } = props;

    const [selectedCard, setSelectedCard] = React.useState(creditCards.items.find(c => c.primary) || creditCards.items[0] || '');
    const [useExisting, setUseExisting] = React.useState(false);
    const [cardError, setCardError] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [newCrowd, setNewCrowd] = React.useState({});

    const tier = parseInt(new URLSearchParams(location.search).get('tier'));
    if (tier === undefined || ![0, 1, 2, 3].includes(tier))
        history.push(`/crowds`);

    const userTrialEligible = !user.loggedIn || user.trialEligible;
    const [selected, setSelected] = React.useState(!userTrialEligible && tier === 0 ? 1 : tier);

    useDeepCompareEffect(() => {
        if (creditCards.loaded && creditCards.items.length) {
            setSelectedCard(creditCards.items.find(c => c.primary) || creditCards.items[0] || '');
            setUseExisting(true);
        }
    }, [creditCards]);

    useEffect(() => {
        if (!creditCards.loaded)
            loadCreditCards();
    }, []);

    const paymentFailedCallback = () => {
        setPaymentError(true);
        setLoading(false);
    };

    const cardClickHandler = tier => {
        setSelected(tier);
    };

    const handleSubmit = async event => {
        event.preventDefault();

        if (tier > 0 && !useExisting) {
            const cardElement = elements.getElement(CardNumberElement);
            if (!cardElement || !cardElement._complete || cardElement._invalid) {
                setCardError(true);
                return;
            }
        }

        setLoading(true);
        if (!newCrowd.id)
            await createCrowd(handleSubscribe(tier), () => setLoading(false));
        else
            handleSubscribe(tier)(newCrowd);
    };

    const handleSubscribe = tier => crowd => {
        setNewCrowd(crowd);
        const crowdId = crowd.id;

        const successCallback = () => {
            loadCreditCards();
            setLoading(false);
            history.push(`/crowds/${crowdId}`);
        };

        if (tier === 0)
            return createSubscription(crowdId, tier, undefined, successCallback, () => setLoading(false));

        if (useExisting) {
            return createSubscription(crowdId, tier, selectedCard.id, successCallback, paymentFailedCallback);
        } else {
            const cardElement = elements.getElement(CardNumberElement);
            return stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            }).then(({error, paymentMethod}) => {
                if (error)
                    throw Error();
                return createSubscription(crowdId, tier, paymentMethod.id, successCallback, paymentFailedCallback);
            }).catch(() => {
                setLoading(false);
                emitError("credit_card_error");
            });
        }
    };

    if (!creditCards.loaded)
        return <Spinner/>;

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <LocalActivityIcon/>
                </Avatar>
            }
            title={<Typography className={classes.headerText}>
                {props.intl.formatMessage({id: 'direct_subscribe'})}
            </Typography>}
            className={classes.header}
        />
        <CardContent className={classes.content}>
            <Typography>
                {props.intl.formatMessage({id: 'direct_subscribe_desc'})}
            </Typography>
            <Typography>
                {props.intl.formatMessage({id: 'direct_subscribe_desc2'})}
            </Typography>
        </CardContent>
        <CardContent>
            <ChooseSubscription displayFreeSubscription={userTrialEligible}
                                createSubscription={createSubscription}
                                hideButtons
                                collapsible
                                hideBestValueOutline
                                cardClickHandler={cardClickHandler}
                                selected={selected}
                                removeContainerSpacing/>
        </CardContent>
        {selected > 0 && <CardContent style={{display: 'flex', justifyContent: 'center'}}>
            <PaymentIntent width='50%'
                           creditCards={creditCards}
                           setSelectedCard={setSelectedCard}
                           setUseExisting={setUseExisting}
                           setCardError={setCardError}
                           useExisting={useExisting}
                           selectedCard={selectedCard}
                           cardError={cardError}
                           paymentError={paymentError}
            />
        </CardContent>}
        <CardContent>
            <Grid container
                  spacing={4}>
                {selected > 0 && <Grid item xs={12}>
                    <Typography className={classes.invoicingText}>
                        {props.intl.formatMessage({id: 'invoicing_after_payment'})}
                    </Typography>
                </Grid>}
                <Grid item xs={12}>
                    <div className={classes.progressWrapper}>
                        <Button color='primary' variant="contained" className={classes.button}
                                onClick={handleSubmit} disabled={loading}>
                            {selected > 0 ? props.intl.formatMessage({id: 'pay'}) : props.intl.formatMessage({id: 'submit'})}
                            {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </CardContent>
    </Card>;
};

const mapStateToProps = state => {
    return {
        user: state.user,
        creditCards: state.creditCards
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadCreditCards: () => dispatch(loadCreditCards()),
        createCrowd: (successCallback, errorCallback) => dispatch(createCrowd(successCallback, errorCallback)),
        createSubscription: (crowdId, tier, paymentMethodId, successCallback, errorCallback) => dispatch(createSubscription(tier, crowdId, paymentMethodId, successCallback, errorCallback)),
        emitError: errCode => dispatch(emitAppNotification(NotificationType.ERROR, errCode, 3000))
    };
};

export default compose(
    withAuthentication,
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(DirectSubscribe);