export const messagesSk = {
    sk: "Slovensky",
    en: "English",
    default_locale: "Východzí jazyk",
    introduction: "Predstavenie",
    benefits: "Benefity",
    pricing: "Cenník",
    for_businesses: "Pre firmy",
    for_public: "Pre verejnosť",
    blog: "Blog",
    login: "Prihlásiť sa",
    email: "Email",
    confirmEmail: "Potvrdiť email",
    contact: "Kontakt",
    dashboard: "Dashboard",
    subscription: "Predplatné",
    choose_subscription_for_crowd: "Zvoľte si predplatné pre Váš crowd",
    choose_subscription: "Zvoľte predplatné",
    direct_subscribe: "Chcem si založiť crowd",
    direct_subscribe_desc: "Založte nový crowd a vyberte si predplatné.",
    direct_subscribe_desc2: "Zvoľte spôsob platby a môžete službu začať využívať.",
    invoicing_after_payment: "Údaje pre vytvorenie faktúry si zadáte po zaplatení programu.",
    menu: "Menu",
    crowds: "Crowdy",
    crowd_name: "Názov",
    crowd_status: "Stav",
    crowd_plan: "Typ predplatného",
    profile: "Profil",
    email_notifications: "Emailové upozornenia",
    email_notification_settings: "Nastavenia emailových upozornení",
    notifications_update_successful: "Nastavenia emailových upozornení úspešne uložené.",
    notification_crowd_created: "Crowd vytvorený",
    notification_crowd_top_level_domain_added: "Crowd top level domain pridaná",
    notification_crowd_subscription_activated: "Predplatné aktivované",
    notification_crowd_subscription_upgraded: "Predplatné aktualizované",
    notification_crowd_subscription_downgraded: "Predplatné aktualizované",
    notification_crowd_subscription_cancelled: "Predplatné zrušené",
    notification_crowd_subscription_past_due: "Platba za predplatné je po termíne",
    notification_crowd_subscription_past_due_temporarily_deactivated: "Predplatné bolo dočasne pozastavené pre chýbajúcu platbu",
    notification_crowd_subscription_renewed: "Predplatné bolo obnovené",
    notification_crowd_subscription_reactivated: "Predplatné bolo znovu aktivované",
    notification_mark_all_as_read: "Označiť všetky ako prečítané",
    notification_idea_added: "Nápad pridaný",
    notification_idea_commented: "Nápad bol okomentovaný",
    notification_idea_liked: "Nápad získal hlas",
    notification_idea_like_milestone_reached: "Nápad získal potrebný počet hlasov",
    notification_idea_marked_by_admin: "Váš nápad bol označený správcom",
    notification_idea_state_changed: "Stav nápadu sa zmenil",
    notification_crowd_role_added: "Pridaná správcovská rola crowdu",
    notifications: "upozornenia",
    notifications_history: "História upozornení",
    notifications_history_you_have: "Máte",
    notifications_history_new_notifications: "nových upozornení.",
    notifications_history_empty: "Žiadne nové upozornenia",
    crowd_profile_settings: "Nastavenie profilu crowdu",
    crowd_domain_settings: "Nastavenie domény crowdu",
    crowd_subscription: "Predplatné crowdu",
    crowd_subscription_active: "AKTÍVNE",
    crowd_subscription_cancelled: "ZRUŠENÉ",
    crowd_categories: "Kategórie nápadov",
    crowd_billing_info: "Nastavenia platby",
    crowd_users: "Privilegovaní užívatelia",
    crowd_users_add_new: "Pridať nových správcov",
    crowd_update_successful: "Nastavenia crowdu aktualizované",
    crowd_created: "Crowd vytvorený",
    crowd_homepage_preview: "Náhľad úvodnej stránky crowdu",
    crowd_title: "Názov",
    crowd_subdomain: "Subdoména",
    crowd_domain: "Top level doména",
    crowd_description: "Popis",
    crowd_gaCode: "Google analytics kód",
    crowd_gaCode_helper: "Zadajte kód v tvare UA-XXXXX-Y",
    crowd_likeMilestone: "Počet hlasov pre upozornenie admina",
    crowd_reward_currency: "Mena odmeny",
    crowd_rewardAmount: "Výška odmeny",
    crowd_visible: "Crowd verejne viditeľný",
    display_default_slideshow: "Zobraziť východziu slideshow",
    display_custom_intro: "Zobraziť vlastné intro",
    crowd_hide_title: "Skryť názov crowdu na domovskej stránke",
    crowd_subdomain_enabled: "Povoliť smerovanie domény",
    crowd_private: "Zmeniť crowd na súkromný",
    crowd_allow_pre_private_logins: "Povoliť prihlásenie pre existujúcich užívateľov",
    crowd_allow_pre_private_logins_desc: "Užívatelia registrovaní na Váš crowd predtým, než bol súkromný, nebudú už môct naň pristupovať, ak ich email doména nie je medzi explicitne povolenými doménami. Toto nastavenie môžte zmeniť a povoliť im nadalej sa prihlasovať do Vášho crowdu prepínačom nižšie.",
    crowd_allow_private_users: "Zapnúť súkromných užívateľov",
    crowd_allow_private_users_desc: "Nastavte všetkých novo registrovaných používateľov ako súkromných. Súkromní užívatelia nemôžu vykonávať interakcie so žiadnym iným verejným alebo súkromným crowdom okrem Vášho, a sú permanentne prepojení jedine s týmto crowdom.",
    crowd_privacy_settings: "Nastavenia súkromia",
    crowd_privacy_desc: "Ak povolíte túto možnosť, zaregistrovať sa budú môcť iba užívatelia s povolenou doménou v emailovej adrese. Pri zmene crowdu na súkromný stratia všetci doterajší užívatelia crowdu prístup k nemu, pokiaľ to nepovolíte explicitne v sekcii nižšie.",
    crowd_privacy_emails_to_allow_desc: "Domény môžete oddeliť medzerou, čiarkou, alebo bodkočiarkou.",
    validation_crowd_private_allowed_emails_empty: "Zoznam povolených domén pre registráciu nemôže byť pre sákromný crowd prázdny.",
    validation_crowd_private_allowed_emails_invalid: "Niektoré z domén nie sú platné.",
    crowd_billing_name: "Názov platobného profilu",
    crowd_billing_companyName: "Názov firmy",
    crowd_billing_street: "Ulica",
    crowd_billing_postalCode: "PSČ",
    crowd_billing_city: "Mesto",
    crowd_billing_country: "Krajina",
    crowd_billing_companyId: "IČO",
    crowd_billing_taxId: "DIČ",
    crowd_billing_vatId: "IČ-DPH",
    crowd_theming_data: "Nastavenie témy",
    crowd_theming_ideas: "Zobrazenie nápadov",
    crowd_ideas_listing_view: "Zobrazenie listingu nápadov",
    crowd_ideas_listing_view_previews: "Karty",
    crowd_ideas_listing_view_rows: "Riadky",
    crowd_cover_image: "Úvodný obrázok",
    crowd_logo: "Logo crowdu",
    crowd_intro_settings: "Nastavenia crowd intra",
    crowd_intro_background_color: "Farba intro pozadia:",
    crowd_no_image_yet: "Nenahrali ste žiaden úvodný obrázok",
    crowd_no_logo_yet: "Logo crowdu zatiaľ nebolo nahraté",
    crowd_does_not_have_logo_notification: "Crowd zatiaľ nemá žiadne logo.",
    crowd_does_not_have_logo_notification_button: "Nahrať logo",
    crowd_not_visible_notification: "Crowd nie je verejný.",
    crowd_not_visible_notification_button: "Zverejniť",
    crowd_no_subscription_notification: "Crowd nemá aktívne predplatné.",
    crowd_no_subscription_notification_button: "Zvoliť predplatné",
    crowd_trial_subscription_notification: "Zo skúšobného predplatného Vám ostáva",
    crowd_trial_subscription_days_remaining_notification: "dní.",
    crowd_trial_subscription_ends_today_notification: "Skúšobné predplatné končí dnes.",
    crowd_subscription_required: "Je potrebné zvoliť predplatné",
    crowd_visible_subscription_required: "Ak chcete crowd prepnnúť na verejný, musíte mať aktívne predplatné.",
    current_subscription_name: "Súčasné predplatné",
    next_subscription_name: "Ďalšie predplatné",
    subscription_start: "Začiatok predplatného",
    subscription_end: "Koniec predplatného",
    subscription_renewal_date: "Ďalší dátum obnovy",
    subscription_price: "Cena predplatného",
    subscription_created: "Predplatné vytvorené",
    subscription_cancelled: "Predplatné zrušené",
    subscription_features: "Výhody",
    subscription_feature_unlimited: "Neobmedzené",
    subscription_feature_support_none: "Bez podpory",
    subscription_feature_support_basic: "Základná podpora",
    subscription_feature_support_sla_5_8: "SLA 5/8 podpora",
    subscription_feature_own_or_3rd_level_domain: "Vlastná doména alebo doména tretej úrovne",
    subscription_feature_3rd_level_domain: "Doména tretej úrovne",
    subscription_feature_private_crowd_enabled: "Áno",
    subscription_feature_private_crowd_disabled: "Nie",
    subscription_feature_private_users_enabled: "Áno",
    subscription_feature_private_users_disabled: "Nie",
    subscription_features_users: "Maximálny počet užívateľov",
    subscription_features_admins: "Maximálny počet správcov",
    subscription_features_managers: "Maximálny počet manažérov",
    subscription_features_ideas: "Maximálny počet nápadov",
    subscription_features_ownDomain: "Typ domény",
    subscription_features_privateCrowd: "Súkromný crowd",
    subscription_features_privateUsers: "Súkromní užívatelia",
    subscription_features_support: "Podpora",
    cancel_subscription: "Zrušiť predplatné",
    change_subscription: "Zmeniť typ predplatného",
    renew_subscription: "Obnoviť predplatné",
    no_active_subscription: "Momentálne nie je aktívne žiadne predplatné",
    no_crowds_yet: "Zatiaľ neexistuje žiaden crowd",
    crowd_does_not_exist: "Crowd neexistuje",
    crowd_not_visible_owner: "Crowd bol skrytý jeho vlastníkom.",
    crowd_is_disabled: "Crowd bol vypnutý",
    validation_crowd_title: "Názov crowdu nemôže byť prázdny",
    validation_crowd_subdomain_empty: "Subdoména crowdu nemôže byť prázdna",
    validation_crowd_subdomain_invalid: "Subdoména crowdu nemôže mať túto hodnotu",
    validation_crowd_subdomain_disabled_domain_empty: "Doména crowdu nemôže byť prázdna, keď je vypnuté smerovanie subdomény",
    validation_crowd_subdomain_regex: "Subdoména crowdu môže obsahovať iba písmená, čísla a pomlčky",
    validation_crowd_domain_regex: "Zdá sa, že táto doména je nesprávna",
    validation_crowd_domain_invalid: "Doména crowdu nemôže mať túto hodnotu",
    validation_crowd_gacode: "Zdá sa, že tento Google Analytics kód je nesprávny",
    validation_crowd_likeMilestone: "Cieľ počtu hlasov musí byť číslo",
    validation_crowd_rewardAmount: "Hodnota odmeny musí byť číslo",
    validation_crowd_subdomain_in_use: "Subdoména crowdu sa už používa",
    validation_crowd_domain_in_use: "Vrcholná doména sa už používa",
    validation_crowd_billing_name: "Fakturačné meno nemôže byť prázdne",
    validation_crowd_billing_companyName: "Názov firmy nemôže byť prázdny",
    validation_crowd_billing_street: "Ulica nemôže byť prázdna",
    validation_crowd_billing_postalCode: "PSČ nemôže byť prázdne",
    validation_crowd_billing_city: "Mesto nemôže byť prázdne",
    validation_crowd_billing_country: "Krajina nemôže byť prázdna",
    validation_crowd_billing_companyId: "IČO nemôže byť prázdne",
    validation_crowd_billing_taxId: "DIČ nemôže byť prázdne",
    create_new_crowd: "Vytvoriť nový crowd",
    active: "Aktívne",
    inactive: "Neaktívne",
    theming: "Témovanie",
    categories: "Kategórie",
    category: "Kategória",
    category_name: "Názov",
    category_description: "Popis",
    category_visible: "Kategória viditeľná pre užívateľov",
    category_ideasCount: "Nápady",
    create_new_category: "Vytvoriť novú kategóriu",
    category_created: "Kategória vytvorená",
    category_update_successful: "Kategória aktualizovaná",
    category_deleted: "Kategória vymazaná",
    category_delete: "Vymazať kategóriu",
    category_delete_delete_are_you_sure: "Ste si istý, že chcete vymazať túto kategóriu?",
    no_categories: "Žiadne kategórie",
    back_to_category_list: "Naspäť na zoznam",
    validation_category_name: "Názov kategórie nemôže byť prázdny",
    billing: "Fakturácia",
    crowd_users_add_button: "Pridať užívateľov",
    crowd_users_role: "Rola užívateľa crowdu",
    role: "Rola",
    crowd_users_emails_to_add: "E-mailové adresy na pridanie",
    crowd_users_emails_to_add_desc: "E-mailové adresy môžu byť oddelené medzerou, čiarkou, či bodkočiarkou.",
    crowd_admin: "správca",
    crowd_admins: "správcov",
    crowd_manager: "manažér",
    crowd_managers: "manažéri",
    crowd_managers2: "manažérov",
    validation_crowd_users_emails: "Niektoré z e-mailových adries nie sú správne",
    validation_crowd_users_emails_empty: "Nie su zadané žiadne emaily",
    validation_crowd_users_do_not_exist: "Užívateľské účty s danými e-mailovými adresami neexistujú",
    validation_crowd_users_do_not_exist_some: "Niektoré zo zadaných užívateľských účtov neexistujú",
    validation_crowd_users_can_not_add: "Nemôžete pridať viac",
    validation_crowd_users_can_only_add: "Môžete pridať iba ",
    validation_crowd_users_too_many: "Vaše predplatné neumožňuje ďaľších superužívateľov. ",
    crowd_users_added_successfully: "Užívatelia úspešne pridaní.",
    crowd_users_updated_successfully: "Užívatelia úspešne updatnutí.",
    crowd_users_updated_some: "Prosím refreshnite si stránku. Niektorí užívatelia boli medzičasom updatnutí iným administrátorom.",
    crowd_users_already_exist: "Užívatelia už existujú",
    crowd_user_deleted: "Užívateľ bol úspešne odstránený.",
    no_crowd_users: "Žiaden užívateľ",
    add_idea: "Pridať nápad",
    all_ideas: "Všetky",
    ideas_none_yet: "Zatiaľ neboli pridané žiadne nápady",
    be_the_first_to_add: "Pridajte nový nápad v tejto kategórii ako prvý",
    crowd_homepage_carousel_caption1: "Pridajte nápad",
    crowd_homepage_carousel_caption2: "Získajte hlasy",
    crowd_homepage_carousel_caption3: "Spoločnosť vyhodnotí váš nápad",
    crowd_homepage_carousel_caption4: "Nový produkt spoločnosti",
    crowd_homepage_carousel_text1: "Používate produkty alebo služby spoločnosti, ale niečo vám chýba. Vidíte jasný priestor na zlepšenie skúseností zákazníkov alebo na zvýšenie ich loajality. Navrhnite nový produkt alebo službu, aby ste vyriešili svoj problém alebo vylepšili existujúcu ponuku spoločnosti.",
    crowd_homepage_carousel_text2: "Dajte o svojom nápade vedieť svojim priateľom a známym. Čím lepší popis, tým viac ľudí ho pochopí a ocení. Keď získate dostatočnú podporu, vášim nápadom sa daná spoločnosť bude zaoberať.",
    crowd_homepage_carousel_text3: "Náš tím produktových manažérov pravidelne kontroluje nápady, ktoré získali dostatočnú podporu zákazníkov. Zhodnotíme ich pridanú hodnotu a uskutočniteľnosť a zmysluplné myšlienky posunieme do fázy implementácie.",
    crowd_homepage_carousel_text4: "Ak sa váš nápad zrealizuje, okamžite získate niekoľko odmien. Dostanete uznanie ako autor myšlienky alebo inovácie a vyriešite problém, ktorý vás trápil. Môžete tiež získať finančnú odmenu.",
    create_new_idea: "Vytvoriť nápad",
    create_new_idea_name: "Titulok",
    create_new_idea_description: "Popis",
    create_new_idea_category: "Kategória",
    create_new_idea_name_hint: "Jednoduchý, stručný a výstižný titulok je najlepší.",
    create_new_idea_description_hint: "Lepšie popíš svoj nápad",
    create_new_idea_category_hint: "Zvoľ vhodnú kategóriu pre nápad ",
    create_new_idea_videos: "Embedovateľné video",
    embeddable_video_url: "Video URL",
    invalid_video_url: "Zlé URL pre video",
    add_new_video_url: "Pridať ďaľšie URL",
    create_new_idea_videos_hint: "Môžeš pridať až 5 videí z Youtube, ktoré sa budú zobrazovať pri Tvojom nápade.",
    create_new_idea_files: "Prílohy",
    create_new_idea_files_hint: "Nahraj prílohy, ktoré pomôžu lepšie vysvetliť Tvoj nápad. Môžeš ich nahrať až 5.",
    create_new_idea_featured_img_hint: "Zvoľ úvodný obrázok.",
    create_new_idea_featured_img: "Úvodný obrázok",
    crowd_limit_reached: "Presiahnutý limit crowdu",
    crowd_user_limit_reached: "Presiahnutý limit počtu užívateľov crowdu. Majiteľ crowdu bol upozornený.",
    ideas_subscription_limit_reached: "Na tento crowd nie je možné pridať viac nápadov.",
    idea_name: "Titulok nápadu",
    no_ideas_yet: "Zatiaľ tu nie sú žiadne nápady.",
    idea_status: "Stav nápadu",
    idea_crowd_name: "Názov crowdu",
    idea_detail: "Podrobnosti",
    idea_comments: "Komentáre",
    idea_likes: "Hlasy",
    rating: "Hodnotenie",
    no_rating_yet: "Idea nemá hodnotenia",
    share: "Zdieľať",
    share_idea_dialog_title: "Zdieľať nápad",
    share_idea_dialog_desc: "Uveďte maily kolegov alebo známych, ktorí by určite mali o tomto nápade vedieť.",
    emails: "Emaily",
    idea_description: "Popis nápadu",
    no_description_available: "Popis nie je k dispozícii",
    featured: "Vybrané",
    rewarded: "Odmenené",
    new_idea: "Nový nápad",
    most_likes: "Najviac hlasov",
    most_comments: "Najviac komentárov",
    submitted: "Predložený",
    in_review: "Vyhodnocovaný",
    deferred: "Odložený",
    approved: "Odobrený",
    underway: "Prebieha",
    implemented: "Implementovaný",
    rejected: "Zamietnutý",
    tag_idea_button: "Označiť nápad",
    change_idea_state_button: "Zmeniť stav",
    appreciate_button: "Hlasuj",
    unlike_button: "Zruš hlas",
    idea_comment_add: "Pridať komentár",
    join_the_discussion: "Pridaj sa k diskusii...",
    log_in_with: "Prihlás sa pomocou",
    idea_comment_edit: "Editovať komentár",
    idea_comment_delete: "Zmazať komentár",
    idea_comment_delete_are_you_sure: "Si si istý, že chceš zmazať komentár?",
    edit_idea: "Editovať nápad",
    edit_idea_current_featured_img_hint: "Aktuálny hlavný obrázok",
    edit_idea_attachments: "Editovať prílohy k nápadu",
    edit_idea_videos: "Editovať videá k nápadu",
    edit_idea_no_attachments: "Žiadne prílohy",
    select_category: "Vyber kategóriu",
    validation_idea_name: "Titulok nápadu nemôže byť prázdny",
    validation_idea_description_empty: "Popis nápadu nemôže byť prázdny",
    validation_idea_description_too_long: "Popis nápadu je",
    validation_crowd_intro_too_long: "Crowd intro je",
    characters_too_long: 'Príliš veľa znakov',
    validation_idea_files: "Nepodporovaný typ súboru. Akceptované sú iba obrázky vo formáte JPG, JPEG, GIF a PNG.",
    validation_idea_featuredImg: "Nepodporovaný typ súboru. Akceptované sú iba obrázky vo formáte JPG, JPEG, GIF a PNG",
    idea_created: "Nápad vytvorený",
    idea_updated: "Nápad aktualizovaný",
    idea_deleted: "Nápad vymazaný",
    idea_shared: "Nápad zdieľaný",
    idea_tagged: "Nápad označený",
    idea_settings: "Nastavenia nápadu",
    idea_question_text: "Textová otázka",
    idea_questionnaire: "Dotazník k nápadu",
    idea_like_questionnaire: "Dotazník k nápadu",
    idea_creation_questions: "Otázky pre nové nápady",
    idea_creation_questions_desc: "Používatelia predkladajúci nové nápady budú musieť poskytnúť odpovede na vaše otázky. " +
         "Pripravili sme zoznam 5 odporúčaných otázok, na ktoré by mali tvorcovia nápadov odpovedať, preto navrhujeme, aby ste ich použili a vyladili podľa svojich potrieb.",
    idea_creation_questions_q1: "Skúste odhadnúť, aké nové príjmy by tento nápad mohol priniesť.",
    idea_creation_questions_q2: "Skúste odhadnúť, koľko úspor by tento nápad mohol priniesť.",
    idea_creation_questions_q3: "Skúste odhadnúť, koľko nových zákazníkov by tento nápad mohol priniesť.",
    idea_creation_questions_q4: "Ako dlho si myslíte, že by trvalo implementovanie tohto nápadu?",
    idea_creation_questions_q5: "Koľko by stála realizácia tohto nápadu?",
    more_than_100k: "Viac ako 100 000€",
    more_than_10k: "Viac ako 10 000",
    less_than_a_month: "Menej ako jeden mesiac",
    one_to_three_months: "Jeden až tri mesiace",
    three_to_six_months: "Tri až šesť mesiacov",
    more_than_six_months: "Viac ako šesť mesiacov",
    idea_question_add_recommended: "Pridať odporúčané otázky",
    create_idea_company_questions: "Biznis otázky",
    create_idea_owner_would_like: "Majiteľ crowdu by chcel, aby ste odpovedali aj na tieto otázky. Odpoveď nemusí byť presná, snažte sa uhádnuť, ako najlepšie viete",
    choose_one: "Zvoľte jednu možnosť",
    idea_questionnaire_enabled: "Dotazník povolený",
    idea_questionnaire_saved: "Dotazník uložený.",
    idea_questionnaire_desc: "Po zahlasovaní za akýkoľvek crowd nápad budú užívatelia požiadaní o vyplnenie tohto dotazníka.",
    idea_question_add: "Pridať otázku",
    idea_question_option_add: "Pridať možnosť",
    idea_question_none_yet: "Zatiaľ neboli pridané žiadne otázky",
    idea_question_radio: "Otázka s jednou odpoveďou",
    idea_question_checkbox: "Otázky s viacerými odpoveďami",
    idea_question: "Otázka k nápadu",
    idea_question_answer: "Odpoveď",
    idea_question_validation_answer_empty: "Odpoveď nemôže byť prázdna",
    idea_question_validation_option_empty: "Minimálne jedna možnosť musí byť označená",
    idea_question_option: "Možnosť",
    idea_question_validation_empty: "Otázka k nápadu nemôže byť prázdna",
    idea_question_option_validation_empty: "Možnosť nemôže byť prázdna",
    idea_liked: "Nápad získal hlas",
    idea_rated: "Nápad ohodnotený",
    idea_unliked: "Nápad stratil hlas",
    idea_stats: "Štatistiky nápadu",
    idea_stats_comments: "Komentáre k nápadu",
    idea_stats_likes: "Štatistiky hlasov",
    idea_stats_all: "Celkovo",
    idea_stats_7days: "Posledných 7 dní",
    idea_stats_30days: "Posledných 30 dní",
    idea_stats_download_answers: "Stiahnúť odpovede",
    comment_created: "Komentár pridaný",
    comment_updated: "Komentár aktualizovaný",
    comment_deleted: "Komentár vymazaný",
    no_comments_yet: "Zatiaľ neboli pridané žiadne komentáre",
    comment_posted_on: "zverejnené na",
    comment_idea_author: "Autor nápadu",
    comment_crowd_admin: "Správca crowdu",
    comments: "Komentáre",
    copyright: "Crowdea, Inc.",
    let_the_crowd: "Nechajte to",
    figure_it_out: "dav vymyslieť",
    for_you: "za Vás",
    let_the_crowd_desc: "Dovoľte zákaznikom stať sa členom Vášho tímu, a umožnite im vymyslieť inovatívne nápady pre Vaše produkty a služby.",
    what_is_crowdea: "Na čo je Crowdea?",
    what_is_crowdea_desc: "Môže byť využitá rôznymi spôsobmi. Povieme si pár príkladov pre inšpiráciu:",
    monetize: "Zarob",
    your_ideas: "svojimi nápadmi",
    love_getting_creative: "Si kreatívny? Vidíš sa v pozícii inovátora alebo si len chceš zjednodušiť život? Si na správnom mieste. Zdieľaj nápady, predveď návrhy nových produktov a služieb a zarob svojou kreativitou.",
    try_crowdea: "Vyskúšajte Crowdea",
    user_benefits: "Pomôžte vylepšiť svoje obľúbené spoločnosti",
    user_benefits_desc: "Crowdea je Vaša jedinečná šanca povedať spoločnostiam, čo skutočne ich zákazníci potrebujú. Poskytnite spätnú väzbu a nápady týkajúce sa kvality, výberu a ľahkosti použitia ich výrobkov.",
    support_your_favorite_ideas: "Podporte svoje obľúbené nápady",
    support_your_favorite_ideas_desc: "Na účasť nemusíte posielať nápady. Môžete si prezrieť návrhy iných ľudí a ak sa vám niečo páči, nezabudnite hlasovať a pomôžte svojim obľúbeným príspevkom stať sa skutočným produktom alebo inováciou.",
    make_your_life_easier: "Uľahčite si život",
    make_your_life_easier_desc: "Používate produkt každý deň a páči sa vám, má však len jednu drobnosť, ktorá vám prekáža a presne viete, ako to napraviť? Povedzte svojej obľúbenej spoločnosti, aké funkcie by vylepšili jej produkt, alebo navrhnite úplne novú ponuku.",
    public_crowds_preview: "Náhľad verejných crowdov",
    your_future_crowd: "Váš budúci crowd",
    your_future_crowd_desc: "Na tomto mieste môže byť práve Váš crowd. Vyskúšajte si hneď teraz!",
    your_future_idea: "Váš budúci nápad",
    your_future_idea_desc: "Určite máte skvelé nápady. Vyberte si crowd a zverejnite ich!",
    most_liked_ideas_preview: "Nápady s najviac hlasmi",
    newest_ideas_preview: "Nedávno pridané nápady",
    innovation: "Inovácie",
    innovation_desc: "Pozbierajte od zákazníkov návrhy na zlepšenie produktov a služieb",
    ab_testing: "AB Testovanie",
    ab_testing_desc: "Otestuje vernosť Vašich zákazníkov, reklamné kampane, či dizajn obalu",
    loyalty: "Vernosť",
    loyalty_desc: "Budujte zákaznícku vernosť sledovaním ich nápadov a následnou implementáciou",
    social_networks: "Sociálne siete",
    social_networks_desc: "Vytvorte zaujímavý obsah a zdieľajte ho s Vašimi fanúšikmi",
    how_does_it_work: "Ako to funguje",
    how_does_it_work_desc: "Za nominálny mesačný poplatok pre Vás zabezpečíme portál. Následne môžete zmeniť doménu alebo upraviť vzhľad portálu pridaním loga, či zmenou farieb.",
    how_does_it_work_user_desc: "Firmy prevádzkujú portály pomenované \"crowdy\", vyzývajúc ich používateľov, aby navrhli nové produkty a služby alebo inovovali ich existujúcu ponuku. Vymyslite úžasný nápad, získajte hlasy za svoj návrh a ak budete úspešní, získajte odmenu.",
    how_does_it_work_users_desc: "Môžete pozvať Vašich zákazníkov prostredníctvom sociálnych sietí, e-mailom, z Vášho CRM systému, alebo odkazom na Vašom webe.",
    how_does_it_work_ideas_desc: "Registrovaní užívatelia môžu pridať nový produkt, službu, či inú inováciu. Následne môžu opísať ich nápad do detailu.",
    ratings: "Hodnotenia",
    how_does_it_work_ratings_desc: "Nie každý zákazník je kreatívny, no aj napriek tomu sa im môže zapáčiť nápad niekoho iného. Užívatelia môžu hlasovať a hodnotiť nápady, ktoré sa im páčia.",
    rewards: "odmeny",
    how_does_it_work_rewards_desc: "Najlepšie hodnotené nápady budú zanalyzované Vašim produktovým tímom. Ak bude nápad implementovaný, pôvodný autor bude mať nárok na malú odmenu.",
    how_much_does_it_cost: "Koľko to stojí?",
    trial: "Skúška",
    concert: "Koncert",
    party: "Párty",
    festival: "Festival",
    non_government_organization: "Mimovládna organizácia",
    free: "Zadarmo",
    free_trial: "Skušobná doba zadarmo",
    try_for_free: "Vyskúšajte zadarmo",
    no_subscription: "Žiadne predplatné",
    buy: "Kúpiť",
    max: "Max.",
    max_users: "Max. {number} užívateľov",
    max_ideas: "Max. {number} nápadov",
    unlimited: "Neobmedzene veľa",
    unlimited_users: "Neobmedzene veľa užívateľov",
    unlimited_ideas: "Neobmedzene veľa nápadov",
    users: "užívatelia",
    ideas: "nápady",
    third_level_domain: "Doména tretej úrovne",
    dedicated_domain: "Vlastná doména",
    shared_crowdea_users: "Zdieľaní užívatelia Crowdea",
    private_crowdea_users: "Súkromní užívatelia Crowdea",
    admin: "správca",
    admins: "správcov",
    superuser: "superužívatel",
    superusers: "superužívatelia",
    limited_support: "Obmedzená podpora",
    standard_support: "Štandardná podpora (SLA 5/8)",
    private_crowd: "Súkromné crowdy / len na pozvánku",
    advanced_crowd_settings: "Pokročilé nastavenia crowdu",
    complementary_services: "Prístup k dodatkovým službám",
    new_crowdea_features_vote: "Prednostné hlasovanie o nových Crowdea funkciách",
    monthly: "mesačne",
    logout: "Odhlásiť",
    logout_successful: "Boli ste odhlásení",
    facebook_login_no_email: "Váš Facebook účet neobsahuje e-mailovú adresu. Prosím, vyplňte ju.",
    facebook_login_error: "Prihlásenie cez Facebook bolo neúspešné. Prosím, skúste iný spôsob.",
    validation_login_email: "Neplatná e-mailová adresa",
    validation_login_password: "Heslo vyzerá byť prázdne",
    invalid_username_password: "Nesprávne meno alebo heslo",
    account_not_verified: "Neoverený účet. Prosím, skontrolujte svoju e-mailovú schránku, v ktorej nájdete aktivačný link.",
    register: "Registrovať sa",
    registration: "Registrácia",
    to_login: "Prejsť na prihlásenie",
    forgot_password: "Zabudnuté heslo",
    reset_password: "Resetovať heslo",
    forgot_password_email_sent: "E-mail obsahujúci link na resetovanie hesla bol odoslaný.",
    password_reset_token_verifying: "Overovanie tokenu na resetovanie hesla, počkajte prosím.",
    password_reset_token_invalid: "Token na resetovanie hesla je neplatný.",
    password_reset_token_used: "Token na resetovanie hesla už bol použitý.",
    password_reset_token_success: "Vaše heslo bolo úspešne resetované! Teraz sa môžete prihlásiť do Crowdea.",
    password: "Heslo",
    current_password: "Súčasné heslo",
    new_password: "Nové heslo",
    confirm_new_password: "Potvrďte nové heslo",
    confirm_password: "Potvrďte heslo",
    phone_number: "Číslo",
    name: "Meno",
    street: "Ulica",
    city: "Mesto",
    country: "Krajina",
    postal_code: "PSČ",
    dob: "Dátum narodenia",
    date: "Dátum",
    time: "Čas",
    validation_account_name: "Meno nemôže byť prázdne",
    validation_account_avatar: "Nepodporovaný typ súboru. Akceptované sú iba obrázky vo formáte JPG, JPEG, GIF a PNG",
    avatar: "Avatar",
    avatar_upload_hint: "Vyberte si avatar.",
    account_update_successful: "Účet bol úspešne aktualizovaný.",
    avatar_upload_successful: "Avatar bol úspešne nahraný.",
    change_password: "Zmeniť heslo",
    set_password: "Nastaviť heslo",
    validation_change_password_currentPassword: "Súčasné heslo nemôže byť prázdne",
    validation_change_password_password: "Nové heslo nespĺňa požiadavky",
    validation_change_password_confirmPassword: "Heslá sa nezhodujú",
    validation_change_password_mismatch: "Nesprávne súčasné heslo",
    change_password_successful: "Heslo bolo úspešne zmenené",
    set_password_successful: "Heslo bolo úspešne nastavené.",
    credit_cards: "Kreditné karty",
    select_credit_card: "Vyberte kreditnú kartu",
    add_new_credit_card: "Pridajte novú kreditnú kartu",
    use_existing_credit_card: "Použite existujúcu kreditnú kartu",
    enter_payment_details: "Vložte platobné údaje",
    payment_details: "PPlatobné údaje",
    no_credit_cards_yet: "Zatiaľ neboli pridané žiadne kreditné karty",
    credit_card_number: "Číslo karty",
    credit_card_expiration: "Platnosť do",
    credit_card_cvv: "CVC",
    credit_card_incorrect_number: "Nesprávne alebo nekompletné údaje kreditnej karty",
    credit_card_payment_failed: "Platba zlyhala. Použite inú kartu.",
    credit_card_error: "Momentálne nie je možné kreditnú kartu spracovať. Prosím, použite inú kartu.",
    credit_card_full_name: "Celé meno",
    validation_credit_card_name_empty: "Meno karty nemôže byť prázdne",
    make_default: "Nastaviť ako predvolenú",
    make_default_credit_card: "Nastaviť ako novú predvolenú kartu",
    credit_card_deleted_new_default: "Kreditná karta bola vymazaná a nová kreditná karta bola nastavená ako predvolená",
    validation_registration_email: "Nesprávna e-mailová adresa",
    validation_registration_email_in_use: "E-mailová adresa sa už používa",
    validation_registration_domain_not_allowed: "E-mailová doména nie je povolená.",
    validation_registration_confirmEmail: "E-mailové adresy sa nezhodujú",
    validation_registration_firstName: "Krstné meno nemôže byť prázdne",
    validation_registration_lastName: "Priezvisko nemôže byť prázdne",
    validation_registration_password: "Toto heslo nespĺňa podmienky",
    validation_registration_confirmPassword: "Heslá sa nezhodujú",
    validation_registration_phoneNumber: "Zdá sa, že toto nie je správne slovenské číslo",
    password_rules: "Heslo musí mať 8 až 25 znakov a obsahovať jedno malé písmeno, jedno veľké písmeno, " +
        "jedno číslo a jeden špeciálny znak, zároveň nesmie obsahovať medzery",
    agree_terms_of_service: "Registráciou svojho účtu súhlasite s",
    terms_of_service: "Zmluva o poskytovaní služieb",
    privacy_policy: "Zásady ochrany osobných údajov",
    and_the: "a",
    registration_successful: "Registrácia úspešná. Presmerujeme Vás na prihlasovaciu obrazovku",
    registration_activation_link_sent: "Registrácia úspešná. Aktivačný link s ďalšími inštrukciami Vám bol zaslaný na e-mailovú adresu.",
    registration_activation_token_verifying: "Overujeme aktivačný token, počkajte prosím.",
    registration_activation_token_invalid: "Aktivačný token je neplatný.",
    registration_activation_token_used: "Aktivačný token už bol použitý.",
    registration_activation_token_success: "Váš účet bol úspešne overený! Teraz sa môžete prihlásiť do Crowdea.",
    no_document_selected: "Nevybraný žiadny dokument",
    select_file: "Vybrať súbor",
    file_upload_image_only: "Nepodporovaný typ súboru. Akceptované sú iba obrázky vo formáte JPG, JPEG, GIF a PNG",
    my_account: "Môj účet",
    about: "O",
    close: "Zavrieť",
    to_verification: "Overiť",
    submit: "Odoslať",
    pay: "Zaplatiť",
    post: "Pridať",
    save: "Uložiť",
    notify_crowd_owners: "Informovať vlastníka crowdu",
    cancel: "Zrušiť",
    edit: "Upraviť",
    accept: "Prijať",
    propose_price: "Navrhnúť cenu",
    complete: "Dokončiť",
    back: "Späť",
    next: "Ďalej",
    upload: "Nahrať",
    delete: "Vymazať",
    default: "Predvolený",
    roles: "Role",
    rows_per_page: "Počet riadkov na stránku",
    all_rows: "Všetky",
    of: "z",
    on: "na",
    yes: "Áno",
    no: "nie",
    enabled: "Zapnuté",
    disabled: "Vypnuté",
    enable: "Zapnúť",
    disable: "Vypnúť",
    status: "Stav",
    created_by: "Vytvoril",
    on_day: 'dňa',
    created_on: "Vytvorené na",
    infinite: "Nekonečné",
    default_error: "Úkon sa nepodarilo dokončiť. Skúste to prosím neskôr.The action could not be completed. Please try again later.",
    optimistic_locking_error: "Kým ste si prezerali informácie, niekto ich upravil. Prosím, obnovte stránku.",
    terms_of_service_headline: "Podmienky používania Aplikácie - Terms of Service",
    terms_of_service_I: "Všeobecné ustanovenia, definície a autorské práva",
    terms_of_service_I_1: "Účelom a predmetom týchto podmienok používania Aplikácie (Terms of Service) spoločnosti Extropian s.r.o.  (ďalej ako „podmienky používania“) je úprava vzájomných práv a povinností Prevádzkovateľa aplikácie a Používateľa aplikácie v súvislosti s používaním aplikácie Crowdea.",
    terms_of_service_I_2: "Aplikácia Crowdea je on-line aplikácia, mobilná aplikácia, webová stránka Prevádzkovateľa aplikácie, softvér, do ktorej sa prihlasujú Používatelia za účelom jej používania za podmienok stanovených týmito podmienkami používania (ďalej ako „Aplikácia“).",
    terms_of_service_I_3: "Používateľom aplikácie je spoločnosť, ktorá má záujem o využívanie služieb Aplikácie,  zaregistrovala sa v Aplikácii a vytvorila si svoje konto/účet (ďalej ako „Používateľ”).",
    terms_of_service_I_4: "Prevádzkovateľom aplikácie je spoločnosť Extropian s.r.o., so sídlom Idanská 775/27 040 11 Košice - mestská časť Západ, IČO: 53179927, zapísaná v obchodnom registri Okresného súdu Košice I, oddiel: Sro, vložka č. 49528/V (ďalej ako „Prevádzkovateľ“).",
    terms_of_service_I_5: "Tieto podmienky používania predstavujú vzájomnú dohodu medzi Prevádzkovateľom a Používateľom týkajúcu sa používania Aplikácie a poskytovania služieb. Používateľ zadaním požadovaných registračných údajov v Aplikácií, teda zaregistrovaním sa súhlasí s podmienkami stanovenými v týchto podmienkach používania, čím medzi Prevádzkovateľom a Používateľom dôjde k uzavretiu zmluvy na používanie Aplikácie v zmysle podmienok uvedených v týchto podmienkach používania a Používateľovi vznikne oprávnenie používať Aplikáciu v súlade s týmito podmienkami používania.",
    terms_of_service_I_6: "Aplikácia je autorským dielom Prevádzkovateľa chránená v zmysle príslušných ustanovení zákona 185/2015 Z. z. Autorský zákon v znení neskorších predpisov (ďalej ako „Autorský zákon”). Prevádzkovateľ je výlučným vlastníkom všetkých majetkových práv a ostatných práv duševného vlastníctva k Aplikácii. Akceptovaním týchto podmienok používania a využívaním služieb Aplikácie Používateľ nenadobúda akékoľvek majetkové práva alebo iné práva duševného vlastníctva k Aplikácii (najmä nie právo upravovať, meniť, zasahovať do Aplikácie, spracovať, adaptovať a vytvárať odvodené diela, vytvárať kópie Aplikácie a šíriť ich). Aplikácia a všetky jej súčasti a funkcionality, vrátane grafických prvkov, ich rozloženia, textov, rozhraní a iných súčastí sú chránené podľa práva Slovenskej republiky v oblasti práv duševného vlastníctva najmä Autorským zákonom. Akékoľvek použitie Aplikácie inak ako v súlade s týmito podmienkami používania si vyžaduje písomný súhlas Prevádzkovateľa.",
    terms_of_service_I_7: "Prevádzkovateľ udeľuje Používateľovi licenciu na používanie Aplikácie, a to obmedzenú, nevýhradnú, odvolateľnú, neprevoditeľnú, časovo obmedzenú po dobu trvania registrácie v Aplikácii. Licencia sa udeľuje na používanie Aplikácie v súlade s jej určením a s podmienkami uvedenými v týchto podmienkach používania. Používateľ nie je oprávnený na iné použitie obsahu v Aplikácii, najmä nesmie kopírovať, upravovať, meniť, zasahovať do Aplikácie, vytvárať odvodené diela, distribuovať, licencovať, predávať, prenášať, verejne zobrazovať, verejne vykonávať, verejne prenášať, vysielať alebo inak využívať Aplikáciu alebo jej obsah, s výnimkami výslovne dohodnutými a akceptovanými v týchto podmienkach používania.",
    terms_of_service_I_8: "Prevádzkovateľ spracúva a používa osobné údaje kontaktnej osoby Používateľa v súvislosti s používaním Aplikácie v súlade s Vyhlásením o ochrane osobných údajov (Privacy Policy), ktoré sa nachádza v Aplikácii.",
    terms_of_service_II: "Poplatky a platobné podmienky",
    terms_of_service_II_1: "Poplatok za používanie Aplikácie je uvedený priamo v Aplikácii a jeho výška závisí od výberu služby Používateľom. Poplatok sa uhrádza mesačne spôsobom predplatného, ktoré je pravidelne fakturované vopred a je nevratné, a to takým spôsobom, že prvé fakturačné obdobie začína dňom nastavenia spustenia služby, trvá jeden mesiac a končí  dňom, ktorý sa číslom zhoduje s dňom objednania služby v nasledujúcom mesiaci (napríklad od 11.11.2020 do 11.12.2020). To znamená, že platby budú vykonávané v rovnaký deň každý mesiac. Ak takýto deň v danom mesiaci neexistuje, posledný deň daného kalendárneho mesiaca bude považovaný za dátum platby predplatného (poplatku). V prípade, ak deň konca fakturačného obdobia nie je zhodný s dňom začiatku fakturačného obdobia postupuje sa nasledovne, uvádzame príklad: predplatné začínajúce 31. januára končí 28. februára (alebo 29. februára v priestupnom roku), potom predplatné začínajúce 31. marca končí 30. apríla. V niektorých prípadoch sa dátum platby poplatku (predplatného) môže zmeniť, napríklad ak sa vybraný spôsob platby Používateľa nepodarilo úspešne vykonať. Ak sa poplatok nepodarilo úspešne uhradiť z dôvodu vypršania platnosti platobnej karty alebo z dôvodu nedostatku finančných prostriedkov na platobnej karte, a ak Používateľ svoje konto nezruší, Prevádzkovateľ môže Používateľovi pozastaviť prístup k objednanej službe v Aplikácii, kým sa úspešne naúčtuje poplatok za dané obdobie, prípadne kým nebude poplatok uhradený prevodom na účet Prevádzkovateľa.",
    terms_of_service_II_2: "Počas obdobia predplatenej vybranej služby bude Používateľovi poskytnutá licencia na používanie Aplikácie na účel, na ktorý Aplikácia slúži.",
    terms_of_service_II_3: "V prípade, ak Prevádzkovateľ nevyužije Aplikáciu počas celého obdobia uhradeného predplatného nebude mu vrátený uhradený poplatok za dané obdobie.",
    terms_of_service_II_4: "Prevádzkovateľ je oprávnený výšku poplatku jednostranne zmeniť. Zmena výšky poplatku sa považuje za oznámenú Používateľovi momentom jej zverejnenia v Aplikácii a účinnosť nadobúda dňom zverejnenia v Aplikácii prípadne iný deň, ktorý bude zverejnený.",
    terms_of_service_II_5: "Poplatok za poskytovanie služieb Aplikácie je možné uhradiť prostredníctvom platobnej karty alebo prevodom na účet Prevádzkovateľa, ktorý je uvedený v Aplikácii ako aj na vystavenej faktúre. Predplatné bude z platobnej karty Používateľa zúčtované v deň vypršania platnosti súčasného predplatného služby, pokiaľ je predplatné stále aktívne a Používateľ ho nezruší. V prípade, ak Používateľ uhrádza poplatok/predplatné prostredníctvom prevodu na účet Prevádzkovateľa, je povinný ho uhradiť v deň splatnosti uvedený na faktúre/v deň vypršania platnosti súčasného predplatného služby.",
    terms_of_service_II_6: "Prevádzkovateľ bude vystavovať Používateľovi faktúru za každé fakturačné obdobie. Faktúra musí obsahovať predpísané náležitosti v zmysle všeobecne záväzných právnych predpisov.",
    terms_of_service_II_7: "Ak sa Používateľ rozhodne pre výber inej verzie služby pre svoje konto, môže tak urobiť kedykoľvek a vybraná služba mu bude aktivovaná nasledujúce fakturačné obdobie. Od nasledujúceho fakturačného obdobia bude Používateľovi účtovaná čiastka zodpovedajúca jeho novému výberu služby.",
    terms_of_service_III: "Prihlásenie do aplikácie, používanie aplikácie a vyhlásenia",
    terms_of_service_III_1: "Používateľ zaregistrovaním sa do Aplikácie a jej používaním vyslovuje súhlas s týmito podmienkami používania a zaväzuje sa ich dodržiavať. Ak Používateľ nesúhlasí s týmito podmienkami používania nemôže používať Aplikáciu a využívať jej služby.",
    terms_of_service_III_2: "Na získanie konta/účtu a používanie Aplikácie je potrebné sa najskôr zaregistrovať. Úspešným ukončením registrácie v Aplikácii si Používateľ vytvorí vlastné konto, ktoré je chránené jedinečným prihlasovacím menom a heslom.",
    terms_of_service_III_3: "Používateľ súhlasí, že bude vo svojom konte udržiavať úplné, pravdivé a aktuálne informácie a údaje. V prípade, ak Používateľ nebude mať uvedené vo svojom konte úplné, pravdivé a aktuálne informácie a údaje, nebude môcť používať Aplikáciu a Prevádzkovateľ je oprávnený jeho konto zrušiť.",
    terms_of_service_III_4: "Používateľ vyhlasuje, že ním zadané registračné údaje v Aplikácii sú úplné, pravdivé a aktuálne a k jeho registrácii a tým k uzavretiu zmluvy došlo konaním osoby oprávnenej zaňho konať. Používateľ je povinný poskytovať Prevádzkovateľovi úplné, pravdivé a aktuálne informácie a údaje. Prevádzkovateľ môže, avšak nie je povinný, vykonať kontrolu, ktorej účelom bude zistenie a overenie identity Používateľa. Prevádzkovateľ nie je zodpovedný za overenie identity, dôveryhodnosť a konanie Používateľa.",
    terms_of_service_III_5: "Používateľ registráciou v Aplikácii a jej používaním vyhlasuje, že je oprávnený na používanie Aplikácie v súlade s týmito podmienkami používania.",
    terms_of_service_III_6: "Používateľ nemôže postúpiť ani inak previesť svoje konto na inú fyzickú alebo právnickú osobu. Používateľ súhlasí a zároveň vyhlasuje, že pri používaní Aplikácie bude dodržiavať všetky platné právne predpisy Slovenskej republiky a tieto podmienky používania a bude používať Aplikáciu len na zákonné účely.",
    terms_of_service_III_7: "Prevádzkovateľ sa zaväzuje prevádzkovať Aplikáciu v súlade so všeobecne záväznými právnymi predpismi SR a zaväzuje sa zabezpečiť funkčnosť Aplikácie s výnimkou prípadov nezávislých na vôli Prevádzkovateľa. Prevádzkovateľ si vyhradzuje právo na technickú odstávku systému z dôvodov jeho údržby a nasadzovania nových funkcionalít, pričom o takýchto technických odstávkach bude informovať Používateľa formou oznámenia priamo v Aplikácii. Používateľ nemá žiadny nárok na náhradu škody spôsobenej nefunkčnosťou Aplikácie.",
    terms_of_service_III_8: "Používateľ súhlasí s tým, že Prevádzkovateľ môže spustiť a zavádzať aktualizácie Aplikácie a poskytovania služieb, a že tieto aktualizácie môžu viesť k zmenám vzhľadu alebo funkčnosti Aplikácie. Prevádzkovateľ bude poskytovať, implementovať, inštalovať a udržiavať na svoje náklady všetky aktualizácie, vylepšenia, opravy a úpravy Aplikácie.",
    terms_of_service_III_9: "Používateľ je povinný postupovať pri používaní Aplikácie tak, aby nedošlo k neoprávnenému sprístupneniu jeho konta osobe, ktorá k tomu nie je oprávnená a je povinný vykonať všetky opatrenia, aby zamedzil úniku prihlasovacích údajov a hesiel, inak zodpovedá za škodu tým spôsobenú Prevádzkovateľovi, iným Používateľom alebo tretej osobe. V prípade neoprávneného použitia konta alebo prihlasovacieho mena a hesla Používateľa, je Používateľ povinný okamžite upovedomiť o tejto skutočnosti Prevádzkovateľa. Používateľ v plnom rozsahu zodpovedá za akékoľvek činnosti realizované pod jeho prihlasovacím menom a heslom v rámci jeho konta.",
    terms_of_service_III_10: "Používateľ sa zaväzuje a vyhlasuje, že nebude Aplikáciu používať spôsobom, ktorým by porušoval práva Prevádzkovateľa k Aplikácii prípadne poškodzoval jeho dobré meno a záujmy ako aj práva iných Používateľov. Použitie Aplikácie na iný účel ako je uvedený v týchto podmienkach používania je zakázané. Používateľ vyhlasuje, že nebude používať Aplikáciu na účely, ktoré sú v rozpore so všeobecne záväznými právnymi predpismi SR a týmito podmienkami používania. Porušenie povinnosti v zmysle tohto ustanovenia sa považuje za podstatné porušenie týchto podmienok používania.",
    terms_of_service_III_11: "Používateľ súhlasí, že v prípade chýb alebo vád Aplikácie a jej funkcionalít sú jeho nároky na odstránenie takýchto chýb alebo vád obmedzené, pričom k ich odstráneniu dôjde v primeranom čase a spôsobom stanoveným Prevádzkovateľom, predovšetkým formou aktualizácie danej funkcionality alebo sprístupnením novšej verzie Aplikácie.",
    terms_of_service_III_12: "Pokiaľ sa ktorékoľvek vyhlásenie Používateľa v zmysle týchto podmienok používania ukáže ako nepravdivé alebo sa takým stane a v tejto súvislosti budú voči Prevádzkovateľovi uplatnené akékoľvek nároky tretích osôb alebo uložené sankcie zo strany orgánov verejnej moci, Používateľ sa zaväzuje Prevádzkovateľovi v plnej miere nahradiť akúkoľvek vzniknutú škodu na prvé požiadanie a bez obmedzenia, a to vrátane akýchkoľvek nákladov Prevádzkovateľa, ktoré vznikli v dôsledku uplatnenia nárokov tretích osôb alebo uloženia sankcií zo strany orgánov verejnej moci alebo v súvislosti s nimi. Poskytnutie nepravdivých vyhlásení Používateľa v zmysle týchto podmienok používania sa považuje za podstatné porušenie týchto podmienok používania.",
    terms_of_service_III_13: "Používateľ si je vedomý skutočnosti a súhlasí, že akýkoľvek nárok, ktorý mu vznikne v súvislosti s konaním/nekonaním iného Používateľa prípadne tretích osôb, bude obmedzený len na nárok voči tomuto Používateľovi prípadne tretej osobe a nebude ho uplatňovať voči Prevádzkovateľovi.",
    terms_of_service_III_14: "Používateľ vyhlasuje, že nebude:",
    terms_of_service_III_14_1: "používať konto iného Používateľa,",
    terms_of_service_III_14_2: "sa pri používaní Aplikácie vydávať za iný subjekt,",
    terms_of_service_III_14_3: "narúšať alebo poškodzovať Aplikáciu,",
    terms_of_service_III_14_4: "uverejňovať alebo používať softvérové vírusy alebo akékoľvek iné počítačové kódy, súbory/ programy, ktoré môžu ohroziť, prerušiť, obmedziť alebo zastaviť funkčnosť Aplikácie a poskytovanie služieb,",
    terms_of_service_III_14_5: "používať automatické skripty pre zber informácií, zbierať/kopírovať e-mailové adresy alebo iné kontaktné/osobné údaje Používateľov,",
    terms_of_service_III_14_6: "žiadnym spôsobom ohrozovať ani poškodzovať Prevádzkovateľa a Aplikáciu,",
    terms_of_service_III_14_7: "používať Aplikáciu spôsobom, ktorý porušuje platné právne predpisy SR alebo tieto podmienky používania.",
    terms_of_service_III_15: "Používateľ je zodpovedný za obsah, ktorý nahráva, vytvorí, upraví alebo zdieľa počas používania Aplikácie a za činnosti (vrátane akejkoľvek straty alebo poškodenia) s tým spojené.",
    terms_of_service_IV: "Zodpovednosť",
    terms_of_service_IV_1: "Prevádzkovateľ nenesie žiadnu zodpovednosť za škodu/ujmu, ktorá by mohla byť spôsobená Používateľovi v súvislosti s používaním Aplikácie.",
    terms_of_service_IV_2: "Používateľ je zodpovedný za všetky činnosti, ktoré sa vykonávajú pod jeho kontom a súhlasí, že bude vždy zachovávať bezpečnosť a dôvernosť svojho používateľského mena a hesla ku kontu. Používateľ je povinný bezodkladne informovať Prevádzkovateľa o akomkoľvek neoprávnenom použití jeho konta. Prevádzkovateľ nezodpovedá za žiadnu ujmu spôsobenú neoprávneným použitím konta Používateľa.",
    terms_of_service_IV_3: "Prevádzkovateľ nezodpovedá za nepretržitú a bezchybnú funkčnosť Aplikácie. Prevádzkovateľ môže kedykoľvek ukončiť fungovanie Aplikácie s okamžitou účinnosťou a to bez uvedenia dôvodu a bez oznámenia Používateľom.",
    terms_of_service_IV_4: "Prevádzkovateľ nezodpovedá za správnosť, presnosť a aktuálnosť údajov Používateľa v Aplikácii. Za správnosť, presnosť a aktuálnosť údajov zodpovedá výlučne Používateľ, ktorý sa registroval a vložil údaje do konta v Aplikácii.",
    terms_of_service_IV_5: "Prevádzkovateľ nenesie zodpovednosť za bezpečnosť a za ochranu údajov, ktoré poskytuje Používateľ v rámci Aplikácie. Vyhlásenie o ochrane osobných údajov (Privacy Policy) je uvedené v Aplikácii. Používateľ nie je zodpovedný za žiadne škody alebo straty nahraných údajov Používateľom spôsobené jeho aktivitami, technickými poruchami, zlyhaním alebo aktivitami tretích strán.",
    terms_of_service_IV_6: "Prevádzkovateľ nenesie zodpovednosť za žiadne nepriame, následné, náhodné škody Používateľa, vrátane ušlých ziskov alebo výnosov Používateľa. Prevádzkovateľ nie je zodpovedný za konanie, opomenutie a správanie tretích strán súvisiace s používaním Aplikácie Používateľom. V prípade nespokojnosti Používateľa je Používateľ oprávnený ukončiť využívanie služieb Aplikácie.",
    terms_of_service_V: "Komunikácia",
    terms_of_service_V_1: "Prevádzkovateľ môže Používateľovi zasielať oznámenia prostredníctvom všeobecného oznamu v Aplikácii, prostredníctvom e-mailu na e-mail adresu Používateľa uvedenú v jeho konte. Oznámenia môže Používateľ zasielať Prevádzkovateľovi na jeho e-mail adresu: hello@crowdea.co.",
    terms_of_service_V_2: "Prevádzkovateľ aj Používateľ sú oprávnení jednostranne meniť svoje kontaktné údaje. Zmeny na strane Prevádzkovateľa budú zverejnené v Aplikácii.",
    terms_of_service_VI: "Náhrada škody",
    terms_of_service_VI_1: "Používateľ výslovne súhlasí a zaväzuje sa nahradiť škodu spôsobenú Prevádzkovateľovi, ktorá mu vznikne v súvislosti s používaním Aplikácie Používateľom, najmä, ktorá bude súvisieť s porušením týchto podmienok používania zo strany Používateľa, porušením platných právnych predpisov SR v súvislosti s používaním Aplikácie Používateľom, a to v celom rozsahu na prvé požiadanie a bez obmedzenia.",
    terms_of_service_VII: "Zrušenie konta a obmedzenie prístupu k aplikácii",
    terms_of_service_VII_1: "Okrem prípadov uvedených v týchto podmienkach používania môže Prevádzkovateľ v prípade nedodržiavania týchto podmienok používania kedykoľvek obmedziť, pozastaviť alebo zrušiť konto Používateľa, a to aj bez predchádzajúceho upozornenia Používateľa, s čim Používateľ výslovne súhlasí. V tom prípade nebude mať Používateľ nárok na akúkoľvek kompenzáciu.",
    terms_of_service_VII_2: "Používateľ si môže kedykoľvek zrušiť svoje konto v Aplikácii prípadne požiadať Prevádzkovateľa o zrušenie konta. Používateľovi nebude účtovaný žiadny storno poplatok. Uhradené poplatky za využívanie služieb Aplikácie sú nevratné.",
    terms_of_service_VIII: "Záverečné ustanovenia",
    terms_of_service_VIII_1: "Vzťah medzi Používateľom a Prevádzkovateľom sa spravuje týmito podmienkami používania a všeobecne záväznými právnymi predpismi SR, najmä zákonom č. 513/1991 Zb. Obchodný zákonník, v znení neskorších predpisov.",
    terms_of_service_VIII_2: "Všetky prípadné spory, ktoré vzniknú zo zmluvného vzťahu medzi Prevádzkovateľom a Používateľom alebo v súvislosti s používaním Aplikácie sa Používateľ a Prevádzkovateľ zaväzujú riešiť najmä vzájomnou dohodou, a až v prípade, že nedôjde k dohode medzi Používateľom a Prevádzkovateľom bude sa spor riešiť súdnou cestou pred príslušným súdom v Slovenskej republike.",
    terms_of_service_VIII_3: "Používateľ nie je oprávnený previesť svoje práva a povinnosti súvisiace s používaním služieb Aplikácie na tretiu osobu bez predchádzajúceho súhlasu Prevádzkovateľa.",
    terms_of_service_VIII_4: "V prípade, ak bude niektoré ustanovenie týchto podmienok používania neplatné alebo neúčinné, nemá táto skutočnosť vplyv na platnosť a účinnosť ostatných ustanovení podmienok používania. V takom prípade Prevádzkovateľ nahradí neplatné alebo neúčinné ustanovenie alebo jeho časť platným/účinným ustanovením vzhľadom na obsah a účel týchto podmienok používania, pričom ostatné ustanovenia týchto podmienok používania zostávajú v platnosti.",
    terms_of_service_VIII_5: "Prevádzkovateľ je oprávnený tieto podmienky používania kedykoľvek jednostranne zmeniť. Prevádzkovateľ bude Používateľa informovať o zmenách podmienok používania, a to oznámením priamo v Aplikácii. Zmeny podmienok používania nadobudnú účinnosť dňom zverejnenia ich nového znenia v Aplikácii, prípadne môže byť v novom znení podmienok používania uvedený neskorší dátum nadobudnutia účinnosti. V prípade, ak Používateľ bude naďalej používať Aplikáciu po nadobudnutí účinnosti nového znenia podmienok používania, znamená to, že súhlasí s ich novým znením a zaväzuje sa ich dodržiavať. V prípade nesúhlasu Používateľa s novým znením podmienok používania, je Používateľ povinný ukončiť používanie Aplikácie a zrušiť si konto, prípadne požiadať Prevádzkovateľa o zrušenie konta.",
    terms_of_service_VIII_6: "Slovenská verzia má vždy prednosť v prípade rozporu medzi slovenskou verziou a jej anglickým prekladom.",
    terms_of_service_VIII_7: "Tieto podmienky používania nadobúdajú platnosť a účinnosť dňa 1.11.2020.",
    privacy_policy_headline: "Vyhlásenie o ochrane osobných údajov – privacy policy",
    privacy_policy_I: "Identifikácia prevádzkovateľa a príslušné právne predpisy",
    privacy_policy_I_1: "Extropian s.r.o., so sídlom Idanská 775/27 040 11 Košice - mestská časť Západ, IČO: 53179927, zapísaná v obchodnom registri Okresného súdu Košice I, oddiel: Sro, vložka č. 49528/V (ďalej ako „Prevádzkovateľ”) je pri spracúvaní osobných údajov Dotknutých osôb zodpovedná za dodržiavanie právnych predpisov o ochrane osobných údajov, a to najmä Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov)  (ďalej aj ako „GDPR”) a zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov (ďalej aj ako „zákon o ochrane osobných údajov”).",
    privacy_policy_II: "Spracovanie osobných údajov",
    privacy_policy_II_1: "Prevádzkovateľ spracúva osobné údaje od kontaktných osôb spoločností (ďalej ako „Dotknuté osoby“ alebo „Dotknutá osoba“), ktoré sa zaregistrujú a používajú po registrácií aplikáciu Crowdea slúžiacu ako platforma pre crowdsourcing nápadov a manažment inovácií (ďalej ako „Aplikácia“).",
    privacy_policy_II_2: "Prevádzkovateľ spracúva nasledujúce osobné údaje Dotknutých osôb:",
    privacy_policy_II_2_1: "Meno a priezvisko",
    privacy_policy_II_2_2: "E-mailová adresa",
    privacy_policy_II_3: "Prevádzkovateľ spracúva osobné údaje Dotknutých osôb výlučne spôsobom, ktorý zodpovedá účelu, na ktorý boli zhromaždené a v súlade so zákonom o ochrane osobných údajov. Hlavným účelom spracúvania osobných údajov uvedených v bode 2 tohto článku je registrácia spoločnosti v Aplikácii. Prevádzkovateľ spracúva osobné údaje na základe súhlasu Dotknutej osoby (v zmysle § 13 ods. 1 písm. a) zákona o ochrane osobných údajov).",
    privacy_policy_II_4: "Väčšina osobných údajov, ktoré Dotknuté osoby poskytujú prostredníctvom Aplikácie Prevádzkovateľovi sú poskytované na dobrovoľnej báze, ktorá si vyžaduje aktívne a dobrovoľné konanie zo strany Dotknutých osôb.",
    privacy_policy_II_5: "Osobné údaje Dotknutých osôb Prevádzkovateľ nebude poskytovať tretím osobám.",
    privacy_policy_II_6: "Osobné údaje Dotknutých osôb môžu byť poskytnuté orgánom verejnej moci, resp. verejným inštitúciám, a to najmä v prípade:",
    privacy_policy_II_6_1: "plnenia zákonnej povinnosti Prevádzkovateľa v rozsahu podľa príslušných právnych predpisov",
    privacy_policy_II_6_2: "preukazovania/uplatňovania právnych nárokov Prevádzkovateľa",
    privacy_policy_II_6_3: "prevencie, odhaľovania, vyšetrovania a stíhania protiprávneho konania",
    privacy_policy_III: "Vyhlásenie prevádzkovateľa a ochrana osobných údajov dotknutých osôb",
    privacy_policy_III_1: "Prevádzkovateľovi záleží na ochrane osobných údajov Dotknutých osôb a dodržiava pravidlá ochrany osobných údajov v súlade s platnými právnymi predpismi o ochrane osobných údajov. Prevádzkovateľ prostredníctvom tohto Vyhlásenia o ochrane osobných údajov poskytuje Dotknutým osobám komplexné informácie podľa zákona o ochrane osobných údajov, ako aj ďalšie dôležité informácie a vysvetlenia za účelom vytvorenia transparentnosti, a to aj s ohľadom na článok 13 a 14 GDPR.",
    privacy_policy_III_2: "Prevádzkovateľ vyhlasuje, že prijal primerané technické, organizačné a bezpečnostné opatrenia za účelom zabezpečenia bezpečnosti spracúvania osobných údajov Dotknutých osôb. Hlavne prijal také opatrenia aby boli osobné údaje Dotknutých osôb, v čo najväčšej miere chránené pred neželaným prístupom. Ochrana osobných údajov bude aktualizovaná primerane aktuálnemu stavu techniky.",
    privacy_policy_III_3: "Prevádzkovateľ garantuje Dotknutým osobám, že nepredá a ani komerčne nevyužije osobné údaje, ktoré získal od Dotknutých osôb v rámci obchodnej spolupráce s akoukoľvek treťou osobou a nezverejní žiadne osobné údaje získané od Dotknutých osôb bez toho, aby Dotknuté osoby vyjadrili svoj individuálny písomný súhlas.",
    privacy_policy_III_4: "Prevádzkovateľ bude spracúvať osobné údaje Dotknutých osôb s použitím rozhodnutia založeného na automatizovaných, čiastočne automatizovaných prostriedkoch a iných ako automatizovaných prostriedkoch spracúvania osobných údajov a tzv. profilovanie, a to v závislosti od účelu spracúvania osobných údajov. V tomto prípade má Dotknutá osoba právo na to, aby sa na ňu nevzťahovalo rozhodnutie, ktoré je založené výlučne na automatizovanom spracúvaní osobných údajov vrátane profilovania. Uvedené neplatí, v prípade ak Dotknutá osoba udelila Prevádzkovateľovi výslovný súhlas vo vzťahu k spracúvaniu jej osobných údajov a uplatňovania rozhodnutia založeného na automatizovaných prostriedkoch spracúvania osobných údajov a tzv. profilovania.  Prevádzkovateľ poskytne Dotknutým osobám informácie o použitom postupe a význame a predpokladaných dôsledkoch takého spracúvania osobných údajov.",
    privacy_policy_IV: "Doba uchovávania osobných údajov dotknutých osôb",
    privacy_policy_IV_1: "Prevádzkovateľ uchováva osobné údaje Dotknutých osôb po dobu používania Aplikácie, teda po dobu, ktorú sú spoločnosti registrované v Aplikácii. Akonáhle si spoločnosť zruší konto v Aplikácii, to znamená, že ju prestane definitívne používať, prípadne Prevádzkovateľ zruší konto spoločnosti, budú osobné údaje Dotknutej osoby vymazané. Údaje, ktoré bude v zmysle osobitnej zákonnej povinnosti potrebné uchovávať po dlhšiu dobu, budú uchovávané len na nevyhnutnú dobu v zmysle príslušného osobitného zákona.",
    privacy_policy_V: "Cookies",
    privacy_policy_V_1: "Prevádzkovateľ bude v Aplikácii využívať súbory typu cookies, pričom sú spracúvané výlučne štatistické údaje, ktoré nemajú povahu osobných údajov a sú získavané výlučne za účelom získavania prehľadu a bližších informáciách o návštevnosti Aplikácie a môžu slúžiť aj na marketingové účely. Používanie cookies počas návštevy Aplikácie je na rozhodnutí Dotknutých osôb.",
    privacy_policy_VI: "Cezhraničný prenos osobných údajov",
    privacy_policy_VI_1: "Prevádzkovateľ nebude vykonávať žiadny cezhraničný prenos osobných údajov Dotknutých osôb do tretej krajiny, ktorá nezaručuje primeranú úroveň ochrany osobných údajov.",
    privacy_policy_VI_2: "Osobné údaje Dotknutých osôb, ktoré budú spracúvané nebudú prenesené z územia členských štátov Európskej únie do tretích krajín.",
    privacy_policy_VII: "Práva dotknutých osôb",
    privacy_policy_VII_1: "Právo na informácie - Dotknutá osoba má právo od Prevádzkovateľa kedykoľvek požadovať informácie o poskytnutých osobných údajoch, ktoré Prevádzkovateľ spracúva, o ich pôvode, o účele spracúvania osobných údajov, kategórii spracúvania osobných údajov, identifikácii príjemcu alebo o kategórii príjemcu, ktorému boli osobné údaje poskytnuté a dobe uchovávania osobných údajov, ak to nie je možné, informácie o kritériách jej určenia.",
    privacy_policy_VII_2: "Právo na prístup k osobným údajom - Dotknutá osoba má právo na prístup k jej osobným údajom a právo na poskytnutie jej osobných údajov. V tomto prípade, ak by vyžadovala Dotknutá osoba prístup k osobným údajom, Prevádzkovateľ je bezodkladne povinný jej takýto prístup umožniť. Prípadne si svoje osobné údaje vie Dotknutá osoba nájsť priamo v Aplikácii vo svojom konte. Za opakované poskytnutie osobných údajov, o ktoré Dotknutá osoba požiada, môže Prevádzkovateľ účtovať primeraný poplatok zodpovedajúci administratívnym nákladom. Prevádzkovateľ je povinný poskytnúť osobné údaje Dotknutej osobe spôsobom podľa jej požiadavky.",
    privacy_policy_VII_3: "Právo na odvolanie súhlasu so spracovaním osobných údajov - V prípade, ak Dotknutá osoba udelila súhlas so spracovaním osobných údajov je oprávnená ho kedykoľvek odvolať, a to bez uvedenia dôvodov a s okamžitou účinnosťou. V takom prípade Dotknutá osoba môže odvolanie súhlasu zaslať na e-mailovú adresu Prevádzkovateľa: hello@crowdea.co. V prípade odvolania súhlasu Dotknutej osoby, Prevádzkovateľ ukončí spracúvanie osobných údajov Dotknutej osoby a osobné údaje budú vymazané, ak nebude existovať iný právny základ pre ich ďalšie spracúvanie. Odvolanie súhlasu so spracovaním osobných údajov nebude mať vplyv na zákonnosť spracúvania vychádzajúceho zo súhlasu pred jeho odvolaním.",
    privacy_policy_VII_4: "Právo na opravu osobných údajov - Dotknutá osoba má právo na opravu nesprávnych osobných údajov, ktoré sa jej týkajú. Dotknutá osoba má právo aj na doplnenie svojich neúplných osobných údajov. V prípade, ak sú uchovávané údaje nesprávne alebo neúplné, môže ich Dotknutá osoba kedykoľvek opraviť vo svojom konte, prípadne túto skutočnosť oznámi priamo Prevádzkovateľovi na e-mailovú adresu Prevádzkovateľa uvedenú na konci tohto vyhlásenia a Prevádzkovateľ osobné údaje Dotknutej osoby bezodkladne opraví, prípadne ich doplní.",
    privacy_policy_VII_5: "Právo na výmaz osobných údajov - Dotknutá osoba má právo na výmaz osobných údajov, ktoré Prevádzkovateľ spracúva. Prevádzkovateľ je povinný bez zbytočného odkladu vymazať osobné údaje Dotknutej osoby, ak Dotknutá osoba uplatnila právo na výmaz, ak:",
    privacy_policy_VII_5_a: "osobné údaje už nie sú potrebné na účel, na ktorý sa získali alebo inak spracúvali,",
    privacy_policy_VII_5_b: "Dotknutá osoba odvolá svoj súhlas, na základe ktorého sa spracúvanie osobných údajov vykonáva, a neexistuje iný právny základ pre spracúvanie osobných údajov,",
    privacy_policy_VII_5_c: "Dotknutá osoba namieta spracúvanie osobných údajov podľa § 27 ods. 1 a 2 zákona o ochrane osobných údajov,",
    privacy_policy_VII_5_d: "osobné údaje Dotknutej osoby sa spracúvajú nezákonne.",
    privacy_policy_VII_5_2: "Prevádzkovateľ bez zbytočného odkladu, najneskôr do jedného mesiaca po uplatnení tohto práva vymaže osobné údaje, ktoré sa týkajú Dotknutej osoby. Vymazanie osobných údajov Dotknutej osoby sa uskutoční na základe žiadosti Dotknutej osoby, ktorú Dotknutá osoba zašle na e-mailovú adresu Prevádzkovateľa uvedenú na konci tohto vyhlásenia. Ak výmaz osobných údajov Dotknutej osoby odporuje zákonným povinnostiam na uchovanie údajov, osobné údaje Dotknutej osoby nebudú vymazané. Právo na výmaz osobných údajov sa neuplatní v prípadoch uvedených v § 23 ods. 4 zákona o ochrane osobných údajov.",
    privacy_policy_VII_6: "Právo na obmedzenie spracúvania osobných údajov - Dotknutá osoba má právo na obmedzenie spracúvania osobných údajov Prevádzkovateľom, ak:",
    privacy_policy_VII_6_a: "Dotknutá osoba namieta správnosť osobných údajov, a to počas obdobia umožňujúceho Prevádzkovateľovi overiť správnosť osobných údajov,",
    privacy_policy_VII_6_b: "spracúvanie osobných údajov je nezákonné a Dotknutá osoba namieta vymazanie osobných údajov a žiada namiesto toho obmedzenie ich použitia,",
    privacy_policy_VII_6_c: "Prevádzkovateľ už nepotrebuje osobné údaje na účel spracúvania osobných údajov, ale potrebuje ich Dotknutá osoba na uplatnenie právneho nároku, alebo",
    privacy_policy_VII_6_d: "Dotknutá osoba namieta spracúvanie osobných údajov podľa § 27 ods. 1 zákona o ochrane osobných údajov, a to až do overenia, či oprávnené dôvody na strane Prevádzkovateľa prevažujú nad oprávnenými dôvodmi Dotknutej osoby.",
    privacy_policy_VII_7: "Právo na prenosnosť osobných údajov - V prípade, ak Dotknutá osoba požaduje od Prevádzkovateľa svoje osobné údaje, ktoré Prevádzkovateľ spracúva, Prevádzkovateľ tieto osobné údaje Dotknutej osobe prípadne inej osobe, ktorá je prevádzkovateľom vydá alebo prenesie, a to v štruktúrovanom a bežne používanom čitateľnom formáte. Uvedené platí len v prípade, ak je to technicky možné.",
    privacy_policy_VII_8: "Právo namietať spracúvanie osobných údajov - Dotknutá osoba má právo namietať spracúvanie osobných údajov na účel priameho marketingu vrátane profilovania. Dotknutá osoba má právo namietať spracúvanie osobných údajov, ktoré sa jej týkajú, z dôvodov týkajúcich sa jej konkrétnej situácie. V prípade takejto námietky Prevádzkovateľ nebude ďalej spracúvať osobné údaje Dotknutej osoby, pokiaľ nebudú dané nevyhnutné oprávnené dôvody na ich ďalšie spracúvanie alebo dôvody na preukazovanie/uplatňovanie právnych nárokov Prevádzkovateľa.",
    privacy_policy_VII_9: "Právo nebyť subjektom automatizovaného individuálneho rozhodovania, vrátane profilovania - Dotknutá osoba má právo, aby sa na ňu nevzťahovalo rozhodnutie, ktoré je založené výlučne na automatizovanom spracúvaní osobných údajov vrátane tzv. profilovania a ktoré má právne účinky, ktoré sa jej týkajú alebo ju obdobne významne ovplyvňujú. Toto právo dotknutej osoby sa neuplatní v prípade, ak je rozhodnutie:",
    privacy_policy_VII_9_a: "nevyhnutné na uzavretie alebo plnenie zmluvy medzi Dotknutou osobou a Prevádzkovateľom,",
    privacy_policy_VII_9_b: "vykonané na základe osobitného predpisu alebo medzinárodnej zmluvy, ktorou je SR viazaná, a v ktorých sú zároveň ustanovené aj vhodné opatrenia zaručujúce ochranu práv a oprávnených záujmov Dotknutej osoby,",
    privacy_policy_VII_9_c: "je založené na výslovnom súhlase Dotknutej osoby.",
    privacy_policy_VII_10: "Právo na podanie sťažnosti - V prípade, ak chce Dotknutá osoba podať sťažnosť, môže tak urobiť, zaslaním e-mailu na email adresu: hello@crowdea.co prípadne zaslaním sťažnosti na adresu: Idanská 775/27 040 11 Košice - mestská časť Západ.",
    privacy_policy_VII_11: "Právo podať návrh na začatie konania - Dotknutá osoba má pri podozrení o neoprávnenom spracúvaní jej osobných údajov Prevádzkovateľom právo podať návrh na začatie konania o ochrane osobných údajov Úradu na ochranu osobných údajov Slovenskej republiky, so sídlom Hraničná 4, 820 07 Bratislava, a to v zmysle § 100 zákona o ochrane osobných údajov.",
    privacy_policy_VIII: "Otázky ohľadom ochrany osobných údajov dotknutej osoby",
    privacy_policy_VIII_1: "V prípade, ak má Dotknutá osoba otázky týkajúce sa ochrany jej osobných údajov môže sa obrátiť na Prevádzkovateľa:",
    privacy_policy_VIII_2: "e-mailová adresa:",
    privacy_policy_VIII_3: "telefonický kontakt:",
    privacy_policy_VIII_4: "Slovenská verzia má vždy prednosť v prípade rozporu medzi slovenskou verziou a jej anglickým prekladom.",
    privacy_policy_VIII_5: "Toto Vyhlásenie o ochrane osobných údajov je platné a účinné od 1.11.2020.",
};
