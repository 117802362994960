import React from "react";
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import SelectLanguage from "../common/selectlanguage/SelectLanguageNavbar";
import {injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import capitalize from "@material-ui/core/utils/capitalize";
import NotificationsMenu from "./NotificationsMenu";
import LoggedInNavbar from "../common/accountDropdown/LoggedInNavbar";

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none'
    },
    badge: {
        '& span.MuiBadge-badge': {
            backgroundColor: theme.palette.secondary.main,
            color: 'white'
        },
    }
}));

const DashboardNavbarMenu = props => {

    const classes = useStyles();
    const {notifications, intl, markNotificationsAsRead} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return <>
        <Grid item>
            <Link to='/crowds' className={classes.link}>
                <Typography color='primary'>
                    {intl.formatMessage({id: 'crowds'})}
                </Typography>
            </Link>
        </Grid>
        <Grid item>
            <Link to='/ideas' className={classes.link}>
                <Typography color='primary'>
                    {capitalize(intl.formatMessage({id: 'ideas'}))}
                </Typography>
            </Link>
        </Grid>
        <Grid item style={{padding: 0}}>
            <IconButton color='primary' onClick={openMenu}>
                {notifications.unreadCount > 0 ?
                    <Badge badgeContent={notifications.unreadCount} className={classes.badge}>
                        <NotificationsIcon/>
                    </Badge> : <NotificationsIcon/>
                }
            </IconButton>
        </Grid>
        <Grid item style={{padding: 0}}>
            <SelectLanguage/>
        </Grid>
        <Grid item style={{padding: 0}}>
            <LoggedInNavbar/>
        </Grid>
        <NotificationsMenu handleClose={handleClose} anchorEl={anchorEl} notifications={notifications} markNotificationsAsRead={markNotificationsAsRead}/>
    </>
};

export default injectIntl(DashboardNavbarMenu);