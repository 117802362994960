import React, {Component} from "react";
import TextField from "@material-ui/core/TextField";
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import {emailRegex} from "../../constants";
import {passwordRules} from "../../utils/passwordRules";
import Button from "@material-ui/core/Button";
import {Link as RouterLink} from "react-router-dom";
import Link from '@material-ui/core/Link';
import {connect} from "react-redux";
import {changeLocale} from "../../actions/locale";
import {register, registrationErrored} from "../../actions/registration";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import ContactsIcon from '@material-ui/icons/Contacts';
import InputAdornment from "@material-ui/core/InputAdornment";
import ServiceAgreementDialog from "./ServiceAgreementDialog";
import {injectIntl} from "react-intl";
import SelectLanguageButton from "../common/selectlanguage/SelectLanguageButton";
import {withAnonymous} from "../common/withAnonymous";
import PrivacyPolicyDialog from "./PrivacyPolicyDialog";

const styles = (theme) => ({
    itemWidth: {
        width: '90%'
    },
    buttonTrayRight: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-end"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    buttonTrayLeft: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-start"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    buttonTrayCenter: {
        display: "flex",
        justifyContent: "center"
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: "10%",
        [theme.breakpoints.down('sm')]: {
            marginTop: "3vh",
            marginBottom: "3vh"
        },
    },
    crowdeaLogo: {
        height: '30px',
        margin: '20px 0 20px'
    },
    avatar: {
        backgroundColor: theme.palette.primary.main
    }
});

class Registration extends Component {

    classes = this.props.classes;

    state = {
        registrationInfo: {
            email: "",
            confirmEmail: "",
            name: "",
            password: "",
            confirmPassword: ""
        },
        validators: {
            email: (regInfo) => emailRegex.test(regInfo.email),
            confirmEmail: (regInfo) => regInfo.email === regInfo.confirmEmail,
            name: (regInfo) => "" !== regInfo.name,
            password: (regInfo) => passwordRules.validate(regInfo.password),
            confirmPassword: (regInfo) => regInfo.password === regInfo.confirmPassword
        },
        validationErrors: [],
        showTerms: false,
        showPrivacyPolicy: false
    };

    validateFormInput = () => {
        const userInput = this.state.registrationInfo;
        const validationErrors = Object.entries(this.state.validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                if (!validator(userInput)) {
                    acc.push(key);
                }
                return acc;
            }, []);

        this.setState({...this.state, validationErrors});
        return validationErrors.length === 0;
    };

    isInvalid = field => {
        return this.state.validationErrors.includes(field) ||
            this.props.registration.errors.hasOwnProperty(field);
    };

    validationErrorMessage = field => {

        if (this.state.validationErrors.includes(field)) {
            return this.props.intl.formatMessage({id: 'validation_registration_' + field});
        }

        if (this.props.registration.errors[field]) {
            return this.props.intl.formatMessage({id: this.props.registration.errors[field]});
        }

        return "";

    };

    handleFormInputChange = name => event => {
        const registrationInfo = {...this.state.registrationInfo, [name]: event.target.value};
        this.setState({...this.state, registrationInfo});
    };

    componentWillUnmount() {
        clearTimeout(this.id);
        this.props.clearRegistrationErrors();
    }

    isPrivateUserLogin() {
        if (this.props.crowdView.data.privateCrowd)
            return {crowdId: this.props.crowdView.data.id};
        return {};
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validateFormInput()) {
            this.props.register({
                ...this.state.registrationInfo,
                ...this.isPrivateUserLogin(),
                locale: this.props.currentLocale
            });
        }
    };

    showTermsOfService = event => {
        event.preventDefault();
        this.setState({
            ...this.state,
            showTerms: true
        });
    };

    showPrivacyPolicy = event => {
        event.preventDefault();
        this.setState({
            ...this.state,
            showPrivacyPolicy: true
        });
    };

    hideTermsOfService = () => {
        this.setState({
            ...this.state,
            showTerms: false
        });
    };

    hidePrivacyPolicy = () => {
        this.setState({
            ...this.state,
            showPrivacyPolicy: false
        });
    };

    render() {
        return (
            <>
                <Container maxWidth="sm">
                    <div className={this.classes.content}>
                        <Card elevation={3}>
                            <CardContent>
                                <form onSubmit={this.handleSubmit}>
                                    <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        alignItems="center"
                                        spacing={1}
                                    >
                                        <Grid item>
                                            <RouterLink to='/'>
                                                <img className={this.classes.crowdeaLogo} src="/images/crowdea.png"/>
                                            </RouterLink>
                                        </Grid>
                                        {this.props.registration.success && (
                                            <Grid item>
                                                <Typography align='center'>
                                                    {this.props.intl.formatMessage({id: 'registration_activation_link_sent'})}
                                                </Typography>
                                            </Grid>
                                        )}
                                        {!this.props.registration.success && (<>
                                            <Grid item>
                                                <Avatar className={this.classes.avatar}><PersonAddIcon/></Avatar>
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    {this.props.intl.formatMessage({id: 'register'})}
                                                </Typography>
                                            </Grid>
                                            <Grid item className={this.classes.itemWidth}>
                                                <TextField
                                                    variant="outlined"
                                                    label={this.props.intl.formatMessage({id: 'email'})}
                                                    type="email"
                                                    name="email"
                                                    autoComplete="email"
                                                    margin="dense"
                                                    fullWidth
                                                    error={this.isInvalid('email')}
                                                    helperText={this.validationErrorMessage('email')}
                                                    value={this.state.registrationInfo.email}
                                                    onChange={this.handleFormInputChange('email')}/>
                                            </Grid>
                                            <Grid item className={this.classes.itemWidth}>
                                                <TextField
                                                    variant="outlined"
                                                    label={this.props.intl.formatMessage({id: 'confirmEmail'})}
                                                    type="email"
                                                    name="confirmEmail"
                                                    autoComplete="email"
                                                    margin="dense"
                                                    fullWidth
                                                    error={this.isInvalid('confirmEmail')}
                                                    helperText={this.validationErrorMessage('confirmEmail')}
                                                    value={this.state.registrationInfo.confirmEmail}
                                                    onChange={this.handleFormInputChange('confirmEmail')}/>
                                            </Grid>
                                            <Grid item className={this.classes.itemWidth}>
                                                <TextField
                                                    variant="outlined"
                                                    label={this.props.intl.formatMessage({id: 'name'})}
                                                    name="name"
                                                    autoComplete="name"
                                                    margin="dense"
                                                    fullWidth
                                                    error={this.isInvalid('name')}
                                                    helperText={this.validationErrorMessage('name')}
                                                    value={this.state.registrationInfo.name}
                                                    onChange={this.handleFormInputChange('name')}/>
                                            </Grid>
                                            <Grid item className={this.classes.itemWidth}>
                                                <TextField
                                                    variant="outlined"
                                                    label={this.props.intl.formatMessage({id: 'password'})}
                                                    type="password"
                                                    name="password"
                                                    autoComplete="new-password"
                                                    margin="dense"
                                                    fullWidth
                                                    error={this.isInvalid('password')}
                                                    helperText={this.validationErrorMessage('password')}
                                                    value={this.state.registrationInfo.password}
                                                    onChange={this.handleFormInputChange('password')}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Tooltip
                                                                    disableFocusListener
                                                                    // disableTouchListener
                                                                    placement="bottom-end"
                                                                    title={this.props.intl.formatMessage({id: 'password_rules'})}>
                                                                    <IconButton>
                                                                        <HelpOutlineRoundedIcon/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item className={this.classes.itemWidth}>
                                                <TextField
                                                    variant="outlined"
                                                    label={this.props.intl.formatMessage({id: 'confirm_password'})}
                                                    type="password"
                                                    name="confirmPassword"
                                                    autoComplete="new-password"
                                                    margin="dense"
                                                    fullWidth
                                                    error={this.isInvalid('confirmPassword')}
                                                    helperText={this.validationErrorMessage('confirmPassword')}
                                                    value={this.state.registrationInfo.confirmPassword}
                                                    onChange={this.handleFormInputChange('confirmPassword')}/>
                                            </Grid>
                                            <Grid item className={this.classes.itemWidth}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="submit"
                                                    style={{color: 'white'}}
                                                    fullWidth>
                                                    {this.props.intl.formatMessage({id: 'register'})}
                                                </Button>
                                            </Grid>
                                            <Grid item className={this.classes.itemWidth}>
                                                <Typography align='center' variant="body2">
                                                    {this.props.intl.formatMessage({id: 'agree_terms_of_service'})}
                                                    {' '}
                                                    <Link underline="none"
                                                          href=""
                                                          onClick={this.showTermsOfService}>
                                                        {this.props.intl.formatMessage({id: 'terms_of_service'})}
                                                    </Link>
                                                    {' '}
                                                    {this.props.intl.formatMessage({id: 'and_the'})}
                                                    {' '}
                                                    <Link underline="none"
                                                          href=""
                                                          onClick={this.showPrivacyPolicy}>
                                                        {this.props.intl.formatMessage({id: 'privacy_policy'})}
                                                    </Link>.
                                                </Typography>
                                            </Grid>
                                        </>)}
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                        <Grid
                            item
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                            style={{marginTop: '10px'}}
                        >
                            <Grid item xs={12} sm={6} className={this.classes.buttonTrayLeft}>
                                <RouterLink to="/login" style={{textDecoration: 'none'}}>
                                    <Button
                                        type="button"
                                        startIcon={<ContactsIcon/>}>
                                        {this.props.intl.formatMessage({id: 'to_login'})}
                                    </Button>
                                </RouterLink>
                            </Grid>
                            <Grid item xs={12} sm={6} className={this.classes.buttonTrayRight}>
                                <SelectLanguageButton/>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
                <ServiceAgreementDialog open={this.state.showTerms} onClose={this.hideTermsOfService}/>
                <PrivacyPolicyDialog open={this.state.showPrivacyPolicy} onClose={this.hidePrivacyPolicy}/>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        currentLocale: state.locale,
        registration: state.registration,
        crowdView: state.crowdView
    }
};

const mapDispatchToProps = dispatch => {
    return {
        register: registrationInfo => dispatch(register(registrationInfo)),
        locale: locale => dispatch(changeLocale(locale)),
        clearRegistrationErrors: () => dispatch(registrationErrored({})),
    };
};

export default compose(
    withAnonymous,
    withStyles(styles, {withTheme: true}),
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(Registration);
