import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import {Link, useHistory} from "react-router-dom";
import {injectIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import {CROWDEA_ORIGIN} from "../../../constants";

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        color: 'black'
    }
}));

const LoggedInMenu = props => {

    const {anchorEl, handleClose, logout, isCrowdHomepage, userRoles, crowdView, intl} = props;
    const classes = useStyles();
    const crowdId = crowdView.data.id;
    const history = useHistory();

    const handleLogoutClick = event => {
        event.stopPropagation();
        handleClose();
        logout(isCrowdHomepage);
    };

    const handleDashboardClick = () => {
        handleClose();
        if (isCrowdHomepage)
            window.location.href = `${CROWDEA_ORIGIN}/crowds/${crowdId}`;
        else
            history.push('/crowds');
    };

    const shouldRenderDashboard = () => {
        if (isCrowdHomepage)
            return userRoles.data[crowdId];
        return true;
    };

    return <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} role={undefined} transition disablePortal>
        {({TransitionProps}) => (
            <Grow {...TransitionProps} style={{transformOrigin: 'center bottom'}}>
                <Paper>
                    <ClickAwayListener onClickAway={() => handleClose()}>
                        <MenuList>
                            <Link to='/account' className={classes.link}>
                                <MenuItem button key={'my_account'} onClick={handleClose}>
                                    <Typography>
                                        {intl.formatMessage({id: 'my_account'})}
                                    </Typography>
                                </MenuItem>
                            </Link>
                            {shouldRenderDashboard() &&
                            <MenuItem button key={'dashboard'} onClick={handleDashboardClick}>
                                <Typography>
                                    {intl.formatMessage({id: 'dashboard'})}
                                </Typography>
                            </MenuItem>
                            }
                            <MenuItem button key={'logout'} onClick={handleLogoutClick}>
                                <Typography>
                                    {intl.formatMessage({id: 'logout'})}
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>;
};

export default injectIntl(LoggedInMenu);