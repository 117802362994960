import {injectIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import Button from "@material-ui/core/Button";
import ServiceAgreementContent from "./ServiceAgreementContent";

const ServiceAgreementDialog = props => {

    const {onClose, open} = props;

    return <>
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='lg'
            fullWidth
            scroll='body'>
            <DialogTitle>
                {props.intl.formatMessage({id: 'terms_of_service_headline'})}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    <ServiceAgreementContent/>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {props.intl.formatMessage({id: 'close'})}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default injectIntl(ServiceAgreementDialog);