import React, {useEffect} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {injectIntl} from "react-intl";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import {Link, Redirect, Route, useHistory, useLocation, useParams} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {updateCrowd, updateThemingData} from "../../actions/crowds";
import Button from "@material-ui/core/Button";
import CrowdBillingInfo from "./CrowdBillingInfo";
import _ from 'lodash';
import CrowdThemingData from "./CrowdTheming";
import {loadCategories} from "../../actions/categories";
import IdeaCategories from "./IdeaCategories";
import CrowdSubscription from "./CrowdSubscription";
import {CROWDEA_HOSTNAME, CrowdUserType, Currency, NotificationType} from "../../constants";
import {cancelSubscription, createSubscription, loadSubscription} from "../../actions/subscriptions";
import Spinner from "../common/Spinner";
import CrowdUsers from "./CrowdUsers";
import {deleteCrowdUser, loadCrowdUsers, updateCrowdUsers} from "../../actions/crowdUsers";
import {loadIdeaQuestionnaire} from "../../actions/ideaQuestionnaire";
import CrowdDetailNotificationStack from "./CrowdDetailNotificationStack";
import CrowdProfile from "./CrowdProfile";
import Hidden from "@material-ui/core/Hidden";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MobileNavMenu from "../common/MobileNavMenu";
import useDeepCompareEffect from "use-deep-compare-effect";
import {loadCreditCards} from "../../actions/creditCards";
import {emitAppNotification} from "../../actions/common";
import IdeaSettings from "./IdeaSettings";


const useStyles = makeStyles(theme => ({
    tabTextColorPrimary: {
        color: theme.palette.primary.main
    },
    viewHomepageButton: {
        color: 'white',
        marginBottom: '10px',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

const crowdDefaults = {
    domain: '',
    subdomain: '',
    subdomainRouting: true,
    title: '',
    description: '',
    billingInfo: {
        name: '',
        companyName: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
        companyId: '',
        taxId: '',
        vatId: ''
    },
    themingData: {
        ideasListingView: '',
        displayDefaultSlideshow: true,
        displayCustomIntro: false,
        customIntroContent: '',
        customIntroBackgroundColor: '#F4B91F'
    },
    locale: '',
    gaCode: '',
    likeMilestone: '',
    rewardAmount: '',
    rewardCurrency: Currency.EUR,
    visible: false,
    hideTitle: false,
    privateCrowd: false,
    privateUsers: false,
    allowPrePrivateLogins: false,
    userInteractionsCount: '',
    ideasCount: ''
};

const CrowdDetail = props => {

    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const crowdId = parseInt(useParams().crowdId);
    const {crowds, categories, user, questionnaires, creditCards, loadCreditCards} = props;
    const subscription = props.subscriptions[crowdId] || {current: {}};
    const crowdUsers = props.crowdUsers[crowdId] || {};
    const crowdCategories = categories[crowdId] || {};
    const questionnaire = questionnaires[crowdId] || {};
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (link, newValue) => {
        setValue(newValue);
        history.push(link);
        handleClose();
    };

    const findCrowd = () => {
        const crowd = crowds.items.find(c => c.id === crowdId);
        if (crowd)
            return _.mergeWith({}, crowdDefaults, crowd, (a, b) => b === null ? a : undefined);
        else if (crowds.loaded)
            history.push('/crowds/');
        return crowdDefaults;
    };

    const crowd = findCrowd();

    useEffect(() => {
        if (!crowdCategories.loaded)
            props.loadCategories(crowdId);

        if (!crowdUsers.loaded)
            props.loadCrowdUsers(crowdId);

        if (!questionnaire.loaded)
            props.loadIdeaQuestionnaire(crowdId);

        if (!subscription.loaded)
            props.loadSubscription(crowdId);

        if (!creditCards.loaded)
            loadCreditCards();
    }, []);

    const isUserManagementAllowed = subscription => subscription.id !== undefined && (subscription.features.privateCrowd || subscription.features.admins > 1 || subscription.features.managers > 1);

    const createNavigationSections = () => {
        const sections = [
            {
                i18n: 'profile',
                link: `/crowds/${crowdId}/profile`
            },
            {
                i18n: 'theming',
                link: `/crowds/${crowdId}/theming`
            },
            {
                i18n: 'categories',
                link: `/crowds/${crowdId}/categories`
            },
            {
                i18n: 'billing',
                link: `/crowds/${crowdId}/billing`
            },
            {
                i18n: 'idea_settings',
                link: `/crowds/${crowdId}/ideaSettings`
            }
        ];

        if (crowd.role === CrowdUserType.CROWD_ADMIN)
            sections.push({
                i18n: 'subscription',
                link: `/crowds/${crowdId}/subscription`
            });
        if (isUserManagementAllowed(subscription.current))
            sections.push({
                i18n: 'users',
                link: `/crowds/${crowdId}/users`
            });
        return sections;
    };

    const navigation = createNavigationSections();

    const preselectTab = () => {
        return Math.max(navigation.map(nav => nav.link).findIndex(link => location.pathname.includes(link)), 0);
    };

    const [value, setValue] = React.useState(preselectTab());

    useDeepCompareEffect(() => {
        setValue(preselectTab());
    }, [crowd]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const viewHomepage = event => {
        event.preventDefault();
        window.open(`https://${crowd.subdomain}.${CROWDEA_HOSTNAME}`, "_blank")
    };

    const getTabsVariant = () => {
        return window.innerWidth > 1440 ? 'fullWidth' : 'scrollable';
    };

    if (!(crowds.loaded && subscription.loaded))
        return <Spinner/>;

    return <>
        <Paper elevation={3} style={{marginBottom: '10px'}}>
            <Hidden smUp>
                <Button
                    fullWidth
                    disableElevation
                    variant="contained"
                    endIcon={<ExpandMoreIcon/>}
                    style={{background: 'transparent'}}
                    onClick={openMenu}
                >
                    {props.intl.formatMessage({id: navigation[value].i18n})}
                </Button>
            </Hidden>
            <Hidden xsDown>
                <Tabs value={value} onChange={handleChange}
                      indicatorColor="primary"
                      scrollButtons="auto"
                      variant={getTabsVariant()}>
                    {navigation.map((nav, index) =>
                        <Tab key={nav.i18n}
                             className={clsx({[classes.tabTextColorPrimary]: value === index})}
                             label={props.intl.formatMessage({id: nav.i18n})}
                             component={Link}
                             to={nav.link}
                        />)}
                </Tabs>
            </Hidden>
        </Paper>
        <CrowdDetailNotificationStack subscription={subscription} crowd={crowd} setValue={setValue}/>
        <Button color='primary' size='small' variant="contained" className={classes.viewHomepageButton}
                onClick={viewHomepage}>
            {props.intl.formatMessage({id: 'crowd_homepage_preview'})}
        </Button>
        <Route path={["/crowds/:crowdId", "/crowds/:crowdId/profile"]} exact
               render={() => <CrowdProfile crowd={crowd}
                                           subscription={subscription}
                                           dialogOnSubmitHandler={() => {
                                               history.push(`/crowds/${crowd.id}/subscription`);
                                               setValue(5);
                                           }}
                                           updateCrowd={props.updateCrowd}/>}/>
        <Route path="/crowds/:crowdId/theming" exact
               render={() => <CrowdThemingData crowd={crowd} updateCrowd={props.updateCrowd} updateThemingData={props.updateThemingData}/>}/>
        <Route path="/crowds/:crowdId/categories"
               render={() => <IdeaCategories crowdId={crowdId}/>}/>
        <Route path="/crowds/:crowdId/subscription" exact
               render={() => {
                   if (crowd.role === CrowdUserType.CROWD_ADMIN)
                       return <CrowdSubscription subscription={subscription}
                                                 reloadCrowdUsers={() => props.loadCrowdUsers(crowdId)}
                                                 createSubscription={props.createSubscription(crowdId)}
                                                 cancelSubscription={props.cancelSubscription(crowdId)}
                                                 creditCards={creditCards}
                                                 loadCreditCards={loadCreditCards}
                                                 emitError={props.emitError}
                                                 trialEligible={crowd.trialEligible && user.trialEligible}/>;
                   else
                       return <Redirect to={{pathname: `/crowds/${crowdId}`}}/>;
               }}/>
        <Route path="/crowds/:crowdId/billing" exact
               render={() => <CrowdBillingInfo crowd={crowd} updateCrowd={props.updateCrowd}/>}/>
        <Route path="/crowds/:crowdId/users" exact
               render={() => {
                   if (isUserManagementAllowed(subscription.current))
                       return <CrowdUsers crowd={crowd}
                                          crowdId={crowdId}
                                          crowdUsers={crowdUsers}
                                          subscription={subscription}
                                          updateCrowdUsers={props.updateCrowdUsers(crowdId)}
                                          updateCrowd={props.updateCrowd}
                                          deleteCrowdUser={props.deleteCrowdUser(crowdId)}/>;
                   else
                       return <Redirect to={{pathname: `/crowds/${crowdId}`}}/>;
               }}/>
        <Route path="/crowds/:crowdId/ideaSettings" exact
               render={() => <IdeaSettings crowdId={crowdId}/>}/>
        <MobileNavMenu handleClose={handleClose} anchorEl={anchorEl} navigation={navigation}
                       handleClick={handleMenuClick}/>
    </>
};

const mapStateToProps = state => {
    return {
        user: state.user,
        crowds: state.crowds,
        crowdUsers: state.crowdUsers,
        categories: state.categories,
        subscriptions: state.subscriptions,
        questionnaires: state.questionnaires,
        creditCards: state.creditCards
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        loadCategories: crowdId => dispatch(loadCategories(crowdId)),
        loadCrowdUsers: crowdId => dispatch(loadCrowdUsers(crowdId)),
        loadSubscription: crowdId => dispatch(loadSubscription(crowdId)),
        loadIdeaQuestionnaire: crowdId => dispatch(loadIdeaQuestionnaire(crowdId)),
        createSubscription: crowdId => (tier, paymentMethodId, successCallback, errorCallback) => dispatch(createSubscription(tier, crowdId, paymentMethodId, successCallback, errorCallback)),
        cancelSubscription: crowdId => () => dispatch(cancelSubscription(crowdId)),
        updateCrowd: (crowd, successCallback) => dispatch(updateCrowd(crowd, successCallback)),
        updateThemingData: (themingData, crowdId) => dispatch(updateThemingData(themingData, crowdId)),
        updateCrowdUsers: crowdId => crowdUsers => dispatch(updateCrowdUsers(crowdUsers, crowdId)),
        deleteCrowdUser: crowdId => crowdUserId => () => dispatch(deleteCrowdUser(crowdId, crowdUserId)),
        loadCreditCards: () => dispatch(loadCreditCards()),
        emitError: errCode => dispatch(emitAppNotification(NotificationType.ERROR, errCode, 3000))
    };
};

export default compose(
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(CrowdDetail);