import React from "react";
import Typography from '@material-ui/core/Typography';
import {makeStyles} from "@material-ui/core/styles";
import SelectLanguage from "../common/selectlanguage/SelectLanguageNavbar";
import {injectIntl} from "react-intl";
import {Link, NavLink, Route} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import LoggedInNavbar from "../common/accountDropdown/LoggedInNavbar";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.indigo.dark
    },
    text: {
        borderBottom: 'inherit',
        color: 'inherit',
        borderBottomColor: 'inherit',
        cursor: 'pointer',
        height: '26px'
    },
    activeLink: {
        borderBottom: '2px solid',
        color: theme.palette.green.main,
        borderBottomColor: theme.palette.green.main
    },
    orangeButton: {
        color: 'white',
        background: 'linear-gradient(to right, #FC9000, #EE5126)',
        borderRadius: '15px',
        width: '170px',
        textTransform: 'none'
    },
}));

const NavbarMenu = props => {

    const classes = useStyles();

    return <>
        <Grid item>
            <NavLink exact to='/'
                     activeClassName={classes.activeLink}
                     className={classes.link}>
                <Typography className={classes.text}>
                    {props.intl.formatMessage({id: 'for_businesses'})}
                </Typography>
            </NavLink>
        </Grid>
        <Grid item>
            <NavLink to='/intro'
                     activeClassName={classes.activeLink}
                     className={classes.link}>
                <Typography className={classes.text}>
                    {props.intl.formatMessage({id: 'for_public'})}
                </Typography>
            </NavLink>
        </Grid>
        {/*<Grid item>
            <Link className={classes.link}>
                <Typography className={classes.text} onClick={redirectToBlog}>
                    {props.intl.formatMessage({id: 'blog'})}
                </Typography>
            </Link>
        </Grid>*/}
        {!props.user.loggedIn && (
            <Grid item>
                <Route exact path="/" render={() =>
                    <Link to='/login' className={classes.link}>
                        <Typography className={classes.text}>
                            {props.intl.formatMessage({id: 'login'})}
                        </Typography>
                    </Link>
                }/>
                <Route exact path="/intro" render={() =>
                    <Link to='/login' className={classes.link}>
                        <Button variant="contained" className={classes.orangeButton} disableElevation>
                            {props.intl.formatMessage({id: 'try_crowdea'})}
                        </Button>
                    </Link>
                }/>
            </Grid>
        )}
        <Grid item style={{padding: 0}}>
            <SelectLanguage useColor='indigo'/>
        </Grid>
        {props.user.loggedIn && <Grid item style={{padding: 0}}>
            <LoggedInNavbar color={'lightgray'}/>
        </Grid>}
    </>
};

export default injectIntl(NavbarMenu);