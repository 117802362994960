import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import clsx from "clsx";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import {easeInOutQuad} from "../../utils/utils";
import Zoom from "@material-ui/core/Zoom/Zoom";
import {useContainerDimensions} from "../../hooks/useContainerDimensions";

const useStyles = makeStyles(theme => ({
    scrollContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        position: 'relative'
    },
    scrollButton: {
        position: 'absolute',
        background: theme.palette.orange.main,
        color: 'white',
        top: 'calc(50% - 28px)',
        zIndex: 1,
        '&:hover': {
            background: theme.palette.orange.light
        }
    },
    leftButton: {
        left: '40px'
    },
    rightButton: {
        right: '40px'
    },
    item: {
        flex: '0 0 auto',
        padding: '8px'
    }
}));

const HorizontalScroll = props => {

    const classes = useStyles();
    const {scrollContainerRef, hideScrollButtons = false} = props;
    const [isScrolling, setIsScrolling] = React.useState(false);
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const {width, height} = useContainerDimensions(scrollContainerRef);

    const onScroll = event => {
        setScrollPosition(event.target.scrollLeft);
    };

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.addEventListener("touchmove", onScroll, false);
        }
    }, [scrollContainerRef.current]);

    const scroll = (element, change, duration) => {
        if (isScrolling)
            return;
        else
            setIsScrolling(true);

        const start = element.scrollLeft;
        let currentTime = 0;
        const increment = 20;

        const animateScroll = () => {
            currentTime += increment;
            element.scrollLeft = easeInOutQuad(currentTime, start, change, duration);
            setScrollPosition(element.scrollLeft);
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            } else {
                setIsScrolling(false);
            }
        };
        animateScroll();
    };

    const scrollLeft = () => {
        scroll(scrollContainerRef.current, -300, 500);
    };

    const scrollRight = () => {
        scroll(scrollContainerRef.current, 300, 500);
    };

    const shouldRenderLeft = () => scrollPosition > 0;
    const shouldRenderRight = () => {
        if (scrollContainerRef.current && scrollContainerRef.current.scrollWidth > width)
            return scrollPosition + width < scrollContainerRef.current.scrollWidth;
        else
            return true;
    };

    return <div style={{position: 'relative'}}>
        {!hideScrollButtons && <>
            <Zoom in={shouldRenderLeft()}>
                <Fab className={clsx(classes.scrollButton, classes.leftButton)} onClick={scrollLeft}>
                    <KeyboardArrowLeftIcon/>
                </Fab>
            </Zoom>
            <Zoom in={shouldRenderRight()}>
                <Fab className={clsx(classes.scrollButton, classes.rightButton)} onClick={scrollRight}>
                    <KeyboardArrowRightIcon/>
                </Fab>
            </Zoom>
        </>}
        <div ref={scrollContainerRef} className={clsx('hideScrollbar', classes.scrollContainer)}
             onScroll={onScroll}>
            {props.children.map(child => <div className={classes.item}>{child}</div>)}
        </div>
    </div>
};

export default injectIntl(HorizontalScroll);