import React, {useEffect} from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {injectIntl} from "react-intl";
import {Link as RouterLink, useParams} from "react-router-dom";
import {activateAccount} from "../../actions/registration";
import {compose} from "redux";
import Button from "@material-ui/core/Button";
import ContactsIcon from '@material-ui/icons/Contacts';
import SelectLanguageButton from "../common/selectlanguage/SelectLanguageButton";
import {makeStyles} from "@material-ui/core/styles";
import {withAnonymous} from "../common/withAnonymous";

const useStyles = makeStyles(theme => ({
    buttonTrayRight: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-end"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    buttonTrayLeft: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-start"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    crowdeaLogo: {
        height: '30px',
        margin: '20px 0 20px'
    }
}));


const AccountActivation = props => {

    const classes = useStyles();
    const {token} = useParams();
    const [messageCode, setMessageCode] = React.useState('registration_activation_token_verifying');

    useEffect(() => {
        activateAccount(token)
            .then(result => {
                if (result) {
                    setMessageCode(result.status)
                }
            })
    }, []);

    return <>
        <Container maxWidth="sm" style={{height: '100vh'}}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'stretch',
                height: '100%'
            }}>
                <Card elevation={3}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={1}
                        >
                            <Grid item>
                                <RouterLink to='/'>
                                    <img className={classes.crowdeaLogo} src="/images/crowdea.png"/>
                                </RouterLink>
                            </Grid>
                            <Grid item>
                                <Typography align='center'>
                                    {props.intl.formatMessage({id: messageCode})}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid
                    item
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{marginTop: '10px'}}
                >
                    <Grid item xs={12} sm={6} className={classes.buttonTrayLeft}>
                        <RouterLink to="/login" style={{textDecoration: 'none'}}>
                            <Button
                                type="button"
                                startIcon={<ContactsIcon/>}>
                                {props.intl.formatMessage({id: 'to_login'})}
                            </Button>
                        </RouterLink>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.buttonTrayRight}>
                        <SelectLanguageButton/>
                    </Grid>
                </Grid>
            </div>
        </Container>
    </>
};

export default compose(
    withAnonymous,
    injectIntl,
)(AccountActivation);
