import React from "react";
import Typography from '@material-ui/core/Typography';
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import ChooseSubscription from "../../subscription/ChooseSubscription";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.secondary.light
    },
    pricingHeaderText: {
        padding: '80px 0',
        color: theme.palette.indigo.dark,
        fontSize: '60px',
        lineHeight: '1.1',
        fontFamily: 'Open Sans',
        [theme.breakpoints.down('xl')]: {
            fontSize: '55px',
        },
        [theme.breakpoints.down('lg')]: {
            fontSize: '45px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '40px',
        },
    },
    purpleBall: {
        borderRadius: '50%',
        height: '20px',
        width: '20px',
        backgroundColor: theme.palette.purple.main,
        position: 'absolute',
        right: 0,
        top: '120px'
    },
    zigzag: {
        position: 'absolute',
        right: '120px',
        top: '30px'
    },
    orangeBall: {
        position: 'absolute',
        left: '-120px',
        top: '-90px'
    }
}));

const Pricing = props => {

    const classes = useStyles();
    const {displayFreeSubscription} = props;
    const history = useHistory();

    const clickHandler = tier => {
        history.push(`/subscription?tier=${tier}`)
    };

    return <div className={classes.container}>
        <Container maxWidth="xl" id='pricing'>
            <div style={{position: 'relative'}}>
                <div className={classes.purpleBall}/>
                <img className={classes.zigzag} src="/images/zigzag.png"/>
            </div>
            <Typography align={"center"} variant={'h1'}
                        className={classes.pricingHeaderText}>
                {props.intl.formatMessage({id: 'how_much_does_it_cost'})}
            </Typography>
            <div style={{position: 'relative'}}>
                <img className={classes.orangeBall} src="/images/orange_ball.png"/>
            </div>
            <ChooseSubscription displayFreeSubscription={displayFreeSubscription}
                                isLandingPage
                                buttonClickHandler={clickHandler}/>
        </Container>
    </div>
};

export default injectIntl(Pricing);