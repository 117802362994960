import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {injectIntl} from "react-intl";
import {Link as RouterLink} from "react-router-dom";
import {compose} from "redux";
import Button from "@material-ui/core/Button";
import ContactsIcon from '@material-ui/icons/Contacts';
import SelectLanguageButton from "../common/selectlanguage/SelectLanguageButton";
import {makeStyles} from "@material-ui/core/styles";
import {withAnonymous} from "../common/withAnonymous";
import TextField from "@material-ui/core/TextField";
import {emailRegex} from "../../constants";
import {sendResetPasswordMail} from "../../actions/login";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
    buttonTrayRight: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-end"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    buttonTrayLeft: {
        display: "flex",
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-start"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center"
        }
    },
    crowdeaLogo: {
        height: '30px',
        margin: '20px 0 20px'
    },
    itemWidth: {
        width: '90%'
    }
}));

const ForgotPasswordSendMail = props => {

    const classes = useStyles();
    const {crowdView} = props;
    const [email, setEmail] = React.useState('');
    const [emailValid, setEmailValid] = React.useState(true);
    const [emailSent, setEmailSent] = React.useState(false);
    const [messageCode, setMessageCode] = React.useState('');

    const validate = () => {
        const validation = emailRegex.test(email);
        setEmailValid(validation);
        return validation;
    };

    const handleSubmit = () => {
        if (validate()) {
            sendResetPasswordMail({
                email,
                ...(crowdView.data.privateCrowd && {crowdId: crowdView.data.id})
            })
                .then(result => {
                    if (result) {
                        setMessageCode(result.status);
                        setEmailSent(true);
                    }
                });
        }
    };

    return <Container maxWidth="sm" style={{height: '100vh'}}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'stretch',
            height: '100%'
        }}>
            <Card elevation={3}>
                <CardContent>
                    <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <RouterLink to='/'>
                                <img className={classes.crowdeaLogo} src="/images/crowdea.png"/>
                            </RouterLink>
                        </Grid>
                        {emailSent ? (
                            <Grid item className={classes.itemWidth}>
                                <Typography align='center'>
                                    {props.intl.formatMessage({id: messageCode})}
                                </Typography>
                            </Grid>
                        ) : (<>
                                <Grid item className={classes.itemWidth}>
                                    <TextField
                                        variant="outlined"
                                        label={props.intl.formatMessage({id: 'email'})}
                                        type="email"
                                        name="email"
                                        autoComplete="email"
                                        margin="dense"
                                        fullWidth
                                        error={!emailValid}
                                        helperText={!emailValid && props.intl.formatMessage({id: 'validation_registration_email'})}
                                        value={email}
                                        onChange={event => setEmail(event.target.value)}/>
                                </Grid>
                                <Grid item className={classes.itemWidth}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={handleSubmit}
                                        style={{color: 'white'}}
                                        fullWidth>
                                        {props.intl.formatMessage({id: 'reset_password'})}
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </CardContent>
            </Card>
            <Grid
                item
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{marginTop: '10px'}}
            >
                <Grid item xs={12} sm={6} className={classes.buttonTrayLeft}>
                    <RouterLink to="/login" style={{textDecoration: 'none'}}>
                        <Button
                            type="button"
                            startIcon={<ContactsIcon/>}>
                            {props.intl.formatMessage({id: 'to_login'})}
                        </Button>
                    </RouterLink>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.buttonTrayRight}>
                    <SelectLanguageButton/>
                </Grid>
            </Grid>
        </div>
    </Container>
};

const mapStateToProps = state => {
    return {
        crowdView: state.crowdView,
    }
};

export default compose(
    withAnonymous,
    injectIntl,
    connect(mapStateToProps),
)(ForgotPasswordSendMail);
