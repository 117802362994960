import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {separatorRegex, wwwDomainRegex} from "../../constants";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import LockIcon from '@material-ui/icons/Lock';
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    }
}));

const CrowdUsersPrivacy = props => {

    const classes = useStyles();
    const {crowd, updateCrowd, subscriptionFeatures} = props;
    const [privateCrowd, setPrivateCrowd] = React.useState(crowd.privateCrowd);
    const [privateUsers, setPrivateUsers] = React.useState(crowd.privateUsers);
    const [allowPrePrivateLogins, setAllowPrePrivateLogins] = React.useState(crowd.allowPrePrivateLogins);
    const [allowedDomains, setAllowedDomains] = React.useState('');
    const [validationErrors, setValidationErrors] = React.useState({});
    const validators = {
        allowedDomains: () => {
            if (allowedDomains === '' && privateCrowd)
                return props.intl.formatMessage({id: 'validation_crowd_private_allowed_emails_empty'});

            const emailArr = allowedDomains.split(separatorRegex).filter(e => e !== '');
            if (!emailArr.every(e => wwwDomainRegex.test(e)))
                return props.intl.formatMessage({id: 'validation_crowd_private_allowed_emails_invalid'});
            return true;
        }
    };

    useDeepCompareEffect(() => {
        setPrivateCrowd(crowd.privateCrowd);
        if (crowd.allowedDomains)
            setAllowedDomains(crowd.allowedDomains.join(', '));
    }, [crowd]);

    const validateInput = () => {
        const result = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});

        setValidationErrors(result);
        return Object.keys(result).length === 0;
    };

    const handleInputChange = event => {
        setAllowedDomains(event.target.value);
    };

    const handlePrivateCrowdChange = () => {
        setPrivateCrowd(!privateCrowd);
    };

    const handlePrePrivateLoginsChange = () => {
        setAllowPrePrivateLogins(!allowPrePrivateLogins);
    };

    const handlePrivateUsersChange = () => {
        setPrivateUsers(!privateUsers);
    };

    const allowedDomainsAsArray = () => {
        return [...new Set(allowedDomains.split(separatorRegex).filter(e => e !== ''))];
    };

    const changesMade = () => {
        return Object.entries({allowedDomains, privateCrowd, allowPrePrivateLogins, privateUsers})
            .reduce((acc, currentValue) => {
                const [key, value] = currentValue;
                if (!acc) {
                    if (key === 'allowedDomains')
                        return !_.isEqual(_.sortBy(allowedDomainsAsArray()), _.sortBy(crowd[key]));
                    return value !== crowd[key];
                }
                return acc;
            }, false);
    };

    const handleSubmit = () => {
        if (validateInput() && changesMade()) {
            const body = {
                id: crowd.id,
                version: crowd.version,
                allowedDomains: allowedDomainsAsArray(),
                privateCrowd,
                privateUsers,
                allowPrePrivateLogins
            };
            updateCrowd(body);
        }
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <LockIcon/>
                </Avatar>
            }
            title={<Typography
                className={'cardHeaderText'}>{props.intl.formatMessage({id: 'crowd_privacy_settings'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'crowd_privacy_desc'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={privateCrowd}
                                onChange={handlePrivateCrowdChange}
                                value={privateCrowd}
                                color="primary"
                            />
                        }
                        label={props.intl.formatMessage({id: 'crowd_private'})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'crowd_privacy_emails_to_allow_desc'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        multiline
                        rows={3}
                        rowsMax={10}
                        label={props.intl.formatMessage({id: 'crowd_users_emails_to_add'})}
                        name="emails"
                        margin="dense"
                        fullWidth
                        error={validationErrors['allowedDomains']}
                        helperText={validationErrors['allowedDomains']}
                        value={allowedDomains}
                        onChange={handleInputChange}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'crowd_allow_pre_private_logins_desc'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={allowPrePrivateLogins}
                                onChange={handlePrePrivateLoginsChange}
                                value={allowPrePrivateLogins}
                                color="primary"
                                disabled={!privateCrowd}
                            />
                        }
                        label={props.intl.formatMessage({id: 'crowd_allow_pre_private_logins'})}
                    />
                </Grid>
                {subscriptionFeatures.privateUsers && <>
                    <Grid item xs={12}>
                        <Typography>
                            {props.intl.formatMessage({id: 'crowd_allow_private_users_desc'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={privateUsers}
                                    onChange={handlePrivateUsersChange}
                                    value={privateUsers}
                                    color="primary"
                                    disabled={!privateCrowd}
                                />
                            }
                            label={props.intl.formatMessage({id: 'crowd_allow_private_users'})}
                        />
                    </Grid>
                </>}
                <Grid item xs={12} sm='auto'>
                    <Button color='primary' variant="contained" className={classes.button} onClick={handleSubmit}>
                        {props.intl.formatMessage({id: 'save'})}
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
};

export default injectIntl(CrowdUsersPrivacy);