import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import {injectIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    menu: {
        zIndex: '10',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90%'
        }
    }
}));

const MobileNavMenu = props => {

    const {handleClose, anchorEl, intl, navigation, handleClick} = props;
    const classes = useStyles();

    return <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} role={undefined} transition disablePortal
                   className={classes.menu}>
        {({TransitionProps}) => (
            <Grow {...TransitionProps} style={{transformOrigin: 'center bottom'}}>
                <Paper>
                    <ClickAwayListener onClickAway={() => handleClose()}>
                        <MenuList>
                            {navigation.map((nav, index) =>
                                <MenuItem onClick={() => handleClick(nav.link, index)}>
                                    <Typography style={{whiteSpace: 'normal'}}>
                                        {nav.i18n ? intl.formatMessage({id: nav.i18n}) : nav.name}
                                    </Typography>
                                </MenuItem>)}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>;
};

export default injectIntl(MobileNavMenu);