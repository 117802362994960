import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import {useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import {API_URL, CrowdUserType} from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import Typography from "@material-ui/core/Typography";
import {Link, useHistory, useParams} from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Spinner from "../common/Spinner";
import InfiniteScroll from "../common/InfiniteScroll";
import clsx from "clsx";
import {determineIdeaTagColor, formatDate} from "../../utils/utils"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import Hidden from "@material-ui/core/Hidden";
import IdeaMedia from "./IdeaMedia";
import {getYoutubeThumbnailUrl, getYoutubeVideoId} from "./common";
import YouTubeIcon from '@material-ui/icons/YouTube';
import _ from 'lodash';
import GenericDialog from "../common/GenericDialog";
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import {changeIdeaState, incrementIdeaViews} from "../../actions/idea";
import {connect} from "react-redux";
import ChatBubbleOutlinedIcon from '@material-ui/icons/ChatBubbleOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import HorizontalScroll from "../common/HorizontalScroll";
import InputBase from "@material-ui/core/InputBase";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {useRedirectWithState} from "../../hooks/useRedirectWithState";
import useDeepCompareEffect from "use-deep-compare-effect";
import {createIdeaRating} from "../../actions/ideaRating";
import ShareIcon from '@material-ui/icons/Share';
import {StyledRating} from "../common/StyledRating ";
import {IdeaStateMenu} from "../ideaAdmin/IdeaStateMenu";
import CategoryIcon from '@material-ui/icons/Category';

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    media: {
        height: '590px',
        [theme.breakpoints.down('md')]: {
            height: '425px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '400px',
        },
        [theme.breakpoints.down('xs')]: {
            height: '230px',
        }
    },
    chips: {
        color: 'white',
        marginLeft: '10px',
        '& svg.MuiChip-deleteIcon': {
            color: 'white'
        }
    },
    attachment: {
        height: '125px',
        [theme.breakpoints.down('md')]: {
            height: '100px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '80px',
            width: '80px'
        },
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center'
    },
    moreInGallery: {
        color: 'white',
        backgroundSize: 'auto',
        fontSize: '28px'
    },
    listItemRoot: {
        paddingLeft: 0,
        paddingRight: '120px',
        alignItems: 'flex-start',
        '& div.MuiListItemText-secondary': {
            color: theme.palette.indigo.dark,
            fontSize: '16px'
        }
    },
    link: {
        textDecoration: 'none'
    },
    tagButton: {
        position: 'absolute',
        right: '10px',
        marginTop: '16px'
    },
    tagButtonWrapper: {
        position: "relative",
        width: '100%'
    },
    appreciateButton: {
        color: 'white',
        fontFamily: 'Open Sans',
        borderRadius: '21px',
        height: '42px',
        width: '170px',
        padding: '0 30px',
        backgroundColor: theme.palette.green.dark,
        '&:hover': {
            backgroundColor: theme.palette.green.light
        }
    },
    ideaName: {
        color: theme.palette.indigo.dark,
        fontSize: '42px',
        lineHeight: '1.1',
        fontFamily: 'Open Sans',
        [theme.breakpoints.down('xs')]: {
            fontSize: '30px',
        }
    },
    createdBy: {
        fontWeight: 'bold',
        opacity: 0.5,
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
        }
    },
    ideaStatWrapper: {
        display: 'inline-flex',
        alignItems: 'center',
        opacity: 0.5
    },
    ideaDesc: {
        fontSize: '18px',
        opacity: 0.5,
        fontWeight: 'bold'
    },
    commentsCaption: {
        color: theme.palette.indigo.dark,
        fontSize: '36px',
        lineHeight: '1.1',
        fontFamily: 'Open Sans',
        [theme.breakpoints.down('xs')]: {
            fontSize: '24px',
        }
    },
    commentAuthorName: {
        color: '#AF501E',
        fontSize: '18px',
        fontFamily: 'Open Sans',
    },
    commentPostedOn: {
        color: 'black',
        opacity: 0.5,
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px'
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px'
        }
    },
    logInWith: {
        opacity: 0.5
    },
    logInWithIconWrapper: {
        backgroundColor: 'white',
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    commentInput: {
        [theme.breakpoints.down('xs')]: {
            paddingBottom: '8px'
        },
        [theme.breakpoints.up('sm')]: {
            paddingRight: '8px'
        }
    },
    commentContentMobile: {
        color: theme.palette.indigo.dark,
        fontSize: '16px'
    }
}));

export const ideaDefaults = {
    name: '',
    description: '',
    category: '',
    featuredImageId: undefined,
    tags: [],
    fileIds: [],
    embeddableVideos: [],
    answers: []
};

const IdeaDetail = props => {

    const classes = useStyles();
    const {
        ideas,
        user,
        categories,
        createComment,
        loadComments,
        comments,
        likeClickHandler,
        openShareDialog,
        ideasLikedCommented,
        updateComment,
        deleteComment,
        isAdmin,
        changeIdeaState,
        incrementIdeaViews,
        rateIdea
    } = props;
    const history = useHistory();
    const intl = useIntl();
    const ideaId = parseInt(useParams().ideaId);
    const scrollContainerRef = React.useRef(null);
    const [comment, setComment] = React.useState('');
    const [editable, setEditable] = React.useState({});
    const ideaComments = comments[ideaId] || {items: []};
    const [ideaMediaModalOpen, setIdeaMediaModalOpen] = React.useState(false);
    const [selectedMediaIndex, setSelectedMediaIndex] = React.useState(0);
    const [deleteCommentModalOpen, setDeleteCommentModalOpen] = React.useState(false);
    const [toDeleteCommentId, setToDeleteCommentId] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const redirectTo = useRedirectWithState();
    const [idea, setIdea] = React.useState(ideaDefaults);
    const [rating, setRating] = React.useState(0);

    useDeepCompareEffect(() => {
        setIdea(findIdea());
    }, [ideas]);

    useDeepCompareEffect(() => {
        if (idea.rating)
            setRating(parseFloat(idea.rating).toFixed(1));
    }, [idea]);

    const findIdea = () => {
        const idea = ideas.data.find(i => i.id === ideaId);
        if (idea)
            return _.mergeWith({}, ideaDefaults, idea, (a, b) => b === null ? a : undefined);
        else if (ideas.loaded)
            history.push('/');
        return ideaDefaults;
    };

    const openMediaModal = index => () => {
        setSelectedMediaIndex(index);
        setIdeaMediaModalOpen(true);
    };

    const closeMediaModal = () => {
        setIdeaMediaModalOpen(false);
    };

    const openCommentModal = commentId => () => {
        setToDeleteCommentId(commentId);
        setDeleteCommentModalOpen(true);
    };

    const closeCommentModal = () => {
        setDeleteCommentModalOpen(false);
    };

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleIdeaStateChange = ideaState => {
        if (idea.ideaState === ideaState)
            return;

        const body = {
            id: idea.id,
            crowdId: idea.crowdId,
            version: idea.version,
            ideaState
        };
        changeIdeaState(body);
    };

    const deleteHandler = () => deleteComment(toDeleteCommentId, idea.id, idea.crowdId);

    useEffect(() => {
        if (!ideaComments.loaded) {
            loadComments(ideaId);
        }

        incrementIdeaViews(ideaId);
    }, []);

    const loadAdditionalComments = cb => {
        loadComments(ideaId, cb, ideaComments.items.length, 20);
    };

    const ideaTags = idea.tags || [];

    const categoryName = () => {
        const cat = categories.data.find(c => c.id === idea.categoryId);
        return cat ? cat.name : '';
    };

    const categoryLink = () => {
        return `/${categoryName().toLowerCase().replace(/\s+/g, '-')}`;
    };

    const handleInputChange = event => {
        setComment(event.target.value);
    };

    const handleEditableInputChange = comment => event => {
        setEditable({
            ...editable,
            [comment.id]: event.target.value
        });
    };

    const submitIdeaRating = value => {
        rateIdea(ideaId, value)
    };

    const isIdeaLiked = () => {
        return ideasLikedCommented.loaded && ideasLikedCommented.data.ideasLikedIds.includes(ideaId);
    };

    const composeCommentHeader = comment => {
        return <>
            <Typography component='span' className={classes.commentAuthorName}>
                {comment.authorName}
            </Typography>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Typography component='span' className={classes.commentPostedOn}>
                {formatDate(comment.createdAt, '.')}
            </Typography>
        </>
    };

    const isCommentPrivileged = comment => {
        return [CrowdUserType.CROWD_MANAGER, CrowdUserType.CROWD_ADMIN].includes(comment.authorCrowdRole) || comment.ownerId === idea.ownerId;
    };

    const postComment = () => {
        if (comment !== '')
            createComment(comment, ideaId, () => setComment(''));
    };

    const renderButton = () => {
        if (window.innerWidth < 450)
            return <Button color='primary' variant="contained"
                           onClick={openMenu} className={classes.tagButton} style={{color: 'white'}}>
                <CategoryIcon/>
            </Button>;
        else
            return <Button color='primary' variant="contained" startIcon={<CategoryIcon/>}
                           onClick={openMenu} className={classes.tagButton} style={{color: 'white'}}>
                {intl.formatMessage({id: 'change_idea_state_button'})}
            </Button>
    };

    const getChips = () => {
        if (!idea.id) return [];
        const tags = idea.ideaState && idea.ideaState !== '' ? [idea.ideaState, ...ideaTags] : [...ideaTags];
        return tags.map(tag => <Chip label={intl.formatMessage({id: tag.toLowerCase()})}
                                     className={clsx(classes.chips, determineIdeaTagColor(tag))}/>);
    };

    const makeEditable = comment => () => {
        setEditable({
            ...editable,
            [comment.id]: comment.content
        });
    };

    const cancelEditing = id => () => {
        const removeEditable = {...editable};
        delete removeEditable[id];
        setEditable(removeEditable);
    };

    const handleUpdate = comment => () => {
        const content = editable[comment.id];
        if (content !== '') {
            const body = {
                ...comment,
                content
            };
            updateComment(idea.id, body, cancelEditing(comment.id));
        }
    };

    const renderCommentAvatar = comment => {
        if (user.id === comment.ownerId)
            return <Avatar src={`${API_URL}/file?fileId=${user.avatarImageId}`}/>;
        else if (comment.authorAvatarId !== undefined)
            return <Avatar src={`${API_URL}/file?fileId=${comment.authorAvatarId}`}/>;
        else
            return <Avatar
                className={classes.avatar}>{comment.authorName.split(/\s/).slice(0, 2).map(s => s.charAt(0)).join("").toUpperCase()}
            </Avatar>;
    };

    const renderComments = () => {
        return ideaComments.items.map(comment => {
                const isEditable = Object.keys(editable).includes(comment.id.toString());
                return <>
                    <Hidden xsDown>
                        <ListItem
                            key={`comment${comment.id}`}
                            className={classes.listItemRoot}>
                            <ListItemAvatar style={{marginTop: '10px'}}>
                                {renderCommentAvatar(comment)}
                            </ListItemAvatar>
                            {listItemContent(comment)}
                        </ListItem>
                    </Hidden>
                    <Hidden smUp>
                        <Grid container spacing={2}>
                            <Grid item>
                                {
                                    comment.authorAvatarId !== undefined ? (
                                        <Avatar
                                            src={`${API_URL}/file?fileId=${comment.authorAvatarId}`}/>
                                    ) : (
                                        <Avatar
                                            className={classes.avatar}>{comment.authorName.split(/\s/).slice(0, 2).map(s => s.charAt(0)).join("").toUpperCase()}
                                        </Avatar>
                                    )
                                }
                            </Grid>
                            <Grid item xs>
                                <Typography className={classes.commentAuthorName}>
                                    {comment.authorName}
                                </Typography>
                                <Typography className={classes.commentPostedOn}>
                                    {formatDate(comment.createdAt, '.')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{paddingBottom: 0}}>
                                {isEditable ? commentTextField(comment) :
                                    <Typography className={classes.commentContentMobile}>
                                        {comment.content}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'right', paddingTop: 0}}>
                                {isEditable ? <>
                                    <IconButton onClick={handleUpdate(comment)}>
                                        <DoneIcon color='primary'/>
                                    </IconButton>
                                    <IconButton onClick={cancelEditing(comment.id)}>
                                        <ClearIcon color='primary'/>
                                    </IconButton></> : <>
                                    <IconButton onClick={makeEditable(comment)}>
                                        <EditIcon color='primary'/>
                                    </IconButton>
                                    <IconButton onClick={openCommentModal(comment.id)}>
                                        <DeleteIcon color='primary'/>
                                    </IconButton></>}
                            </Grid>
                        </Grid>
                    </Hidden>
                </>;
            }
        )
    };

    const commentTextField = comment => {
        return <TextField
            label={intl.formatMessage({id: 'idea_comment_edit'})}
            variant="outlined"
            name={`comment${comment.id}`}
            margin="dense"
            fullWidth
            multiline
            inputProps={{maxLength: 600}}
            helperText={`${comment.content.length}/600`}
            value={editable[comment.id]}
            onChange={handleEditableInputChange(comment)}
            InputProps={{style: {backgroundColor: 'white'}}}/>;
    };

    const listItemContent = comment => {
        const isEditable = Object.keys(editable).includes(comment.id.toString());
        return <>
            <ListItemText
                secondaryTypographyProps={{component: 'div'}}
                primary={composeCommentHeader(comment)}
                secondary={isEditable ? commentTextField(comment) : comment.content}/>
            {user.id === comment.ownerId &&
            <ListItemSecondaryAction>
                {isEditable ? <>
                    <IconButton onClick={handleUpdate(comment)}>
                        <DoneIcon color='primary'/>
                    </IconButton>
                    <IconButton onClick={cancelEditing(comment.id)}>
                        <ClearIcon color='primary'/>
                    </IconButton></> : <>
                    <IconButton onClick={makeEditable(comment)}>
                        <EditIcon color='primary'/>
                    </IconButton>
                    <IconButton onClick={openCommentModal(comment.id)}>
                        <DeleteIcon color='primary'/>
                    </IconButton></>}
            </ListItemSecondaryAction>
            }
        </>;
    };

    const createVideoThumbnail = (url, index) => {
        const videoId = getYoutubeVideoId(url);
        let style = {};
        if (videoId)
            style = {backgroundImage: `url(${getYoutubeThumbnailUrl(videoId)}`};
        else
            style = {backgroundColor: 'black'};

        return <div className={classes.attachment}
                    style={style}
                    onClick={openMediaModal(idea.fileIds.length + index)}>
            <YouTubeIcon style={{color: 'orangered', height: '60px', width: '60px'}}/>
        </div>
    };

    const renderDesktopGalleryThumbs = () => {
        let files = idea.fileIds;
        let videos = idea.embeddableVideos;
        let moreInGallery = 0;
        if (files.length + videos.length > 0) {

            if (files.length + videos.length > 6) {
                moreInGallery = files.length + videos.length - 5;
                files = files.slice(0, 5);
                videos = videos.slice(0, 5 - files.length);
            }

            return <CardContent className={'cardContent'}>
                <Grid container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      spacing={2}
                >
                    {files.map((id, index) => <Grid item xs={12} sm={6} md={2} lg={2}>
                        <div className={classes.attachment}
                             style={{backgroundImage: `url(${API_URL}/file?fileId=${id})`}}
                             onClick={openMediaModal(index)}>
                        </div>
                    </Grid>)}
                    {videos.map((url, index) => <Grid item xs={12} sm={6} md={2} lg={2}>
                        {createVideoThumbnail(url, index)}
                    </Grid>)}
                    {moreInGallery > 0 &&
                    <Grid item xs={12} sm={6} md={2} lg={2}>
                        <div className={clsx(classes.attachment, classes.moreInGallery)}
                             style={{backgroundImage: 'url("/images/gallery_icon.png")'}}
                             onClick={openMediaModal(0)}>
                            +{moreInGallery}
                        </div>
                    </Grid>}
                </Grid>
            </CardContent>
        } else
            return null;
    };

    const renderMobileGalleryThumbs = () => {
        const files = idea.fileIds.map((id, index) => <div className={classes.attachment}
                                                           style={{backgroundImage: `url(${API_URL}/file?fileId=${id})`}}
                                                           onClick={openMediaModal(index)}>
        </div>);
        const videos = idea.embeddableVideos.map((url, index) => createVideoThumbnail(url, index));

        return <HorizontalScroll scrollContainerRef={scrollContainerRef} hideScrollButtons>
            {files.concat(videos)}
        </HorizontalScroll>
    };

    const renderCenteredButtonAndIdeaStats = paddingTop => {
        return <CardContent className={'cardContent'} style={{paddingTop}}>
            <Grid container
                  justify="center"
                  spacing={2}
            >
                <Grid item xs={12} style={{textAlign: 'center'}}>
                    <Button color='primary' variant="contained"
                            startIcon={isIdeaLiked() ? <FavoriteIcon/> : <FavoriteBorderIcon/>}
                            onClick={likeClickHandler(ideaId, isIdeaLiked())}
                            className={classes.appreciateButton}>
                        {intl.formatMessage({id: isIdeaLiked() ? 'unlike_button' : 'appreciate_button'})}
                    </Button>
                </Grid>
                <Grid item xs='auto'>
                        <span className={classes.ideaStatWrapper}>
                            <div style={{padding: '12px', display: 'flex'}}>
                                <VisibilityIcon fontSize='small'/>
                            </div>
                            <Typography component='span' style={{fontSize: '18px'}}>
                                {idea.viewCount}
                            </Typography>
                        </span>
                </Grid>
                <Grid item xs='auto'>
                        <span className={classes.ideaStatWrapper}>
                            <div style={{padding: '12px', display: 'flex'}}>
                                <ChatBubbleOutlinedIcon fontSize='small'/>
                            </div>
                            <Typography component='span' style={{fontSize: '18px'}}>
                                {idea.commentCount}
                            </Typography>
                        </span>
                </Grid>
                <Grid item xs='auto'>
                        <span className={classes.ideaStatWrapper}>
                            <div style={{padding: '12px', display: 'flex'}}>
                                <FavoriteIcon fontSize='small'/>
                            </div>
                            <Typography component='span' style={{fontSize: '18px'}}>
                                {idea.likeCount}
                            </Typography>
                        </span>
                </Grid>
                <Grid item xs='auto'>
                        <span className={classes.ideaStatWrapper}>
                            <div style={{padding: '12px', display: 'flex'}}>
                                <ShareIcon fontSize='small'/>
                            </div>
                            <Typography component='span' style={{fontSize: '18px'}}>
                                {idea.shareCount}
                            </Typography>
                        </span>
                </Grid>
            </Grid>
        </CardContent>;
    };

    const renderAvatar = () => {
        if (!user.id) {
            return <AccountCircleIcon
                style={{color: 'lightgray', fontSize: '50px', marginTop: '-5px', marginLeft: '-5px'}}/>
        } else {
            if (user.avatarImageId)
                return <Avatar src={`${API_URL}/file?fileId=${user.avatarImageId}`}/>;
            else return <Avatar
                className={classes.avatar}>{user.name.split(/\s/).slice(0, 2).map(s => s.charAt(0)).join("").toUpperCase()}
            </Avatar>;
        }
    };

    return <>
        <Card elevation={3} style={{overflow: 'visible'}}>
            <CardContent className={'cardHeader'}>
                {/*Link to={categoryLink()} className={classes.link}>
                    <Typography gutterBottom variant="body2" color="textSecondary">
                        {categoryName()}
                    </Typography>
                </Link>*/}
                <Grid container justify="center">
                    <Grid item xs={12} md>
                        <Typography gutterBottom className={classes.ideaName}>
                            {idea.name}
                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={12} md={'auto'}>
                            <Button color='primary' variant="contained"
                                    startIcon={isIdeaLiked() ? <FavoriteIcon/> : <FavoriteBorderIcon/>}
                                    onClick={likeClickHandler(ideaId, isIdeaLiked())}
                                    className={classes.appreciateButton}>
                                {intl.formatMessage({id: isIdeaLiked() ? 'unlike_button' : 'appreciate_button'})}
                            </Button>
                        </Grid>
                    </Hidden>
                </Grid>
                <Grid container justify="center" spacing={1}>
                    <Grid item xs={12} md>
                        <Typography className={classes.createdBy}>
                            {intl.formatMessage({id: 'created_by'})} {idea.creatorName} {intl.formatMessage({id: 'on'})} {formatDate(idea.createdAt)}
                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        <Grid item xs={12} md={'auto'}>
                            <span className={classes.ideaStatWrapper}>
                                <div style={{padding: '12px', display: 'flex'}}>
                                    <VisibilityIcon fontSize='small'/>
                                </div>
                                <Typography component='span' style={{fontSize: '18px'}}>
                                    {idea.viewCount}
                                </Typography>
                            </span>
                            <span className={classes.ideaStatWrapper}>
                                <div style={{padding: '12px', display: 'flex'}}>
                                    <ChatBubbleOutlinedIcon fontSize='small'/>
                                </div>
                                <Typography component='span' style={{fontSize: '18px'}}>
                                    {idea.commentCount}
                                </Typography>
                            </span>
                            <span className={classes.ideaStatWrapper}>
                                <div style={{padding: '12px', display: 'flex'}}>
                                    <FavoriteIcon fontSize='small'/>
                                </div>
                                <Typography component='span' style={{fontSize: '18px'}}>
                                    {idea.likeCount}
                                </Typography>
                            </span>
                            <span className={classes.ideaStatWrapper}>
                                <div style={{padding: '12px', display: 'flex'}}>
                                    <ShareIcon fontSize='small'/>
                                </div>
                                <Typography component='span' style={{fontSize: '18px'}}>
                                    {idea.shareCount}
                                </Typography>
                            </span>
                        </Grid>
                    </Hidden>
                    <Grid container spacing={1} item xs={12}>
                        <Grid item>
                            <StyledRating precision={0.1}
                                          value={rating}
                                          readOnly={!user.loggedIn}
                                          style={{padding: '6px 0'}}
                                          onChange={(event, newValue) => submitIdeaRating(newValue)}/>
                        </Grid>
                        <Grid item xs>
                            {rating !== 0 ? <Typography style={{fontWeight: 'bold', opacity: 0.5, padding: '6px 0'}}>
                                {intl.formatMessage({id: 'rating'})}:&nbsp;{rating}
                            </Typography> : <Typography style={{fontWeight: 'bold', opacity: 0.5, padding: '6px 0'}}>
                                {intl.formatMessage({id: 'no_rating_yet'})}
                            </Typography>}
                        </Grid>
                        {user.loggedIn && <Grid item>
                            <Button color='primary'
                                    onClick={() => openShareDialog(ideaId)}
                                    endIcon={<ShareIcon/>}
                            >
                                {intl.formatMessage({id: 'share'})}
                            </Button>
                        </Grid>}
                    </Grid>
                </Grid>
            </CardContent>
            <div style={{position: 'absolute', marginTop: '-16px'}}>
                {getChips()}
            </div>
            {isAdmin &&
            <div className={classes.tagButtonWrapper}>
                {renderButton()}
            </div>
            }
            {idea.featuredImageId ? (
                <CardMedia className={classes.media} image={`${API_URL}/file?fileId=${idea.featuredImageId}`}/>
            ) : (<div style={{textAlign: 'center', backgroundColor: '#fafafa', color: '#f1f1f1'}}>
                    <SvgIcon style={{height: '230px', width: '230px'}}>
                        <svg aria-hidden="true" focusable="false"
                             role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 512 512">
                            <path
                                d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z"/>
                            <path
                                d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z"/>
                        </svg>
                    </SvgIcon>
                </div>
            )}
            <Hidden smDown>
                {renderDesktopGalleryThumbs()}
            </Hidden>
            <Hidden mdUp>
                {renderMobileGalleryThumbs()}
                {renderCenteredButtonAndIdeaStats('32px')}
            </Hidden>
            <CardContent className={'cardContent'}>
                <Typography gutterBottom className={classes.ideaDesc}>
                    {parse(DOMPurify.sanitize(idea.description))}
                </Typography>
            </CardContent>
            <Hidden smDown>
                {renderCenteredButtonAndIdeaStats()}
            </Hidden>
            <CardContent className={'cardContent'} style={{display: 'flex'}}>
                {user.id === idea.ownerId && (
                    <Link to={`/idea/${ideaId}/edit`} style={{textDecoration: 'none'}}>
                        <Button color='primary' variant="contained" style={{color: 'white'}}>
                            {intl.formatMessage({id: 'edit'})}
                        </Button>
                    </Link>
                )}
            </CardContent>
        </Card>
        <CardContent style={{padding: '32px 0 16px'}}>
            <Typography className={classes.commentsCaption} gutterBottom>
                {intl.formatMessage({id: 'comments'})}
            </Typography>
            <Typography gutterBottom>
                {idea.commentCount} {intl.formatMessage({id: 'comments'})}
            </Typography>
        </CardContent>
        <CardContent style={{padding: '16px 0'}}>
            <Grid container alignItems={'flex-start'} style={{paddingTop: '10px'}}>
                <Hidden xsDown>
                    <Grid item style={{paddingRight: '8px'}}>
                        {renderAvatar()}
                    </Grid>
                </Hidden>
                <Grid item xs className={classes.commentInput}>
                    <div style={{backgroundColor: 'white', borderRadius: '6px', padding: '10px'}}>
                        <InputBase
                            style={{padding: 0}}
                            placeholder={intl.formatMessage({id: 'join_the_discussion'}).toUpperCase()}
                            name='comment'
                            margin="dense"
                            fullWidth
                            multiline
                            disabled={!user.loggedIn}
                            inputProps={{maxLength: 600}}
                            endAdornment={<InputAdornment position="end">{comment.length}/600</InputAdornment>}
                            value={comment}
                            onChange={handleInputChange}/>
                    </div>
                </Grid>
                <Grid item xs={12} sm='auto' style={{textAlign: 'end'}}>
                    <Button color='primary' variant="contained" disabled={!user.loggedIn}
                            style={{color: 'white', height: '38px'}}
                            onClick={postComment}>
                        {intl.formatMessage({id: 'post'})}
                    </Button>
                </Grid>
            </Grid>
            {!user.loggedIn &&
            <Grid container spacing={1} alignItems={'flex-start'} style={{paddingTop: '10px'}}>
                <Grid item xs={12}>
                    <Typography gutterBottom className={classes.logInWith}>
                        {intl.formatMessage({id: 'log_in_with'}).toUpperCase()}
                    </Typography>
                </Grid>
                <Grid item>
                    <div className={classes.logInWithIconWrapper} onClick={() => redirectTo('/login')}>
                        <img src="/images/facebook.png" style={{height: '23px'}}/>
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.logInWithIconWrapper} onClick={() => redirectTo('/login')}>
                        <img src="/images/google_colored.png" style={{height: '23px'}}/>
                    </div>
                </Grid>
                <Grid item>
                    <div className={classes.logInWithIconWrapper} onClick={() => redirectTo('/login')}>
                        <img src="/images/logo384.png" style={{height: '23px'}}/>
                    </div>
                </Grid>
            </Grid>}
        </CardContent>
        <CardContent style={{padding: '16px 0'}}>
            {ideaComments.loaded &&
            (ideaComments.items.length ? (<>
                    <List dense>
                        <InfiniteScroll
                            loadMore={loadAdditionalComments}
                            hasMore={ideaComments.items.length < idea.commentCount}
                            threshold={100}
                            loader={<Spinner minHeight={'15vh'}/>}
                        >
                            {renderComments()}
                        </InfiniteScroll>
                    </List>
                </>
            ) : (
                <Typography align='center' color="textSecondary">
                    {intl.formatMessage({id: 'no_comments_yet'})}
                </Typography>
            ))
            }
        </CardContent>
        <IdeaStateMenu handleClose={handleClose} anchorEl={anchorEl} handleSubmit={handleIdeaStateChange}/>
        <IdeaMedia isOpen={ideaMediaModalOpen}
                   handleClose={closeMediaModal}
                   fileIds={idea.fileIds}
                   embeddableVideos={idea.embeddableVideos}
                   preselectedSlide={selectedMediaIndex}/>
        <GenericDialog open={deleteCommentModalOpen}
                       onClose={closeCommentModal}
                       onSubmit={deleteHandler}
                       titleText={"idea_comment_delete"}
                       contentText={'idea_comment_delete_are_you_sure'}
                       submitButtonText={'delete'}/>
    </>
};

const mapDispatchToProps = dispatch => {
    return {
        changeIdeaState: body => dispatch(changeIdeaState(body)),
        incrementIdeaViews: ideaId => dispatch(incrementIdeaViews(ideaId)),
        rateIdea: (ideaId, value) => dispatch(createIdeaRating(ideaId, value))
    };
};

export default connect(null, mapDispatchToProps)(IdeaDetail);
