import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import {LabeledText} from "../common/LabeledText";
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    subscriptionActive: {
        color: 'green'
    },
    subscriptionInactive: {
        color: 'orangered'
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    }
}));

const ActiveSubscription = props => {

    const classes = useStyles();
    const {subscription, cancelSubscription, changeSubscription, renewSubscription} = props;
    const currentSubscription = subscription.current || {};
    const nextSubscription = subscription.next || {};
    const features = currentSubscription.features || {};

    const timestampToDate = ts => {
        const date = new Date(ts * 1000);
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    };

    const getValidTo = () => {
        if (currentSubscription.validTo)
            return timestampToDate(currentSubscription.validTo);
        else
            return props.intl.formatMessage({id: 'infinite'});
    };

    const isSubscriptionActive = () => {
        return currentSubscription.active || nextSubscription.active;
    };

    const displayNextSubscription = () => {
        return nextSubscription.id !== undefined;
    };

    const parseFeature = key => {
        if (features[key] === -1)
            return props.intl.formatMessage({id: 'subscription_feature_unlimited'});
        if (key === 'support')
            return props.intl.formatMessage({id: 'subscription_feature_support_' + features[key]});
        if (key === 'ownDomain')
            return props.intl.formatMessage({id: features[key] ? 'subscription_feature_own_or_3rd_level_domain' : 'subscription_feature_3rd_level_domain'});
        if (key === 'privateCrowd')
            return props.intl.formatMessage({id: features[key] ? 'subscription_feature_private_crowd_enabled' : 'subscription_feature_private_crowd_disabled'});
        if (key === 'privateUsers')
            return props.intl.formatMessage({id: features[key] ? 'subscription_feature_private_users_enabled' : 'subscription_feature_private_users_disabled'});

        return features[key];
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <LocalActivityIcon/>
                </Avatar>
            }
            title={<Typography
                className={'cardHeaderText'}>{props.intl.formatMessage({id: 'crowd_subscription'})}</Typography>}
            subheader={currentSubscription.id && <Typography
                className={isSubscriptionActive() ? classes.subscriptionActive : classes.subscriptionInactive}>
                {props.intl.formatMessage({id: isSubscriptionActive() ? 'crowd_subscription_active' : 'crowd_subscription_cancelled'})}
            </Typography>}
            className={'cardHeader'}
        />
        {currentSubscription.id ? (<CardContent className={'cardContent'}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid item xs={12} sm={displayNextSubscription() ? 6 : 12}>
                        <LabeledText
                            fullWidth
                            label={props.intl.formatMessage({id: 'current_subscription_name'})}
                            name="name"
                            value={props.intl.formatMessage({id: currentSubscription.name.toLowerCase()})}
                        />
                    </Grid>
                    {displayNextSubscription() && (
                        <Grid item xs={12} sm={6}>
                            <LabeledText
                                fullWidth
                                label={props.intl.formatMessage({id: 'next_subscription_name'})}
                                name="name"
                                value={props.intl.formatMessage({id: nextSubscription.name.toLowerCase()})}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <LabeledText
                            fullWidth
                            label={props.intl.formatMessage({id: 'subscription_start'})}
                            name="name"
                            value={timestampToDate(currentSubscription.validFrom)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledText
                            fullWidth
                            label={props.intl.formatMessage({id: currentSubscription.paymentProcessorId && isSubscriptionActive() ? 'subscription_renewal_date' : 'subscription_end'})}
                            name="name"
                            value={getValidTo()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <LabeledText
                            fullWidth
                            label={props.intl.formatMessage({id: 'subscription_price'})}
                            name="name"
                            value={`${currentSubscription.baseMonthlyPrice} ${currentSubscription.currency.toUpperCase()}`}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <List dense
                              subheader={
                                  <Typography>
                                      {props.intl.formatMessage({id: 'subscription_features'})}
                                  </Typography>
                              }>
                            {Object.keys(features).map(key =>
                                <ListItem>
                                    <ListItemText
                                        primary={props.intl.formatMessage({id: 'subscription_features_' + key})}
                                        secondary={parseFeature(key)}
                                    />
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                    {isSubscriptionActive() ? (<>
                            <Grid item xs={12} sm='auto'>
                                <Button color='primary' variant="contained" className={classes.button}
                                        onClick={changeSubscription}>
                                    {props.intl.formatMessage({id: 'change_subscription'})}
                                </Button>
                            </Grid>
                            {currentSubscription.paymentProcessorId && (
                                <Grid item xs={12} sm='auto'>
                                    <Button color='secondary' variant="contained" className={classes.button}
                                            onClick={cancelSubscription}>
                                        {props.intl.formatMessage({id: 'cancel_subscription'})}
                                    </Button>
                                </Grid>
                            )}
                        </>
                    ) : (<Grid item xs={12} sm='auto'>
                            <Button color='primary' variant="contained" className={classes.button}
                                    onClick={renewSubscription}>
                                {props.intl.formatMessage({id: 'renew_subscription'})}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        ) : (
            <CardContent className={'cardContent'}>
                <Typography align='center'>
                    {props.intl.formatMessage({id: 'no_active_subscription'})}
                </Typography>
            </CardContent>
        )}
    </Card>;
};

export default injectIntl(ActiveSubscription);