import React from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ServiceAgreementDialog from "../registration/ServiceAgreementDialog";
import PrivacyPolicyDialog from "../registration/PrivacyPolicyDialog";
import {Hidden} from "@material-ui/core";
import {CROWDEA_ORIGIN} from "../../constants";
import {Link} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    positioningWrapper: {
        flexShrink: 0,
    },
    footer: {
        paddingTop: '30px',
        paddingBottom: '40px',
        color: '#C1C4C8',
    },
    crowdeaLogo: {
        height: '20px'
    },
    divider: {
        marginBottom: '20px'
    },
    centerOnMobile: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        },
    },
    link: {
        textDecoration: 'none',
        color: 'black',
    },
}));


const Footer = props => {

    const classes = useStyles();
    const [termsShown, setTermsShown] = React.useState(false);
    const [privacyPolicyShown, setPrivacyPolicyShown] = React.useState(false);

    const showTermsOfService = event => {
        event.preventDefault();
        setTermsShown(true);
    };

    const showPrivacyPolicy = event => {
        event.preventDefault();
        setPrivacyPolicyShown(true);
    };

    return <>
        <div className={classes.positioningWrapper}>
            <Container maxWidth="xl" className={classes.footer}>
                <Divider className={classes.divider}/>
                <Grid container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                      spacing={4}
                >
                    <Grid item xs={12} md={'auto'} className={classes.centerOnMobile}>
                        <a href={CROWDEA_ORIGIN}>
                            <img className={classes.crowdeaLogo} src="/images/crowdea.png"/>
                        </a>
                    </Grid>
                    <Grid item xs={12} md={'auto'} className={classes.centerOnMobile}>
                        <Typography className={classes.copyright}>
                            {'© ' + new Date().getFullYear() + ' ' + props.intl.formatMessage({id: 'copyright'})}
                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        <Grid item md/>
                    </Hidden>
                    <Grid item xs={12} md={'auto'} className={classes.centerOnMobile}>
                        <Link className={classes.link}
                              href=""
                              onClick={showTermsOfService}>
                            {props.intl.formatMessage({id: 'terms_of_service'})}
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={'auto'} className={classes.centerOnMobile}>
                        <Link className={classes.link}
                              href=""
                              onClick={showPrivacyPolicy}>
                            {props.intl.formatMessage({id: 'privacy_policy'})}
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </div>
        <ServiceAgreementDialog open={termsShown} onClose={() => setTermsShown(false)}/>
        <PrivacyPolicyDialog open={privacyPolicyShown} onClose={() => setPrivacyPolicyShown(false)}/>
    </>;
};

export default injectIntl(Footer);