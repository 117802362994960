import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import {useIntl} from "react-intl";
import {lighten, makeStyles} from "@material-ui/core/styles";
import {IdeaState} from "../../constants";

const useStyles = makeStyles(theme => ({
    highlight: {
        '&:hover': {
            backgroundColor: lighten(theme.palette.primary.main, 0.85),
        }
    }
}));

export const IdeaStateMenu = props => {

    const classes = useStyles();
    const intl = useIntl();
    const {handleClose, anchorEl, handleSubmit} = props;

    const handleClick = ideaState => () => {
        handleSubmit(ideaState);
        handleClose();
    };

    return <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} transition>
        {({TransitionProps}) => (
            <Grow {...TransitionProps}>
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList>
                            {Object.values(IdeaState).map(value =>
                                <MenuItem key={value}
                                          value={value}
                                          className={classes.highlight}
                                          onClick={handleClick(value)}>
                                    <Typography>
                                        {intl.formatMessage({id: value.toLowerCase()})}
                                    </Typography>
                                </MenuItem>
                            )}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>;
};