import React, {useEffect} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {injectIntl} from "react-intl";
import {
    changePassword,
    clearChangePasswordErrors,
    loadNotificationSettings,
    updateUser,
    uploadUserAvatar
} from "../../actions/user";
import ProfileInfo from "./UserProfileInfo";
import Grid from "@material-ui/core/Grid";
import ChangePassword from "./ChangePassword";
import SetPassword from "./SetPassword";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import NotificationSettings from "./NotificationSettings";
import {Link, Redirect, Route, useHistory, useLocation} from "react-router-dom";
import NotificationHistory from "./NotificationHistory";
import {makeStyles} from "@material-ui/core/styles";
import {loadNotifications, markNotificationsAsRead} from "../../actions/notification";
import MobileNavMenu from "../common/MobileNavMenu";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {CrowdUserType} from "../../constants";
import {deleteCreditCard, loadCreditCards} from "../../actions/creditCards";
import CreditCards from "./CreditCards";
import UserAvatar from "./UserAvatar";
import {withAuthentication} from "../common/withAuthentication";


const useStyles = makeStyles(theme => ({
    gridItem: {
        width: '100%'
    },
    tabTextColorPrimary: {
        color: theme.palette.primary.main
    }
}));

// TODO add transfer ownership card, like on heroku

const MyAccount = props => {

    const classes = useStyles();
    const location = useLocation();
    const {
        notificationSettings,
        notifications,
        loadNotifications,
        loadSettings,
        markNotificationsAsRead,
        creditCards,
        loadCreditCards,
        deleteCreditCard,
        crowds,
        user
    } = props;
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const shouldDisplayBilling = crowds.items.some(c => c.role === CrowdUserType.CROWD_ADMIN);

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = (link, newValue) => {
        setValue(newValue);
        history.push(link);
        handleClose();
    };

    const createNavigationSections = () => {
        const nav = [
            {
                i18n: 'profile',
                link: `/account/profile`
            },
            {
                i18n: 'email_notifications',
                link: `/account/notificationSettings`
            },
            {
                i18n: 'notifications_history',
                link: `/account/notificationHistory`
            }
        ];

        if (shouldDisplayBilling)
            return nav.concat([
                {
                    i18n: 'credit_cards',
                    link: `/account/creditCards`
                }
            ]);
        return nav;
    };

    const navigation = createNavigationSections();

    const preselectTab = () => {
        return Math.max(navigation.map(nav => nav.link).indexOf(location.pathname), 0);
    };

    useEffect(() => {
        if (!notificationSettings.length) {
            loadSettings();
        }
        if (!creditCards.loaded)
            loadCreditCards();
    }, []);

    const [value, setValue] = React.useState(preselectTab());

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return <>
        <Paper elevation={3} style={{marginBottom: '10px'}}>
            <Hidden smUp>
                <Button
                    fullWidth
                    disableElevation
                    variant="contained"
                    endIcon={<ExpandMoreIcon/>}
                    style={{background: 'transparent'}}
                    onClick={openMenu}
                >
                    {props.intl.formatMessage({id: navigation[value].i18n})}
                </Button>
            </Hidden>
            <Hidden xsDown>
                <Tabs value={value} onChange={handleChange} indicatorColor="primary"
                      variant='fullWidth'>
                    <Tab className={clsx({[classes.tabTextColorPrimary]: value === 0})}
                         label={props.intl.formatMessage({id: 'profile'})}
                         component={Link}
                         to="/account/profile"
                    />
                    <Tab className={clsx({[classes.tabTextColorPrimary]: value === 1})}
                         label={props.intl.formatMessage({id: 'email_notifications'})}
                         component={Link}
                         to="/account/notificationSettings"
                    />
                    <Tab className={clsx({[classes.tabTextColorPrimary]: value === 2})}
                         label={props.intl.formatMessage({id: 'notifications_history'})}
                         component={Link}
                         to="/account/notificationHistory"
                    />
                    {shouldDisplayBilling && <Tab className={clsx({[classes.tabTextColorPrimary]: value === 3})}
                                                  label={props.intl.formatMessage({id: 'credit_cards'})}
                                                  component={Link}
                                                  to="/account/creditCards"
                    />}
                </Tabs>
            </Hidden>
        </Paper>
        <Route path={["/account", "/account/profile"]} exact render={() =>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                spacing={1}
            >
                <Grid item className={classes.gridItem}>
                    <UserAvatar user={user} updateAccount={props.updateAccount}
                                uploadUserAvatar={props.uploadUserAvatar}/>
                </Grid>
                <Grid item className={classes.gridItem}>
                    <ProfileInfo user={user} updateAccount={props.updateAccount}
                                 handleTabChange={() => setValue(0)}/>
                </Grid>
                <Grid item className={classes.gridItem}>
                    {user.hasPassword ?
                        <ChangePassword apiErrors={props.passwordChange.errors}
                                        onSubmit={props.changePassword}
                                        user={user}
                                        clearApiErrors={props.clearApiErrors}
                        /> :
                        <SetPassword onSubmit={props.changePassword}
                                     user={user}/>
                    }
                </Grid>
            </Grid>
        }/>
        <Route path="/account/notificationSettings" exact
               render={() => <NotificationSettings notificationSettings={notificationSettings}/>}/>
        <Route path="/account/notificationHistory" exact
               render={() => <NotificationHistory handleTabChange={() => setValue(2)}
                                                  notifications={notifications}
                                                  loadNotifications={loadNotifications}
                                                  markNotificationsAsRead={markNotificationsAsRead}/>}/>
        <Route path="/account/creditCards" exact
               render={() => {
                   if (shouldDisplayBilling)
                       return <CreditCards
                           creditCards={creditCards}
                           loadCreditCards={loadCreditCards}
                           deleteCreditCard={deleteCreditCard}/>;
                   return <Redirect to="/account"/>;
               }}/>
        <MobileNavMenu handleClose={handleClose} anchorEl={anchorEl} navigation={navigation}
                       handleClick={handleMenuClick}/>
    </>
};

const mapStateToProps = state => {
    return {
        user: state.user,
        crowds: state.crowds,
        passwordChange: state.passwordChange,
        notificationSettings: state.notificationSettings,
        notifications: state.notifications,
        creditCards: state.creditCards
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAccount: acc => dispatch(updateUser(acc, true)),
        uploadUserAvatar: (formData, successCb) => dispatch(uploadUserAvatar(formData, successCb)),
        changePassword: (body, hadPassword, successCallback) => dispatch(changePassword(body, hadPassword, successCallback)),
        clearApiErrors: () => dispatch(clearChangePasswordErrors()),
        loadSettings: () => dispatch(loadNotificationSettings()),
        loadNotifications: (cb, offset, limit) => dispatch(loadNotifications(cb, offset, limit)),
        markNotificationsAsRead: ids => dispatch(markNotificationsAsRead(ids)),
        loadCreditCards: () => dispatch(loadCreditCards()),
        deleteCreditCard: (card, successCallback) => dispatch(deleteCreditCard(card, successCallback)),
    };
};

export default compose(
    withAuthentication,
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps),
)(MyAccount);