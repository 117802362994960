import React from "react";
import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import {injectIntl} from "react-intl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

const Trial = props => {

    const {
        isLandingPage,
        buttonClickHandler,
        cardClickHandler,
        hideButtons,
        selected
    } = props;

    const cardElevation = isLandingPage ? 0 : 3;

    return <Card
        className={clsx('pricingCard', selected === 0 ? 'pricingCardSelected' : 'pricingCardHover', {'cursorPointer': selected !== undefined})}
        elevation={cardElevation}
        onClick={cardClickHandler}>
        <div className={'pricingCardImageWrapper'}>
            <img src="/images/free_subscription.png"/>
        </div>
        <CardContent>
            <Typography variant={'h4'}
                        className={clsx('pricingCardHeaderText', 'pricingCardItemMargin')}>
                {props.intl.formatMessage({id: 'trial'})}
            </Typography>
            <Typography color='primary'
                        className={clsx('pricingCardTextWrapper', 'pricingCardPriceText', {'purpleColor': isLandingPage})}>
                {props.intl.formatMessage({id: 'free'})}
            </Typography>
            <div style={{height: '24px'}}>&nbsp;</div>
            {!hideButtons && <Button variant="contained" color="primary" disableElevation
                                     className={clsx('pricingCardButton', 'pricingCardItemMargin', {'purpleBackground': isLandingPage})}
                                     onClick={buttonClickHandler}>
                {props.intl.formatMessage({id: 'try_for_free'})}
            </Button>}
            <div>&nbsp;</div>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'max_users'}, {number: '50'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'max_ideas'}, {number: '50'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'third_level_domain'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'shared_crowdea_users'})}
            </Typography>
        </CardContent>
    </Card>
};

export default injectIntl(Trial);