import {ActionType, API_URL} from "../constants";
import {extractBodyOrThrow} from "./common";

export function loadPublicCrowds() {
    return async dispatch => {
        fetch(`${API_URL}/public/crowds`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(crowds => dispatch(crowdsLoaded(crowds)))
            .catch(error => console.log(error.message));
    };
}

function crowdsLoaded(items) {
    return {
        type: ActionType.LANDING_PAGE_CROWDS_LOADED,
        items
    };
}

export function loadMostLiked() {
    return async dispatch => {
        fetch(`${API_URL}/public/ideas/mostLiked`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(crowds => dispatch(mostLikedLoaded(crowds)))
            .catch(error => console.log(error.message));
    };
}

function mostLikedLoaded(items) {
    return {
        type: ActionType.LANDING_PAGE_MOST_LIKED_LOADED,
        items
    };
}

export function loadMostRecent() {
    return async dispatch => {
        fetch(`${API_URL}/public/ideas/recent`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(crowds => dispatch(mostRecentLoaded(crowds)))
            .catch(error => console.log(error.message));
    };
}

function mostRecentLoaded(items) {
    return {
        type: ActionType.LANDING_PAGE_MOST_RECENT_LOADED,
        items
    };
}