import React from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import SidebarButton from "../layout/SidebarButton";
import Typography from "@material-ui/core/Typography";
import CrowdNavbarMenu from "./CrowdNavbarMenu";
import {Link} from "react-router-dom";
import {API_URL} from "../../constants";

const useStyles = makeStyles(theme => ({
    appBar: {
        background: 'white'
    },
    toolBarDesktop: {
        height: '100px'
    },
    toolBarMobile: {
        height: '65px'
    },
    crowdLogo: {
        height: '100%',
        marginRight: '15px'
    },
    crowdName: {
        color: '#777',
        width: '100%',
        fontSize: '30px',
        [theme.breakpoints.down('md')]: {
            fontSize: '22px'
        }
    },
    crowdLogoLink: {
        height: '100%'
    }
}));

const CrowdNavbar = props => {

    const classes = useStyles();
    const {
        crowdName,
        hideTitle,
        toggleDrawer,
        user,
        generateAddIdeaClickHandler,
        notifications,
        markNotificationsAsRead,
        featuredImageId
    } = props;

    return <>
        <Hidden lgUp>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolBarMobile}>
                    {featuredImageId !== null && <Link to='/' className={classes.crowdLogoLink}>
                        <img className={classes.crowdLogo} src={`${API_URL}/file?fileId=${featuredImageId}`}/>
                    </Link>}
                    {!hideTitle && <Typography className={classes.crowdName}>
                        <Link to='/' style={{textDecoration: 'none', color: 'inherit'}}>
                            {crowdName}
                        </Link>
                    </Typography>}
                    <Grid container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                          spacing={4}
                    >
                        <SidebarButton toggleDrawer={toggleDrawer}/>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Hidden>
        <Hidden mdDown>
            <AppBar position="fixed" className={classes.appBar}>
                <Container maxWidth="xl" className={classes.navbarContainer}>
                    <Toolbar className={classes.toolBarDesktop}>
                        {featuredImageId !== null &&
                        <Link to='/' className={classes.crowdLogoLink}>
                            <img className={classes.crowdLogo} src={`${API_URL}/file?fileId=${featuredImageId}`}/>
                        </Link>}
                        {!hideTitle && <Typography className={classes.crowdName}>
                            <Link to='/' style={{textDecoration: 'none', color: 'inherit'}}>
                                {crowdName}
                            </Link>
                        </Typography>}
                        <Grid container
                              direction="row"
                              justify="flex-end"
                              alignItems="center"
                              spacing={4}
                        >
                            <CrowdNavbarMenu user={user}
                                             generateAddIdeaClickHandler={generateAddIdeaClickHandler}
                                             notifications={notifications}
                                             markNotificationsAsRead={markNotificationsAsRead}/>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
        </Hidden>
    </>
};

export default injectIntl(CrowdNavbar);