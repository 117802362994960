import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import SelectLanguageSidebar from "../common/selectlanguage/SelectLanguageSidebar";
import capitalize from "@material-ui/core/utils/capitalize";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: 240,
            flexShrink: 0
        },
    },
    drawerPaper: {
        width: 240,
        background: 'linear-gradient(to right, #1EB1F1, #27D2F8)'
    },
    toolbar: theme.mixins.toolbar,
    link: {
        textDecoration: 'none',
        color: 'white',
    },
    linkText: {
        fontSize: '23px'
    },
    menuItem: {
        color: 'white',
        fontSize: '28px'
    },
    notificationsBadge: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        width: '30px',
        height: '30px',
        marginLeft: '5px'
    }
}));

const DashboardSidebar = props => {

    const classes = useStyles();
    const {notifications, intl, open, toggleDrawer, closeDrawer, logout} = props;

    return <>
        <nav className={classes.drawer}>
            <Hidden lgUp>
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={open}
                    onClose={toggleDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <div onClick={closeDrawer} onKeyDown={closeDrawer} className={classes.toolbar}>
                        <ListItem button key={'menu'}>
                            <Typography className={classes.menuItem}>
                                {intl.formatMessage({id: 'menu'})}
                            </Typography>
                        </ListItem>
                        <Divider/>
                        <List>
                            <Link to='/crowds' className={classes.link}>
                                <ListItem button key={'crowds'}>
                                    <Typography className={classes.linkText}>
                                        {intl.formatMessage({id: 'crowds'})}
                                    </Typography>
                                </ListItem>
                            </Link>
                            <Link to='/ideas' className={classes.link}>
                                <ListItem button key={'ideas'}>
                                    <Typography className={classes.linkText}>
                                        {capitalize(intl.formatMessage({id: 'ideas'}))}
                                    </Typography>
                                </ListItem>
                            </Link>
                            <Link to='/account/notificationHistory' className={classes.link}>
                                <ListItem button key={'notifications'}>
                                    <Typography className={classes.linkText}>
                                        {capitalize(intl.formatMessage({id: 'notifications'}))}
                                    </Typography>
                                    {notifications.unreadCount > 0 &&
                                    <Avatar className={classes.notificationsBadge}>
                                        {notifications.unreadCount}
                                    </Avatar>
                                    }
                                </ListItem>
                            </Link>
                            <Link to='/account' className={classes.link}>
                                <ListItem button key={'account'}>
                                    <Typography className={classes.linkText}>
                                        {intl.formatMessage({id: 'my_account'})}
                                    </Typography>
                                </ListItem>
                            </Link>
                            <Divider/>
                            <Link to='' className={classes.link}>
                                <ListItem button key={'logout'} onClick={logout}>
                                    <Typography className={classes.linkText}>
                                        {intl.formatMessage({id: 'logout'})}
                                    </Typography>
                                </ListItem>
                            </Link>
                            <Divider/>
                            <SelectLanguageSidebar/>
                        </List>
                    </div>
                </Drawer>
            </Hidden>
        </nav>
    </>;
};

export default injectIntl(DashboardSidebar);