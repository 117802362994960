import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {userLogOut} from "../../actions/login";
import {connect} from "react-redux";
import Footer from "../layout/Footer";
import CrowdNavbar from "./CrowdNavbar";
import CrowdSidebar from "./CrowdSidebar";
import {
    createCrowdViewComment,
    deleteCrowdViewComment,
    loadCrowdView,
    loadCrowdViewCategories,
    loadCrowdViewComments,
    loadCrowdViewIdeas,
    loadCrowdViewQuestionnaire,
    updateCrowdViewComment
} from "../../actions/crowdView";
import MyAccount from "../userAccount/MyAccount";
import CreateIdea from "./CreateIdea";
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {createIdea, updateCrowdIdea} from "../../actions/idea";
import IdeasAll from "./IdeasAll";
import IdeaDetail from "./IdeaDetail";
import IdeaEdit from "./IdeaEdit";
import QuestionnaireDialog from "./QuestionnaireDialog";
import {createIdeaLike, unlikeIdea} from "../../actions/ideaLike";
import {loadIdeasLikedAndCommentedIds, loadUserRoles} from "../../actions/user";
import useDeepCompareEffect from "use-deep-compare-effect";
import SubscriptionLimitReachedDialog from "./SubscriptionLimitReachedDialog";
import {loadNotifications, markNotificationsAsRead} from "../../actions/notification";
import {useRedirectWithState} from "../../hooks/useRedirectWithState";
import CrowdCarousel from "./CrowdCarousel";
import {CustomIntro} from "./CustomIntro";
import {ShareIdeaDialog} from "./ShareIdeaDialog";
import {QuestionnaireType} from "../../constants";

const useStyles = makeStyles(theme => ({
    toolBarDesktop: {
        height: '100px'
    },
    toolBarMobile: {
        height: '65px'
    },
    crowdeaLogo: {
        height: '30px'
    },
    content: {
        flex: '1 0 auto',
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        }
    },
    flexBox: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#F9FAFC',
    },
    toolbarFiller: {
        height: '100px',
        [theme.breakpoints.down('md')]: {
            height: '65px'
        }
    }
}));

const Crowd = props => {

    const classes = useStyles();
    const redirectTo = useRedirectWithState();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [clickedIdeaId, setClickedIdeaId] = React.useState(false);
    const [showIdeaLikeQuestionnaire, setShowIdeaLikeQuestionnaire] = React.useState(false);
    const [showSubLimitDialog, setShowSubLimitDialog] = React.useState(false);
    const [showIdeaShareDialog, setShowIdeaShareDialog] = React.useState(false);
    const [ideaToBeSharedId, setIdeaToBeSharedId] = React.useState(null);

    const {
        domain,
        crowdView,
        categories,
        user,
        userRoles,
        ideas,
        comments,
        createComment,
        loadComments,
        questionnaire,
        createIdeaLike,
        ideasLikedCommented,
        unlikeIdea,
        loadNotifications,
        loadUserRoles,
        notifications,
        markNotificationsAsRead,
        updateComment,
        deleteComment
    } = props;
    const history = useHistory();
    const location = useLocation();
    const isAdmin = userRoles.data[crowdView.data.id];
    const ideaLikeQuestionnaire = (questionnaire.loaded && questionnaire[QuestionnaireType.IDEA_LIKE]) || {};
    const ideaCreationQuestionnaire = (questionnaire.loaded && questionnaire[QuestionnaireType.IDEA_CREATION]) || {};

    const deferLoading = () => {
        return crowdView.loaded && (!crowdView.data.privateCrowd || (crowdView.data.privateCrowd && user.loggedIn));
    };

    useDeepCompareEffect(() => {
        if (!crowdView.loaded)
            props.loadCrowdView(domain);
        if (!categories.loaded && deferLoading())
            props.loadCrowdViewCategories(domain);
        if (!ideas.loaded && deferLoading())
            props.loadIdeas(domain);
        if (!questionnaire.loaded && deferLoading())
            props.loadCrowdViewQuestionnaire(domain);
    }, [user, crowdView]);

    useDeepCompareEffect(() => {
        if (user.loggedIn) {
            props.loadIdeasLikedAndCommentedIds();
            if (!notifications.loaded)
                loadNotifications();
            if (!userRoles.loaded)
                loadUserRoles();
        }
    }, [user]);

    if (crowdView.data.privateCrowd && !user.loggedIn)
        return <Redirect to={{pathname: '/login', state: {from: location.pathname + location.search}}}/>;

    const closeDrawer = event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        toggleDrawer();
    };

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    const likeClickHandler = (ideaId, ideaLiked) => () => {
        if (!user.loggedIn) {
            redirectTo('/login');
            return;
        }

        if (ideaLiked) {
            unlikeIdea(domain, ideaId);
        } else {
            if (ideaLikeQuestionnaire.questions && !ideasLikedCommented.data.ideasAnsweredIds.includes(ideaId)) {
                setClickedIdeaId(ideaId);
                setShowIdeaLikeQuestionnaire(true);
            } else {
                createIdeaLike(domain, ideaId);
            }
        }
    };

    const openShareDialog = ideaId => {
        setIdeaToBeSharedId(ideaId);
        setShowIdeaShareDialog(true);
    };

    const canCreateIdea = () => {
        return crowdView.data.features && (crowdView.data.features.ideas < 0 || ideas.data.length < crowdView.data.features.ideas);
    };

    const generateAddIdeaClickHandler = categoryId => () => {
        if (canCreateIdea())
            if (categoryId)
                history.push({
                    pathname: '/createIdea',
                    state: {categoryId}
                });
            else
                history.push('/createIdea');
        else
            setShowSubLimitDialog(true);
    };

    return <>
        <CrowdNavbar user={user} crowdName={crowdView.data.title} hideTitle={crowdView.data.hideTitle}
                     toggleDrawer={toggleDrawer}
                     notifications={notifications} markNotificationsAsRead={markNotificationsAsRead}
                     generateAddIdeaClickHandler={generateAddIdeaClickHandler}
                     featuredImageId={crowdView.data.featuredImageId}/>
        <CrowdSidebar user={user} logout={props.logout} open={drawerOpen} toggleDrawer={toggleDrawer}
                      notifications={notifications} closeDrawer={closeDrawer} crowdId={crowdView.data.id}
                      userRoles={userRoles}/>
        <div className={classes.toolbarFiller}/>
        {crowdView.data.themingData.displayDefaultSlideshow && <Route
            render={({location}) => location.pathname === '/' || !['account', 'idea', 'createIdea'].includes(location.pathname.split('/')[1])
                ? <CrowdCarousel/>
                : null
            }/>}
        {crowdView.data.themingData.displayCustomIntro && <Route
            render={({location}) => location.pathname === '/' || !['account', 'idea', 'createIdea'].includes(location.pathname.split('/')[1])
                ? <CustomIntro themingData={crowdView.data.themingData}/>
                : null
            }/>}
        <div className={classes.flexBox}>
            <main className={classes.content}>
                <Container maxWidth="xl">
                    <Switch>
                        <Route path="/account" component={MyAccount}/>
                        <Route path="/idea/:ideaId" exact
                               render={() => <IdeaDetail categories={categories} ideas={ideas} user={user}
                                                         isAdmin={isAdmin}
                                                         createComment={createComment(domain)}
                                                         loadComments={loadComments(domain)}
                                                         likeClickHandler={likeClickHandler}
                                                         openShareDialog={openShareDialog}
                                                         ideasLikedCommented={ideasLikedCommented}
                                                         updateComment={updateComment(domain)}
                                                         deleteComment={deleteComment(domain)}
                                                         comments={comments}/>}/>
                        <Route path="/idea/:ideaId/edit"
                               render={() => <IdeaEdit categories={categories}
                                                       questionnaire={ideaCreationQuestionnaire}
                                                       ideas={ideas}
                                                       user={user}
                                                       updateIdea={props.updateIdea}/>}/>
                        <Route path="/createIdea"
                               render={() =>
                                   <CreateIdea canCreate={canCreateIdea()}
                                               questionnaire={ideaCreationQuestionnaire}
                                               createIdea={props.createIdea}
                                               categories={categories}
                                               crowdId={crowdView.data.id}/>}/>
                        <Route path="/:categoryName?"
                               render={() => <IdeasAll categories={categories}
                                                       ideas={ideas}
                                                       isAdmin={isAdmin}
                                                       user={user}
                                                       openShareDialog={openShareDialog}
                                                       generateAddIdeaClickHandler={generateAddIdeaClickHandler}
                                                       ideasLikedCommented={ideasLikedCommented}
                                                       likeClickHandler={likeClickHandler}/>}/>
                    </Switch>
                </Container>
            </main>
            <Footer/>
        </div>
        <QuestionnaireDialog open={showIdeaLikeQuestionnaire}
                             createIdeaLike={createIdeaLike}
                             onClose={() => setShowIdeaLikeQuestionnaire(false)}
                             questionnaire={ideaLikeQuestionnaire}
                             ideaId={clickedIdeaId}
                             domain={domain}/>
        <SubscriptionLimitReachedDialog open={showSubLimitDialog}
                                        onClose={() => setShowSubLimitDialog(false)}/>
        <ShareIdeaDialog open={showIdeaShareDialog} onClose={() => setShowIdeaShareDialog(false)} ideaId={ideaToBeSharedId}/>
    </>
};


const mapStateToProps = state => {
    return {
        user: state.user,
        userRoles: state.userRoles,
        crowdView: state.crowdView,
        categories: state.crowdViewCategories,
        ideas: state.crowdViewIdeas,
        comments: state.comments,
        questionnaire: state.crowdViewQuestionnaire,
        ideasLikedCommented: state.crowdViewIdeasLikedCommented,
        notifications: state.notifications
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(userLogOut(true)),
        loadCrowdView: domain => dispatch(loadCrowdView(domain)),
        loadCrowdViewQuestionnaire: domain => dispatch(loadCrowdViewQuestionnaire(domain)),
        loadCrowdViewCategories: domain => dispatch(loadCrowdViewCategories(domain)),
        loadIdeas: domain => dispatch(loadCrowdViewIdeas(domain)),
        loadComments: domain => (ideaId, cb, offset, limit) => dispatch(loadCrowdViewComments(domain, ideaId, cb, offset, limit)),
        loadUserRoles: () => dispatch(loadUserRoles()),
        loadNotifications: () => dispatch(loadNotifications()),
        loadIdeasLikedAndCommentedIds: () => dispatch(loadIdeasLikedAndCommentedIds()),
        createIdea: (body, successCb, errorCb) => dispatch(createIdea(body, successCb, errorCb)),
        createComment: domain => (content, ideaId, successCb) => dispatch(createCrowdViewComment(domain, content, ideaId, successCb)),
        createIdeaLike: (domain, ideaId, questionnaireId, body, successCb) => dispatch(createIdeaLike(domain, ideaId, questionnaireId, body, successCb)),
        unlikeIdea: (domain, ideaId) => dispatch(unlikeIdea(domain, ideaId)),
        updateIdea: (body, successCb) => dispatch(updateCrowdIdea(body, successCb)),
        markNotificationsAsRead: ids => dispatch(markNotificationsAsRead(ids)),
        updateComment: domain => (ideaId, body, successCb) => dispatch(updateCrowdViewComment(domain, ideaId, body, successCb)),
        deleteComment: domain => (commentId, ideaId) => dispatch(deleteCrowdViewComment(domain, commentId, ideaId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Crowd);