import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Carousel} from "react-responsive-carousel";
import {API_URL} from "../../constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ReactPlayer from "react-player";
import {getYoutubeThumbnailUrl, getYoutubeVideoId} from "./common";
import YouTubeIcon from '@material-ui/icons/YouTube';
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import Hidden from "@material-ui/core/Hidden";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    dialogTitleText: {
        paddingLeft: '10px',
        fontSize: '0.9rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        },
    },
    dialogContent: {
        '&:first-child': {
            paddingTop: 0
        },
        padding: 0
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    dialogTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    dialog: {
        '& div.MuiPaper-root.MuiDialog-paper': {
            [theme.breakpoints.down('xs')]: {
                margin: '16px',
                width: 'calc(100% - 32px)',
                maxWidth: 'calc(100% - 32px)'
            }
        }
    },
    thumbnailsWrapper: {
        margin: 0,
        padding: '16px',
        width: '100%'
    },
    videoThumbnail: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        width: '115px',
        height: '100px',
        borderRadius: '4px',
    },
    imageThumbnail: {
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '115px',
        height: '100px',
        cursor: 'pointer',
        borderRadius: '4px',
    },
    selectedThumbnail: {
        borderColor: theme.palette.primary.main,
        border: '2px solid',
    },
    nonselectedThumbnail: {
        border: '2px solid transparent'
    },
    hoverThumbnail: {
        borderRadius: '4px',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            border: '2px solid'
        }
    },
    videoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        paddingBottom: '62.5%'
    },
    videoSlide: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    imageWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        maxWidth: '1280px',
        width: '100%',
        maxHeight: '800px',
    },
    imageSlide: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: 'auto !important',
        height: 'auto'
    },
    dotIndicator: {
        height: '13px',
        width: '13px',
        opacity: 0.5,
        margin: '13px',
        color: 'white'
    },
    dotIndicatorSelected: {
        opacity: 1
    }
}));

const VideoSlide = ({url, onPlay, playing, videoWrapperClass, videoSlideClass}) => (
    <div className={videoWrapperClass}>
        <ReactPlayer width="100%" height="100%" url={url} controls onPlay={onPlay} playing={playing}
                     className={videoSlideClass}/>
    </div>
);

const PictureSlide = ({pictureId, imageWrapperClass, imageSlideClass}) => (
    <div className={imageWrapperClass}>
        <img alt="" src={`${API_URL}/file?fileId=${pictureId}`} className={imageSlideClass}/>
    </div>
);

const IdeaMedia = props => {

    const classes = useStyles();
    const {
        handleClose,
        isOpen,
        fileIds,
        embeddableVideos,
        preselectedSlide = 0,
        intl
    } = props;

    const [currentSlide, setCurrentSlide] = React.useState(preselectedSlide);
    const [currentlyPlaying, setCurrentlyPlaying] = React.useState();

    useEffect(() => {
        setCurrentSlide(preselectedSlide);
    }, [preselectedSlide]);

    const updateCurrentSlide = carouselIndex => {
        if (currentSlide !== carouselIndex)
            setCurrentSlide(carouselIndex);
        setCurrentlyPlaying();
    };

    const onPlay = index => () => {
        setCurrentlyPlaying(index);
    };

    const handleExited = () => {
        handleClose();
    };

    const createVideoThumbnail = (url, index) => {
        const videoId = getYoutubeVideoId(url);
        const style = videoId ? {backgroundImage: `url(${getYoutubeThumbnailUrl(videoId)}`} : {backgroundColor: 'black'};
        return <div className={classes.videoThumbnail} onClick={() => setCurrentSlide(index)}
                    style={style}>
            <YouTubeIcon style={{color: 'orangered'}}/>
        </div>
    };

    const createImageThumbnail = (pictureId, index) => {
        return <div className={classes.imageThumbnail} onClick={() => setCurrentSlide(index)}>
            <img alt="" src={`${API_URL}/file?fileId=${pictureId}`} style={{
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto !important',
                height: 'auto'
            }}/>
        </div>;
    };

    const renderChildren = () => {
        const pictures = fileIds.map(pictureId => <PictureSlide pictureId={pictureId}
                                                                imageWrapperClass={classes.imageWrapper}
                                                                imageSlideClass={classes.imageSlide}/>);
        const videos = embeddableVideos.map((url, index) => <VideoSlide url={url}
                                                                        onPlay={onPlay(fileIds.length + index)}
                                                                        playing={fileIds.length + index === currentlyPlaying}
                                                                        videoWrapperClass={classes.videoWrapper}
                                                                        videoSlideClass={classes.videoSlide}/>);
        return pictures.concat(videos);
    };

    const children = renderChildren();

    const renderThumbnails = () =>
        children.map((item, index) => <Grid item
                                            className={clsx(index === currentSlide ? classes.selectedThumbnail : classes.nonselectedThumbnail, classes.hoverThumbnail)}>
                {item.props.url ? createVideoThumbnail(item.props.url, index) : createImageThumbnail(item.props.pictureId, index)}
            </Grid>
        );

    const renderIndicators = (onClickHandler, isSelected, index, label) =>
            <Hidden mdUp>
                <FiberManualRecordIcon onClick={onClickHandler}
                                       onKeyDown={onClickHandler}
                                       value={index}
                                       key={index}
                                       className={clsx(classes.dotIndicator, {[classes.dotIndicatorSelected]: isSelected})}/>
            </Hidden>;

    return <Dialog fullWidth
                   maxWidth='xl'
                   className={classes.dialog}
                   open={isOpen}
                   scroll='body'
                   onExited={handleExited}
                   onClose={handleClose}>
        <DialogContent className={classes.dialogContent}>
            <Carousel autoPlay={false}
                      selectedItem={currentSlide}
                      showThumbs={false}
                      onChange={updateCurrentSlide}
                      renderIndicator={renderIndicators}
            >
                {children}
            </Carousel>
            <Hidden smDown>
                <Grid container spacing={2} className={classes.thumbnailsWrapper}>
                    {renderThumbnails()}
                </Grid>
            </Hidden>
        </DialogContent>
        <DialogActions>
            <Button color='primary' variant="contained" className={classes.button}
                    onClick={handleClose}>
                {intl.formatMessage({id: 'close'})}
            </Button>
        </DialogActions>
    </Dialog>
};

export default injectIntl(IdeaMedia);