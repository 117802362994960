import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Button from "@material-ui/core/Button";
import {injectIntl} from "react-intl";
import {lighten, makeStyles} from "@material-ui/core/styles";
import {API_URL, CrowdUserType} from "../../constants";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Spinner from "../common/Spinner";
import InfiniteScroll from "../common/InfiniteScroll";
import clsx from "clsx";
import {formatDate} from "../../utils/utils"
import CardHeader from "@material-ui/core/CardHeader";
import ChatIcon from '@material-ui/icons/Chat';
import Grid from "@material-ui/core/Grid";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    media: {
        height: '700px',
        [theme.breakpoints.down('md')]: {
            height: '500px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '350px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '230px'
        },
    },
    tagButton: {
        color: 'white',
        position: 'absolute',
        marginLeft: '10px',
        marginTop: '16px'
    },
    attachment: {
        height: '200px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        textAlign: 'end'
    },
    listItem: {
        backgroundColor: lighten(theme.palette.primary.main, 0.85),
    },
    listItemRoot: {
        paddingRight: '120px',
    }
}));


const IdeaComments = props => {

    const classes = useStyles();
    const {idea, createComment, loadComments, comments, deleteComment, updateComment} = props;
    const [comment, setComment] = React.useState('');
    const [editable, setEditable] = React.useState({});

    const loadAdditionalComments = cb => {
        loadComments(idea.crowdId, idea.id, cb, comments.items.length, 20);
    };

    const handleInputChange = event => {
        setComment(event.target.value);
    };

    const makeEditable = comment => () => {
        setEditable({
            ...editable,
            [comment.id]: comment.content
        });
    };

    const cancelEditing = id => () => {
        const removeEditable = {...editable};
        delete removeEditable[id];
        setEditable(removeEditable);
    };

    const handleEditableInputChange = comment => event => {
        setEditable({
            ...editable,
            [comment.id]: event.target.value
        });
    };

    const commentTextField = comment => {
        return <TextField
            label={props.intl.formatMessage({id: 'idea_comment_edit'})}
            variant="outlined"
            name={`comment${comment.id}`}
            margin="dense"
            fullWidth
            multiline
            inputProps={{maxLength: 600}}
            helperText={`${comment.content.length}/600`}
            value={editable[comment.id]}
            onChange={handleEditableInputChange(comment)}
            InputProps={{style: {backgroundColor: 'white'}}}/>;
    };

    const listItemContent = comment => {
        const isEditable = Object.keys(editable).includes(comment.id.toString());
        return <>
            <ListItemText
                secondaryTypographyProps={{component: 'div'}}
                primary={composeCommentHeader(comment).join(", ")}
                secondary={isEditable ? commentTextField(comment) : comment.content}/>
            <ListItemSecondaryAction>
                {isEditable ? <>
                    <IconButton onClick={handleUpdate(comment)}>
                        <DoneIcon color='primary'/>
                    </IconButton>
                    <IconButton onClick={cancelEditing(comment.id)}>
                        <ClearIcon color='primary'/>
                    </IconButton></> : <>
                    <IconButton onClick={makeEditable(comment)}>
                        <EditIcon color='primary'/>
                    </IconButton>
                    <IconButton onClick={() => deleteComment(comment.id, idea.id, idea.crowdId)}>
                        <DeleteIcon color='primary'/>
                    </IconButton></>}
            </ListItemSecondaryAction>
        </>;
    };

    const composeCommentHeader = comment => {
        const text = [];
        text.push(comment.authorName);
        if (comment.ownerId === idea.ownerId)
            text.push(props.intl.formatMessage({id: 'comment_idea_author'}));
        else if ([CrowdUserType.CROWD_MANAGER, CrowdUserType.CROWD_ADMIN].includes(comment.authorCrowdRole))
            text.push(props.intl.formatMessage({id: 'comment_crowd_admin'}));

        text.push(`${props.intl.formatMessage({id: 'comment_posted_on'})} ${formatDate(comment.createdAt)}`);

        return text;
    };

    const isCommentPrivileged = comment => {
        return [CrowdUserType.CROWD_MANAGER, CrowdUserType.CROWD_ADMIN].includes(comment.authorCrowdRole) || comment.ownerId === idea.ownerId;
    };

    const postComment = () => {
        if (comment !== '')
            createComment(idea.crowdId, idea.id, comment, () => setComment(''));
    };

    const handleUpdate = comment => () => {
        const content = editable[comment.id];
        if (content !== '') {
            const body = {
                ...comment,
                content
            };
            updateComment(idea.crowdId, idea.id, body, cancelEditing(comment.id));
        }
    };

    const renderListItems = () => {
        return comments.items.map(comment => {
                const isEditable = Object.keys(editable).includes(comment.id.toString());
                return <>
                    <Hidden xsDown>
                        <ListItem
                            key={`comment${comment.id}`}
                            className={clsx({[classes.listItem]: isCommentPrivileged(comment)}, classes.listItemRoot)}>
                            <ListItemAvatar>
                                {
                                    comment.authorAvatarId !== undefined ? (
                                        <Avatar src={`${API_URL}/file?fileId=${comment.authorAvatarId}`}/>
                                    ) : (
                                        <Avatar
                                            className={classes.avatar}>{comment.authorName.split(/\s/).slice(0, 2).map(s => s.charAt(0)).join("").toUpperCase()}
                                        </Avatar>
                                    )
                                }
                            </ListItemAvatar>
                            {listItemContent(comment)}
                        </ListItem>
                    </Hidden>
                    <Hidden smUp>
                        <Grid container spacing={2} className={clsx({[classes.listItem]: isCommentPrivileged(comment)})}>
                            <Grid item>
                                {
                                    comment.authorAvatarId !== undefined ? (
                                        <Avatar
                                            src={`${API_URL}/file?fileId=${comment.authorAvatarId}`}/>
                                    ) : (
                                        <Avatar
                                            className={classes.avatar}>{comment.authorName.split(/\s/).slice(0, 2).map(s => s.charAt(0)).join("").toUpperCase()}
                                        </Avatar>
                                    )
                                }
                            </Grid>
                            <Grid item xs>
                                <Typography component='div' variant='body2'>
                                    {composeCommentHeader(comment).slice(0, -1).join(", ")}
                                </Typography>
                                <Typography component='div' variant='body2'>
                                    {composeCommentHeader(comment).slice(-1).join(", ")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{paddingBottom: 0}}>
                                {isEditable ? commentTextField(comment) :
                                    <Typography variant='body2' color="textSecondary">
                                        {comment.content}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item xs={12} style={{textAlign: 'right', paddingTop: 0}}>
                                {isEditable ? <>
                                    <IconButton onClick={handleUpdate(comment)}>
                                        <DoneIcon color='primary'/>
                                    </IconButton>
                                    <IconButton onClick={cancelEditing(comment.id)}>
                                        <ClearIcon color='primary'/>
                                    </IconButton></> : <>
                                    <IconButton onClick={makeEditable(comment)}>
                                        <EditIcon color='primary'/>
                                    </IconButton>
                                    <IconButton onClick={() => deleteComment(comment.id, idea.id, idea.crowdId)}>
                                        <DeleteIcon color='primary'/>
                                    </IconButton></>}
                            </Grid>
                        </Grid>
                    </Hidden>
                </>;
            }
        )
    };

    return <Card elevation={3} style={{overflow: 'visible', minHeight: '65vh'}}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <ChatIcon/>
                </Avatar>
            }
            title={<Typography className={'cardHeaderText'}>{props.intl.formatMessage({id: 'comments'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <TextField
                label={props.intl.formatMessage({id: 'idea_comment_add'})}
                variant="outlined"
                name='comment'
                margin="dense"
                fullWidth
                multiline
                rowsMax={6}
                inputProps={{maxLength: 600}}
                helperText={`${comment.length}/600`}
                value={comment}
                onChange={handleInputChange}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <Button color='primary' variant="contained" size='small' style={{color: 'white'}}
                                    onClick={postComment}>
                                {props.intl.formatMessage({id: 'post'})}
                            </Button>
                        </InputAdornment>
                }}/>
        </CardContent>
        <CardContent className={'cardContent'}>
            {comments.loaded &&
            (comments.items.length ? (<>
                    <List dense>
                        <InfiniteScroll
                            loadMore={loadAdditionalComments}
                            hasMore={comments.items.length < idea.commentCount}
                            threshold={100}
                            loader={<Spinner minHeight={'15vh'}/>}
                        >
                            {renderListItems()}
                        </InfiniteScroll>
                    </List>
                </>
            ) : (<Grid container
                       direction="column"
                       justify="center"
                       style={{minHeight: '60vh'}}>
                    <Grid item xs/>
                    <Grid item>
                        <Typography align='center' color="textSecondary">
                            {props.intl.formatMessage({id: 'no_comments_yet'})}
                        </Typography>
                    </Grid>
                    <Grid item xs/>
                </Grid>
            ))
            }
        </CardContent>
    </Card>
};

export default injectIntl(IdeaComments);
