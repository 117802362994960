export const messagesEn = {
    sk: "Slovensky",
    en: "English",
    default_locale: "Default locale",
    introduction: "Introduction",
    benefits: "Benefits",
    pricing: "Pricing",
    for_businesses: "For businesses",
    for_public: "For public",
    blog: "Blog",
    login: "Log in",
    email: "Email",
    confirmEmail: "Confirm email",
    contact: "Contact",
    dashboard: "Dashboard",
    subscription: "Subscription",
    choose_subscription_for_crowd: "Please choose a subscription plan for your crowd",
    choose_subscription: "Choose a subscription",
    direct_subscribe: "Subscribe with a new crowd",
    direct_subscribe_desc: "Create a brand new crowd and subscribe.",
    direct_subscribe_desc2: "Please select a payment method below and you are all set.",
    invoicing_after_payment: "You can fill out the invoice information after the payment is made.",
    menu: "Menu",
    crowds: "Crowds",
    crowd_name: "Name",
    crowd_status: "Status",
    crowd_plan: "Plan",
    profile: "Profile",
    email_notifications: "Email notifications",
    email_notification_settings: "Email notification settings",
    notifications_update_successful: "Email notification settings updated",
    notification_crowd_created: "Crowd created",
    notification_crowd_top_level_domain_added: "Crowd top level domain added",
    notification_crowd_subscription_activated: "Subscription activated",
    notification_crowd_subscription_upgraded: "Subscription upgraded",
    notification_crowd_subscription_downgraded: "Subscription downgraded",
    notification_crowd_subscription_cancelled: "Subscription cancelled",
    notification_crowd_subscription_past_due: "Subscription past due",
    notification_crowd_subscription_past_due_temporarily_deactivated: "Subscription past due temporarily deactivated",
    notification_crowd_subscription_renewed: "Subscription renewed",
    notification_crowd_subscription_reactivated: "Subscription reactivated",
    notification_mark_all_as_read: "Mark all as read",
    notification_idea_added: "Idea added",
    notification_idea_commented: "Idea commented on",
    notification_idea_liked: "Idea liked",
    notification_idea_like_milestone_reached: "Idea likes milestone reached",
    notification_idea_marked_by_admin: "Idea marked by admin",
    notification_idea_state_changed: "Idea state changed",
    notification_crowd_role_added: "Crowd privileged role added",
    notifications: "notifications",
    notifications_history: "Notifications history",
    notifications_history_you_have: "You have",
    notifications_history_new_notifications: "new notifications.",
    notifications_history_empty: "No notifications yet",
    crowd_profile_settings: "Crowd profile settings",
    crowd_domain_settings: "Crowd domain settings",
    crowd_subscription: "Crowd subscription",
    crowd_subscription_active: "ACTIVE",
    crowd_subscription_cancelled: "CANCELLED",
    crowd_categories: "Crowd categories",
    crowd_billing_info: "Crowd billing info",
    crowd_users: "Privileged crowd users",
    crowd_users_add_new: "Add new privileged crowd users",
    crowd_update_successful: "Crowd settings updated",
    crowd_created: "Crowd created",
    crowd_homepage_preview: "Crowd homepage preview",
    crowd_title: "Title",
    crowd_subdomain: "Subdomain",
    crowd_domain: "Top level domain",
    crowd_description: "Description",
    crowd_gaCode: "Google analytics code",
    crowd_gaCode_helper: "A code in the form of UA-XXXXX-Y",
    crowd_likeMilestone: "Like milestone to notify crowd admin",
    crowd_reward_currency: "Reward currency",
    crowd_rewardAmount: "Reward amount",
    crowd_visible: "Crowd visible to users",
    display_default_slideshow: "Display default slideshow",
    display_custom_intro: "Display custom intro",
    crowd_hide_title: "Hide title on crowd homepage",
    crowd_subdomain_enabled: "Enable subdomain routing",
    crowd_private: "Make crowd private",
    crowd_allow_pre_private_logins: "Allow logins for previously registered users",
    crowd_allow_pre_private_logins_desc: "Users, who interacted with your crowd before it was private, may no longer be able to access it if their email domain is not one of the whitelisted domains. You can enable them to still be able to log in by using the switch below.",
    crowd_allow_private_users: "Enable private users",
    crowd_allow_private_users_desc: "Make all newly registered users private. Private users will not be able to interact with any other public or private crowd but yours, and are permanently bound to this crowd only.",
    crowd_privacy_settings: "Crowd privacy settings",
    crowd_privacy_desc: "When enabled, only users with email domains you explicitly allow will be able to register and use the crowd. Be careful when making a crowd private, as all users registered up to that point will no longer be able to login and interact with the crowd, unless explicitly allowed to do so below.",
    crowd_privacy_emails_to_allow_desc: "Domains can be separated by a whitespace, a comma, or a semicolon.",
    validation_crowd_private_allowed_emails_empty: "Allowed email domains can not be empty while crowd is private",
    validation_crowd_private_allowed_emails_invalid: "Some of the domains are invalid",
    crowd_billing_name: "Billing name",
    crowd_billing_companyName: "Company name",
    crowd_billing_street: "Street",
    crowd_billing_postalCode: "Postal code",
    crowd_billing_city: "City",
    crowd_billing_country: "Country",
    crowd_billing_companyId: "Company ID",
    crowd_billing_taxId: "Tax ID",
    crowd_billing_vatId: "VAT ID",
    crowd_theming_data: "Crowd theming data",
    crowd_theming_ideas: "Ideas theming",
    crowd_ideas_listing_view: "Ideas listing view",
    crowd_ideas_listing_view_previews: "Previews",
    crowd_ideas_listing_view_rows: "Rows",
    crowd_cover_image: "Cover image",
    crowd_logo: "Crowd logo",
    crowd_intro_settings: "Crowd intro settings",
    crowd_intro_background_color: "Intro background color:",
    crowd_no_image_yet: "No crowd cover image uploaded yet",
    crowd_no_logo_yet: "No crowd logo uploaded yet",
    crowd_does_not_have_logo_notification: "The crowd has no logo yet.",
    crowd_does_not_have_logo_notification_button: "Upload logo",
    crowd_not_visible_notification: "The crowd is hidden from users.",
    crowd_not_visible_notification_button: "Make visible",
    crowd_no_subscription_notification: "The crowd does not have an active subscription.",
    crowd_no_subscription_notification_button: "Subscribe",
    crowd_trial_subscription_notification: "Your trial subscription has",
    crowd_trial_subscription_days_remaining_notification: "days remaining",
    crowd_trial_subscription_ends_today_notification: "The trial period ends today.",
    crowd_subscription_required: "Subscription required",
    crowd_visible_subscription_required: "Making the crowd visible to users requires an active subscription.",
    current_subscription_name: "Current subscription",
    next_subscription_name: "Next subscription",
    subscription_start: "Subscription start",
    subscription_end: "Subscription end",
    subscription_renewal_date: "Next renewal date",
    subscription_price: "Subscription plan price",
    subscription_created: "Subscription created",
    subscription_cancelled: "Subscription cancelled",
    subscription_features: "Features",
    subscription_feature_unlimited: "Unlimited",
    subscription_feature_support_none: "No support",
    subscription_feature_support_basic: "Basic support",
    subscription_feature_support_sla_5_8: "SLA 5/8 support",
    subscription_feature_own_or_3rd_level_domain: "Own domain or 3rd level domain",
    subscription_feature_3rd_level_domain: "3rd level domain",
    subscription_feature_private_crowd_enabled: "Yes",
    subscription_feature_private_crowd_disabled: "No",
    subscription_feature_private_users_enabled: "Yes",
    subscription_feature_private_users_disabled: "No",
    subscription_features_users: "Max number of users",
    subscription_features_admins: "Max number of admins",
    subscription_features_managers: "Max number of managers",
    subscription_features_ideas: "Max number of ideas",
    subscription_features_ownDomain: "Domain type",
    subscription_features_privateCrowd: "Private crowd",
    subscription_features_privateUsers: "Private users",
    subscription_features_support: "Support",
    cancel_subscription: "Cancel subscription",
    change_subscription: "Change subscription type",
    renew_subscription: "Renew subscription",
    no_active_subscription: "No currently active subscription",
    no_crowds_yet: "No crowds yet",
    crowd_does_not_exist: "The crowd does not exist",
    crowd_not_visible_owner: "The crowd has been hidden by its owner.",
    crowd_is_disabled: "The crowd has been disabled",
    validation_crowd_title: "Crowd title can not be empty",
    validation_crowd_subdomain_empty: "Crowd subdomain can not be empty",
    validation_crowd_subdomain_invalid: "Crowd subdomain can not have this value",
    validation_crowd_subdomain_disabled_domain_empty: "Crowd domain can not be empty when subdomain routing is disabled",
    validation_crowd_subdomain_regex: "Crowd subdomain can only contain letters, digits or a dash",
    validation_crowd_domain_regex: "That does not appear to be valid domain",
    validation_crowd_domain_invalid: "Crowd domain can not have this value",
    validation_crowd_gacode: "That doesn't appear to be a valid Google analytics code",
    validation_crowd_likeMilestone: "Like milestone has to be a number",
    validation_crowd_rewardAmount: "Reward amount has to be a number",
    validation_crowd_subdomain_in_use: "Crowd subdomain is already in use",
    validation_crowd_domain_in_use: "Top level domain is already in use",
    validation_crowd_billing_name: "Billing name can not be empty",
    validation_crowd_billing_companyName: "Company name can not be empty",
    validation_crowd_billing_street: "Street can not be empty",
    validation_crowd_billing_postalCode: "Postal code can not be empty",
    validation_crowd_billing_city: "City can not be empty",
    validation_crowd_billing_country: "Country can not be empty",
    validation_crowd_billing_companyId: "Company ID can not be empty",
    validation_crowd_billing_taxId: "Company tax ID can not be empty",
    create_new_crowd: "Create new crowd",
    active: "Active",
    inactive: "Inactive",
    theming: "Theming",
    categories: "Categories",
    category: "Category",
    category_name: "Name",
    category_description: "Description",
    category_visible: "Category visible to users",
    category_ideasCount: "Ideas",
    create_new_category: "Create new category",
    category_created: "Category created",
    category_update_successful: "Category updated",
    category_deleted: "Category deleted",
    category_delete: "Delete category",
    category_delete_delete_are_you_sure: "Are you sure you want to delete the category?",
    no_categories: "No categories",
    back_to_category_list: "Back to list",
    validation_category_name: "Category name can not be empty",
    billing: "Billing",
    crowd_users_add_button: "Add users",
    crowd_users_role: "Crowd user role",
    role: "Role",
    crowd_users_emails_to_add: "Emails to add",
    crowd_users_emails_to_add_desc: "Emails can be separated by a whitespace, a comma, or a semicolon.",
    crowd_admin: "admin",
    crowd_admins: "admins",
    crowd_manager: "manager",
    crowd_managers: "managers",
    crowd_managers2: "managers",
    validation_crowd_users_emails: "Some of the email addresses are invalid",
    validation_crowd_users_emails_empty: "No emails entered",
    validation_crowd_users_do_not_exist: "User accounts with the given email addresses do not exist",
    validation_crowd_users_do_not_exist_some: "Some of the entered user accounts do not exist",
    validation_crowd_users_can_not_add: "You can not add any more ",
    validation_crowd_users_can_only_add: "You can only add ",
    validation_crowd_users_too_many: "Your subscription limit does not allow that many privileged users. ",
    crowd_users_added_successfully: "Crowd users successfully added.",
    crowd_users_updated_successfully: "Crowd users successfully updated.",
    crowd_users_updated_some: "Some of the crowd users have been updated by someone else in the meantime. Please refresh the page.",
    crowd_users_already_exist: "Crowd users already exist",
    crowd_user_deleted: "Crowd user successfully removed.",
    no_crowd_users: "No users",
    add_idea: "Add idea",
    all_ideas: "All",
    ideas_none_yet: "No ideas yet",
    be_the_first_to_add: "Be the first to contribute an idea in this category",
    crowd_homepage_carousel_caption1: "Add an idea",
    crowd_homepage_carousel_caption2: "Get votes",
    crowd_homepage_carousel_caption3: "Company evaluates your idea",
    crowd_homepage_carousel_caption4: "A new company product",
    crowd_homepage_carousel_text1: "You are using the products or services of the company, but something is missing. You see a clear space to improve your customer experience or increase your loyalty. Design a new product or service to solve your problem or improve what you already have that a company offers.",
    crowd_homepage_carousel_text2: "Let your friends and acquaintances know about your idea. The better the description, the more people will understand and appreciate it. When you get the necessary support, your idea will be shifted to a judgment panel.",
    crowd_homepage_carousel_text3: "Our team of product managers regularly reviews ideas that have received sufficient customer support. We will evaluate their added value and feasibility and for ideas that make sense we will shift them to the implementation phase.",
    crowd_homepage_carousel_text4: "If your idea gets implemented, you will get a few rewards immediately. You will be given recognition as an author of an idea or an innovation. You will finally get a solution to the problem that bothered you. You also can receive a reward.",
    create_new_idea: "Create an idea",
    create_new_idea_name: "Name",
    create_new_idea_description: "Description",
    create_new_idea_category: "Category",
    create_new_idea_name_hint: "Simple, brief and impactful names work the best.",
    create_new_idea_description_hint: "Describe the idea in more detail.",
    create_new_idea_category_hint: "Choose a category, where the idea belongs",
    create_new_idea_videos: "Embeddable videos",
    embeddable_video_url: "Video URL",
    invalid_video_url: "Ivalid video URL",
    add_new_video_url: "Add one more URL",
    create_new_idea_videos_hint: "Enter embeddable videos to display with the idea. You can add up to 5.",
    create_new_idea_files: "Attachments",
    create_new_idea_files_hint: "Upload files that are relevant to your idea and help to better explain it. You can choose up to 5.",
    create_new_idea_featured_img_hint: "Select a featured image.",
    create_new_idea_featured_img: "Featured image",
    crowd_limit_reached: "Crowd limit reached",
    crowd_user_limit_reached: "Crowd user limit reached. Crowd owner notified.",
    ideas_subscription_limit_reached: "No more ideas can be posted on this crowd.",
    idea_name: "Idea name",
    no_ideas_yet: "No ideas yet",
    idea_status: "Idea status",
    idea_crowd_name: "Crowd name",
    idea_detail: "Details",
    idea_comments: "Comments",
    idea_likes: "Likes",
    rating: "Rating",
    no_rating_yet: "No rating yet",
    share: "Share",
    share_idea_dialog_title: "Share the idea",
    share_idea_dialog_desc: "Know of people who might be interested in this idea? Let them know by sending them an email.",
    emails: "Emails",
    idea_description: "Idea description",
    no_description_available: "No description available",
    featured: "Featured",
    rewarded: "Rewarded",
    new_idea: "New idea",
    most_likes: "Most likes",
    most_comments: "Most comments",
    submitted: "Submitted",
    in_review: "In review",
    deferred: "Deferred",
    approved: "Approved",
    underway: "Underway",
    implemented: "Implemented",
    rejected: "Rejected",
    tag_idea_button: "Tag idea",
    change_idea_state_button: "Change state",
    appreciate_button: "Appreciate",
    unlike_button: "Unlike",
    idea_comment_add: "Add comment",
    join_the_discussion: "Join the discussion...",
    log_in_with: "Log in with",
    idea_comment_edit: "Edit comment",
    idea_comment_delete: "Delete comment",
    idea_comment_delete_are_you_sure: "Are you sure you want to delete the comment?",
    edit_idea: "Edit idea",
    edit_idea_current_featured_img_hint: "Current featured image",
    edit_idea_attachments: "Edit idea attachments",
    edit_idea_videos: "Edit idea videos",
    edit_idea_no_attachments: "No attachments",
    select_category: "Select one",
    validation_idea_name: "Idea name can not be empty",
    validation_idea_description_empty: "Idea description can not be empty",
    validation_idea_description_too_long: "Idea description is",
    validation_crowd_intro_too_long: "Crowd intro is",
    characters_too_long: 'characters too long',
    validation_idea_files: "Unsupported file type. Only JPG, JPEG, GIF and PNG images are accepted",
    validation_idea_featuredImg: "Unsupported file type. Only JPG, JPEG, GIF and PNG images are accepted",
    idea_created: "Idea created",
    idea_updated: "Idea updated",
    idea_deleted: "Idea deleted",
    idea_shared: "Idea shared",
    idea_tagged: "Idea tagged",
    idea_settings: "Idea settings",
    idea_question_text: "Text question",
    idea_questionnaire: "Idea questionnaire",
    idea_like_questionnaire: "Idea like questionnaire",
    idea_creation_questions: "Idea creation questions",
    idea_creation_questions_desc: "Users submitting new ideas will also have to provide answers to your questions. " +
        "We have prepared a list of 5 recommended questions for idea creators to answer, so we suggest you use them and tweak them according to your needs.",
    idea_creation_questions_q1: "Please try to estimate, how much new revenue this idea could bring in.",
    idea_creation_questions_q2: "Please try to estimate, how much savings this idea could result into.",
    idea_creation_questions_q3: "Please try to estimate, how many new customers this idea could bring in.",
    idea_creation_questions_q4: "How long do you think it would take to implement this idea?",
    idea_creation_questions_q5: "How much would it cost to implement this idea?",
    more_than_100k: "More than 100 000€",
    more_than_10k: "More than 10 000",
    less_than_a_month: "Less than a month",
    one_to_three_months: "One to three months",
    three_to_six_months: "Three to six months",
    more_than_six_months: "More than six months",
    idea_question_add_recommended: "Add recommended questions",
    create_idea_company_questions: "Company questions",
    create_idea_owner_would_like: "Crowd owner would like you to also answer these questions. The answer does not have to be exact, " +
        "try to guess to the best of your ability",
    choose_one: "Choose one",
    idea_questionnaire_enabled: "Questionnaire enabled",
    idea_questionnaire_saved: "Questionnaire saved.",
    idea_questionnaire_desc: "Users will be prompted to answer this questionnaire upon clicking a like button on any crowd idea.",
    idea_question_add: "Add a question",
    idea_question_option_add: "Add an option",
    idea_question_none_yet: "No questions yet",
    idea_question_radio: "Single choice question",
    idea_question_checkbox: "Multiple choice question",
    idea_question: "Idea question",
    idea_question_answer: "Answer",
    idea_question_validation_answer_empty: "Answer can not be empty",
    idea_question_validation_option_empty: "At least one option has to be selected",
    idea_question_option: "Option",
    idea_question_validation_empty: "Idea question can not be empty",
    idea_question_option_validation_empty: "Option can not be empty",
    idea_liked: "Idea liked",
    idea_rated: "Idea rated",
    idea_unliked: "Idea unliked",
    idea_stats: "Idea stats",
    idea_stats_comments: "Comments stats",
    idea_stats_likes: "Likes stats",
    idea_stats_all: "All time",
    idea_stats_7days: "Last 7 days",
    idea_stats_30days: "Last 30 days",
    idea_stats_download_answers: "Download answers",
    comment_created: "Comment posted",
    comment_updated: "Comment updated",
    comment_deleted: "Comment deleted",
    no_comments_yet: "No comments yet",
    comment_posted_on: "posted on",
    comment_idea_author: "Idea author",
    comment_crowd_admin: "Crowd admin",
    comments: "Comments",
    copyright: "Crowdea, Inc.",
    let_the_crowd: "Let the crowd",
    figure_it_out: "figure it out",
    for_you: "for you",
    let_the_crowd_desc: "Allow your customers to become a part of your team, and let them come up with innovative ideas for your products and services.",
    what_is_crowdea: "What is Crowdea for?",
    what_is_crowdea_desc: "It can be used for various purposes. We name a few examples for inspiration:",
    monetize: "Monetize",
    your_ideas: "your ideas",
    love_getting_creative: "Love getting creative? Fancy yourself as an innovator or just want to make your life easier? Well, this is the place to be. Share your ideas, showcase your proposals for new products and services and earn money for them.",
    try_crowdea: "Try Crowdea",
    user_benefits: "Help improve your favorite companies",
    user_benefits_desc: "Crowdea is your unique chance to tell companies what their customers needs really are. Provide feedback and ideas about quality, choice and convenience of their products.",
    support_your_favorite_ideas: "Support your favorite ideas",
    support_your_favorite_ideas_desc: "You don't have to submit an idea to participate. You can look through other people's proposals and if you like something, make sure you vote and help your favorite submissions on their way to perhaps becoming a real product or innovation.",
    make_your_life_easier: "Make your life easier",
    make_your_life_easier_desc: "There's a product you use everyday and you love it. There's just this little thing that bothers you and you know exactly how to fix it. Tell your favorite company what features would make their product better or suggest a completely new offering.",
    public_crowds_preview: "Public crowds preview",
    your_future_crowd: "Your future crowd",
    your_future_crowd_desc: "This right here can be your future crowd. Sign up today!",
    your_future_idea: "Your future idea",
    your_future_idea_desc: "We bet you have some neat ideas. Pick a crowd and post them!",
    most_liked_ideas_preview: "Most liked ideas",
    newest_ideas_preview: "Recently added ideas",
    innovation: "Innovation",
    innovation_desc: "Collect customer suggestions for improvements of products or services",
    ab_testing: "AB Testing",
    ab_testing_desc: "Put your customer loyalty, messaging campaigns or packaging design to test",
    loyalty: "Loyalty",
    loyalty_desc: "Build customer loyalty by listening to their input and implementing it",
    social_networks: "Social networks",
    social_networks_desc: "Generate an interesting content and share it with your followers",
    how_does_it_work: "How does it work",
    how_does_it_work_desc: "For a nominal monthly fee, we provision a portal for you. You can then change the domain, or customize its looks by adding a logo or changing colors.",
    how_does_it_work_user_desc: "Companies run portals called \"crowds\" asking our users to suggest new products and services or innovate their existing offering. Come up with an awesome idea, get votes for your proposal and earn money rewards if you are successful.",
    how_does_it_work_users_desc: "You can invite your customers over social networks, by email, from you CRM system, or by a simple company webpage link.",
    how_does_it_work_ideas_desc: "Registered users can add a new product or service idea, or other innovation. They then describe their idea in detail.",
    ratings: "ratings",
    how_does_it_work_ratings_desc: "Not every customer is necessarily creative, but they can still appreciate ideas of others. Users can vote for and rate ideas they like.",
    rewards: "rewards",
    how_does_it_work_rewards_desc: "Top rated ideas will be evaluated by your product team. If an idea is implemented, the original author is eligible for a small reward",
    how_much_does_it_cost: "How much does it cost?",
    trial: "Trial",
    concert: "Concert",
    party: "Party",
    festival: "Festival",
    non_government_organization: "Non-government organization",
    free: "Free",
    free_trial: "Free trial",
    try_for_free: "Try for free",
    no_subscription: "No subscription",
    buy: "Buy",
    max: "Max",
    max_users: "Max {number} users",
    max_ideas: "Max {number} ideas",
    unlimited: "Unlimited",
    unlimited_users: "Unlimited users",
    unlimited_ideas: "Unlimited ideas",
    users: "users",
    ideas: "ideas",
    third_level_domain: "Third level domain",
    dedicated_domain: "Dedicated domain",
    shared_crowdea_users: "Shared Crowdea users",
    private_crowdea_users: "Private Crowdea users",
    admin: "admin",
    admins: "admins",
    superuser: "superuser",
    superusers: "superusers",
    limited_support: "Limited support",
    standard_support: "Standard support (SLA 5/8)",
    private_crowd: "Private/invitation only crowds",
    advanced_crowd_settings: "Advanced crowd settings",
    complementary_services: "Access to complementary services",
    new_crowdea_features_vote: "Priority voting on new Crowdea features",
    monthly: "monthly",
    logout: "Log out",
    logout_successful: "You have been logged out",
    facebook_login_no_email: "Your Facebook account does not include an email address. Please fill it it now.",
    facebook_login_error: "Facebook login was unsuccessful. Please try a different method",
    validation_login_email: "Not a valid email address",
    validation_login_password: "The password appears to be empty",
    invalid_username_password: "Invalid username or password",
    account_not_verified: "Account not verified. Please check your email for an activation link.",
    register: "Register",
    registration: "Registration",
    to_login: "To login",
    forgot_password: "Forgot password",
    reset_password: "Reset password",
    forgot_password_email_sent: "Email with a link to reset password has been sent.",
    password_reset_token_verifying: "Verifying the password reset token, please wait.",
    password_reset_token_invalid: "The password reset token is invalid.",
    password_reset_token_used: "The password reset token has already been used.",
    password_reset_token_success: "Your password has been successfully reset! You can now go to the login page to access Crowdea.",
    password: "Password",
    current_password: "Current password",
    new_password: "New password",
    confirm_new_password: "Confirm new password",
    confirm_password: "Confirm password",
    phone_number: "Phone number",
    name: "Name",
    street: "Street",
    city: "City",
    country: "Country",
    postal_code: "Postal code",
    dob: "Date of birth",
    date: "Date",
    time: "Time",
    validation_account_name: "Name can not be empty",
    validation_account_avatar: "Unsupported file type. Only JPG, JPEG, GIF and PNG images are accepted",
    avatar: "Avatar",
    avatar_upload_hint: "Select an avatar image.",
    account_update_successful: "Account update was successful.",
    avatar_upload_successful: "Avatar successfully uploaded.",
    change_password: "Change password",
    set_password: "Set password",
    validation_change_password_currentPassword: "The current password can not be empty",
    validation_change_password_password: "The new password does not meet the requirements",
    validation_change_password_confirmPassword: "Passwords do not match",
    validation_change_password_mismatch: "Invalid current password",
    change_password_successful: "Password change was successful.",
    set_password_successful: "Password was successfully set.",
    credit_cards: "Credit cards",
    select_credit_card: "Select a credit card",
    add_new_credit_card: "Add a new credit card",
    use_existing_credit_card: "Use an existing credit card",
    enter_payment_details: "Enter payment details",
    payment_details: "Payment details",
    no_credit_cards_yet: "No credit cards yet",
    credit_card_number: "Card number",
    credit_card_expiration: "Expiration date",
    credit_card_cvv: "CVC",
    credit_card_incorrect_number: "Incorrect or incomplete credit card details",
    credit_card_payment_failed: "The payment failed. Please enter a different card.",
    credit_card_error: "The credit card could not be processed at this time. Please enter a different card.",
    credit_card_full_name: "Full name",
    validation_credit_card_name_empty: "Card name can not be empty",
    make_default: "Make default",
    make_default_credit_card: "Make this your new default card",
    credit_card_deleted_new_default: "The credit card was deleted and a new credit card was marked as default.",
    validation_registration_email: "Not a valid email address",
    validation_registration_email_in_use: "Email address is already in use",
    validation_registration_domain_not_allowed: "Email domain is not allowed.",
    validation_registration_confirmEmail: "Emails do not match",
    validation_registration_firstName: "First name can not be empty",
    validation_registration_lastName: "Last name can not be empty",
    validation_registration_password: "This password does not meet the requirements",
    validation_registration_confirmPassword: "Passwords do not match",
    validation_registration_phoneNumber: "This does not appear to be a valid SK phone number",
    password_rules: "Password has to be 8 to 25 characters long, has to contain one lowercase, one uppercase, " +
        "one digit and one special character and must not contain spaces",
    agree_terms_of_service: "By registering your account, you agree to our",
    terms_of_service: "Services Agreement",
    privacy_policy: "Privacy policy",
    and_the: "and the",
    registration_successful: "Registration was successful. You will be redirected to login screen.",
    registration_activation_link_sent: "Registration was successful. An activation link with further instructions was sent to your email.",
    registration_activation_token_verifying: "Verifying the activation token, please wait.",
    registration_activation_token_invalid: "The account activation token is invalid.",
    registration_activation_token_used: "The account activation token has already been used.",
    registration_activation_token_success: "Your account has been successfully verified! You can now go to the login page to access Crowdea.",
    no_document_selected: "No document selected",
    select_file: "Select file",
    file_upload_image_only: "Unsupported file type. Only JPG, JPEG, GIF and PNG images are accepted",
    my_account: "My account",
    about: "About",
    close: "Close",
    to_verification: "Verify",
    submit: "Submit",
    pay: "Pay",
    post: "Post",
    save: "Save",
    notify_crowd_owners: "Notify crowd owner",
    cancel: "Cancel",
    edit: "Edit",
    accept: "Accept",
    propose_price: "Propose a price",
    complete: "Complete",
    back: "Back",
    next: "Next",
    upload: "Upload",
    delete: "Delete",
    default: "Default",
    roles: "Roles",
    rows_per_page: "Rows per page",
    all_rows: "All",
    of: "of",
    on: "on",
    yes: "Yes",
    no: "no",
    enabled: "Enabled",
    disabled: "Disabled",
    enable: "Enable",
    disable: "Disable",
    status: "Status",
    created_by: "Created by",
    on_day: 'on',
    created_on: "Created on",
    infinite: "Infinite",
    default_error: "The action could not be completed. Please try again later.",
    optimistic_locking_error: "Someone else has modified the information while you were viewing it. Please refresh the page.",
    terms_of_service_headline: "Terms of use of the Application - Terms of Service",
    terms_of_service_I: "General provisions, definitions and copyright",
    terms_of_service_I_1: "For the purpose and subject of these Terms of Service, the Terms of Service of Extropian s.r.o. (hereinafter referred to as the \"Terms of Use\") is an adjustment of the mutual rights and obligations of the Application Operator and the Application User in connection with the use of the Crowdea application.",
    terms_of_service_I_2: "The Crowdea Application is an online application, a mobile application, the website of the Application Operator, software to which Users login for the purpose of its use under the conditions set out in these Terms of Use (hereinafter referred to as the \"Application\").",
    terms_of_service_I_3: "The user of the Application is a company that is interested in using the services of the Application, has registered in the Application and created its account (hereinafter referred to as the \"User\").",
    terms_of_service_I_4: "The operator of the Application is the company Extropian s.r.o., with its registered office at Idanská 775/27 040 11 Košice - city District Západ, IČO: 53179927, registered in the Commercial Register of the District Court of Košice I, section: Sro, File no. 49528 / V (hereinafter referred to as the \"Operator\").",
    terms_of_service_I_5: "These terms of use represent a mutual agreement between the Operator and the User regarding the use of the Application and the provision of services. By entering the required registration data in the Application, i.e. by registering, the User agrees with the conditions set out in these Terms of Use, thereby concluding a contract between the Operator and the User to use the Application in accordance with the conditions set out in these Terms of Use and the User is authorized to use the Application in accordance terms of use.",
    terms_of_service_I_6: "The Application is protected by the copyright of the Operator in accordance with the relevant provisions of Act 185/2015 Coll. Copyright Act as amended (hereinafter referred to as the \"Copyright Act\"). The Operator is the sole owner of all property rights and other intellectual property rights to the Application. By accepting these terms of use and using the services of the Application, the User does not acquire any property rights or other intellectual property rights to the Application (especially not the right to modify, change, interfere with the Application, process, adapt and create derivative works, make copies of the Application and distribute them). The Application and all its components and functionalities, including graphic elements, their layout, texts, interfaces and other components are protected under the law of the Slovak Republic in the field of intellectual property rights, in particular the Copyright Act. Any use of the Application other than in accordance with these terms of use requires the written consent of the Operator.",
    terms_of_service_I_7: "The Operator grants the User a license to use the Application, namely limited, non-exclusive, revocable, non-transferable, limited in time for the duration of registration in the Application. A license is granted to use the Application in accordance with its purpose and the terms set forth in these Terms of Use. The User is not authorized to otherwise use the content in the Application, in particular he may not copy, modify, change, interfere with the Application, create derivative works, distribute, license, sell, transmit, publicly display, publicly perform, publicly transmit, broadcast or otherwise use the Application or its contents, with the exceptions expressly agreed and accepted in these terms of use.",
    terms_of_service_I_8: "The Operator processes and uses the personal data of the User's contact person in connection with the use of the Application in accordance with the Privacy Policy, which is located in the Application.",
    terms_of_service_II: "Fees and payment terms",
    terms_of_service_II_1: "The fee for the use of the Application is stated directly in the Application, and its amount depends on the choice of the service by the User. The fee is paid monthly in the form of a subscription, which is regularly invoiced in advance and is non-refundable, in such a way that the first billing period begins on the day the service is set up, lasts one month and ends on the day which the number coincides with for example from 11/11/2020 to 11/12/2020). This means that payments will be made on the same day each month. If such a day does not exist in a given month, the last day of the given calendar month will be considered as the date of payment of the subscription (fee). If the end date of the billing period does not coincide with the start date of the billing period, the following is an example: subscriptions beginning on January 31 end on February 28 (or February 29 in a leap year), then subscriptions beginning on March 31 end on 30 April. In some cases, the date of payment of the fee (subscription) may change, for example, if the selected method of payment of the User could not be successfully performed. If the fee could not be successfully paid due to the expiration of the payment card or due to lack of funds on the payment card, and if the User does not cancel his account, the Operator may suspend the User access to the ordered service in the Application until the fee is successfully charged for the period. until the fee is paid by transfer to the Operator's account.",
    terms_of_service_II_2: "During the subscription period of the selected service, the User will be granted a license, to use the Application for the purpose for which the Application will be used.",
    terms_of_service_II_3: "In the event that the Operator does not use the Application during the entire period of the paid subscription, the paid fee for the given period will not be refunded.",
    terms_of_service_II_4: "The Operator is entitled to unilaterally change the amount of the fee. The change in the amount of the fee is considered notified to the User at the moment of its publication in the Application and takes effect on the day of publication in the Application or another day that will be published.",
    terms_of_service_II_5: "The fee for the provision of the Services of the Application may be paid by means of a payment card or by transfer to the Operator's account specified in the Application as well as on the issued invoice. The subscription will be charged to the User's payment card on the day of expiration of the current subscription service, as long as the subscription is still active and the User does not cancel it. In the event that the User pays the fee/subscription by transfer to the Operator's account, he is obliged to pay it on the due date stated on the invoice / on the day of expiry of the current subscription service.",
    terms_of_service_II_6: "The Operator will issue an invoice to the User for each invoicing period. The invoice must contain the prescribed requirements in accordance with generally binding legal regulations.",
    terms_of_service_II_7: "If the User decides to choose another version of the service for his account, he can do so at any time, and the selected service will be activated for the next billing period. From the next billing period, the User will be charged the amount, corresponding to his new service selection.",
    terms_of_service_III: "Application login, application use and declarations",
    terms_of_service_III_1: "By registering in the Application and using it, the User agrees to these terms of use and undertakes to comply with them. If the User does not agree with these terms of use, he cannot use the Application and use its services.",
    terms_of_service_III_2: "In order to obtain an account and use the Application, you must first register. By successfully completing the registration in the Application, the User will create his own account, which is protected by a unique login name and password.",
    terms_of_service_III_3: "The User agrees to keep complete, true and up-to-date information and data in his account. In the event that the User does not have complete, true and current information and data listed in his account, he will not be able to use the Application, and the Operator is entitled to cancel his account.",
    terms_of_service_III_4: "The User declares that the registration data entered by him in the Application are complete, true and current and that his registration and thus the conclusion of the contract took place by the actions of the person authorized to act on his behalf. The User is obliged to provide the Operator with complete, true and up-to-date information and data. The Operator may but is not obliged to, perform a check, the purpose of which will be to find out and verify the identity of the User. The Operator is not responsible for verifying the identity, trustworthiness and conduct of the User.",
    terms_of_service_III_5: "By registering in the Application and using it, the User declares that he is authorized to use the Application in accordance with these terms of use.",
    terms_of_service_III_6: "The User may not assign or otherwise transfer his account to another natural or legal person. The User agrees and at the same time declares that when using the Application he will comply with all valid legal regulations of the Slovak Republic and these conditions of use and will use the Application only for legal purposes.",
    terms_of_service_III_7: "The Operator undertakes to operate the Application in accordance with generally binding legal regulations of the Slovak Republic and undertakes to ensure the functionality of the Application, except in cases independent of the will of the Operator. The Operator reserves the right to technical shutdown of the system due to its maintenance and deployment of new functionalities, while it will inform the User about such technical outages in the form of a notification directly in the Application. The User is not entitled to compensation for damage caused by a malfunction of the Application.",
    terms_of_service_III_8: "The User agrees that the Operator may run and implement updates to the Application and the provision of services and that these updates may lead to changes in the appearance or functionality of the Application. The Operator will provide, implement, install and maintain at its own expense all updates, enhancements, fixes and modifications to the Application.",
    terms_of_service_III_9: "The User is obliged to proceed when using the Application so that his account is not unauthorized access to a person who is not authorized to do so and is obliged to take all measures to prevent leakage of login data and passwords. Otherwise, he is liable for damage caused to the Operator, other User or third party. In the event of unauthorized use of the User's account or login name and password, the User is obliged to notify the Operator of this fact immediately. The User is fully responsible for any activities performed under his login name and password within his account.",
    terms_of_service_III_10: "The User undertakes and declares that he will not use the Application in a way that would violate the Operator's rights to the Application or damage his reputation and interests as well as the rights of other Users. Use of the Application for any purpose other than as stated in these Terms of Use is prohibited. The User declares that he will not use the Application for purposes that are in conflict with generally binding legal regulations of the Slovak Republic and these terms of use. A breach of an obligation under this provision shall be considered a material breach of these Terms of Use.",
    terms_of_service_III_11: "The User agrees that in case of errors or defects of the Application and its functionalities, its claims for removal of such errors or defects are limited, and they will be removed at a reasonable time and in the manner specified by the Operator, especially by updating the functionality or making a newer version of the Application.",
    terms_of_service_III_12: "If any statement of the User in accordance with these terms of use proves to be false or does so and in this connection, any claims of third parties or sanctions imposed by public authorities will be asserted against the Operator, the User undertakes to fully compensate the Operator for any damage incurred, upon the first request and without limitation, including any costs incurred by the Operator as a result of the exercise of third party claims or the imposition of sanctions by or in connection with public authorities. The provision of false statements by the User in accordance with these terms of use is considered a material breach of these terms of use.",
    terms_of_service_III_13: "The User is aware of the facts and agrees that any claim that arises in connection with the actions/omissions of another User or third parties will be limited to a claim against this User or a third party and will not exercise it against the Operator.",
    terms_of_service_III_14: "The User declares that he will not:",
    terms_of_service_III_14_1: "use another User's account,",
    terms_of_service_III_14_2: "impersonate another entity when using the Application,",
    terms_of_service_III_14_3: "disrupt or damage the Application,",
    terms_of_service_III_14_4: "publish or use software viruses or any other computer code, files/programs that may endanger, interrupt, limit or stop the functionality of the Application and the provision of services,",
    terms_of_service_III_14_5: "use automatic scripts to collect information, collect / copy e-mail addresses or another contact / personal data of Users,",
    terms_of_service_III_14_6: "endanger or damage the Operator and the Application in any way,",
    terms_of_service_III_14_7: "use the Application in a manner that violates applicable Slovak legislation or these terms of use.",
    terms_of_service_III_15: "The User is responsible for the content he uploads, creates, modifies or shares while using the Application and for the activities (including any loss or damage) associated with it.",
    terms_of_service_IV: "Responsibility",
    terms_of_service_IV_1: "The Operator does not bear any responsibility for damage/harm that could be caused to the User in connection with the use of the Application.",
    terms_of_service_IV_2: "The User is responsible for all activities performed under his account and agrees to always maintain the security and confidentiality of his username and password to the account. The User is obliged to immediately inform the Operator of any unauthorized use of his account. The Operator is not liable for any damage caused by unauthorized use of the User's account.",
    terms_of_service_IV_3: "The Operator is not responsible for the continuous and faultless functionality of the Application. The Operator may terminate the operation of the Application at any time with immediate effect, without giving a reason and without notifying the User.",
    terms_of_service_IV_4: "The Operator is not responsible for the correctness, accuracy and timeliness of the User's data in the Application. The correctness, accuracy and timeliness of the data is the sole responsibility of the User who has registered and entered the data into the account in the Application.",
    terms_of_service_IV_5: "The Operator is not responsible for the security and data protection provided by the User within the Application. The Privacy Policy is set out in the Application. The User is not liable for any damage or loss of data uploaded by the User caused by its activities, technical failures, failures or activities of third parties.",
    terms_of_service_IV_6: "The Operator is not liable for any indirect, consequential, incidental damages of the User, including lost profits or revenues of the User. The Operator is not responsible for the actions, omissions and behavior of third parties related to the use of the Application by the User. In case of dissatisfaction of the User, the User is entitled to terminate the use of the services of the Application.",
    terms_of_service_V: "Communication",
    terms_of_service_V_1: "The Operator may send notifications to the User via a general notification in the Application, via e-mail to the e-mail address of the User specified in his account. The User may send notifications to the Operator to his e-mail address: hello@crowdea.co.",
    terms_of_service_V_2: "The Operator and the User are entitled to change their contact details unilaterally. Changes on the part of the Operator will be published in the Application.",
    terms_of_service_VI: "Compensation of damages",
    terms_of_service_VI_1: "The User expressly agrees and undertakes to compensate the damage caused to the Operator, which arises in connection with the use of the Application by the User, especially which will be related to violation of these terms of use by the User, violation of applicable legislation in connection with the use of the Application by the User, and this in its entirety upon first request and without limitation.",
    terms_of_service_VII: "Account cancellation and restriction of access to the application",
    terms_of_service_VII_1: "Except in the cases specified in these Terms of Use, the Operator may at any time, in the event of non-compliance with these Terms of Use, limit, suspend or cancel the User's account, even without prior notice to the User, with which the User expressly agrees. In that case, the User will not be entitled to any compensation.",
    terms_of_service_VII_2: "The User may at any time cancel his account in the Application or request the Operator to cancel the account. The User will not be charged any cancellation fee. Paid fees for using the Application services are non-refundable.",
    terms_of_service_VIII: "Final provisions",
    terms_of_service_VIII_1: "The relationship between the User and the Operator is governed by these terms of use and generally binding legal regulations of the Slovak Republic, in particular, Act no. 513/1991 Coll. Commercial Code, as amended.",
    terms_of_service_VIII_2: "All possible disputes arising from the contractual relationship between the Operator and the User or in connection with the use of the Application, the User and the Operator undertake to resolve in particular by mutual agreement, and only in the absence of an agreement between the User and the Operator will the dispute be resolved through court, before the competent court in the Slovak Republic.",
    terms_of_service_VIII_3: "The User is not entitled to transfer his rights and obligations related to the use of the Application services to a third party without the prior consent of the Operator.",
    terms_of_service_VIII_4: "In the event that any provision of these Terms of Use is invalid or ineffective, this fact shall not affect the validity and effectiveness of the other provisions of the Terms of Use. In such a case, the Operator shall replace the invalid or ineffective provision or part thereof with a valid/effective provision with respect to the content and purpose of these Terms of Use, while the other provisions of these Terms of Use remain in force.",
    terms_of_service_VIII_5: "The Operator is entitled to change these conditions of use at any time unilaterally. The Operator will inform the User about changes in the conditions of use by notifying directly in the Application. Changes to the terms of use will take effect on the day of publication of their new wording in the Application, or the new wording of the terms of use may specify a later date of entry into force. In the event that the User will continue to use the Application after the entry into force of the new version of the terms of use, it means that he agrees with their new version and undertakes to comply with them. In the event of the User's disagreement with the new wording of the terms of use, the User is obliged to terminate the use of the Application and cancel the account, or request the Operator to cancel the account.",
    terms_of_service_VIII_6: "The Slovak version shall always prevail in case of any discrepancy between the Slovakian version and its English translation.",
    terms_of_service_VIII_7: "These Terms of Use shall enter into force and effect on 1.11.2020.",
    privacy_policy_headline: "Privacy policy",
    privacy_policy_I: "Identification of the operator and relevant legislation",
    privacy_policy_I_1: "Extropian s.r.o., with its registered office at Idanská 775/27 040 11 Košice - city District Západ, IČO: 53179927, entered in the Commercial Register of the District Court of Košice I, section: Sro, File no. 49528 / V (hereinafter referred to as the \"Operator\") is responsible for compliance with personal data protection legislation, in particular, Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of individuals with regard to the processing of personal data of data subjects in the processing of personal data and on the free movement of such data, repealing Directive 95/46 / EC (General Data Protection Regulation) (hereinafter \"GDPR\") and Act no. 18/2018 Coll. on Personal Data Protection and Amendments to Certain Acts (hereinafter also referred to as the \"Personal Data Protection Act\").",
    privacy_policy_II: "Personal data processing",
    privacy_policy_II_1: "The Operator processes personal data from the contact persons of the companies (hereinafter referred to as \"Concerned Person\" or \"Concenrend Persons\"), who register and use after registration the Crowdea application is serving as a platform for crowdsourcing ideas and innovation management (hereinafter \"Application\").",
    privacy_policy_II_2: "The Operator processes the following personal data of the Concerned Persons:",
    privacy_policy_II_2_1: "Name and surname",
    privacy_policy_II_2_2: "E-mail address",
    privacy_policy_II_3: "The Operator processes the personal data of the Data subjects exclusively in a manner that corresponds to the purpose for which they were collected and in accordance with the Personal Data Protection Act. The main purpose of processing the personal data listed in point 2 of this article is to register the company in the Application. The Operator processes personal data on the basis of the consent of the Concerned Person (pursuant to Section 13 (1) (a) of the Personal Data Protection Act).",
    privacy_policy_II_4: "Most of the personal data provided by the concerned Persons through the Application to the Operator are provided on a voluntary basis, which requires active and voluntary action by the concerned Persons. 5. Personal data of concerned Persons The Operator will not provide to third parties.",
    privacy_policy_II_5: "Personal data of concerned Persons The Operator will not provide to third parties.",
    privacy_policy_II_6: "Personal data of the persons concerned may be provided to public authorities, resp. Public institutions, in particular in the case of:",
    privacy_policy_II_6_1: "fulfillment of the legal obligation of the Operator to the extent according to the relevant legal regulations",
    privacy_policy_II_6_2: "proving / exercising the legal claims of the Operator",
    privacy_policy_II_6_3: "the prevention, detection, investigation and prosecution of infringements",
    privacy_policy_III: "Declaration of the operator and protection of personal data of  the concerned persons",
    privacy_policy_III_1: "The Operator cares about the protection of personal data of the Data subjects and observes the rules of personal data protection in accordance with the valid legal regulations on personal data protection. Through this Personal Data Protection Statement, the Operator provides the Data subjects with comprehensive information in accordance with the Personal Data Protection Act, as well as other important information and explanations in order to create transparency, also with regard to Articles 13 and 14 of the GDPR.",
    privacy_policy_III_2: "The Operator declares that it has taken appropriate technical, organizational and security measures to ensure the security of the processing of personal data of the persons concerned. In particular, it took measures to ensure that the personal data of the persons concerned were protected as far as possible from unwanted access. The protection of personal data will be updated according to the current state of technique.",
    privacy_policy_III_3: "The Operator guarantees to the Concerned Persons that it will not sell or commercially use personal data obtained from the Concerned Persons in the course of business cooperation with any third party and will not disclose any personal data obtained from the Concerned Persons without the Concerned Persons expressing their individual written consent.",
    privacy_policy_III_4: "The Operator will process the personal data of the Data subjects using a decision based on automated, partially automated means and other than automated means of personal data processing and the so-called profiling, depending on the purpose of the processing of personal data. In this case, the data subject has the right not to be subject to a decision based solely on the automated processing of personal data, including profiling. This does not apply if the Affected Person has given the Operator explicit consent in relation to the processing of his personal data and the application of a decision based on automated means of personal data processing and the so-called profiling. The Controller shall provide the Concerned Persons with information on the procedure used and the meaning and expected consequences of such processing of personal data.",
    privacy_policy_IV: "Period of storage of personal data of the persons concerned",
    privacy_policy_IV_1: "The Operator retains the personal data of the Concerned Persons for the period of using the Application, i.e. for the period during which the companies are registered in the Application. As soon as the company cancels the account in the Application, i.e. it ceases to be used definitively, or the Operator cancels the company's account, the personal data of the Concerned Person will be deleted. Data that will need to be kept for a longer period in accordance with a special legal obligation will be kept only for the necessary period in accordance with the relevant special law.",
    privacy_policy_V: "Cookies",
    privacy_policy_V_1: "The Operator will use cookies in the Application, while only statistical data are processed, which are not in the nature of personal data and are obtained exclusively for the purpose of obtaining an overview and more detailed information about the Application's traffic and can also be used for marketing purposes. The use of cookies during a visit to the Application is at the discretion of the Concerend Persons.",
    privacy_policy_VI: "Cross-border transfer of personal data",
    privacy_policy_VI_1: "The controller shall not carry out any cross-border transfer of the personal data of the Data subjects to a third country which does not guarantee an adequate level of protection of personal data.",
    privacy_policy_VI_2: "Personal data of the concerned persons,  which will be processed, can not be transferred from the territory of the Member States of the European Union to third countries.",
    privacy_policy_VII: "Rights of the persons concerned",
    privacy_policy_VII_1: "The right to information - The data subject has the right to request from the Operator at any time information on provided personal data processed by the Operator, their origin, the purpose of personal data processing, personal data processing category, identification of the recipient or recipient category to whom personal data were provided and personal data retention period. , if this is not possible, information on the criteria for its determination.",
    privacy_policy_VII_2: "Right of access to personal data - The Concerned Person has the right to access his or her personal data and the right to provide his or her personal data. In this case, if the Concerned Person requires access to personal data, the Operator is immediately obliged to allow such access. Alternatively, the Concerned Person can find his / her personal data directly in the Application in his / her account. The Operator may charge a reasonable fee corresponding to the administrative costs for the repeated provision of personal data requested by the Concerned Person. The Operator is obliged to provide personal data to the Concerned Person in a manner required by his / her request.",
    privacy_policy_VII_3: "The right to withdraw consent to the processing of personal data - If the Concerned Person has given his consent to the processing of personal data, he is entitled to revoke it at any time, without giving reasons and with immediate effect. In such a case, the Concerned Person may send the revocation of consent to the e-mail address of the Operator: hello@crowdea.co. In the event of revocation of the data subject's consent, the Operator will terminate the processing of the data subject of the concerned person and the personal data will be deleted, unless there is another legal basis for their further processing. Revocation of consent to the processing of personal data will not affect the lawfulness of the processing based on the consent prior to its revocation.",
    privacy_policy_VII_4: "The right to correct personal data - The concerned person has the right to correct incorrect personal data concerning him. The concerned person also has the right to supplement his or her incomplete personal data. In the event that the stored data is incorrect or incomplete, the Concerned Person may correct them at any time in his account, or notify the Operator directly to the Operator's e-mail address at the end of this statement and the Operator will correct or supplement the concerned person data without delay.",
    privacy_policy_VII_5: "The right to delete personal data - The concerned person has the right to delete personal data processed by the Operator. The Operator is obliged to delete the personal data of the Concerned Person without undue delay if the Affected Person has exercised the right to delete, if:",
    privacy_policy_VII_5_a: "personal data are no longer needed for the purpose for which they were obtained or otherwise processed,",
    privacy_policy_VII_5_b: "The concerned person withdraws his or her consent on the basis of which the processing of personal data is carried out, and there is no other legal basis for the processing of personal data,",
    privacy_policy_VII_5_c: "The data subject objects to the processing of personal data pursuant to § 27 par. 1 and 2 of the Personal Data Protection Act,",
    privacy_policy_VII_5_d: "the personal data of the concerned person are processed illegally.",
    privacy_policy_VII_5_2: "The Operator shall delete the personal data concerning the Concerned Person without undue delay, no later than one month after the exercise of this right. The deletion of the personal data of the Concerned Person shall take place on the basis of the request of the Concerned Person, which the Concerned Person shall send to the e-mail address of the Operator specified at the end of this declaration. If the deletion of the data subject's personal data contradicts the legal obligations to retain data, the data subject of the data subject will not be deleted. The right to delete personal data shall not apply in the cases specified in § 23 par. 4 of the Personal Data Protection Act.",
    privacy_policy_VII_6: "The right to restrict the processing of personal data - The concerned person has the right to restrict the processing of personal data by the Operator if:",
    privacy_policy_VII_6_a: "The data subject objects to the accuracy of personal data, during a period enabling the Operator to verify the accuracy of personal data,",
    privacy_policy_VII_6_b: "the processing of personal data is illegal, and the concerned person objects to the deletion of the personal data and requests instead the restriction of their use,",
    privacy_policy_VII_6_c: "The Operator needs personal data for the purpose of processing personal data, but the concerned person needs them to exercise a legal claim, or",
    privacy_policy_VII_6_d: "The concerned person objects to the processing of personal data pursuant to § 27 par. One of the Personal Data Protection Act, until it is verified whether the justified reasons on the part of the Operator outweigh the justified reasons of the Concerned Person.",
    privacy_policy_VII_7: "The right to the transfer of personal data - In the event that the Concerned Person requests from the Operator his personal data processed by the Operator, the Operator shall provide this personal data to the Affected Person or another person who issues or transmits it to the Operator, in a structured and commonly used readable format. It will only apply if technically possible.",
    privacy_policy_VII_8: "The right to object to the processing of personal data - The concerned person has the right to object to the processing of personal data for the purpose of direct marketing, including profiling. The data subject has the right to object to the processing of personal data concerning him or her on grounds relating to his or her specific situation. In the event of such an objection, the Operator will not further process the personal data of the Concerned Person, unless the necessary legitimate reasons for their further processing or reasons for proving/asserting the legal claims of the Operator are given.",
    privacy_policy_VII_9: "The right not to be the subject of automated individual decision-making, including profiling - The Concerned Person has the right not to be subject to a decision which is based exclusively on the automated processing of personal data, including the so-called profiling and which has legal effects affecting or similarly significantly affecting it. This right of the Person concerned shall not apply if the decision is:",
    privacy_policy_VII_9_a: "necessary for the conclusion or performance of the contract between the Concerned Person and the Operator,",
    privacy_policy_VII_9_b: "carried out on the basis of a special regulation or an international agreement by which the Slovak Republic is bound, and in which appropriate measures guaranteeing the protection of the rights and legitimate interests of the Person concerned are also established,",
    privacy_policy_VII_9_c: "is based on the express consent of the Person concerned.",
    privacy_policy_VII_10: "Right to lodge a complaint If the Concerned Person wishes to file a complaint, he may do so by sending an e-mail to the email address: hello@crowdea.co or by sending a complaint to the address: Idanská 775/27 040 11 Košice - city District Západ.",
    privacy_policy_VII_11: "The right to initiate proceedings - If the concerned person is suspected of unauthorized processing of his personal data, the Operator has the right to file a motion to initiate proceedings on personal data protection of the Office for Personal Data Protection of the Slovak Republic, with its registered office at Hraničná 4, 820 07 Bratislava, pursuant to Section 100 of the Personal Data Protection Act.",
    privacy_policy_VIII: "Personal data protection issues of the person concerned",
    privacy_policy_VIII_1: "If the Concerned Person has questions regarding the protection of his personal data, he may contact the Operator:",
    privacy_policy_VIII_2: "e-mail address",
    privacy_policy_VIII_3: "telephone contact:",
    privacy_policy_VIII_4: "The Slovak version shall always prevail in case of any discrepancy between the Slovakian version and its English translation.",
    privacy_policy_VIII_5: "This Privacy Statement is valid and effective from 1.11.2020.",
};
