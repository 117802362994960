import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Hidden from "@material-ui/core/Hidden";

const useStyles = makeStyles(theme => ({
    carousel: {
        marginBottom: '20px',
        '& ul.control-dots': {
            top: 0,
            maxWidth: '1440px',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            bottom: 'unset',
            [theme.breakpoints.down('sm')]: {
                bottom: 0,
                top: 'unset'
            }

        },
        '& li.slide': {
            background: 'none'
        },
        '& p.carousel-status': {
            top: '7px',
            right: '14px'
        }
    },
    slide: {
        paddingTop: '100px',
        paddingBottom: '60px',
        backgroundColor: '#F4B91F',
        height: '400px',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '35px',
            height: '420px',
        }
    },
    slideCaption: {
        textAlign: 'left',
        fontSize: '30px',
        color: 'white',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            lineHeight: '32px',
        }
    },
    slideText: {
        textAlign: 'left',
        fontWeight: 'bold',
        opacity: 0.5
    },
    indicatorWrapper: {
        flexGrow: 0,
        maxWidth: '20%',
        flexBasis: '20%',
        cursor: 'pointer',
        display: 'inline-block',
        borderBottom: '1px solid #926f13',
        height: '70px',
        lineHeight: '70px',
        '&:focus': {
            outline: 'none'
        }
    },
    indicator: {
        verticalAlign: 'middle',
        lineHeight: 'normal',
        display: 'inline-block',
        padding: '0 20px',
        opacity: 0.5,
        fontWeight: 'bold'
    },
    indicatorWrapperSelected: {
        borderBottom: '1px solid white',
    },
    indicatorSelected: {
        color: 'white',
        opacity: 1
    },
    image: {
        height: '200px',
        width: 'unset !important',
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            opacity: 0.4,
            marginTop: '70px'
        }
    },
    dotIndicator: {
        height: '13px',
        width: '13px',
        opacity: 0.5,
        margin: '13px'
    },
    dotIndicatorSelected: {
        opacity: 1,
        color: 'white'
    }
}));

const CrowdCarousel = props => {

    const classes = useStyles();
    const {intl} = props;
    const [hideSlideshow, setHideSlideshow] = React.useState(!!localStorage.getItem('hideCrowdSlideshow'));

    if (hideSlideshow)
        return null;

    const renderIndicators = (onClickHandler, isSelected, index, label) =>
        <>
            <Hidden mdUp>
                <FiberManualRecordIcon onClick={onClickHandler}
                                       onKeyDown={onClickHandler}
                                       value={index}
                                       key={index}
                                       className={clsx(classes.dotIndicator, {[classes.dotIndicatorSelected]: isSelected})}/>
            </Hidden>
            <Hidden smDown>
                <div onClick={onClickHandler}
                     onKeyDown={onClickHandler}
                     key={index}
                     tabIndex={0}
                     className={clsx(classes.indicatorWrapper, {[classes.indicatorWrapperSelected]: isSelected})}>
                    <Typography className={clsx(classes.indicator, {[classes.dotIndicatorSelected]: isSelected})}
                                component='span'>
                        {index + 1}. {intl.formatMessage({id: `crowd_homepage_carousel_caption${index + 1}`})}
                    </Typography>
                </div>
            </Hidden>
        </>;

    const handleClose = () => {
        localStorage.setItem('hideCrowdSlideshow', 'true');
        setHideSlideshow(true);
    };

    const closeButton = (current, total) => {
        return <CloseIcon style={{cursor: 'pointer'}} onClick={handleClose}/>;
    };

    return <Carousel autoPlay
                     infiniteLoop
                     interval={5000}
                     showArrows={false}
                     className={classes.carousel}
                     renderIndicator={renderIndicators}
                     statusFormatter={closeButton}
                     showThumbs={false}>
        <Grid
            container
            alignItems="center"
            justify='center'
            spacing={4}
            className={classes.slide}>
            <Hidden smDown>
                <Grid item xs={5} sm={4} md={3} xl='auto'>
                    <img className={classes.image} src="/images/search.svg"/>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <img className={classes.image} style={{position: 'absolute', opacity: 0.4}} src="/images/search.svg"/>
            </Hidden>
            <Grid item xs={10} md={6} xl={4}>
                <Typography className={classes.slideCaption} gutterBottom>
                    {intl.formatMessage({id: 'crowd_homepage_carousel_caption1'})}
                </Typography>
                <Typography className={classes.slideText}>
                    {intl.formatMessage({id: 'crowd_homepage_carousel_text1'})}
                </Typography>
            </Grid>
        </Grid>
        <Grid
            container
            alignItems="center"
            justify='center'
            spacing={4}
            className={classes.slide}>
            <Hidden smDown>
                <Grid item xs={5} sm={4} md={3} xl='auto'>
                    <img className={classes.image} src="/images/bulb.svg"/>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <img className={classes.image} style={{position: 'absolute', opacity: 0.4}} src="/images/bulb.svg"/>
            </Hidden>
            <Grid item xs={10} md={6} xl={4}>
                <Typography className={classes.slideCaption} gutterBottom>
                    {intl.formatMessage({id: 'crowd_homepage_carousel_caption2'})}
                </Typography>
                <Typography className={classes.slideText}>
                    {intl.formatMessage({id: 'crowd_homepage_carousel_text2'})}
                </Typography>
            </Grid>
        </Grid>
        <Grid
            container
            alignItems="center"
            justify='center'
            spacing={4}
            className={classes.slide}>
            <Hidden smDown>
                <Grid item xs={5} sm={4} md={3} xl='auto'>
                    <img className={classes.image} src="/images/heart.svg"/>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <img className={classes.image} style={{position: 'absolute', opacity: 0.4}} src="/images/heart.svg"/>
            </Hidden>
            <Grid item xs={10} md={6} xl={4}>
                <Typography className={classes.slideCaption} gutterBottom>
                    {intl.formatMessage({id: 'crowd_homepage_carousel_caption3'})}
                </Typography>
                <Typography className={classes.slideText}>
                    {intl.formatMessage({id: 'crowd_homepage_carousel_text3'})}
                </Typography>
            </Grid>
        </Grid>
        <Grid
            container
            alignItems="center"
            justify='center'
            spacing={4}
            className={classes.slide}>
            <Hidden smDown>
                <Grid item xs={5} sm={4} md={3} xl='auto'>
                    <img className={classes.image} src="/images/package.svg"/>
                </Grid>
            </Hidden>
            <Hidden mdUp>
                <img className={classes.image} style={{position: 'absolute', opacity: 0.4}} src="/images/package.svg"/>
            </Hidden>
            <Grid item xs={10} md={6} xl={4}>
                <Typography className={classes.slideCaption} gutterBottom>
                    {intl.formatMessage({id: 'crowd_homepage_carousel_caption4'})}
                </Typography>
                <Typography className={classes.slideText}>
                    {intl.formatMessage({id: 'crowd_homepage_carousel_text4'})}
                </Typography>
            </Grid>
        </Grid>
    </Carousel>
};

export default injectIntl(CrowdCarousel);