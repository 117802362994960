import React, {Component} from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TranslateIcon from '@material-ui/icons/Translate';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import {injectIntl} from "react-intl";
import {compose} from "redux";
import {changeLocale} from "../../../actions/locale";
import {SelectLanguageMenu} from "./SelectLanguageMenu";

class SelectLanguageButton extends Component {

    classes = this.props.classes;
    state = {
        anchorEl: null
    };

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget});
    };

    handleClose = locale => {
        this.setState({anchorEl: null});
        this.props.changeLocale(locale)
    };

    render() {
        return (
            <>
                <Button
                    onClick={this.handleClick}
                    variant="contained"
                    color="primary"
                    style={{color: 'white'}}
                    startIcon={<TranslateIcon/>}
                    endIcon={<ExpandMoreIcon/>}
                >
                    {this.props.intl.formatMessage({id: this.props.currentLocale})}
                </Button>
                <SelectLanguageMenu
                    anchorEl={this.state.anchorEl}
                    handleClose={this.handleClose}
                    currentLocale={this.props.currentLocale}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentLocale: state.locale
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLocale: (locale) => dispatch(changeLocale(locale))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(SelectLanguageButton);
