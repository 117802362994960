import React from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Collapse from "@material-ui/core/Collapse";
import Party from "./Party";
import Concert from "./Concert";
import Festival from "./Festival";
import Trial from "./Trial";

const useStyles = makeStyles(theme => ({
    gridContainerNoSpacing: {
        width: '100%',
        margin: 0
    },
    opacityGradient: {
        backgroundColor: 'white',
        WebkitMaskImage: '-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,1)))',
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: '50px',
        width: '100%',
        pointerEvents: 'none'
    }
}));

// TODO add a subscription pricing configurer, something like https://www.mailgun.com/pricing

const ChooseSubscription = props => {

    const classes = useStyles();
    const {
        displayFreeSubscription,
        removeContainerSpacing,
        excludeTier = false,
        collapsible = false,
        hideBestValueOutline = false,
        isLandingPage,
        buttonClickHandler,
        cardClickHandler,
        hideButtons,
        selected
    } = props;
    let gridItems = 4;
    if (!displayFreeSubscription)
        gridItems = gridItems - 1;
    if (excludeTier)
        gridItems = gridItems - 1;

    const responsiveWidth = {
        xs: 12,
        sm: 6,
        lg: 3,
        ...(gridItems === 3 && {
            sm: 4,
            lg: 4
        }),
        ...(gridItems === 2 && {
            lg: 4
        })
    };
    const [shownAll, setShownAll] = React.useState(false);

    const showAll = !collapsible || shownAll;

    const handleButtonClick = tier => event => {
        event.stopPropagation();
        buttonClickHandler(tier);
    };

    const handleCardClick = tier => event => {
        event.stopPropagation();
        if (cardClickHandler)
            cardClickHandler(tier);
    };

    const gridSpacing = () => {
        if (!collapsible)
            return window.innerWidth > 600 ? {spacing: 4} : {spacing: 2};
        return {};
    };

    return <Grid container
                 direction="row"
                 justify="center"
                 alignItems="flex-start"
                 {...gridSpacing()}
                 className={clsx({[classes.gridContainerNoSpacing]: removeContainerSpacing})}>
        {displayFreeSubscription && (
            <Grid item {...responsiveWidth}>
                {collapsible ?
                    <Collapse in={showAll} collapsedHeight={305} style={{padding: '20px', position: "relative"}}>
                        <Trial isLandingPage={isLandingPage}
                               buttonClickHandler={handleButtonClick(0)}
                               cardClickHandler={handleCardClick(0)}
                               hideButtons={hideButtons}
                               selected={selected}/>
                        {!showAll && <div className={clsx(classes.opacityGradient, 'cursorPointer')}
                                          onClick={handleCardClick(0)}
                        />}
                    </Collapse> :
                    <Trial isLandingPage={isLandingPage}
                           buttonClickHandler={handleButtonClick(0)}
                           cardClickHandler={handleCardClick(0)}
                           hideButtons={hideButtons}
                           selected={selected}/>}
            </Grid>)}
        {excludeTier !== 1 && (
            <Grid item {...responsiveWidth}>
                {collapsible ?
                    <Collapse in={showAll} collapsedHeight={305} style={{padding: '20px', position: "relative"}}>
                        <Party isLandingPage={isLandingPage}
                               buttonClickHandler={handleButtonClick(1)}
                               cardClickHandler={handleCardClick(1)}
                               hideButtons={hideButtons}
                               selected={selected}/>
                        {!showAll && <div className={clsx(classes.opacityGradient, 'cursorPointer')}
                                          onClick={handleCardClick(1)}
                        />}
                    </Collapse> :
                    <Party isLandingPage={isLandingPage}
                           buttonClickHandler={handleButtonClick(1)}
                           cardClickHandler={handleCardClick(1)}
                           hideButtons={hideButtons}
                           selected={selected}/>}
            </Grid>
        )}
        {excludeTier !== 2 && (
            <Grid item {...responsiveWidth}>
                {collapsible ?
                    <Collapse in={showAll} collapsedHeight={305} style={{padding: '20px', position: "relative"}}>
                        <Concert isLandingPage={isLandingPage}
                                 buttonClickHandler={handleButtonClick(2)}
                                 cardClickHandler={handleCardClick(2)}
                                 hideButtons={hideButtons}
                                 hideBestValueOutline={hideBestValueOutline}
                                 selected={selected}/>
                        {!showAll && <div className={clsx(classes.opacityGradient, 'cursorPointer')}
                                          onClick={handleCardClick(2)}
                        />}
                    </Collapse> :
                    <Concert isLandingPage={isLandingPage}
                             buttonClickHandler={handleButtonClick(2)}
                             cardClickHandler={handleCardClick(2)}
                             hideButtons={hideButtons}
                             hideBestValueOutline={hideBestValueOutline}
                             selected={selected}/>}
            </Grid>)}
        {excludeTier !== 3 && (
            <Grid item {...responsiveWidth}>
                {collapsible ?
                    <Collapse in={showAll} collapsedHeight={305} style={{padding: '20px', position: "relative"}}>
                        <Festival isLandingPage={isLandingPage}
                                  buttonClickHandler={handleButtonClick(3)}
                                  cardClickHandler={handleCardClick(3)}
                                  hideButtons={hideButtons}
                                  selected={selected}/>
                        {!showAll && <div className={clsx(classes.opacityGradient, 'cursorPointer')}
                                          onClick={handleCardClick(3)}
                        />}
                    </Collapse> :
                    <Festival isLandingPage={isLandingPage}
                              buttonClickHandler={handleButtonClick(3)}
                              cardClickHandler={handleCardClick(3)}
                              hideButtons={hideButtons}
                              hideBestValueOutline={hideBestValueOutline}
                              selected={selected}/>}
            </Grid>)}
        {collapsible && <div style={{width: '100%', textAlign: 'center', padding: '25px 0 0'}}>
            {shownAll ? <ExpandLessIcon className={'cursorPointer'} fontSize='large' color='primary'
                                        onClick={() => setShownAll(false)}/> :
                <ExpandMoreIcon className={'cursorPointer'} fontSize='large' color='primary'
                                onClick={() => setShownAll(true)}/>}
        </div>}
    </Grid>
};

export default injectIntl(ChooseSubscription);