import React from "react";
import {injectIntl} from "react-intl";
import Intro from "./Intro";
import Benefits from "./Benefits";
import HowItWorks from "./HowItWorks";
import Pricing from "./Pricing";
import Contact from "../Contact";

const CustomerLanding = props => {

    const {user} = props;

    return <>
        <Intro/>
        <Benefits/>
        <HowItWorks/>
        <Pricing displayFreeSubscription={!user.loggedIn || user.trialEligible}/>
        <Contact/>
    </>
};

export default injectIntl(CustomerLanding);