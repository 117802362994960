import {injectIntl} from "react-intl";
import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    caption: {
        fontWeight: 'bold'
    },
}));

const PrivacyPolicyContent = props => {

    const classes = useStyles();

    return <>
        <Typography paragraph className={classes.caption}>
            I. {props.intl.formatMessage({id: 'privacy_policy_I'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'privacy_policy_I_1'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            II. {props.intl.formatMessage({id: 'privacy_policy_II'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'privacy_policy_II_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'privacy_policy_II_2'})}
        </Typography>
        <ul>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_II_2_1'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_II_2_2'})}
                </Typography>
            </li>
        </ul>
        <Typography paragraph>
            3. {props.intl.formatMessage({id: 'privacy_policy_II_3'})}
        </Typography>
        <Typography paragraph>
            4. {props.intl.formatMessage({id: 'privacy_policy_II_4'})}
        </Typography>
        <Typography paragraph>
            5. {props.intl.formatMessage({id: 'privacy_policy_II_5'})}
        </Typography>
        <Typography paragraph>
            6. {props.intl.formatMessage({id: 'privacy_policy_II_6'})}
        </Typography>
        <ul>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_II_6_1'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_II_6_2'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_II_6_3'})}
                </Typography>
            </li>
        </ul>
        <Typography paragraph className={classes.caption}>
            III. {props.intl.formatMessage({id: 'privacy_policy_III'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'privacy_policy_III_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'privacy_policy_III_2'})}
        </Typography>
        <Typography paragraph>
            3. {props.intl.formatMessage({id: 'privacy_policy_III_3'})}
        </Typography>
        <Typography paragraph>
            4. {props.intl.formatMessage({id: 'privacy_policy_III_4'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            IV. {props.intl.formatMessage({id: 'privacy_policy_IV'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'privacy_policy_IV_1'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            V. {props.intl.formatMessage({id: 'privacy_policy_V'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'privacy_policy_V_1'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            VI. {props.intl.formatMessage({id: 'privacy_policy_VI'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'privacy_policy_VI_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'privacy_policy_VI_2'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            VII. {props.intl.formatMessage({id: 'privacy_policy_VII'})}
        </Typography>
        <Typography paragraph>
            1. {props.intl.formatMessage({id: 'privacy_policy_VII_1'})}
        </Typography>
        <Typography paragraph>
            2. {props.intl.formatMessage({id: 'privacy_policy_VII_2'})}
        </Typography>
        <Typography paragraph>
            3. {props.intl.formatMessage({id: 'privacy_policy_VII_3'})}
        </Typography>
        <Typography paragraph>
            4. {props.intl.formatMessage({id: 'privacy_policy_VII_4'})}
        </Typography>
        <Typography paragraph>
            5. {props.intl.formatMessage({id: 'privacy_policy_VII_5'})}
        </Typography>
        <ol style={{listStyleType: 'lower-alpha'}}>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_5_a'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_5_b'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_5_c'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_5_d'})}
                </Typography>
            </li>
        </ol>
        <Typography paragraph>
            {props.intl.formatMessage({id: 'privacy_policy_VII_5_2'})}
        </Typography>
        <Typography paragraph>
            6. {props.intl.formatMessage({id: 'privacy_policy_VII_6'})}
        </Typography>
        <ol style={{listStyleType: 'lower-alpha'}}>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_6_a'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_6_b'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_6_c'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_6_d'})}
                </Typography>
            </li>
        </ol>
        <Typography paragraph>
            7. {props.intl.formatMessage({id: 'privacy_policy_VII_7'})}
        </Typography>
        <Typography paragraph>
            8. {props.intl.formatMessage({id: 'privacy_policy_VII_7'})}
        </Typography>v
        <Typography paragraph>
            9. {props.intl.formatMessage({id: 'privacy_policy_VII_7'})}
        </Typography>
        <ol style={{listStyleType: 'lower-alpha'}}>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_9_a'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_9_b'})}
                </Typography>
            </li>
            <li>
                <Typography paragraph>
                    {props.intl.formatMessage({id: 'privacy_policy_VII_9_c'})}
                </Typography>
            </li>
        </ol>
        <Typography paragraph>
            10. {props.intl.formatMessage({id: 'privacy_policy_VII_10'})}
        </Typography>
        <Typography paragraph>
            11. {props.intl.formatMessage({id: 'privacy_policy_VII_11'})}
        </Typography>
        <Typography paragraph className={classes.caption}>
            VIII. {props.intl.formatMessage({id: 'privacy_policy_VIII'})}
        </Typography>
        <Typography paragraph>
            {props.intl.formatMessage({id: 'privacy_policy_VIII_1'})}
        </Typography>
        <Typography paragraph>
            Extropian s.r.o.<br/>
            Idanská 775/27<br/>
            040 11 Košice<br/>
            {props.intl.formatMessage({id: 'privacy_policy_VIII_2'})} hello@crowdea.co<br/>
            {props.intl.formatMessage({id: 'privacy_policy_VIII_3'})} +421904943833
        </Typography>
        <Typography paragraph>
            {props.intl.formatMessage({id: 'privacy_policy_VIII_4'})}
        </Typography>
        <Typography paragraph>
            {props.intl.formatMessage({id: 'privacy_policy_VIII_5'})}
        </Typography>
    </>;
};

export default injectIntl(PrivacyPolicyContent);