import {ActionType} from "../constants";

export function extractBodyOrThrow(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response.json();
}

export function throwOnError(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

export function emitAppNotification(variant, messageCode, duration = 3000, permanent = false) {
    return {
        type: ActionType.APP_NOTIFICATION,
        notification: {
            variant,
            messageCode,
            duration: permanent ? null : duration,
            timestamp: new Date().getTime()
        }
    };
}

export function parseApiValidationErrors(errs) {
    return errs.reduce((acc, err) => {
        acc[err.param] = err.msg;
        return acc;
    }, {});
}