import React, {Component} from 'react';
import TextField from "@material-ui/core/TextField";
import {Button} from "@material-ui/core";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import {compose} from "redux";
import {emailRegex} from "../../constants";

class EmailPasswordLogin extends Component {
    state = {
        loginInfo: {
            email: "",
            password: ""
        },
        validators: {
            email: (loginInfo) => emailRegex.test(loginInfo.email),
            password: (loginInfo) => "" !== loginInfo.password,
        },
        validationErrors: []
    };

    handleFormInputChange = name => event => {
        const loginInfo = {...this.state.loginInfo, [name]: event.target.value};
        this.setState({...this.state, loginInfo});
    };

    handleSubmit = (event) => {
        event.preventDefault();
        if (this.validateFormInput()) {
            this.setState({...this.state, validationErrors: []});
            this.props.login(this.state.loginInfo)
        }
    };

    validateFormInput = () => {
        const userInput = this.state.loginInfo;
        const validationErrors = Object.entries(this.state.validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                if (!validator(userInput)) {
                    acc.push(key);
                }
                return acc;
            }, []);

        this.setState({...this.state, validationErrors});
        return validationErrors.length === 0;
    };

    isInvalid = (field) => {
        return this.state.validationErrors.includes(field);
    };

    validationErrorMessage = (field) => {
        if (this.state.validationErrors.includes(field)) {
            return this.props.intl.formatMessage({id: 'validation_login_' + field});
        }
        return "";

    };

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="stretch"
                    spacing={1}
                >
                    <Grid item>
                        <TextField
                            variant="outlined"
                            label={this.props.intl.formatMessage({id: 'email'})}
                            type="email"
                            name="email"
                            autoComplete="username"
                            margin="dense"
                            fullWidth
                            error={this.isInvalid('email')}
                            helperText={this.validationErrorMessage('email')}
                            value={this.state.loginInfo.email}
                            onChange={this.handleFormInputChange('email')}/>
                    </Grid>
                    <Grid item>
                        <TextField
                            variant="outlined"
                            label={this.props.intl.formatMessage({id: 'password'})}
                            type="password"
                            name="password"
                            autoComplete="current-password"
                            margin="dense"
                            fullWidth
                            error={this.isInvalid('password')}
                            helperText={this.validationErrorMessage('password')}
                            value={this.state.loginInfo.password}
                            onChange={this.handleFormInputChange('password')}/>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{color: 'white'}}
                            fullWidth>
                            {this.props.intl.formatMessage({id: 'login'})}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        )
    }
}

export default compose(
    injectIntl
)(EmailPasswordLogin);
