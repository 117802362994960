import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";
import {loadCrowdViewIdeas} from "./crowdView";

export function loadIdeas() {
    return async dispatch => {
        secureFetch(API_URL + "/idea",
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(ideas => dispatch(ideasLoaded(ideas)))
            .catch(error => console.log(error.message));
    };
}

function ideasLoaded(items) {
    return {
        type: ActionType.IDEAS_LOADED,
        items
    };
}

export function createIdea(formData, successCallback, errorCallback) {
    return async (dispatch, getState) => {
        secureFetch(API_URL + "/idea",
            {
                method: 'POST',
                body: formData
            })
            .then(response => extractBodyOrThrow(response))
            .then(result => {
                if (result.error) {
                    dispatch(emitAppNotification(NotificationType.WARNING, result.error, 0, true));
                    const {crowdView} = getState();
                    dispatch(loadCrowdViewIdeas(crowdView.data.subdomain));
                } else {
                    dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_created", 3000));
                    dispatch(ideaCreated(result));
                    successCallback();
                }
            })
            .catch(error => {
                if (errorCallback)
                    errorCallback();
                console.log(error.message)
            });
    };
}

function ideaCreated(item) {
    return {
        type: ActionType.CROWD_VIEW_IDEA_LOADED,
        item
    };
}

export function updateCrowdIdea(formData, successCallback, errorCallback) {
    return async dispatch => {
        secureFetch(API_URL + "/idea",
            {
                method: 'PUT',
                body: formData
            })
            .then(response => extractBodyOrThrow(response))
            .then(idea => {
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_updated", 3000));
                dispatch(crowdIdeaUpdated(idea));
                if (successCallback())
                    successCallback();
            })
            .catch(error => {
                if (errorCallback)
                    errorCallback();
                console.log(error.message)
            });
    };
}

function crowdIdeaUpdated(item) {
    return {
        type: ActionType.CROWD_VIEW_IDEA_UPDATED,
        item
    };
}

export function incrementIdeaViews(ideaId) {
    return async dispatch => {
        secureFetch(`${API_URL}/public/ideas/views/${ideaId}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => dispatch(ideaViewed(ideaId)))
            .catch(error => console.log(error.message));
    };
}

function ideaViewed(ideaId) {
    return {
        type: ActionType.CROWD_VIEW_IDEA_VIEWED,
        ideaId
    };
}

export function updateIdea(formData, successCb) {
    return async dispatch => {
        secureFetch(API_URL + "/idea/admin",
            {
                method: 'PUT',
                body: formData
            })
            .then(response => extractBodyOrThrow(response))
            .then(idea => {
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_updated", 3000));
                dispatch(ideaUpdated(idea));
                if (successCb())
                    successCb();
            })
            .catch(error => console.log(error.message));
    };
}

function ideaUpdated(item) {
    return {
        type: ActionType.IDEA_UPDATED,
        item
    };
}

export function tagIdea(body) {
    return async dispatch => {
        secureFetch(API_URL + "/idea/tag",
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => extractBodyOrThrow(response))
            .then(idea => {
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_updated", 3000));
                dispatch(ideaUpdated(idea));
                dispatch(crowdIdeaUpdated(idea));
            })
            .catch(error => console.log(error.message));
    };
}

export function changeIdeaState(body) {
    return async dispatch => {
        secureFetch(API_URL + "/idea/changeState",
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => extractBodyOrThrow(response))
            .then(idea => {
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_updated", 3000));
                dispatch(ideaUpdated(idea));
                dispatch(crowdIdeaUpdated(idea));
            })
            .catch(error => console.log(error.message));
    };
}

export function deleteIdea(ideaId, crowdId) {
    return async dispatch => {
        secureFetch(`${API_URL}/idea/${crowdId}/${ideaId}`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                dispatch(ideaDeleted(ideaId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_deleted", 3000));
            })
            .catch(error => console.log(error.message));
    };
}

function ideaDeleted(id) {
    return {
        type: ActionType.IDEA_DELETED,
        id
    };
}

export function loadIdeaStats(crowdId, ideaId) {
    return async dispatch => {
        secureFetch(`${API_URL}/idea/stats/${crowdId}/${ideaId}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(stats => dispatch(ideaStatsLoaded(ideaId, stats)))
            .catch(error => console.log(error.message));
    };
}

function ideaStatsLoaded(ideaId, item) {
    return {
        type: ActionType.IDEA_STATS_LOADED,
        ideaId,
        item
    };
}

export function exportIdeaAnswers(crowdId, ideaId) {
    return secureFetch(`${API_URL}/idea/export/${crowdId}/${ideaId}`,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
        .then(async res => {
            // inspiration taken from https://gist.github.com/devloco/5f779216c988438777b76e7db113d05c
            const blob = await res.blob();
            const filename = res.headers.get('content-disposition').split('filename=')[1];
            const url = window.URL.createObjectURL(blob);

            let link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.click();

            // For Firefox
            setTimeout(() => { window.URL.revokeObjectURL(url); }, 250);
        })
        .catch(error => console.log(error.message));
}

export function shareIdea(emails, ideaId) {
    return async dispatch => {
        secureFetch(`${API_URL}/idea/share`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ideaId,
                    emails
                })
            })
            .then(() => {
                dispatch(emitAppNotification(NotificationType.SUCCESS, "idea_shared", 3000));
                dispatch(ideaShared(ideaId));
            })
            .catch(error => console.log(error.message));
    };
}

function ideaShared(ideaId) {
    return {
        type: ActionType.IDEA_SHARED,
        ideaId
    };
}