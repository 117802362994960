import React, {useEffect} from 'react';
import './App.css';
import {Redirect, Route, Switch} from 'react-router-dom';
import {IntlProvider} from "react-intl";
import {connect} from "react-redux";
import {messagesSk} from "./i18n/messages_sk";
import {messagesEn} from "./i18n/messages_en";
import Notification from "./components/common/Notification";
import Login from "./components/login/Login";
import Registration from "./components/registration/Registration";
import Dashboard from "./components/dashboard/Dashboard";
import AccountActivation from "./components/registration/AccountActivation";
import ForgotPasswordSendMail from "./components/login/ForgotPasswordSendMail";
import ForgotPasswordReset from "./components/login/ForgotPasswordReset";
import Crowd from "./components/crowdHomepage/Crowd";
import {loadCrowdView} from "./actions/crowdView";
import Spinner from "./components/common/Spinner";
import CrowdNotFound from "./components/crowdHomepage/CrowdNotFound";
import useDeepCompareEffect from "use-deep-compare-effect";
import {websocketConnect} from "./actions/websocket";
import {CROWDEA_HOSTNAME, ENVIRONMENT, REACT_APP_LOGIN_URL, ReservedSubdomains} from "./constants";
import LoginRedirect from "./components/login/LoginRedirect";
import {changeLocale} from "./actions/locale";
import {useGlobalStyles} from "./style/global";
import LandingPage from "./components/landingPage/LandingPage";
import PrivacyPolicy from "./components/registration/PrivacyPolicy";
import ServiceAgreement from "./components/registration/ServiceAgreement";
import {loadUser} from "./actions/user";
import useAnalytics from "./components/common/useAnalytics";

const messages = {
    sk: messagesSk,
    en: messagesEn
};

export const shouldDisplayCrowdHomepage = () => {

    let hostname = '';
    if (ENVIRONMENT === 'production')
        hostname = window.location.hostname.replace('www.', '');
    else {
        // hostname = CROWDEA_HOSTNAME;
        hostname = `supercrowd.${CROWDEA_HOSTNAME}`;
        // hostname = `privatecrowd.${CROWDEA_HOSTNAME}`;
        // hostname = `admincrowd.${CROWDEA_HOSTNAME}`;
        // hostname = 'telekom.napady.com';
    }

    const hostnameArr = hostname.split('.');
    const shouldDisplayHomepage = !(hostname === CROWDEA_HOSTNAME || ReservedSubdomains.includes(hostnameArr[0]));
    const hostnameLength = hostnameArr.length;
    const baseDomainLength = CROWDEA_HOSTNAME.split('.').length;
    const isSubdomain = hostnameLength > baseDomainLength &&
        hostnameArr.slice(hostnameLength - baseDomainLength).join('.') === CROWDEA_HOSTNAME;
    const domain = isSubdomain ? hostnameArr.slice(0, hostnameLength - baseDomainLength).join('.') : hostname;

    return [shouldDisplayHomepage, isSubdomain, domain];
};

export const shouldDisplayLogin = () => {
    if (ENVIRONMENT === 'production')
        return window.location.origin.replace('www.', '') === REACT_APP_LOGIN_URL;
    else {
        return false;
        // return true;
    }
};

// this method will be used instead of shouldDisplayCrowdHomepage once the application is split in 2
/*const isSubdomain = () => {
    const hostname = window.location.hostname;
    const hostnameArr = hostname.split('.');
    return hostnameArr.length >= 3 && hostnameArr[hostnameArr.length - 2] === 'crowdea';
};*/

const App = props => {

    useGlobalStyles();
    const {
        user,
        crowdView,
        notification,
        locale,
        loadCrowdView,
        loadUser,
        websocket,
        websocketConnect,
        changeLocale
    } = props;
    const [displayHomepage, isSubdomain, domain] = shouldDisplayCrowdHomepage();
    const displayLogin = shouldDisplayLogin();
    useAnalytics(displayHomepage);

    useEffect(() => {
        if (!crowdView.loaded && displayHomepage && !displayLogin)
            loadCrowdView(domain);

        if (!displayHomepage && !displayLogin && !user.loaded)
            loadUser();
    }, []);

    useDeepCompareEffect(() => {
        if (websocket.id === undefined && user.loggedIn)
            websocketConnect(user.id);
        if (user.loggedIn && locale !== user.locale)
            changeLocale(user.locale);
    }, [user]);

    useDeepCompareEffect(() => {
        if (crowdView.loaded && !user.loaded)
            loadUser();
    }, [crowdView]);

    const routeTree = () => {

        if (displayLogin) return <Login/>;

        if (!user.loaded)
            return <Spinner/>;

        if (displayHomepage) {
            if (!crowdView.loaded)
                return <Spinner/>;

            if (crowdView.loaded) {
                if (!crowdView.data.active)
                    return <CrowdNotFound messageCode='crowd_is_disabled'/>;
                if (!crowdView.data.subdomain || (crowdView.data.features.ownDomain && isSubdomain && !crowdView.data.subdomainRouting))
                    return <CrowdNotFound/>;
                if (!crowdView.data.visible)
                    return <CrowdNotFound messageCode='crowd_not_visible_owner'/>;
            }

            return <Switch>
                <Route path="/login" component={LoginRedirect}/>
                <Route path="/register" component={Registration}/>
                <Route path="/privacyPolicy" component={PrivacyPolicy}/>
                <Route path="/serviceAgreement" component={ServiceAgreement}/>
                <Route path="/accountActivation/:token?" component={AccountActivation}/>
                <Route path="/forgotPassword" component={ForgotPasswordSendMail}/>
                <Route path="/resetPassword/:token?" component={ForgotPasswordReset}/>
                <Route path={["/:categoryName?", "/createIdea", "/account", "/idea"]}
                       render={() => <Crowd domain={domain}/>}/>
                <Route render={() => <Redirect to="/"/>}/>
            </Switch>;
        }

        return <Switch>
            <Route path="/login" component={LoginRedirect}/>
            <Route path="/register" component={Registration}/>
            <Route path="/privacyPolicy" component={PrivacyPolicy}/>
            <Route path="/serviceAgreement" component={ServiceAgreement}/>
            <Route path="/accountActivation/:token?" component={AccountActivation}/>
            <Route path="/forgotPassword" component={ForgotPasswordSendMail}/>
            <Route path="/resetPassword/:token?" component={ForgotPasswordReset}/>
            <Route path={["/crowds/:crowdId?", "/account", "/ideas/:ideaId?", "/subscription"]}
                   component={Dashboard}/>
            <Route path="/intro" component={LandingPage}/>
            <Route path="/" exact component={LandingPage}/>
            <Route render={() => <Redirect to="/"/>}/>
        </Switch>;
    };

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            {routeTree()}
            {notification.timestamp && (
                <Notification
                    key={notification.timestamp}
                    variant={notification.variant}
                    open={true}
                    messageCode={notification.messageCode}
                    duration={notification.duration}/>)}
        </IntlProvider>
    );
};

const mapStateToProps = state => {
    return {
        locale: state.locale,
        notification: state.appNotification,
        crowdView: state.crowdView,
        user: state.user,
        websocket: state.websocket
    };
};

const mapDispatchToProps = dispatch => {
    return {
        loadUser: () => dispatch(loadUser()),
        loadCrowdView: domain => dispatch(loadCrowdView(domain)),
        websocketConnect: userId => dispatch(websocketConnect(userId)),
        changeLocale: locale => dispatch(changeLocale(locale))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
