import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import ColorLensIcon from '@material-ui/icons/ColorLens';
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import {uploadCoverImage} from "../../actions/crowds";
import useDeepCompareEffect from "use-deep-compare-effect";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {
    API_URL, CROWD_INTRO_LENGTH_LIMIT,
    IDEA_DESCRIPTION_LENGTH_LIMIT,
    IdeasListingView,
    TINY_MCE_API_KEY,
    ValidImageTypes
} from "../../constants";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {injectIntl} from "react-intl";
import Grow from "@material-ui/core/Grow";
import clsx from "clsx";
import {isEqual} from 'lodash';
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from '@material-ui/icons/Cancel';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SvgIcon from "@material-ui/core/SvgIcon";
import {Editor} from "@tinymce/tinymce-react/lib/es2015/main/ts";
import {ColorPicker} from 'material-ui-color';
import {ColorButton} from 'material-ui-color';
import {runValidators} from "../../utils/utils";
import differenceWith from 'lodash/differenceWith';

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    centerOnMobile: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    errorText: {
        color: 'red'
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    selectFileButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    deleteMainImageButton: {
        position: 'absolute',
        marginLeft: '-24px',
        marginTop: '-24px'
    },
    imageWrapper: {
        width: '50%',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%'
    },
    sectionCaption: {
        fontSize: '20px',
        marginTop: '20px'
    },
    colorPicker: {
        display: 'inline-block'
    }
}));

const CrowdTheming = props => {

    const classes = useStyles();
    const {crowd, updateCrowd, updateThemingData} = props;
    const [themingDataState, setThemingDataState] = React.useState(crowd.themingData);
    const [coverImage, setCoverImage] = React.useState(false);
    const [imageError, setImageError] = React.useState(false);
    const [editor, setEditor] = React.useState(null);
    const [validationErrors, setValidationErrors] = React.useState({});

    const palette = {
        red: '#ff0000',
        blue: '#0000ff',
        green: '#00ff00',
        yellow: 'yellow',
        cyan: 'cyan',
        lime: 'lime',
        gray: 'gray',
        orange: 'orange',
        purple: 'purple',
        black: 'black',
        white: 'white',
        pink: 'pink',
        darkblue: 'darkblue',
    };

    const validators = {
        customIntroContent: () => {
            const descLength = editor.getContent({format: 'text'}).trim().length;
            if (descLength > CROWD_INTRO_LENGTH_LIMIT)
                return [props.intl.formatMessage({id: 'validation_crowd_intro_too_long'}),
                    descLength - CROWD_INTRO_LENGTH_LIMIT,
                    props.intl.formatMessage({id: 'characters_too_long'})].join(' ');
            return true;
        }
    };

    useDeepCompareEffect(() => {
        setThemingDataState(crowd.themingData);
    }, [crowd]);

    const dropdownSelected = name => event => {
        event.preventDefault();
        setThemingDataState({
            ...themingDataState,
            [name]: event.target.value
        })
    };

    const selectFile = event => {
        const file = event.target.files[0];
        if (file) {
            setCoverImage(file);
        }
    };

    const uploadFile = () => {
        if (!coverImage)
            return;

        const imageValid = ValidImageTypes.includes(coverImage.type);
        if (!imageValid) {
            setImageError(true);
            return;
        }

        const body = new FormData();
        body.append('id', crowd.id);
        body.append('version', crowd.version);
        body.append('coverImage', coverImage);

        uploadCoverImage(body);
    };

    const removeCoverImage = event => {
        event.stopPropagation();
        updateCrowd({
            id: crowd.id,
            version: crowd.version,
            featuredImageId: null
        });
    };

    const handleSwitchChange = name => event => {
        if (event.target.checked) {
            if (name === 'displayDefaultSlideshow')
                setThemingDataState({
                    ...themingDataState,
                    displayDefaultSlideshow: event.target.checked,
                    displayCustomIntro: false
                });
            if (name === 'displayCustomIntro')
                setThemingDataState({
                    ...themingDataState,
                    displayCustomIntro: event.target.checked,
                    displayDefaultSlideshow: false
                });
        } else
            setThemingDataState({
                ...themingDataState,
                [name]: event.target.checked
            });
    };

    const handleEditorChange = (content, editor) => {
        const descLength = editor.getContent({format: 'text'}).trim().length;
        if (descLength > CROWD_INTRO_LENGTH_LIMIT) {
            const errText = [props.intl.formatMessage({id: 'validation_crowd_intro_too_long'}),
                descLength - CROWD_INTRO_LENGTH_LIMIT,
                props.intl.formatMessage({id: 'characters_too_long'})].join(' ');

            setValidationErrors({
                ...validationErrors,
                description: errText
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                description: undefined
            });
        }
    };

    const editorInit = (event, editor) => {
        setEditor(editor);
    };

    const handleColorChange = color => {
        setThemingDataState({
            ...themingDataState,
            customIntroBackgroundColor: '#' + color.hex
        });
    };

    const validateInput = () => {
        const errors = runValidators(validators);
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const changesMade = () => {
        return !isEqual({
            ...themingDataState,
            customIntroBackgroundColor: themingDataState.customIntroBackgroundColor,
            customIntroContent: editor.getContent().trim()
        }, crowd.themingData);
    };

    const handleSubmit = () => {
        if (validateInput() && changesMade()) {
            const diff = differenceWith(Object.entries({
                ...themingDataState,
                customIntroBackgroundColor: themingDataState.customIntroBackgroundColor,
                customIntroContent: editor.getContent().trim()
            }), Object.entries(crowd.themingData), isEqual).reduce((acc, [key, val]) => {
                acc[key] = val;
                return acc;
            }, {});

            const body = {
                id: crowd.themingData.id,
                crowdId: crowd.id,
                version: crowd.themingData.version,
                ...diff
            };
            updateThemingData(body, crowd.id);
        }
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <ColorLensIcon/>
                </Avatar>
            }
            title={<Typography
                className={'cardHeaderText'}>{props.intl.formatMessage({id: 'crowd_theming_data'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Typography className={clsx(classes.centerOnMobile, classes.sectionCaption)}>
                        {props.intl.formatMessage({id: 'crowd_logo'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <div style={{textAlign: 'center'}}>
                        {crowd.id === undefined || crowd.featuredImageId === null ?
                            (<div style={{textAlign: 'center', backgroundColor: '#fafafa', color: '#f1f1f1'}}>
                                <SvgIcon style={{height: '230px', width: '230px'}}>
                                    <svg aria-hidden="true" focusable="false"
                                         role="img" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 512 512">
                                        <path
                                            d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z"/>
                                        <path
                                            d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z"/>
                                    </svg>
                                </SvgIcon>
                            </div>) :
                            <div className={classes.imageWrapper}>
                                <img loading="lazy" className={classes.image}
                                     src={`${API_URL}/file?fileId=${crowd.featuredImageId}`}/>
                                <IconButton color='primary' className={classes.deleteMainImageButton}
                                            onClick={removeCoverImage}>
                                    <CancelIcon/>
                                </IconButton>
                            </div>}
                    </div>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={selectFile}
                        id="crowd-cover-image"
                        type="file"
                    />
                    <label htmlFor="crowd-cover-image">
                        <Button variant="outlined" color="primary" component="span"
                                className={classes.selectFileButton}>
                            {props.intl.formatMessage({id: 'select_file'})}
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <Typography className={classes.centerOnMobile}>
                        {coverImage ?
                            coverImage.name :
                            props.intl.formatMessage({id: 'no_document_selected'})}
                    </Typography>
                </Grid>
                {imageError && <Grid item xs={12} sm={'auto'}>
                    <Grow in={imageError}>
                        <Typography variant={'caption'} className={clsx(classes.errorText, classes.centerOnMobile)}>
                            {props.intl.formatMessage({id: 'file_upload_image_only'})}
                        </Typography>
                    </Grow>
                </Grid>}
                <Grid item xs={12}>
                    <Button color='primary' variant="contained" className={classes.button} onClick={uploadFile}>
                        {props.intl.formatMessage({id: 'upload'})}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography className={clsx(classes.centerOnMobile, classes.sectionCaption)}>
                        {props.intl.formatMessage({id: 'crowd_intro_settings'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={themingDataState.displayDefaultSlideshow}
                                onChange={handleSwitchChange('displayDefaultSlideshow')}
                                value={themingDataState.displayDefaultSlideshow}
                                color="primary"
                            />
                        }
                        label={props.intl.formatMessage({id: 'display_default_slideshow'})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={themingDataState.displayCustomIntro}
                                onChange={handleSwitchChange('displayCustomIntro')}
                                value={themingDataState.displayCustomIntro}
                                color="primary"
                            />
                        }
                        label={props.intl.formatMessage({id: 'display_custom_intro'})}
                    />
                </Grid>
                <Grid item
                      xs={12}
                      container
                      spacing={2}
                      justify="flex-start"
                      alignItems="center">
                    <Grid item>
                        <Typography style={{display: 'inline-block'}}>
                            {props.intl.formatMessage({id: 'crowd_intro_background_color'})}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ColorPicker hideTextfield
                                     palette={palette}
                                     value={themingDataState.customIntroBackgroundColor}
                                     onChange={handleColorChange}/>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Editor
                        apiKey={TINY_MCE_API_KEY}
                        initialValue={themingDataState.customIntroContent}
                        onInit={editorInit}
                        onEditorChange={handleEditorChange}
                        init={{
                            height: 300,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar_mode: 'wrap',
                            mobile: {
                                toolbar_mode: 'wrap'
                            },
                            toolbar:
                                'undo redo | formatselect | bold italic | forecolor | \
                                alignleft aligncenter alignright alignjustify | outdent indent | \
                                bullist numlist | help'
                        }}
                    />
                </Grid>
                {/*<Grid item xs={12} style={{marginBottom: '10px'}}/>
                <Grid item xs={12}>
                    <Typography className={classes.centerOnMobile} variant='h6'>
                        {props.intl.formatMessage({id: 'crowd_theming_ideas'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="theming_data_select_label">
                            {props.intl.formatMessage({id: 'crowd_ideas_listing_view'})}
                        </InputLabel>
                        <Select
                            labelId="theming_data_select_label"
                            id="theming_data_select"
                            fullWidth
                            label={props.intl.formatMessage({id: 'crowd_ideas_listing_view'})}
                            value={themingDataState.ideasListingView}
                            onChange={dropdownSelected('ideasListingView')}
                        >
                            {Object.values(IdeasListingView).map(value =>
                                <MenuItem key={value}
                                          value={value}>{props.intl.formatMessage({id: `crowd_ideas_listing_view_${value.toLowerCase()}`})}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>*/}
                <Grid item xs={12}>
                    <Button color='primary' variant="contained" className={classes.button} onClick={handleSubmit}>
                        {props.intl.formatMessage({id: 'save'})}
                    </Button>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
};

export default injectIntl(CrowdTheming);