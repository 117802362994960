import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import ReactGA from 'react-ga';
import {store} from "../../store/configureStore";
import useDeepCompareEffect from "use-deep-compare-effect";
import {ANALYTICS_TRACKING_ID} from "../../constants";

const useAnalytics = displayHomepage => {
    const location = useLocation();
    const {crowdView} = store.getState();
    const [initialized, setInitialized] = useState(false);
    const [trackers, setTrackers] = useState([]);

    useEffect(() => {
        if (!displayHomepage) {
            ReactGA.initialize([{trackingId: ANALYTICS_TRACKING_ID}], {debug: true});
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search, trackers);
        }
    }, [initialized, location]);

    useDeepCompareEffect(() => {
        if (displayHomepage && crowdView.loaded && crowdView.data.gaCode && crowdView.data.gaCode !== '') {
            ReactGA.initialize(
                [
                    {
                        trackingId: ANALYTICS_TRACKING_ID
                    },
                    {
                        trackingId: crowdView.data.gaCode,
                        gaOptions: {name: 'customerTracker'}
                    }
                ],
                {debug: true}
            );
            setTrackers(['customerTracker']);
            setInitialized(true);
        }
    }, [crowdView]);
};

export default useAnalytics;