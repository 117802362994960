import React from 'react';
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import {compose} from "redux";
import {changeLocale} from "../../../actions/locale";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import IconButton from "@material-ui/core/IconButton";
import LoggedInMenu from "./LoggedInMenu";
import {userLogOut} from "../../../actions/login";
import {API_URL} from "../../../constants";
import Avatar from "@material-ui/core/Avatar";

const LoggedInNavbar = props => {

    const {logout, color = 'primary', user, userRoles, crowdView, isCrowdHomepage = false} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = locale => {
        setAnchorEl(null);
        changeLocale(locale);
    };

    const getColor = () => {
        return color === 'primary' ? {color: 'primary'}: {style: {color}};
    };

    return (
        <>
            <IconButton onClick={handleClick} color='primary'>
                {user.avatarImageId ? <Avatar src={`${API_URL}/file?fileId=${user.avatarImageId}`}/> :
                    <AccountCircleIcon fontSize={'large'} {...getColor()}/>}
            </IconButton>
            <LoggedInMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                userRoles={userRoles}
                crowdView={crowdView}
                isCrowdHomepage={isCrowdHomepage}
                logout={logout}
            />
        </>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user,
        userRoles: state.userRoles,
        crowdView: state.crowdView
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: (keepState) => dispatch(userLogOut(keepState))
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(LoggedInNavbar);
