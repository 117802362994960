import React from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles(theme => ({
    backgroundWrapper: {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: theme.palette.secondary.light,
        [theme.breakpoints.up('lg')]: {
            height: '360px',
            backgroundImage: 'url("/images/wave.png")',
        },
        [theme.breakpoints.down('md')]: {
            height: '365px',
            backgroundImage: 'url("/images/half_ellipse.png")',
            marginTop: '25px'
        }
    },
    container: {
        paddingTop: '60px',
        overflow: 'hidden'
    },
    text1: {
        fontSize: '30px',
        lineHeight: '39px',
        fontWeight: '300',
        color: 'white',
        marginLeft: '37px',
        display: 'inline-block',
        [theme.breakpoints.down('md')]: {
            marginLeft: 'unset'
        }
    },
    emailPhone: {
        display: 'inline-block',
        fontSize: '22px',
        lineHeight: '1.42857143',
        fontWeight: '300',
        color: 'white',
        width: '215px',
        textAlign: 'left'
    },
    address: {
        fontSize: '22px',
        fontStyle: 'normal',
        lineHeight: '1.42857143',
        color: 'white',
        textAlign: 'left',
        width: '215px',
    },
    image: {
        paddingRight: '10px'
    },
    contactWrapper: {
        marginLeft: '150px',
        [theme.breakpoints.down('lg')]: {
            marginLeft: 'calc(150px - (1280px - 100vw) / 2)'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 'unset'
        },
    },
    addressWrapper: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    centerOnMobileFlex: {
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center'
        },
    },
    centerOnMobile: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center'
        },
    }
}));

const Contact = props => {

    const classes = useStyles();

    return <div className={classes.backgroundWrapper}>
        <Container maxWidth="xl" className={classes.container}>
            <Grid container
                  className={classes.contactWrapper}
            spacing={2}>
                <Grid item xs={12} className={classes.centerOnMobile}>
                    <Typography className={classes.text1}>
                        {props.intl.formatMessage({id: 'contact'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.centerOnMobile}>
                    <img className={classes.image} src="/images/envelope.png"/>
                    <Typography className={classes.emailPhone}>
                        hello@crowdea.co
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.centerOnMobile}>
                    <img className={classes.image} src="/images/phone.png"/>
                    <Typography className={classes.emailPhone}>
                        +421 904 943 833
                    </Typography>
                </Grid>
                <Grid item xs={12} container className={classes.centerOnMobileFlex}>
                    <img className={classes.image} src="/images/location.png"/>
                    <Typography className={classes.address}>
                        engerau labs s.r.o.<br/>
                        Staré Grunty 18<br/>
                        841 04 Bratislava<br/>
                        Slovak Republic
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </div>
};

export default injectIntl(Contact);