import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";

export function loadComments(crowdId, ideaId, successCb, offset = 0, limit = 20) {
    return async dispatch => {
        secureFetch(`${API_URL}/comment/${crowdId}/${ideaId}?offset=${offset}&limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(comments => {
                dispatch(commentsLoaded(comments, ideaId));
                if (successCb)
                    successCb();
            })
            .catch(error => console.log(error.message));
    };
}

function commentsLoaded(items, ideaId) {
    return {
        type: ActionType.COMMENTS_LOADED,
        items,
        ideaId
    };
}

export function createComment(crowdId, ideaId, content, successCb) {
    return async dispatch => {
        secureFetch(API_URL + "/comment/" + crowdId,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    content,
                    ideaId
                })
            })
            .then(response => extractBodyOrThrow(response))
            .then(comment => {
                dispatch(commentLoaded(comment, ideaId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "comment_created", 3000));
                successCb();
            })
            .catch(error => console.log(error.message));
    };
}

function commentLoaded(item, ideaId) {
    return {
        type: ActionType.COMMENT_LOADED,
        item,
        ideaId
    };
}

export function updateComment(crowdId, ideaId, body, successCb) {
    return async dispatch => {
        secureFetch(API_URL + "/comment/" + crowdId,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
            .then(response => extractBodyOrThrow(response))
            .then(comment => {
                dispatch(commentUpdated(comment, ideaId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "comment_updated", 3000));
                successCb();
            })
            .catch(error => console.log(error.message));
    };
}

function commentUpdated(item, ideaId) {
    return {
        type: ActionType.COMMENT_UPDATED,
        item,
        ideaId
    };
}

export function deleteComment(commentId, ideaId, crowdId) {
    return async dispatch => {
        secureFetch(`${API_URL}/comment/${crowdId}/${commentId}`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                dispatch(commentDeleted(commentId, ideaId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "comment_deleted", 3000));
            })
            .catch(error => console.log(error.message));
    };
}

function commentDeleted(commentId, ideaId) {
    return {
        type: ActionType.COMMENT_DELETED,
        commentId,
        ideaId
    };
}