import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {compose} from "redux";
import {withAuthentication} from "../common/withAuthentication";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {API_URL, IDEA_DESCRIPTION_LENGTH_LIMIT, TINY_MCE_API_KEY, ValidImageTypes} from "../../constants";
import clsx from "clsx";
import {useHistory, useParams} from "react-router-dom";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import useDeepCompareEffect from "use-deep-compare-effect";
import Spinner from "../common/Spinner";
import SvgIcon from "@material-ui/core/SvgIcon/SvgIcon";
import {ideaDefaults} from "./IdeaDetail";
import _ from 'lodash';
import ReactPlayer from "react-player";
import {Editor} from '@tinymce/tinymce-react';
import Grow from "@material-ui/core/Grow";
import CircularProgress from "@material-ui/core/CircularProgress";
import isEqual from 'lodash/isEqual';
import differenceWith from 'lodash/differenceWith';

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    centerOnMobile: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    errorText: {
        color: 'red'
    },
    image: {
        maxHeight: '590px',
        maxWidth: '890px',
        [theme.breakpoints.down('md')]: {
            maxHeight: '425px',
            maxWidth: '640px'
        },
        [theme.breakpoints.down('sm')]: {
            maxHeight: '400px',
            maxWidth: '520px'
        },
        [theme.breakpoints.down('xs')]: {
            maxHeight: '170px',
            maxWidth: '250px'
        }
    },
    attachment: {
        height: '200px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        textAlign: 'end'
    },
    videoWrapper: {
        height: '200px',
        position: 'relative'
    },
    deleteAttachmentButton: {
        marginRight: '-24px',
        marginTop: '-24px'
    },
    deleteVideoButton: {
        marginRight: '-24px',
        marginTop: '-24px',
        position: 'absolute',
        top: 0,
        right: 0
    },
    deleteMainImageButton: {
        position: 'absolute',
        marginLeft: '-24px',
        marginTop: '-24px'
    },
    gutter: {
        marginBottom: '20px'
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    outlinedButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    }
}));


const IdeaEdit = props => {

    const classes = useStyles();
    const ideaId = parseInt(useParams().ideaId);
    const history = useHistory();
    const {ideas, categories, user, updateIdea, questionnaire} = props;
    const findIdea = () => {
        const idea = ideas.data.find(i => i.id === ideaId);
        if (idea) {
            const mergedIdea = _.mergeWith({}, ideaDefaults, idea, (a, b) => b === null ? a : undefined);
            return {
                ...mergedIdea,
                category: categories.data.find(c => c.id === idea.categoryId)
            };
        } else if (ideas.loaded)
            history.push('/');

        return ideaDefaults;
    };
    const idea = findIdea();

    const [ideaState, setIdeaState] = React.useState(idea);

    const initAnswersState = () => {
        if (questionnaire.questions) {
            return questionnaire.questions.map(q => idea.answers.find(a => a.questionId === q.id) || {questionId: q.id, answer: q.options[0]});
        } else return [];
    };
    const [answers, setAnswers] = React.useState(initAnswersState());

    useDeepCompareEffect(() => {
        setAnswers(initAnswersState());
    }, [questionnaire]);

    useDeepCompareEffect(() => {
        setIdeaState({
            ...idea,
            category: categories.data.find(c => c.id === idea.categoryId) || ''
        });
    }, [categories, ideas]);

    const [editor, setEditor] = React.useState(null);
    const [newFiles, setNewFiles] = React.useState([]);
    const [newFeaturedImg, setNewFeaturedImg] = React.useState(false);
    const [newVideos, setNewVideos] = React.useState(['']);
    const [validationErrors, setValidationErrors] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    const validators = {
        name: () => {
            if (ideaState.name === '')
                return 'validation_idea_name';
            return true;
        },
        description: () => {
            const descLength = editor.getContent({format: 'text'}).trim().length;
            if (descLength === 0)
                return props.intl.formatMessage({id: 'validation_idea_description_empty'});
            if (descLength > IDEA_DESCRIPTION_LENGTH_LIMIT)
                return [props.intl.formatMessage({id: 'validation_idea_description_too_long'}),
                    descLength - IDEA_DESCRIPTION_LENGTH_LIMIT,
                    props.intl.formatMessage({id: 'characters_too_long'})].join(' ');
            return true;
        },
        files: () => {
            if (!newFiles.every(img => ValidImageTypes.includes(img.type)))
                return 'validation_idea_files';
            return true;
        },
        featuredImg: () => {
            if (newFeaturedImg && !ValidImageTypes.includes(newFeaturedImg.type))
                return 'validation_idea_featuredImg';
            return true;
        },
    };

    const validateInput = () => {
        const result = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});

        setValidationErrors(result);
        return Object.keys(result).length === 0;
    };

    const categorySelected = event => {
        event.preventDefault();
        setIdeaState({
            ...ideaState,
            category: event.target.value
        })
    };

    const answerSelected = index => event => {
        event.preventDefault();
        const newState = [...answers];
        newState[index].answer = event.target.value;
        setAnswers(newState);
    };

    const handleInputChange = name => event => {
        const clearError = {...validationErrors};
        delete clearError[name];
        setValidationErrors(clearError);
        setIdeaState({
            ...ideaState,
            [name]: event.target.value
        });
    };

    const handleEditorChange = (content, editor) => {
        const descLength = editor.getContent({format: 'text'}).trim().length;
        if (descLength > IDEA_DESCRIPTION_LENGTH_LIMIT) {
            const errText = [props.intl.formatMessage({id: 'validation_idea_description_too_long'}),
                descLength - IDEA_DESCRIPTION_LENGTH_LIMIT,
                props.intl.formatMessage({id: 'characters_too_long'})].join(' ');

            setValidationErrors({
                ...validationErrors,
                description: errText
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                description: undefined
            });
        }
    };

    const editorInit = (event, editor) => {
        setEditor(editor);
    };

    const handleVideoUrlInputChange = index => event => {
        if (validationErrors.newVideos && validationErrors.newVideos[index]) {
            const clearError = [...validationErrors.newVideos];
            clearError[index] = undefined;
            setValidationErrors({...validationErrors, newVideos: clearError});
        }

        const newState = [...newVideos];
        newState[index] = event.target.value;
        setNewVideos(newState);
    };

    const addNewVideoUrl = () => {
        if (ideaState.embeddableVideos.length + newVideos.length >= 5)
            return;

        const newState = [...newVideos];
        newState.push('');
        setNewVideos(newState);
    };

    const removeVideoUrl = index => () => {
        if (validationErrors.newVideos) {
            const clearError = [...validationErrors.newVideos];
            clearError.splice(index, 1);
            setValidationErrors({...validationErrors, newVideos: clearError});
        }

        const newState = [...newVideos];
        newState.splice(index, 1);
        setNewVideos(newState);
    };

    const isVideoUrlInvalid = index => {
        return validationErrors.newVideos && validationErrors.newVideos[index];
    };

    const getVideoUrlErrorText = index => {
        return isVideoUrlInvalid(index) && props.intl.formatMessage({id: 'invalid_video_url'});
    };

    const selectFile = event => {
        const file = event.target.files[0];
        if (file) {
            setNewFiles([...newFiles, file]);
        }
    };

    const selectFeaturedImg = event => {
        const file = event.target.files[0];
        if (file) {
            setNewFeaturedImg(file);
        }
    };

    const removeFile = index => () => {
        setNewFiles(newFiles.filter((file, i) => i !== index));
    };

    const removeExistingAttachment = fileId => () => {
        setIdeaState({
            ...ideaState,
            fileIds: ideaState.fileIds.filter(id => id !== fileId)
        })
    };

    const removeExistingVideo = videoUrl => () => {
        setIdeaState({
            ...ideaState,
            embeddableVideos: ideaState.embeddableVideos.filter(url => url !== videoUrl)
        })
    };

    const removeFeaturedImage = () => {
        setIdeaState({
            ...ideaState,
            featuredImageId: undefined
        })
    };

    const successCallback = () => {
        history.push(`/idea/${idea.id}`);
        setLoading(false);
    };

    const errorCallback = () => {
        setLoading(false);
    };

    const changedAnswers = () => {
        const originalState = idea.answers.sort(a => a.questionId);
        const newState = answers.sort(a => a.questionId);

        return differenceWith(newState, originalState, isEqual);
    };

    const handleSubmit = () => {
        if (validateInput()) {
            const body = new FormData();
            body.append('id', idea.id);
            body.append('version', idea.version);
            body.append('crowdId', idea.crowdId);
            body.append('commentCount', idea.commentCount);
            body.append('name', ideaState.name);
            body.append('description', editor.getContent().trim());
            body.append('fileIds', ideaState.fileIds);
            body.append('categoryId', ideaState.category.id);

            const answersDiff = changedAnswers();
            if (answersDiff.length)
                body.append('answers', JSON.stringify(answersDiff));

            newFiles.forEach(file => body.append('attachments', file));

            ideaState.embeddableVideos
                .concat(newVideos.filter(v => v.trim() !== ''))
                .forEach(url => body.append('embeddableVideos[]', url));

            if (ideaState.featuredImageId !== undefined)
                body.append('featuredImageId', ideaState.featuredImageId);

            if (newFeaturedImg)
                body.append('newFeaturedImg', newFeaturedImg);

            setLoading(true);
            updateIdea(body, successCallback, errorCallback);
        }
    };

    if (idea.id === undefined)
        return <Spinner/>;

    if (idea.ownerId !== user.id)
        history.push('/');

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <WbIncandescentOutlinedIcon style={{transform: 'rotate(180deg)'}}/>
                </Avatar>
            }
            title={<Typography className={'cardHeaderText'}>{props.intl.formatMessage({id: 'edit_idea'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'create_new_idea_name'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_name_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.gutter}>
                    <TextField
                        label={props.intl.formatMessage({id: 'idea_name'})}
                        variant="outlined"
                        name='name'
                        margin="dense"
                        fullWidth={true}
                        error={validationErrors['name']}
                        helperText={validationErrors['name'] && props.intl.formatMessage({id: validationErrors['name']})}
                        value={ideaState['name']}
                        onChange={handleInputChange('name')}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'create_new_idea_description'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_description_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Editor
                        apiKey={TINY_MCE_API_KEY}
                        initialValue={idea.description}
                        onInit={editorInit}
                        onEditorChange={handleEditorChange}
                        init={{
                            height: 400,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar_mode: 'wrap',
                            mobile: {
                                toolbar_mode: 'wrap'
                            },
                            toolbar:
                                'undo redo | formatselect | bold italic | forecolor backcolor | \
                                alignleft aligncenter alignright alignjustify | outdent indent | \
                                bullist numlist | code | help'
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Grow in={validationErrors.description}>
                        <Typography variant={'caption'} className={clsx(classes.errorText, classes.centerOnMobile)}>
                            {validationErrors.description}
                        </Typography>
                    </Grow>
                </Grid>
                <Grid item xs={12} className={classes.gutter}/>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'create_new_idea_category'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_category_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.gutter}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="category_select_label">
                            {props.intl.formatMessage({id: 'category'})}
                        </InputLabel>
                        <Select
                            labelId="category_select_label"
                            id="category_select"
                            fullWidth
                            label={props.intl.formatMessage({id: 'category'})}
                            value={ideaState.category}
                            onChange={categorySelected}
                        >
                            {categories.data.map(value =>
                                <MenuItem key={value.id}
                                          value={value}>{value.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                {questionnaire.questions && <>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {props.intl.formatMessage({id: 'create_idea_company_questions'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            {props.intl.formatMessage({id: 'create_idea_owner_would_like'})}
                        </Typography>
                    </Grid>
                    {questionnaire.questions.map((question, index) =>
                        <Grid item xs={12} className={classes.gutter}>
                            <Typography style={{paddingBottom: '10px'}}>
                                {question.questionText}
                            </Typography>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id={`idea-creation-question${index}`}>
                                    {props.intl.formatMessage({id: 'choose_one'})}
                                </InputLabel>
                                <Select
                                    margin="dense"
                                    labelId={`idea-creation-question${index}`}
                                    id={`idea-creation-question${index}`}
                                    fullWidth
                                    label={props.intl.formatMessage({id: 'choose_one'})}
                                    value={(answers[index] && answers[index].answer) || ''}
                                    onChange={answerSelected(index)}
                                >
                                    {question.options.map((option, optionIndex) =>
                                        <MenuItem key={`idea-creation-question${index}-${optionIndex}`}
                                                  value={option}>{option}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </>}
                <Grid item xs={12} className={classes.gutter}/>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'edit_idea_current_featured_img_hint'})}
                    </Typography>
                </Grid>
                {!(ideaState.featuredImageId === undefined || ideaState.featuredImageId === null) ? (
                    <Grid item xs={12}>
                        <div style={{textAlign: 'center'}}>
                            <img className={classes.image} loading="lazy"
                                 src={`${API_URL}/file?fileId=${idea.featuredImageId}`}/>
                            <IconButton color='primary' className={classes.deleteMainImageButton}
                                        onClick={removeFeaturedImage}>
                                <CancelIcon/>
                            </IconButton>
                        </div>
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <div style={{textAlign: 'center', backgroundColor: '#fafafa', color: '#f1f1f1'}}>
                            <SvgIcon style={{height: '230px', width: '230px'}}>
                                <svg focusable="false"
                                     role="img" xmlns="http://www.w3.org/2000/svg"
                                     viewBox="0 0 512 512">
                                    <path
                                        d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z"/>
                                    <path
                                        d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z"/>
                                </svg>
                            </SvgIcon>
                        </div>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_featured_img_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={selectFeaturedImg}
                        id="idea-featured-img"
                        type="file"
                    />
                    <label htmlFor="idea-featured-img">
                        <Button variant="outlined" color="primary" component="span" className={classes.outlinedButton}>
                            {props.intl.formatMessage({id: 'select_file'})}
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <Typography className={classes.centerOnMobile}>
                        {newFeaturedImg ?
                            newFeaturedImg.name :
                            props.intl.formatMessage({id: 'no_document_selected'})}
                    </Typography>
                </Grid>
                {validationErrors['featuredImg'] && (
                    <Grid item xs={12}>
                        <Typography variant={'caption'} className={clsx(classes.errorText, classes.centerOnMobile)}>
                            {props.intl.formatMessage({id: validationErrors['featuredImg']})}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.gutter}/>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'edit_idea_videos'})}
                    </Typography>
                </Grid>
                {ideaState.embeddableVideos.length > 0 && (
                    <Grid item xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                          spacing={2}
                          className={classes.gutter}
                    >
                        {ideaState.embeddableVideos.map(url => <Grid item xs={12} sm={6} md={4} lg={3}>
                            <div className={classes.videoWrapper}>
                                <IconButton color='primary' className={classes.deleteVideoButton}
                                            onClick={removeExistingVideo(url)}>
                                    <CancelIcon/>
                                </IconButton>
                                <ReactPlayer width="100%" height="100%" url={url} controls/>
                            </div>
                        </Grid>)}
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_videos_hint'})}
                    </Typography>
                </Grid>
                {ideaState.embeddableVideos.length < 5 && <>
                    {newVideos.map((value, index) => <Grid item xs={12} style={{display: 'flex'}}>
                        <TextField
                            label={`${props.intl.formatMessage({id: 'embeddable_video_url'})} ${index + 1}`}
                            variant="outlined"
                            name={`videoUrl${index}`}
                            margin="dense"
                            style={{flexGrow: 1}}
                            error={isVideoUrlInvalid(index)}
                            helperText={getVideoUrlErrorText(index)}
                            value={value}
                            onChange={handleVideoUrlInputChange(index)}/>
                        {newVideos.length > 1 && (
                            <IconButton color='secondary' onClick={removeVideoUrl(index)}>
                                <CancelIcon/>
                            </IconButton>
                        )}
                    </Grid>)}
                    <Grid item xs={12}>
                        <Button color='primary' variant="contained" className={classes.button}
                                startIcon={<AddCircleIcon/>}
                                onClick={addNewVideoUrl}>
                            {props.intl.formatMessage({id: 'add_new_video_url'})}
                        </Button>
                    </Grid>
                </>
                }
                <Grid item xs={12} className={classes.gutter}/>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'edit_idea_attachments'})}
                    </Typography>
                </Grid>
                {ideaState.fileIds.length > 0 && (
                    <Grid item xs={12}
                          container
                          direction="row"
                          justify="flex-start"
                          alignItems="center"
                          spacing={2}
                          className={classes.gutter}
                    >
                        {ideaState.fileIds.map(id => <Grid item xs={12} sm={6} md={4} lg={3}>
                            <div className={classes.attachment}
                                 style={{backgroundImage: `url(${API_URL}/file?fileId=${id})`}}>
                                <IconButton color='primary' className={classes.deleteAttachmentButton}
                                            onClick={removeExistingAttachment(id)}>
                                    <CancelIcon/>
                                </IconButton>
                            </div>
                        </Grid>)}
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_files_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <input
                        disabled={ideaState.fileIds.length + newFiles.length >= 5}
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={selectFile}
                        id="idea-file"
                        type="file"
                    />
                    <label htmlFor="idea-file">
                        <Button disabled={ideaState.fileIds.length + newFiles.length >= 5}
                                variant="outlined"
                                className={classes.outlinedButton}
                                color="primary" component="span">
                            {props.intl.formatMessage({id: 'select_file'})}
                        </Button>
                    </label>
                </Grid>
                {newFiles.length > 0 && (
                    <Grid item xs={12} sm={'auto'}>
                        <List dense>
                            {newFiles.map((file, index) =>
                                <ListItem>
                                    <ListItemText primary={file.name}/>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" onClick={removeFile(index)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                )}
                {validationErrors['files'] && (
                    <Grid item xs={12}>
                        <Typography variant={'caption'} className={clsx(classes.errorText, classes.centerOnMobile)}>
                            {props.intl.formatMessage({id: validationErrors['files']})}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.gutter}/>
                <Grid item xs={12} sm='auto'>
                    <div className={'progressWrapper'}>
                        <Button color='primary'
                                variant="contained"
                                className={classes.button}
                                disabled={loading}
                                onClick={handleSubmit}>
                            {props.intl.formatMessage({id: 'save'})}
                            {loading && <CircularProgress size={24} className={'buttonProgress'}/>}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
};

export default compose(
    withAuthentication,
    injectIntl
)(IdeaEdit);