import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {injectIntl} from "react-intl";

const headCells = [
    {id: 'name', i18nLabel: 'category_name', width: '70%', align: 'left'},
    {id: 'ideasCount', i18nLabel: 'category_ideasCount', width: '10%', align: 'center'},
    {id: 'actions', i18nLabel: '', width: '20%'}
];

const IdeaCategoriesTableHead = props => {
    const {order, orderBy, onRequestSort} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return <TableHead>
        <TableRow>
            {headCells.map(headCell => <TableCell
                key={headCell.id}
                align={headCell.align}
                sortDirection={orderBy === headCell.id ? order : false}
                style={{width: headCell.width}}
            >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.i18nLabel !== '' && props.intl.formatMessage({id: headCell.i18nLabel})}
                </TableSortLabel>
            </TableCell>)}
        </TableRow>
    </TableHead>;
};

export default injectIntl(IdeaCategoriesTableHead);