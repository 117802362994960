import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import SelectLanguageSidebar from "../common/selectlanguage/SelectLanguageSidebar";
import capitalize from "@material-ui/core/utils/capitalize";
import Avatar from "@material-ui/core/Avatar";
import {CROWDEA_ORIGIN} from "../../constants";
import {useRedirectWithState} from "../../hooks/useRedirectWithState";

const useStyles = makeStyles(theme => ({
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: 240,
            flexShrink: 0
        },
    },
    drawerPaper: {
        width: 240,
        background: 'linear-gradient(to right, #1EB1F1, #27D2F8)'
    },
    toolbar: theme.mixins.toolbar,
    link: {
        textDecoration: 'none',
    },
    linkText: {
        fontSize: '23px',
        color: 'white'
    },
    menuItem: {
        color: 'white',
        fontSize: '28px'
    },
    notificationsBadge: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        width: '30px',
        height: '30px',
        marginLeft: '5px'
    },
    pointer: {
        cursor: 'pointer'
    }
}));

const CrowdSidebar = props => {

    const classes = useStyles();
    const {user, notifications, intl, open, toggleDrawer, closeDrawer, logout, userRoles, crowdId} = props;
    const redirectTo = useRedirectWithState();

    const handleDashboardClick = () => {
        window.location.href = `${CROWDEA_ORIGIN}/crowds/${crowdId}`;
    };

    const shouldRenderDashboard = () => {
        return userRoles.data[crowdId];
    };

    return <>
        <nav className={classes.drawer}>
            <Hidden lgUp>
                <Drawer
                    variant="temporary"
                    anchor="right"
                    open={open}
                    onClose={toggleDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                        keepMounted: true
                    }}
                >
                    <div onClick={closeDrawer} onKeyDown={closeDrawer} className={classes.toolbar}>
                        <ListItem button key={'menu'}>
                            <Typography className={classes.menuItem}>
                                {intl.formatMessage({id: 'menu'})}
                            </Typography>
                        </ListItem>
                        <Divider/>
                        <List>
                            <Link to='/createIdea' className={classes.link}>
                                <ListItem button key={'introduction'}>
                                    <Typography className={classes.linkText}>
                                        {intl.formatMessage({id: 'add_idea'})}
                                    </Typography>
                                </ListItem>
                            </Link>
                            <Link to='/account/notificationHistory' className={classes.link}>
                                <ListItem button key={'notifications'}>
                                    <Typography className={classes.linkText}>
                                        {capitalize(intl.formatMessage({id: 'notifications'}))}
                                    </Typography>
                                    {notifications.unreadCount > 0 &&
                                    <Avatar className={classes.notificationsBadge}>
                                        {notifications.unreadCount}
                                    </Avatar>
                                    }
                                </ListItem>
                            </Link>
                            {user.loggedIn ? (<>
                                    <Link to='/account' className={classes.link}>
                                        <ListItem button key={'account'}>
                                            <Typography className={classes.linkText}>
                                                {intl.formatMessage({id: 'my_account'})}
                                            </Typography>
                                        </ListItem>
                                    </Link>
                                    {shouldRenderDashboard() &&
                                    <ListItem button key={'account'} onClick={handleDashboardClick}>
                                        <Typography className={classes.linkText}>
                                            {intl.formatMessage({id: 'dashboard'})}
                                        </Typography>
                                    </ListItem>}
                                    <Divider/>
                                    <Link to='' className={classes.link}>
                                        <ListItem button key={'logout'} onClick={logout}>
                                            <Typography className={classes.linkText}>
                                                {intl.formatMessage({id: 'logout'})}
                                            </Typography>
                                        </ListItem>
                                    </Link>
                                </>
                            ) : (
                                <ListItem button key={'login'} onClick={() => redirectTo('/login')}
                                          className={classes.pointer}>
                                    <Typography className={classes.linkText}>
                                        {intl.formatMessage({id: 'login'})}
                                    </Typography>
                                </ListItem>
                            )}
                            <Divider/>
                            <SelectLanguageSidebar/>
                        </List>
                    </div>
                </Drawer>
            </Hidden>
        </nav>
    </>;
};

export default injectIntl(CrowdSidebar);