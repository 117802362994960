import React from 'react';
import {Locale} from "../../../constants"
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";

export const SelectLanguageMenu = props => {

    return <Popper open={Boolean(props.anchorEl)} anchorEl={props.anchorEl} role={undefined} transition disablePortal>
        {({TransitionProps}) => (
            <Grow {...TransitionProps} style={{transformOrigin: 'center bottom'}}>
                <Paper>
                    <ClickAwayListener onClickAway={() => props.handleClose(props.currentLocale)}>
                        <MenuList>
                            <MenuItem onClick={() => props.handleClose(Locale.EN)}>
                                <Typography>
                                    English
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={() => props.handleClose(Locale.SK)}>
                                <Typography>
                                    Slovensky
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>;
};