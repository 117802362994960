import {injectIntl} from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";


const SubscriptionLimitReachedDialog = props => {

    const {open, onClose, onSubmit} = props;

    const handleClose = () => {
        onClose();
    };

    const handleSubmit = () => {
        onSubmit();
        handleClose();
    };

    return <>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth='lg'
            fullWidth
            scroll='body'>
            <DialogTitle>
                {props.intl.formatMessage({id: 'crowd_limit_reached'})}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {props.intl.formatMessage({id: 'ideas_subscription_limit_reached'})}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={handleClose}>
                    {props.intl.formatMessage({id: 'close'})}
                </Button>
                <Button color='primary' variant="contained" style={{color: 'white'}} onClick={handleClose}>
                    {props.intl.formatMessage({id: 'notify_crowd_owners'})}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
};

export default injectIntl(SubscriptionLimitReachedDialog);