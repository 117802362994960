import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";

export function createCategory(body, crowdId, successCallback) {
    return async dispatch => {
        secureFetch(API_URL + "/category",
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    ...body,
                    crowdId
                })
            })
            .then(response => extractBodyOrThrow(response))
            .then(category => {
                dispatch(categoryLoaded(category, crowdId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "category_created", 3000));
                if (successCallback)
                    successCallback();
            })
            .catch(error => console.log(error.message));
    };
}

function categoryLoaded(item, crowdId) {
    return {
        type: ActionType.CATEGORY_LOADED,
        item,
        crowdId
    };
}

export function loadCategories(crowdId) {
    return async dispatch => {
        secureFetch(API_URL + "/category/" + crowdId,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(categories => dispatch(categoriesLoaded(categories, crowdId)))
            .catch(error => console.log(error.message));
    };
}

function categoriesLoaded(items, crowdId) {
    return {
        type: ActionType.CATEGORIES_LOADED,
        items,
        crowdId
    };
}

export function updateCategory(category, crowdId, successCallback) {
    return async dispatch => {
        secureFetch(API_URL + "/category",
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(category)
            })
            .then(response =>
                response.json().then(updatedCategory => {
                    dispatch(categoryUpdated(updatedCategory, crowdId));
                    dispatch(emitAppNotification(NotificationType.SUCCESS, "category_update_successful", 3000));
                    if (successCallback)
                        successCallback();
                })
            ).catch(error => console.log(error.message))
    };
}

function categoryUpdated(item, crowdId) {
    return {
        type: ActionType.CATEGORY_UPDATED,
        item,
        crowdId
    };
}

export function deleteCategory(categoryId, crowdId, successCallback) {
    return async dispatch => {
        secureFetch(`${API_URL}/category/${crowdId}/${categoryId}`,
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(() => {
                dispatch(categoryDeleted(categoryId, crowdId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "category_deleted", 3000));
                if (successCallback)
                    successCallback();
            })
            .catch(error => console.log(error.message));
    };
}

function categoryDeleted(categoryId, crowdId) {
    return {
        type: ActionType.CATEGORY_DELETED,
        categoryId,
        crowdId
    };
}