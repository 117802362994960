import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import Button from "@material-ui/core/Button";
import {useIntl} from "react-intl";
import {makeStyles} from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import {API_URL} from "../../constants";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ChatBubbleOutlinedIcon from '@material-ui/icons/ChatBubbleOutlined';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import {determineIdeaTagColor, extractPreviewTextFromHtml, formatDate, shortenString} from "../../utils/utils"
import {changeIdeaState} from "../../actions/idea";
import {connect} from "react-redux";
import CategoryIcon from '@material-ui/icons/Category';
import clsx from "clsx";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import Grid from "@material-ui/core/Grid";
import useDeepCompareEffect from "use-deep-compare-effect";
import {createIdeaRating} from "../../actions/ideaRating";
import {StyledRating} from "../common/StyledRating ";
import ShareIcon from '@material-ui/icons/Share';
import {IdeaStateMenu} from "../ideaAdmin/IdeaStateMenu";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    chips: {
        color: 'white',
        marginLeft: '10px',
        marginBottom: '5px',
        '& svg.MuiChip-deleteIcon': {
            color: 'white'
        }
    },
    tagButton: {
        position: 'absolute',
        right: '10px',
        marginTop: '16px'
    },
    tagButtonWrapper: {
        position: "relative",
        width: '100%'
    },
    ideaName: {
        color: theme.palette.indigo.dark,
        fontSize: '24px',
        fontFamily: 'Open Sans',
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
        }
    },
    textContent: {
        height: '190px'
    },
    ideaStatWrapper: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    editButton: {
        color: 'white',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    defaultPictureWrapper: {
        textAlign: 'center',
        backgroundColor: '#fafafa',
        color: '#f1f1f1',
        height: '230px'
    }
}));


const IdeaPreview = props => {
    const classes = useStyles();
    const intl = useIntl();
    const {
        user,
        idea,
        ideaAuthored,
        ideaLiked,
        ideaCommented,
        likeClickHandler,
        changeIdeaState,
        isAdmin,
        rateIdea,
        openShareDialog
    } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [rating, setRating] = React.useState(0);

    useDeepCompareEffect(() => {
        if (idea.rating)
            setRating(parseFloat(idea.rating).toFixed(1));
    }, [idea]);

    const ideaTags = idea.tags || [];

    const openMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleIdeaStateChange = ideaState => {
        if (idea.ideaState === ideaState)
            return;

        const body = {
            id: idea.id,
            crowdId: idea.crowdId,
            version: idea.version,
            ideaState
        };
        changeIdeaState(body);
    };

    const submitIdeaRating = value => {
        rateIdea(idea.id, value)
    };

    const renderButton = () => {
        if (window.innerWidth < 450)
            return <Button color='primary' variant="contained"
                           onClick={openMenu} className={classes.tagButton} style={{color: 'white'}}>
                <CategoryIcon/>
            </Button>;
        else
            return <Button color='primary' variant="contained" startIcon={<CategoryIcon/>}
                           onClick={openMenu} className={classes.tagButton} style={{color: 'white'}}>
                {intl.formatMessage({id: 'change_idea_state_button'})}
            </Button>
    };

    const getChips = () => {
        const tags = idea.ideaState && idea.ideaState !== '' ? [idea.ideaState, ...ideaTags] : [...ideaTags];
        return tags.map(tag => <Chip size="small"
                                         label={intl.formatMessage({id: tag.toLowerCase()})}
                                         className={clsx(classes.chips, determineIdeaTagColor(tag))}/>);
    };

    const composeDescription = () => {
        if (!idea.description || idea.description === '')
            return intl.formatMessage({id: 'no_description_available'});
        return shortenString(extractPreviewTextFromHtml(idea.description), 120);
    };

    return <>
        <Card elevation={3} style={{overflow: 'visible', position: 'relative'}}>
            <div style={{position: 'absolute', marginTop: '-12px'}}>
                {getChips()}
            </div>
            {isAdmin &&
            <div className={classes.tagButtonWrapper}>
                {renderButton()}
            </div>
            }
            <Link to={`/idea/${idea.id}`} style={{textDecoration: 'none', color: 'black'}}>
                {idea.featuredImageId ? (
                    <CardMedia style={{height: '230px'}}
                               image={`${API_URL}/file?fileId=${idea.featuredImageId}`}/>
                ) : (<div className={classes.defaultPictureWrapper}>
                        <SvgIcon style={{height: '230px', width: '230px'}}>
                            <svg aria-hidden="true" focusable="false"
                                 role="img" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 512 512">
                                <path
                                    d="M368,224c26.5,0,48-21.5,48-48c0-26.5-21.5-48-48-48c-26.5,0-48,21.5-48,48C320,202.5,341.5,224,368,224z"/>
                                <path
                                    d="M452,64H60c-15.6,0-28,12.7-28,28.3v327.4c0,15.6,12.4,28.3,28,28.3h392c15.6,0,28-12.7,28-28.3V92.3   C480,76.7,467.6,64,452,64z M348.9,261.7c-3-3.5-7.6-6.2-12.8-6.2c-5.1,0-8.7,2.4-12.8,5.7l-18.7,15.8c-3.9,2.8-7,4.7-11.5,4.7   c-4.3,0-8.2-1.6-11-4.1c-1-0.9-2.8-2.6-4.3-4.1L224,215.3c-4-4.6-10-7.5-16.7-7.5c-6.7,0-12.9,3.3-16.8,7.8L64,368.2V107.7   c1-6.8,6.3-11.7,13.1-11.7h357.7c6.9,0,12.5,5.1,12.9,12l0.3,260.4L348.9,261.7z"/>
                            </svg>
                        </SvgIcon>
                    </div>
                )}
                <CardContent className={classes.textContent}>
                    <Typography gutterBottom className={classes.ideaName}>
                        {shortenString(idea.name, 24)}
                    </Typography>
                    <Typography gutterBottom variant="body2" color="textSecondary">
                        {composeDescription()}
                    </Typography>
                </CardContent>
                <CardContent style={{padding: '0 16px', height: '48px'}}>
                    {ideaAuthored ? (
                        <Link to={`/idea/${idea.id}/edit`} style={{textDecoration: 'none'}}>
                            <Button color='primary' variant="contained" className={classes.editButton}>
                                {intl.formatMessage({id: 'edit'})}
                            </Button>
                        </Link>
                    ) : (
                        <Typography>
                            {intl.formatMessage({id: 'created_by'})} {idea.creatorName} {intl.formatMessage({id: 'on'})} {formatDate(idea.createdAt)}
                        </Typography>
                    )}
                </CardContent>
            </Link>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item>
                        <StyledRating precision={0.1}
                                      value={rating}
                                      readOnly={!user.loggedIn}
                                      onChange={(event, newValue) => submitIdeaRating(newValue)}/>
                    </Grid>
                    <Grid item>
                        {rating !== 0 ? <Typography style={{fontWeight: 'bold', opacity: 0.5}}>
                            {intl.formatMessage({id: 'rating'})}:&nbsp;{rating}
                        </Typography> : <Typography style={{fontWeight: 'bold', opacity: 0.5}}>
                            {intl.formatMessage({id: 'no_rating_yet'})}
                        </Typography>}

                    </Grid>
                </Grid>
            </CardContent>
            <CardContent style={{paddingBottom: '8px', textAlign: 'end'}}>
                        <span className={classes.ideaStatWrapper}>
                            <div style={{padding: '12px', display: 'flex'}}>
                                <VisibilityOutlinedIcon color='primary'/>
                            </div>
                            <Typography component='span' color='primary' style={{fontSize: '24px'}}>
                                {idea.viewCount}
                            </Typography>
                        </span>
                <Link to={`/idea/${idea.id}`} className={classes.ideaStatWrapper}
                      style={{textDecoration: 'none'}}>
                    <IconButton color='primary'>
                        {ideaCommented ? <ChatBubbleOutlinedIcon/> : <ChatBubbleOutlineIcon/>}
                    </IconButton>
                    <Typography component='span' color='primary'
                                style={{fontSize: '24px', display: 'flex', alignItems: 'center'}}>
                        {idea.commentCount}
                    </Typography>
                </Link>
                <span className={classes.ideaStatWrapper}>
                            <IconButton color='primary' onClick={likeClickHandler}>
                                {ideaLiked ? <FavoriteIcon/> : <FavoriteBorderIcon/>}
                            </IconButton>
                            <Typography component='span' color='primary' style={{fontSize: '24px'}}>
                                {idea.likeCount}
                            </Typography>
                        </span>
                <span className={classes.ideaStatWrapper} style={{marginRight: '10px'}}>
                            <IconButton color='primary' onClick={openShareDialog}>
                                <ShareIcon/>
                            </IconButton>
                            <Typography component='span' color='primary' style={{fontSize: '24px'}}>
                                {idea.shareCount}
                            </Typography>
                        </span>
            </CardContent>
        </Card>
        <IdeaStateMenu handleClose={handleClose} anchorEl={anchorEl} handleSubmit={handleIdeaStateChange}/>
    </>
};

const mapDispatchToProps = dispatch => {
    return {
        changeIdeaState: body => dispatch(changeIdeaState(body)),
        rateIdea: (ideaId, value) => dispatch(createIdeaRating(ideaId, value))
    };
};

export default connect(null, mapDispatchToProps)(IdeaPreview);
