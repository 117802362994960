import {store} from "../store/configureStore";
import {secureFetch} from "../http/secureFetch";
import {ActionType, API_URL, NotificationType} from "../constants";
import {emitAppNotification, extractBodyOrThrow} from "./common";

export function addCrowdUsers(crowdId, body, successCb, errorCb) {
    return secureFetch(API_URL + "/crowdUsers",
        {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...body,
                crowdId
            })
        })
        .then(response => extractBodyOrThrow(response))
        .then(({nonExistingMails, roles}) => {

            if (roles.length) {
                store.dispatch(emitAppNotification(NotificationType.SUCCESS, "crowd_users_added_successfully", 3000));
                store.dispatch(crowdUsersAdded(roles, crowdId));
                successCb();

                if (nonExistingMails.length) {
                    setTimeout(() => store.dispatch(emitAppNotification(NotificationType.WARNING, "validation_crowd_users_do_not_exist_some", 3000)), 3000);
                }
            } else {
                if (nonExistingMails.length) {
                    errorCb();
                } else {
                    store.dispatch(emitAppNotification(NotificationType.WARNING, "crowd_users_already_exist", 3000));
                }
            }
        }).catch(error => console.log(error.message));
}

function crowdUsersAdded(items, crowdId) {
    return {
        type: ActionType.CROWD_USERS_ADDED,
        items,
        crowdId
    };
}

export function loadCrowdUsers(crowdId) {
    return async dispatch => {
        secureFetch(API_URL + "/crowdUsers/" + crowdId,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => extractBodyOrThrow(response))
            .then(crowdUsers => dispatch(crowdUsersLoaded(crowdUsers, crowdId)))
            .catch(error => console.log(error.message));
    };
}

function crowdUsersLoaded(items, crowdId) {
    return {
        type: ActionType.CROWD_USERS_LOADED,
        items,
        crowdId
    };
}

export function updateCrowdUsers(crowdUsers, crowdId) {
    return async dispatch => {
        secureFetch(API_URL + "/crowdUsers",
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    crowdUsers,
                    crowdId
                })
            })
            .then(response => extractBodyOrThrow(response))
            .then(updatedCrowdUsers => {
                dispatch(crowdUsersUpdated(updatedCrowdUsers, crowdId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "crowd_users_updated_successfully", 3000));
                if(updatedCrowdUsers.length < crowdUsers.length)
                    setTimeout(() => dispatch(emitAppNotification(NotificationType.WARNING, "crowd_users_updated_some", 3000)), 3000);
            }).catch(error => console.log(error.message));
    };
}

function crowdUsersUpdated(items, crowdId) {
    return {
        type: ActionType.CROWD_USERS_UPDATED,
        items,
        crowdId
    };
}

export function deleteCrowdUser(crowdId, crowdUserId) {
    return async dispatch => {
        secureFetch(API_URL + "/crowdUsers",
            {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({crowdId, crowdUserId})
            })
            .then(() => {
                dispatch(crowdUserDeleted(crowdId, crowdUserId));
                dispatch(emitAppNotification(NotificationType.SUCCESS, "crowd_user_deleted", 3000));
            })
            .catch(error => console.log(error.message));
    };
}

function crowdUserDeleted(crowdId, crowdUserId) {
    return {
        type: ActionType.CROWD_USER_DELETED,
        crowdUserId,
        crowdId
    };
}