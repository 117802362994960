import React from "react";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {useHistory} from "react-router-dom";
import SidebarButton from "../layout/SidebarButton";
import DashboardNavbarMenu from "./DashboardNavbarMenu";

const useStyles = makeStyles(theme => ({
    appBar: {
        background: 'white'
    },
    toolBarDesktop: {
        height: '70px'
    },
    toolBarMobile: {
        height: '65px'
    },
    crowdeaLogo: {
        cursor: 'pointer',
        height: '30px'
    },
}));

const DashboardNavbar = props => {

    const classes = useStyles();
    const {toggleDrawer, logout, notifications, markNotificationsAsRead} = props;
    const history = useHistory();

    return <>
        <Hidden lgUp>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.toolBarMobile}>
                    <img className={classes.crowdeaLogo} src="/images/crowdea.png"
                         onClick={() => history.push('/')}/>
                    <Grid container
                          direction="row"
                          justify="flex-end"
                          alignItems="center"
                          spacing={4}
                    >
                        <SidebarButton toggleDrawer={toggleDrawer}/>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Hidden>
        <Hidden mdDown>
            <AppBar position="fixed" className={classes.appBar}>
                <Container maxWidth="xl" className={classes.navbarContainer}>
                    <Toolbar className={classes.toolBarDesktop}>
                        <img className={classes.crowdeaLogo} src="/images/crowdea.png"
                             onClick={() => history.push('/')}/>
                        <Grid container
                              direction="row"
                              justify="flex-end"
                              alignItems="center"
                              spacing={4}
                        >
                            <DashboardNavbarMenu logout={logout} notifications={notifications}
                                                 markNotificationsAsRead={markNotificationsAsRead}/>
                        </Grid>
                    </Toolbar>
                </Container>
            </AppBar>
        </Hidden>
    </>
};

export default injectIntl(DashboardNavbar);