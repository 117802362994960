import React from "react";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import {injectIntl} from "react-intl";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import {compose} from "redux";
import {withAuthentication} from "../common/withAuthentication";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import {IDEA_DESCRIPTION_LENGTH_LIMIT, TINY_MCE_API_KEY, ValidImageTypes} from "../../constants";
import clsx from "clsx";
import {useHistory, useLocation} from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import {Editor} from '@tinymce/tinymce-react';
import Grow from "@material-ui/core/Grow";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    avatar: {
        backgroundColor: theme.palette.primary.main
    },
    centerOnMobile: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        },
    },
    errorText: {
        color: 'red'
    },
    gutter: {
        marginBottom: '20px'
    },
    button: {
        color: 'white',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    outlinedButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
    mceToolbar: {
        '& div.tox-toolbar--scrolling': {
            flexWrap: 'wrap'
        },
    }
}));


const CreateIdea = props => {

    const classes = useStyles();
    const {categories, crowdId, createIdea, canCreate, questionnaire} = props;
    const location = useLocation();
    const preselectedCategoryId = location.state && location.state.categoryId;
    const [ideaState, setIdeaState] = React.useState({
        name: '',
        category: '',
        embeddableVideos: ['']
    });

    useDeepCompareEffect(() => {
        setIdeaState({
            ...ideaState,
            category: preselectedCategoryId ? categories.data.find(c => c.id === preselectedCategoryId) : categories.data[0]
        });
    }, [categories]);

    const initState = () => {
        if (questionnaire.questions) {
            return questionnaire.questions.map(q => ({questionId: q.id, answer: q.options[0]}));
        } else return [];
    };

    const [answers, setAnswers] = React.useState(initState());

    useDeepCompareEffect(() => {
        setAnswers(initState());
    }, [questionnaire]);

    const [editor, setEditor] = React.useState(null);
    const [files, setFiles] = React.useState([]);
    const [featuredImg, setFeaturedImg] = React.useState(false);
    const [validationErrors, setValidationErrors] = React.useState({});
    const [loading, setLoading] = React.useState(false);

    const validators = {
        name: () => {
            if (ideaState.name === '')
                return 'validation_idea_name';
            return true;
        },
        description: () => {
            const descLength = editor.getContent({format: 'text'}).trim().length;
            if (descLength === 0)
                return props.intl.formatMessage({id: 'validation_idea_description_empty'});
            if (descLength > IDEA_DESCRIPTION_LENGTH_LIMIT)
                return [props.intl.formatMessage({id: 'validation_idea_description_too_long'}),
                    descLength - IDEA_DESCRIPTION_LENGTH_LIMIT,
                    props.intl.formatMessage({id: 'characters_too_long'})].join(' ');
            return true;
        },
        files: () => {
            if (!files.every(img => ValidImageTypes.includes(img.type)))
                return 'validation_idea_files';
            return true;
        },
        featuredImg: () => {
            if (featuredImg && !ValidImageTypes.includes(featuredImg.type))
                return 'validation_idea_featuredImg';
            return true;
        }
    };

    const history = useHistory();

    const validateInput = () => {
        const result = Object.entries(validators)
            .reduce((acc, currentValue) => {
                const [key, validator] = currentValue;
                const output = validator();
                if (output !== true) {
                    acc[key] = output;
                }
                return acc;
            }, {});

        setValidationErrors(result);
        return Object.keys(result).length === 0;
    };

    const categorySelected = event => {
        event.preventDefault();
        setIdeaState({
            ...ideaState,
            category: event.target.value
        })
    };

    const answerSelected = index => event => {
        event.preventDefault();
        const newState = [...answers];
        newState[index].answer = event.target.value;
        setAnswers(newState);
    };

    const handleInputChange = name => event => {
        const clearError = {...validationErrors};
        delete clearError[name];
        setValidationErrors(clearError);
        setIdeaState({
            ...ideaState,
            [name]: event.target.value
        });
    };

    const handleEditorChange = (content, editor) => {
        const descLength = editor.getContent({format: 'text'}).trim().length;
        if (descLength > IDEA_DESCRIPTION_LENGTH_LIMIT) {
            const errText = [props.intl.formatMessage({id: 'validation_idea_description_too_long'}),
                descLength - IDEA_DESCRIPTION_LENGTH_LIMIT,
                props.intl.formatMessage({id: 'characters_too_long'})].join(' ');

            setValidationErrors({
                ...validationErrors,
                description: errText
            });
        } else {
            setValidationErrors({
                ...validationErrors,
                description: undefined
            });
        }
    };

    const editorInit = (event, editor) => {
        setEditor(editor);
    };

    const handleVideoUrlInputChange = index => event => {
        if (validationErrors.embeddableVideos && validationErrors.embeddableVideos[index]) {
            const clearError = [...validationErrors.embeddableVideos];
            clearError[index] = undefined;
            setValidationErrors({...validationErrors, embeddableVideos: clearError});
        }

        const newState = [...ideaState.embeddableVideos];
        newState[index] = event.target.value;
        setIdeaState({
            ...ideaState,
            embeddableVideos: newState
        });
    };

    const addNewVideoUrl = () => {
        if (ideaState.embeddableVideos.length === 5)
            return;

        const embeddableVideos = [...ideaState.embeddableVideos];
        embeddableVideos.push('');
        setIdeaState({
            ...ideaState,
            embeddableVideos
        });
    };

    const removeVideoUrl = index => () => {
        if (validationErrors.embeddableVideos) {
            const clearError = [...validationErrors.embeddableVideos];
            clearError.splice(index, 1);
            setValidationErrors({...validationErrors, embeddableVideos: clearError});
        }

        const embeddableVideos = [...ideaState.embeddableVideos];
        embeddableVideos.splice(index, 1);
        setIdeaState({
            ...ideaState,
            embeddableVideos
        });
    };

    const isVideoUrlInvalid = index => {
        return validationErrors.embeddableVideos && validationErrors.embeddableVideos[index];
    };

    const getVideoUrlErrorText = index => {
        return isVideoUrlInvalid(index) && props.intl.formatMessage({id: 'invalid_video_url'});
    };

    const selectFile = event => {
        const file = event.target.files[0];
        if (file) {
            setFiles([...files, file]);
        }
    };

    const selectFeaturedImg = event => {
        const file = event.target.files[0];
        if (file) {
            setFeaturedImg(file);
        }
    };

    const removeFile = index => () => {
        setFiles(files.filter((file, i) => i !== index));
    };

    const successCallback = () => {
        history.push('/');
        setLoading(false);
    };

    const errorCallback = () => {
        setLoading(false);
    };

    const handleSubmit = () => {
        if (validateInput()) {
            const body = new FormData();
            body.append('name', ideaState.name);
            body.append('description', editor.getContent().trim());
            body.append('categoryId', ideaState.category.id);
            body.append('crowdId', crowdId);

            if (questionnaire.questions)
                body.append('answers', JSON.stringify(answers));

            files.forEach(file => body.append('attachments', file));
            ideaState.embeddableVideos.filter(v => v.trim() !== '').forEach(video => body.append('embeddableVideos[]', video));

            if (featuredImg)
                body.append('featuredImg', featuredImg);

            setLoading(true);
            createIdea(body, successCallback, errorCallback);
        }
    };

    return <Card elevation={3}>
        <CardHeader
            avatar={
                <Avatar className={classes.avatar}>
                    <WbIncandescentOutlinedIcon style={{transform: 'rotate(180deg)'}}/>
                </Avatar>
            }
            title={<Typography
                className={'cardHeaderText'}>{props.intl.formatMessage({id: 'create_new_idea'})}</Typography>}
            className={'cardHeader'}
        />
        <CardContent className={'cardContent'}>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'create_new_idea_name'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_name_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.gutter}>
                    <TextField
                        label={props.intl.formatMessage({id: 'idea_name'})}
                        variant="outlined"
                        name='name'
                        margin="dense"
                        fullWidth={true}
                        error={validationErrors['name']}
                        helperText={validationErrors['name'] && props.intl.formatMessage({id: validationErrors['name']})}
                        value={ideaState['name']}
                        onChange={handleInputChange('name')}/>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'create_new_idea_description'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_description_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Editor
                        apiKey={TINY_MCE_API_KEY}
                        initialValue={''}
                        onInit={editorInit}
                        onEditorChange={handleEditorChange}
                        init={{
                            height: 400,
                            menubar: false,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar_mode: 'wrap',
                            mobile: {
                                toolbar_mode: 'wrap'
                            },
                            toolbar:
                                'undo redo | formatselect | bold italic | forecolor backcolor | \
                                alignleft aligncenter alignright alignjustify | outdent indent | \
                                bullist numlist | code | help'
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm>
                    <Grow in={validationErrors.description}>
                        <Typography variant={'caption'} className={clsx(classes.errorText, classes.centerOnMobile)}>
                            {validationErrors.description}
                        </Typography>
                    </Grow>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'create_new_idea_category'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_category_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="category_select_label">
                            {props.intl.formatMessage({id: 'category'})}
                        </InputLabel>
                        <Select
                            margin="dense"
                            labelId="category_select_label"
                            id="category_select"
                            fullWidth
                            label={props.intl.formatMessage({id: 'category'})}
                            value={ideaState.category}
                            onChange={categorySelected}
                        >
                            {categories.data.map(value =>
                                <MenuItem key={value.id}
                                          value={value}>{value.name}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} className={classes.gutter}/>
                {questionnaire.questions && <>
                    <Grid item xs={12}>
                        <Typography variant="h5">
                            {props.intl.formatMessage({id: 'create_idea_company_questions'})}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            {props.intl.formatMessage({id: 'create_idea_owner_would_like'})}
                        </Typography>
                    </Grid>
                    {questionnaire.questions.map((question, index) =>
                        <Grid item xs={12} className={classes.gutter}>
                            <Typography style={{paddingBottom: '10px'}}>
                                {question.questionText}
                            </Typography>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id={`idea-creation-question${index}`}>
                                    {props.intl.formatMessage({id: 'choose_one'})}
                                </InputLabel>
                                <Select
                                    margin="dense"
                                    labelId={`idea-creation-question${index}`}
                                    id={`idea-creation-question${index}`}
                                    fullWidth
                                    label={props.intl.formatMessage({id: 'choose_one'})}
                                    value={(answers[index] && answers[index].answer) || ''}
                                    onChange={answerSelected(index)}
                                >
                                    {question.options.map((option, optionIndex) =>
                                        <MenuItem key={`idea-creation-question${index}-${optionIndex}`}
                                                  value={option}>{option}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </>}
                <Grid item xs={12} className={classes.gutter}/>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'create_new_idea_featured_img'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_featured_img_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <input
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={selectFeaturedImg}
                        id="idea-featured-img"
                        type="file"
                    />
                    <label htmlFor="idea-featured-img">
                        <Button variant="outlined" color="primary" component="span" className={classes.outlinedButton}>
                            {props.intl.formatMessage({id: 'select_file'})}
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={12} sm={'auto'}>
                    <Typography className={classes.centerOnMobile}>
                        {featuredImg ?
                            featuredImg.name :
                            props.intl.formatMessage({id: 'no_document_selected'})}
                    </Typography>
                </Grid>
                {validationErrors['featuredImg'] && (
                    <Grid item xs={12}>
                        <Typography variant={'caption'} className={clsx(classes.errorText, classes.centerOnMobile)}>
                            {props.intl.formatMessage({id: validationErrors['featuredImg']})}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.gutter}/>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'create_new_idea_videos'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_videos_hint'})}
                    </Typography>
                </Grid>
                {ideaState.embeddableVideos.map((value, index) => <Grid item xs={12} style={{display: 'flex'}}>
                    <TextField
                        label={`${props.intl.formatMessage({id: 'embeddable_video_url'})} ${index + 1}`}
                        variant="outlined"
                        name={`videoUrl${index}`}
                        margin="dense"
                        style={{flexGrow: 1}}
                        error={isVideoUrlInvalid(index)}
                        helperText={getVideoUrlErrorText(index)}
                        value={value}
                        onChange={handleVideoUrlInputChange(index)}/>
                    {ideaState.embeddableVideos.length > 1 && (
                        <IconButton color='secondary' onClick={removeVideoUrl(index)}>
                            <CancelIcon/>
                        </IconButton>
                    )}
                </Grid>)}
                <Grid item xs={12}>
                    <Button color='primary' variant="contained" className={classes.button}
                            startIcon={<AddCircleIcon/>}
                            onClick={addNewVideoUrl}>
                        {props.intl.formatMessage({id: 'add_new_video_url'})}
                    </Button>
                </Grid>
                <Grid item xs={12} className={classes.gutter}/>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        {props.intl.formatMessage({id: 'create_new_idea_files'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography>
                        {props.intl.formatMessage({id: 'create_new_idea_files_hint'})}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <input
                        disabled={files.length >= 5}
                        accept="image/*"
                        style={{display: 'none'}}
                        onChange={selectFile}
                        id="idea-file"
                        type="file"
                    />
                    <label htmlFor="idea-file">
                        <Button disabled={files.length >= 5} variant="outlined" color="primary" component="span"
                                className={classes.outlinedButton}>
                            {props.intl.formatMessage({id: 'select_file'})}
                        </Button>
                    </label>
                </Grid>
                {files.length > 0 && (
                    <Grid item xs={12} sm={'auto'}>
                        <List dense>
                            {files.map((file, index) =>
                                <ListItem>
                                    <ListItemText primary={file.name}/>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" onClick={removeFile(index)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )}
                        </List>
                    </Grid>
                )}
                {validationErrors['files'] && (
                    <Grid item xs={12}>
                        <Typography variant={'caption'} className={clsx(classes.errorText, classes.centerOnMobile)}>
                            {props.intl.formatMessage({id: validationErrors['files']})}
                        </Typography>
                    </Grid>
                )}
                <Grid item xs={12} className={classes.gutter}/>
                <Grid item xs={12} sm='auto'>
                    <div className={'progressWrapper'}>
                        <Button color='primary' variant="contained" style={{color: 'white'}} onClick={handleSubmit}
                                disabled={!canCreate || loading}
                                className={classes.button}>
                            {props.intl.formatMessage({id: 'save'})}
                            {loading && <CircularProgress size={24} className={'buttonProgress'}/>}
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
};

export default compose(
    withAuthentication,
    injectIntl
)(CreateIdea);