import React from "react";
import {useIntl} from "react-intl";
import {isObject} from "../utils/utils";

export const useCommonTextFieldProps = (values, validationErrors, handleInputChange) => {
    const intl = useIntl();
    const simpleValue = !isObject(values);

    if (simpleValue)
        return name => ({
            label: intl.formatMessage({id: name}),
            variant: "outlined",
            name: name,
            margin: "dense",
            fullWidth: true,
            error: !!validationErrors,
            helperText: !!validationErrors && intl.formatMessage({id: validationErrors}),
            value: values,
            onChange: handleInputChange
        });
    else
        return (name, i18n) => ({
            label: intl.formatMessage({id: i18n ? i18n : name}),
            variant: "outlined",
            name: name,
            margin: "dense",
            fullWidth: true,
            error: validationErrors[name] !== undefined,
            helperText: validationErrors[name] && intl.formatMessage({id: validationErrors[name]}),
            value: values[name],
            onChange: handleInputChange(name)
        });
};