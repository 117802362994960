import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {injectIntl} from "react-intl";
import Hidden from "@material-ui/core/Hidden";

const headCells = [
    {id: 'name', i18nLabel: 'crowd_name', width: '35%'},
    {id: 'status', i18nLabel: 'crowd_status', width: '15%'},
    {id: 'plan', i18nLabel: 'crowd_plan', width: '30%'},
    {id: 'actions', i18nLabel: '', width: '20%'}
];

const CrowdsTableHead = props => {
    const {order, orderBy, onRequestSort} = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const renderCell = headCell => {
        return <TableCell
            key={headCell.id}
            align='left'
            sortDirection={orderBy === headCell.id ? order : false}
            style={{width: headCell.width}}
        >
            {headCell.id !== 'actions' && (
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.i18nLabel !== '' && props.intl.formatMessage({id: headCell.i18nLabel})}
                </TableSortLabel>
            )}
        </TableCell>
    };

    return <TableHead>
        <TableRow>
            {headCells.map(headCell => {
                    if (headCell.id === 'plan')
                        return <Hidden smDown>
                            {renderCell(headCell)}
                        </Hidden>;
                    return renderCell(headCell)
                }
            )}
        </TableRow>
    </TableHead>;
};

export default injectIntl(CrowdsTableHead);