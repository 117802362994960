import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {injectIntl} from "react-intl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {CROWDEA_HOSTNAME} from "../../constants";

const useStyles = makeStyles(theme => ({
    crowdeaLogo: {
        height: '60px'
    }
}));

const CrowdNotFound = props => {

    const classes = useStyles();
    const {messageCode = 'crowd_does_not_exist'} = props;

    return <Container maxWidth="xl">
        <Grid container
              direction="column"
              justify="center"
              style={{height: '100vh'}}>
            <Grid item xs/>
            <Grid item style={{textAlign: 'center'}}>
                <a href={`//${CROWDEA_HOSTNAME}`}>
                    <img className={classes.crowdeaLogo} src="/images/crowdea.png"/>
                </a>
            </Grid>
            <Grid item>
                <Typography variant="h6" align='center'>
                    {props.intl.formatMessage({id: messageCode})}
                </Typography>
            </Grid>
            <Grid item xs/>
        </Grid>
    </Container>;
};

export default injectIntl(CrowdNotFound);