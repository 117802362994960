import React from "react";
import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import {injectIntl} from "react-intl";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    bestValueCard: {
        overflow: 'visible'
    },
    bestValueCardBorder: {
        border: '2px solid transparent',
        borderColor: '#FEC42D',
    },
    bestValueCardHover: {
        '&:hover': {
            boxShadow: '0 0 20px -2px #FEC42D',
        }
    },
    bestValue: {
        backgroundColor: theme.palette.orange.main,
        borderRadius: '5px',
        color: "white",
        marginTop: '-18px',
        padding: '6px 16px',
        position: 'absolute'
    }
}));

const Concert = props => {

    const classes = useStyles();
    const {
        isLandingPage,
        buttonClickHandler,
        cardClickHandler,
        hideButtons,
        selected,
        hideBestValueOutline
    } = props;

    const cardElevation = isLandingPage ? 0 : 3;

    return <Card
        className={clsx('pricingCard',
            classes.bestValueCard,
            selected === 2 ? 'pricingCardSelected' : classes.bestValueCardHover,
            {
                'cursorPointer': selected !== undefined,
                [classes.bestValueCardBorder]: !hideBestValueOutline
            })}
        elevation={cardElevation} onClick={cardClickHandler}>
        <div style={{display: "flex", justifyContent: 'center'}}>
            <Typography className={classes.bestValue}>Best value</Typography>
        </div>
        <div className={'pricingCardImageWrapper'}>
            <img src="/images/concert.png"/>
        </div>
        <CardContent>
            <Typography variant={'h4'}
                        className={clsx('pricingCardHeaderText', 'pricingCardItemMargin')}>
                {props.intl.formatMessage({id: 'concert'})}
            </Typography>
            <div className={'pricingCardTextWrapper'}>
                <Typography color='primary' component={'span'}
                            className={clsx('pricingCardEuroSign', {'purpleColor': isLandingPage})}>
                    {'€'}
                </Typography>
                <Typography color='primary' component={'span'}
                            className={clsx('pricingCardPriceText', {'purpleColor': isLandingPage})}>
                    {'219'}
                </Typography>
            </div>
            <Typography className={'pricingCardMonthlyText'}>
                {props.intl.formatMessage({id: 'monthly'})}
            </Typography>
            {!hideButtons && <Button variant="contained" color="primary" disableElevation
                                     className={clsx('pricingCardButton', 'pricingCardItemMargin', {'purpleBackground': isLandingPage})}
                                     onClick={buttonClickHandler}>
                {props.intl.formatMessage({id: 'buy'})}
            </Button>}
            <div>&nbsp;</div>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'max_users'}, {number: '5000'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'max_ideas'}, {number: '500'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {'1 ' + props.intl.formatMessage({id: 'crowd_admin'}) + ' + 3 ' +
                props.intl.formatMessage({id: 'crowd_managers'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'third_level_domain'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'shared_crowdea_users'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'standard_support'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'private_crowd'})}
            </Typography>
            <Typography className={'pricingCardItemMargin'}>
                {props.intl.formatMessage({id: 'advanced_crowd_settings'})}
            </Typography>
        </CardContent>
    </Card>
};

export default injectIntl(Concert);