import React from 'react';
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import {injectIntl} from "react-intl";
import ListItem from "@material-ui/core/ListItem";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import capitalize from "@material-ui/core/utils/capitalize";
import {makeStyles} from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(4),
    }
}));

const CrowdUsersMenu = props => {

    const classes = useStyles();
    const {handleClose, deleteCrowdUser, handleStatusClick, allowedUserRoles, crowdUser = {}, anchorEl} = props;
    const [open, setOpen] = React.useState(false);

    const toggleSubList = event => {
        event.stopPropagation();
        setOpen(!open);
    };

    const handleDelete = () => {
        deleteCrowdUser(crowdUser.id);
        setOpen(false);
        handleClose();
    };

    const collapseAndClose = (crowdUser, value) => () => {
        setOpen(false);
        handleClose(crowdUser, value);
    };

    const changeStatus = crowdUser => () => {
        handleClose();
        handleStatusClick(crowdUser)();
        setOpen(false);
    };

    return <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} role={undefined} transition style={{width: '150px'}}>
        {({TransitionProps}) => (
            <Grow {...TransitionProps}>
                <Paper>
                    <ClickAwayListener onClickAway={collapseAndClose(crowdUser, undefined)}>
                        <MenuList>
                            <MenuItem key='delete' onClick={handleDelete}>
                                <Typography>
                                    {props.intl.formatMessage({id:'delete'})}
                                </Typography>
                            </MenuItem>
                            <MenuItem key='status' onClick={changeStatus(crowdUser)}>
                                <Typography>
                                    {props.intl.formatMessage({id: crowdUser.disabled === true ? 'enable' : 'disable'})}
                                </Typography>
                            </MenuItem>
                            <MenuItem button onClick={toggleSubList} style={{justifyContent: 'space-between'}}>
                                <Typography>
                                    {props.intl.formatMessage({id:'roles'})}
                                </Typography>
                                {open ? <ExpandLess/> : <ExpandMore/>}
                            </MenuItem>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {allowedUserRoles.map(value =>
                                        <ListItem key={value}
                                                  value={value}
                                                  className={classes.nested}
                                                  onClick={collapseAndClose(crowdUser, crowdUser.userType === value ? undefined : value)}>
                                            <Typography>
                                                {capitalize(props.intl.formatMessage({id: value.toLowerCase()}))}
                                            </Typography>
                                            {crowdUser.userType === value && <ListItemIcon style={{marginLeft: '15px'}}>
                                                <DoneIcon fontSize="small"/>
                                            </ListItemIcon>}
                                        </ListItem>
                                    )}
                                </List>
                            </Collapse>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )}
    </Popper>;
};

export default injectIntl(CrowdUsersMenu);